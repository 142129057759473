import react, { useState } from "react";
import appInfo from "./app-info";
import { useDispatch } from "react-redux";
import { TabAdd, UpdateSelected } from "../utils/slices/TabList";
import Store from "../utils/store";
import { modals } from "@mantine/modals";
import YearAndDate from "./YearAndDate";
import { SetYearAndDateFocus } from "../utils/slices/UserListSlice";
import { YearAndDateTable } from "../FunctionsCall/YearAndDate/YearAndDateTable";
import { Text } from "@mantine/core";
import { ChangeCompany } from "../FunctionsCall/ChangeCompany/ChangeCompany";
import AutoBillFormSE from "../Transaction/AutoBill/AutoBillFormSE";
import { ModalFunction } from "../utils/slices/ModalSlice";
import TransactionSalesForm from "../Transaction/SaleEntry/TransactionSalesForm";
import DataFreeze from "../Utility/AdvanceUtility/DataFreeze";
import FinYearDelete from "../Utility/DataUtility/FinYearDelete";
import BackUpForm from "../Utility/SystemUtility/BackUpForm";
import BatchMergeForm from "../Utility/AdvanceUtility/BatchMergeForm";
import ChangeMRPSalesRateForm from "../Utility/AdvanceUtility/ChangeMRPSalesRateForm";
import DataIntegrityForm from "../Utility/AdvanceUtility/DataIntegrityForm";
import DataExportForm from "../Utility/DataUtility/DataExportForm";
import DataimportForm from "../Utility/DataUtility/DataimportForm";
import OtherImportForm from "../Utility/DataUtility/OtherImportForm";
import FinYearBackupForm from "../Utility/DataUtility/FinYearBackupForm";
import FinYearRestoreForm from "../Utility/DataUtility/FinYearRestoreForm";
import NewFinYearForm from "../Utility/YearEnd/NewFinYearForm";
import MailingLetter from "../Utility/Personal Diary/MailingLetter";
import Reminder from "../Utility/Personal Diary/Reminder";
import BillFormChallanModal from "../Transaction/JobWorkOut/JobWorkOutBill/BillFormChallanModal";
import AddressBook from "../Utility/Personal Diary/AddressBook";
import { ChangeCompanyTable } from "../FunctionsCall/ChangeCompany/ChangeCompanyTable";
import UserFieldEquEntry from "../SetupComponent/AdvanceSetup/Equation/UserFieldEquEntry";
// import UserSecurity from "../SetupComponent/User/User Security/UserSecurity";
import VoucherFmtForm from "../SetupComponent/VoucherFormat/VoucherFmtForm";
import VoucherListModal from "../ReportComponent/Account Books/VoucherListModal";
import UserSecurityNew from "../SetupComponent/User/User Security/UserSecurityNew";
import AboutUsInsideApp from "../layout/AboutUs";
import ProductionQuickEntryForm from "../Transaction/Production/ProductionQuickEntryForm";

export function M52JS(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  Store.dispatch(TabAdd(OBJ));
}
export function M46JS(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  Store.dispatch(TabAdd(OBJ));
}

export function TDSMaster(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  Store.dispatch(TabAdd(OBJ));
}
export function ShowForm(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  Store.dispatch(TabAdd(OBJ));
  // console.log("Call Show Form==>", OBJ);
}
export function CashBankMaster(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  Store.dispatch(TabAdd(OBJ));
}

export function JournalMaster(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  Store.dispatch(TabAdd(OBJ));
}

// export function indexOf(itemOBJ) {
//   const OBJ = JSON.parse(itemOBJ)
//   // Store.dispatch(TabAdd(OBJ))
//   console.log("indexOf function",OBJ);
// }

export function CALLTMJS(itemOBJ) {
  // console.log("ItemOBj", itemOBJ);
  const OBJ = JSON.parse(itemOBJ);
  // console.log(OBJ, "CALLTMJS-");
  if (OBJ?.id === "13891386") {
    return  Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={500}>{OBJ?.name}</Text>,
        MAction: true,
        MSize: "80%",
        position:'right',
        MBody: (i) => <UserFieldEquEntry obj={OBJ} index={i} />,
        // MBody: (i) => <VoucherFmtForm obj={OBJ} index={i} />,

        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  }else if(OBJ?.id === "05910585"){
    return  Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={500}>{OBJ?.name}</Text>,
        MAction: true,
        MSize: "50%",
        position:'right',
        MBody: (i) => <VoucherListModal obj={OBJ} index={i} />,
        // MBody: (i) => <VoucherFmtForm obj={OBJ} index={i} />,

        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else {
    // console.log("OBJJ",OBJ);
    
    Store.dispatch(TabAdd(OBJ)); 
  }
  // Store.dispatch(UpdateSelected(OBJ.id));
  // console.log("Tabmenuitems", appInfo.Tabmenuitems);
  // appInfo.tabitemRef.current.instance.option('dataSource', appInfo.Tabmenuitems);
  // appInfo.tabitemRef.current.instance.option('selectedIndex', appInfo.Tabmenuitems.length - 1);
}

export function Main_ChangeYear(itemOBJ) {
  // <YearAndDate true={true} />
  // const OBJ = JSON.parse(itemOBJ);
  // let NewObj = {...OBJ, FocusState:true}
  // Store.dispatch(SetYearAndDateFocus(NewObj))

  const OBJ = JSON.parse(itemOBJ);
  console.log("Year and date OBJ===>", OBJ);
  return modals.open({
    title: <Text fw={700}>{OBJ?.name}</Text>,
    size: "md",
    centered: true,
    closeOnClickOutside: false,
    closeOnEscape: false,
    withCloseButton: false,
    children: <YearAndDateTable OBJ={OBJ} />,
  });
}

export function AutoBillForm(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  // console.log("autoBill OBJ===>", OBJ);

  return Store.dispatch(
    ModalFunction({
      onclose: () => { },
      MTitle: <Text fw={700}>{OBJ?.name}</Text>,
      MAction: true,
      MSize: "50%",
      MBody: (i) => <AutoBillFormSE OBJ={OBJ} index={i} />,
      MClose: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
  );
}
export function CallVouFormat(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  // console.log("autoBill OBJ===>", OBJ);

  return Store.dispatch(
    ModalFunction({
      onclose: () => { },
      MTitle: <Text fw={700}>{OBJ?.name}</Text>,
      MAction: true,
      MSize: "50%",
      MBody: (i) => <VoucherFmtForm OBJ={OBJ} index={i} />,
      MClose: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
  );
}

export function ShowCompanyList(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  // console.log("ShowCompanyList OBJ===>", OBJ);

  return modals.open({
    title: <Text fw={700}>{OBJ?.name}</Text>,
    size: "lg",
    centered: true,
    closeOnClickOutside: false,
    closeOnEscape: false,
    withCloseButton: false,
    children: <ChangeCompanyTable OBJ={OBJ} />,
    zIndex: "100",
  });
}

export function CallURorMo(itemOBJ){
  const OBJ = JSON.parse(itemOBJ);

  return Store.dispatch(
    ModalFunction({
      onclose: () => { },
      MTitle: <Text fw={700}>{OBJ?.name}</Text>,
      MAction: true,
      MSize: "75%",
      MBody: (i) => <UserSecurityNew OBJ={OBJ} index={i} />,
      MClose: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
  );
}



// export function StockJor(itemOBJ) {
//   const OBJ = JSON.parse(itemOBJ);
//   console.log("StockJor OBJ===>", OBJ);
//   Store.dispatch(TabAdd(OBJ));
// }

export function OpeningStock(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  Store.dispatch(TabAdd(OBJ));
}

export function indexOf(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  // console.log("ShowCompanyList OBJ===>", OBJ);

  return modals.open({
    title: <Text fw={700}>{OBJ?.name}</Text>,
    size: "md",
    centered: true,
    closeOnClickOutside: false,
    closeOnEscape: false,
    withCloseButton: false,
    children: <ChangeCompany OBJ={OBJ} />,
  });
}

export function VouEntAct(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  // console.log("Transaction VouEntAct===>", OBJ);
  if (OBJ?.id == "02700249" || OBJ?.id == "02340213") {
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ.name}</Text>,
        MAction: true,
        MSize: "98%",
        fullScreen: false,
        MBody: (i) => <TransactionSalesForm obj={OBJ} index={i} />,
        MClose: true,
        onclose: () => { },
      })
    );
  } else if (
    OBJ?.id == "04080399" ||
    OBJ?.id == "20452041" ||
    OBJ?.id == "04290417"
  ) {
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ.name}</Text>,
        MAction: true,
        MSize: "98%",
        fullScreen: false,
        MBody: (i) => <BillFormChallanModal obj={OBJ} index={i} />,
        MClose: true,
        onclose: () => { },
      })
    );
  }  

}

// Utility Menu

export function ShowAddressBook(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  // console.log("OBJ", OBJ);
  
  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "75%",
      fullScreen: false ,
      MBody: (i) => <AddressBook obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function ShowDFZ(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);

//   Store.dispatch(
//     ModalFunction({
//         onclose: (e) => {},
//         MTitle: <Text fw={700}>{OBJ?.text}</Text>,
//         MAction: true,
//         MSize: "sm",
        
//         MBody: (i) => <ECommerceOperatorForm OBJ={OBJ} index={i} />,
//         MClose: true,
//         Overlay: {
//           backgroundOpacity: 0.8,
//           blur: 5,
//         },
//     })
// )

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "md",
      position:'right',
      fullScreen: false,
      MBody: (i) => <DataFreeze obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function ShowYrList(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "50%",
      fullScreen: false,
      MBody: (i) => <FinYearDelete obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function systemUtilityBackup(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "50%",
      fullScreen: false,
      MBody: (i) => <BackUpForm obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function ShowBatchMerge(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "50%",
      fullScreen: false,
      MBody: (i) => <BatchMergeForm obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function ChangeRate(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "50%",
      fullScreen: false,
      MBody: (i) => <ChangeMRPSalesRateForm obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function ShowDataInte(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "50%",
      fullScreen: false,
      MBody: (i) => <DataIntegrityForm obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function ShowDataExp(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "50%",
      fullScreen: false,
      MBody: (i) => <DataExportForm obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function ShowDataImp(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "50%",
      fullScreen: false,
      MBody: (i) => <DataimportForm obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function ShowOtherImp(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "50%",
      fullScreen: false,
      MBody: (i) => <OtherImportForm obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function ShowYrBackup(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "50%",
      fullScreen: false,
      MBody: (i) => <FinYearBackupForm obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function ShowYrRestore(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "50%",
      fullScreen: false,
      MBody: (i) => <FinYearRestoreForm obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function ShowNewFinYear(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "50%",
      fullScreen: false,
      MBody: (i) => <NewFinYearForm obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function ShowMailLetter(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "lg",
      position:"right",
      fullScreen: false,
      MBody: (i) => <MailingLetter obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function ShowRemind(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "75%",
      // position:'center',
      fullScreen: false,
      MBody: (i) => <Reminder obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}
export function Main_AboutUs(itemOBJ){
  const OBJ = JSON.parse(itemOBJ);

  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "xl",
      // position:'center',
      fullScreen: false,
      MBody: (i) => <AboutUsInsideApp/>,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function Quick_production(itemOBJ){
  const OBJ = JSON.parse(itemOBJ);
  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{OBJ.name}</Text>,
      MAction: true,
      MSize: "80%",
      // position:'center',
      fullScreen: false,
      MBody: (i) => <ProductionQuickEntryForm obj={OBJ} index={i} />,
      MClose: true,
      onclose: () => { },
    })
  );
}

export function BindFunWithPara(itemObject, s) {
  console.log("itemObject=>", itemObject);

  var paraStart = itemObject.action.indexOf("(");
  var paraEnd = itemObject.action.lastIndexOf(")");
  var fun = itemObject.action.substring(0, paraStart);
  var para = itemObject.action.substring(paraStart + 1, paraEnd); // paraEnd-1
  var paraArr = para.split("~C~");
  if (para.includes(",")) {
    paraArr = para.replaceAll("'", "").replaceAll(`\"`, "").split(",");
  }

  // console.log("fun==>" + paraArr);
  var para0,
    para1,
    para2,
    para3,
    para4,
    para5,
    para6,
    para7,
    para8,
    para9,
    para10;
  if (paraArr.length > 0) {
    para0 = paraArr[0];
  }
  if (paraArr.length > 1) {
    para1 = paraArr[1];
  }
  if (paraArr.length > 2) {
    para2 = paraArr[2];
  }
  if (paraArr.length > 3) {
    para3 = paraArr[3];
  }
  if (paraArr.length > 4) {
    para4 = paraArr[4];
  }
  if (paraArr.length > 5) {
    para5 = paraArr[5];
  }
  if (paraArr.length > 6) {
    para6 = paraArr[6];
  }
  if (paraArr.length > 7) {
    para7 = paraArr[7];
  }
  if (paraArr.length > 8) {
    para8 = paraArr[8];
  }
  if (paraArr.length > 9) {
    para9 = paraArr[9];
  }
  if (paraArr.length > 10) {
    para10 = paraArr[10];
  }
  console.log("btnnnn",fun);
  
  if (fun.indexOf("window.") !== -1) {
    fun = fun.substring(7);
  }
  const OBJ = JSON.stringify({
    id: itemObject.id,
    name: itemObject.name,
    type: itemObject.type,
    p0: para0,
    p1: para1,
    p2: para2,
    p3: para3,
  });

  console.log("Common OBJ=>", OBJ + "fun==>" + fun);
  eval(fun + "(`" + OBJ + "`)");
}

export default BindFunWithPara;
