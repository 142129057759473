import { Text } from "@mantine/core";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import TransactionSalesForm from "../SaleEntry/TransactionSalesForm";
import JournalForm from "./Journal/JournalForm";
import GlobalClass from "../../utils/GlobalClass";
import { GetAccountListObject, TransactionDeleteApi } from "../../utils/TransactionSlices/Transaction";
import JEDetailPage from "./Journal/JEDetailPage";

export function VouEntAct(itemOBJ) {
    const OBJ = JSON.parse(itemOBJ);
    console.log('Transaction Journal Entry Object===>', OBJ);
    if (OBJ.p0 === 'A') { //Add
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "80%",
            fullScreen: false,
            MBody: (i) => (<JournalForm obj={OBJ} index={i} />),
            MClose: true,
            onclose: () => { }
        }))
    } else if (OBJ.p0 === 'E' && OBJ?.id) { // Edit
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "80%",
            fullScreen: false,
            MBody: (i) => (<JournalForm obj={OBJ} index={i} />),
            MClose: true,
            onclose: () => { }
        }))
    } else if (OBJ.p0 === 'D' && OBJ?.id) { //Delete
        // console.log("Delete Object==>", OBJ);
        let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj

        switch (OBJ.page) {
            case "04920489": //BankPayment
                Store.dispatch(GetAccountListObject({
                    id: "04920489",
                    name: "Bank Payment",
                    p0: "MT",
                    p1: "T41VLISTJRBP",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["04920489"]
                }))
                break;
            case "04950489"://BankReceipt
                Store.dispatch(GetAccountListObject({
                    id: "04950489",
                    name: "Bank Receipt",
                    p0: "MT",
                    p1: "T41VLISTJRBR",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["04950489"]
                }))
                break;
            case "04980489": //CashPayment 
                Store.dispatch(GetAccountListObject({
                    id: "04980489",
                    name: "Cash Payment",
                    p0: "MT",
                    p1: "T41VLISTJRCP",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["04980489"]
                }))
                break;
            case "05010489": //CashREceipt 
                Store.dispatch(GetAccountListObject({
                    id: "05010489",
                    name: "Cash Receipt",
                    p0: "MT",
                    p1: "T41VLISTJRCR",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["05010489"]
                }))
                break;
            case "05040489": //Contra 
                Store.dispatch(GetAccountListObject({
                    id: "05040489",
                    name: "Contra",
                    p0: "MT",
                    p1: "T41VLISTJRBC",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["05040489"]
                }))
                break;
            case "05070489": //CreditNote 
                Store.dispatch(GetAccountListObject({
                    id: "05070489",
                    name: "Credit Note",
                    p0: "MT",
                    p1: "T41VLISTJRNC",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["05070489"]
                }))
                break;
            case "05100489": //DebitNote 
                Store.dispatch(GetAccountListObject({
                    id: "05100489",
                    name: "Debit Note",
                    p0: "MT",
                    p1: "T41VLISTJRND",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["05100489"]
                }))
                break;
            case "05130489": //Journal 
                Store.dispatch(GetAccountListObject({
                    id: "05130489",
                    name: "Journal",
                    p0: "MT",
                    p1: "T41VLISTJRJR",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["05130489"]
                }))
                break;
            default:
                break;
        }

        let AccountListObject = Store.getState().Transaction.AccountListObject

        if (window.confirm('Confirm to Delete ?')) {
            Store.dispatch(TransactionDeleteApi({ ...OBJ, lJournal: true, AcountListObj: AccountListObject }))
        }
    } else if (OBJ.p0 === 'U' && OBJ?.id) { // User Field
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "50%",
            fullScreen: false,
            MBody: (i) => (<JournalForm obj={OBJ} index={i} />),
            MClose: true,
            onclose: () => { }
        }))
    }else if(OBJ.p0 === 'VD' && OBJ?.id){ //Detail
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "50%",
            MBody: (i) => (<JEDetailPage obj={OBJ} index={i} />),
            MClose: true,
            onclose: () => { }
        }))
    }
}