import { Button, Grid, Paper } from '@mantine/core';
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLDateBox from '../../PlusComponent/PLDateBox';
import { useDispatch, useSelector } from 'react-redux';
import { GetButtonLoading } from '../../utils/TransactionSlices/EWayBillSlice';

const GetEwb = (props) => {
    const { G04Obj, setG04Obj, DrawerObjSend, setDrawerObjSend } = props
    const ButtonLoading = useSelector((state) => state.EWayBill?.ButtonLoading)
    console.log("GetEwb props", props)
    const [ewbObj, setEwbObj] = useState({})
    const dispatch = useDispatch()

    useEffect(() => {
        if (G04Obj) {
            setEwbObj(G04Obj)
        }
    }, [G04Obj])

    const updateEWBFun = () => {
        setG04Obj(ewbObj)
        // .then(() => setDrawerObjSend({ ...DrawerObjSend, open: false }))
    }

    return (
        <>
            <Paper shadow="md" radius="md" p="xs" withBorder>
                <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid.Col span={3} style={{ fontSize: 12 }}>E-Way Bill No.</Grid.Col>
                        <Grid.Col span={9}>
                            <PLTextBox
                                value={ewbObj?.G04F24}
                                setEdit={(e) => {
                                    setEwbObj({ ...ewbObj, G04F24: e.target.value })
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid.Col span={3} style={{ fontSize: 12 }}>From Date</Grid.Col>
                        <Grid.Col span={5}>
                            <PLDateBox
                                dispformat="DD/MM/YYYY"
                                defaultval={ewbObj?.G04F25}
                                setEdit={(e) => {
                                    // let hours = e?.getHours()
                                    // let ampm = hours >= 12 ? "PM" : "AM"
                                    // console.log("Date Value", e?.toLocaleTimeString() + ' ' + ampm)
                                    // setFromTime(e?.toLocaleTimeString() + ' ' + ampm)
                                    let year = e.toLocaleString("en-IN", {
                                        year: "numeric",
                                    });
                                    let month = e.toLocaleString("en-IN", {
                                        month: "2-digit",
                                    });
                                    let day = e.toLocaleString("en-IN", {
                                        day: "2-digit",
                                    });

                                    let Date = year + month + day;
                                    setEwbObj({ ...ewbObj, G04F25: Date });
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <PLTextBox
                            // value={fromTime}
                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid.Col span={3} style={{ fontSize: 12 }}>Valid Upto</Grid.Col>
                        <Grid.Col span={5}>
                            <PLDateBox
                                dispformat="DD/MM/YYYY"
                                defaultval={ewbObj?.G04F55}
                                setEdit={(e) => {
                                    // let hours = e?.getHours()
                                    // let ampm = hours >= 12 ? "PM" : "AM"
                                    // console.log("Date Value", e?.toLocaleTimeString() + ' ' + ampm)
                                    // setValidTime(e?.toLocaleTimeString() + ' ' + ampm)
                                    let year = e.toLocaleString("en-IN", {
                                        year: "numeric",
                                    });
                                    let month = e.toLocaleString("en-IN", {
                                        month: "2-digit",
                                    });
                                    let day = e.toLocaleString("en-IN", {
                                        day: "2-digit",
                                    });

                                    let Date = year + month + day;
                                    console.log("Date ===>", Date);
                                    setEwbObj({ ...ewbObj, G04F55: Date });
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <PLTextBox
                            // value={validTime}
                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col
                        span={12}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            loading={ButtonLoading?.getEWB}
                            style={{ height: "30px" }}
                            size="sm"
                            onClick={() => {
                                dispatch(GetButtonLoading({ ...ButtonLoading, getEWB: true }))
                                updateEWBFun()
                                // handleSaveEwayBill("G")
                            }}
                        >OK</Button>

                    </Grid.Col>
                </Grid>
            </Paper>
        </>
    )
}

export default GetEwb