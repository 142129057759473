import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import axios from "axios";
import GlobalClass from "../GlobalClass";

export const CashBankApi = createAsyncThunk(
    "CashBank/CashBankApi",
    async (obj) => {
        // console.log("CashBankApi OBJ", obj);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": "",
                    "cCode": "",
                    "cOPara": obj?.id === "04500447" ? "C" : "J",
                });
            const response1 = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetVouList + "?pa=" + param)
            // console.log("CashBank Data", response1.data.data)
            const response = response1.data.data
            return { response, obj };
        }
        catch (error) {
            GlobalClass.Notify("error", "Error", error.message)
            return { error }
        }
    }


)

// export const QuickGetVouEnt = createAsyncThunk(
//     "CashBank/QuickGetVouEnt",
//     async (obj) => {
//         console.log("CashBankAddApi", obj);
//         try {
//             let param =
//                 JSON.stringify({
//                     "CorpID": gensetting.getcorpid(),
//                     "cUser": gensetting.getcuser(),
//                     "cPass": gensetting.getcpass(),
//                     "CmpNo": gensetting.getcmpno(),
//                     "cAction": "A",
//                     "cCode": obj?.cCode ?? "",
//                     "cOPara": obj?.id == "04500447" ? "" : "J",
//                 });
//             const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.QuickGetVouEnt + "?pa=" + param)
//             // console.log("GetVouEnt response", response);
//             return response;

//         } catch (error) {
//             GlobalClass.Notify("error", "Error", error.message)
//             return { error }
//         }
//     }
// )

export const CashBankAddApi = createAsyncThunk(
    "CashBank/CashBankAddApi",
    async (obj) => {
        console.log("CashBankAddApi", obj);
        try {

            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": "A",
                    "cCode": obj?.cCode ? obj?.cCode : obj?.id == "04500447" ? "CRCR" : "JRJR",
                    "cOPara": obj?.id == "04560447" ? "J" : "",
                });
            // if (obj?.cCode) {
            //     param = { ...param, cCode: obj?.cCode }
            // }
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.QuickGetVouEnt + "?pa=" + param)
            // console.log("GetVouEnt response", response);
            return response;

        } catch (error) {
            GlobalClass.Notify("error", "Error", error.message)
            return { error }
        }
    }
)

export const CashBankDeleteApi = createAsyncThunk(
    "CashBank/CashBankDeleteApi",
    async ({ obj, cid }) => {
        // console.log("CashBankDeleteApi");
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": "D",
                    "cCode": cid,
                    "cOPara": "",
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.QuickGetVouEnt + "?pa=" + param)
            // console.log("CashBankAddData response", response);

            return { response, cid };

        } catch (e) {
            GlobalClass.Notify("error", "Error", `${e?.message}`);
            return { e };
        }
    }
)

export const CashBankEditApi = createAsyncThunk(
    "CashBank/CashBankEditApi",
    async ({ obj, rowId }) => {
        // console.log("CashBankEditApi","obj", obj, "rowId", rowId);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": "E",
                    "cCode": rowId,
                    "cOPara": obj.id === "04500447" ? "" : "J",
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.QuickGetVouEnt + "?pa=" + param)
            // console.log(response, "CashBankEditData response");
            return { response };

        } catch (error) {
            return { error }
        }
    }
)

const CashBank = createSlice({
    name: 'CashBank',
    initialState: {
        CashBankData: [],
        isLoading: false,
        hasError: false,
        ErrorMsg: {},
        success: "",
        CashBankAddData: [],
        CashBankEditData: [],
        unSavedRowsData: [],
        isDeleted: "",
        obj: "",
        T41: [],
        Data: [],
        toggleButton: false,
        editedRows: [],
        AddEditDeleteApiHasError: false,
        AddEditDeleteApiErrorMsg: {},
        ApiToggleBtn: {
            flag: false,
        },
        saveBtnLoader: false,
        invoiceValue: "",
        cashBankAddEditData: []
    },
    reducers: {
        // getUnSavedRows: (state, action) => {
        //     if (!action.payload) {
        //         console.log("action.payload if", action.payload);
        //         state.unSavedRowsData = []
        //     }
        //     else {
        //         console.log("action.payload else", action.payload);
        //         state.unSavedRowsData.push(action.payload)
        //     }
        // },
        // getEditData: (state, action) => {
        //     state.CashBankEditData = [];
        // },
        getEditedRows: (state, action) => {
            state.editedRows = action.payload;
        },

        getData: (state, action) => {
            state.Data = action.payload
        },
        getT41: (state, action) => {
            state.T41 = action.payload;
        },
        getDeletedRowStatus: (state, action) => {
            state.isDeleted = action.payload
        },
        setToggleButton: (state, action) => {
            state.toggleButton = action.payload;
        },
        // getSuccessStatus: (state, action) => {
        //     state.success = action.payload
        // },
        getFlagStatus: (state, action) => {
            state.ApiToggleBtn.flag = action.payload
        },
        getSaveBtnLoader: (state, action) => {
            state.saveBtnLoader = action.payload;
        },
        setInvoiceValue: (state, action) => {
            state.invoiceValue = action.payload;
        },
        setSuccess: (state, action) => {
            // console.log("setSuccess", action?.payload);
            state.success = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(CashBankApi.pending, (state, action) => {
                if (state.ApiToggleBtn.flag) {
                    state.isLoading = false
                }
                else {
                    state.isLoading = true;
                    state.hasError = false;
                }
            })
            .addCase(CashBankApi.fulfilled, (state, action) => {

                const { response, obj } = action.payload

                // console.log(state.obj, "CashBankApi obj", obj?.id);

                if (state.obj != obj?.id) {
                    if (!response?.error) {
                        state.CashBankData = response;
                        state.isLoading = false;
                        state.hasError = false;
                        state.success = true;
                        state.obj = obj?.id
                    }
                    else {
                        state.isLoading = false;
                        state.hasError = true;
                        state.ErrorMsg = response?.error;
                    }
                }
                if (state.obj == obj?.id) {
                    if (!response?.error) {
                        state.ApiToggleBtn.flag = false;
                        state.CashBankData = response;
                        state.isLoading = false
                        state.invoiceValue = "";
                        // state.saveBtnLoader = false;
                    }

                }

            })
            .addCase(CashBankApi.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })
            .addCase(CashBankAddApi.pending, (state, action) => {
                state.AddEditDeleteApiHasError = false;
            })
            .addCase(CashBankAddApi.fulfilled, (state, action) => {
                const response = action.payload
                // console.log("response slice", action.payload);

                if (!action.payload?.error) {
                    state.AddEditDeleteApiHasError = false;
                    state.CashBankAddData = response.data.data;
                    state.cashBankAddEditData = response.data.data;
                    state.success = false;
                }
                else {
                    state.AddEditDeleteApiHasError = true;
                    state.AddEditDeleteApiErrorMsg = action.payload?.error;
                }

            })
            .addCase(CashBankAddApi.rejected, (state, action) => {
                state.AddEditDeleteApiHasError = true;
            })
            .addCase(CashBankDeleteApi.pending, (state, action) => {
                state.AddEditDeleteApiHasError = false;
            })
            .addCase(CashBankDeleteApi.fulfilled, (state, action) => {
                const { response } = action.payload
                // console.log("response slice", action.payload);

                if (!action.payload?.error) {
                    if (response.data.status == "SUCCESS") {
                        state.AddEditDeleteApiHasError = false;
                        state.isDeleted = true
                    }
                    else {
                        state.isDeleted = false
                    }
                }
                else {
                    state.AddEditDeleteApiHasError = true;
                    state.AddEditDeleteApiErrorMsg = action.payload?.error;
                    state.isDeleted = false;
                }

            })
            .addCase(CashBankDeleteApi.rejected, (state, action) => {
                state.AddEditDeleteApiHasError = true;
            })
            .addCase(CashBankEditApi.pending, (state, action) => {
                state.AddEditDeleteApiHasError = false;
            })
            .addCase(CashBankEditApi.fulfilled, (state, action) => {
                const { response } = action.payload
                if (!action.payload?.error) {
                    if (response.data.status === "SUCCESS") {
                        state.CashBankEditData = response.data.data;
                        state.cashBankAddEditData = response.data.data;
                        state.AddEditDeleteApiHasError = false;
                    }
                    else {
                        state.AddEditDeleteApiHasError = true;
                    }

                }
                else {
                    state.AddEditDeleteApiHasError = true;
                    state.AddEditDeleteApiErrorMsg = action.payload?.error;
                }
            })
            .addCase(CashBankEditApi.rejected, (state, action) => {
                state.AddEditDeleteApiHasError = true;
            })
    }
})


export const { getData, getDeletedRowStatus, setToggleButton, getFlagStatus, getSuccessStatus, getEditedRows, getSaveBtnLoader, setInvoiceValue, setSuccess } = CashBank.actions;
export default CashBank.reducer;



















// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import gensetting from "../gensetting";
// import axios from "axios";
// import GlobalClass from "../GlobalClass";

// export const CashBankApi = createAsyncThunk(
//     "CashBank/CashBankApi",
//     async (obj) => {
//         console.log("CashBankApi OBJ", obj);
//         try {
//             let param =
//                 JSON.stringify({
//                     "CorpID": gensetting.getcorpid(),
//                     "cUser": gensetting.getcuser(),
//                     "cPass": gensetting.getcpass(),
//                     "CmpNo": gensetting.getcmpno(),
//                     "cAction": "",
//                     "cCode": "",
//                     "cOPara": obj?.id == "04500447" ? "C" : "J",
//                 });
//             const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetVouList + "?pa=" + param)
//             console.log("CashBank Data", response.data.data)
//             return response.data.data;
//         }
//         catch (error) {
//             GlobalClass.Notify("error", "Error", error.message)
//             return { error }
//         }
//     }


// )

// export const CashBankAddApi = createAsyncThunk(
//     "CashBank/CashBankAddApi",
//     async ({ obj, cid }) => {
//         console.log({ obj, cid });
//         try {
//             let param =
//                 JSON.stringify({
//                     "CorpID": gensetting.getcorpid(),
//                     "cUser": gensetting.getcuser(),
//                     "cPass": gensetting.getcpass(),
//                     "CmpNo": gensetting.getcmpno(),
//                     "cAction": cid ? "D" : "A",
//                     "cCode": obj?.id == "04500447" ? cid ? cid : "CRCR" : cid ? cid : "JRJR",
//                     "cOPara": obj?.id == "04500447" ? "" : cid ? "" : "J",
//                 });
//             const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.QuickGetVouEnt + "?pa=" + param)
//             console.log("CashBankAddData response", response);
//             return { response, cid };

//         } catch (error) {
//             GlobalClass.Notify("error", "Error", error.message)
//             return { error }
//         }
//     }
// )

// export const CashBankEditApi = createAsyncThunk(
//     "CashBank/CashBankEditApi",
//     async ({ obj, rowId }) => {
//         console.log("obj", obj, "rowId", rowId);
//         try {
//             let param =
//                 JSON.stringify({
//                     "CorpID": gensetting.getcorpid(),
//                     "cUser": gensetting.getcuser(),
//                     "cPass": gensetting.getcpass(),
//                     "CmpNo": gensetting.getcmpno(),
//                     "cAction": "E",
//                     "cCode": rowId,
//                     "cOPara": obj.id === "04500447" ? "" : "J",
//                 });
//             const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.QuickGetVouEnt + "?pa=" + param)
//             console.log(response.data.data, "CashBankEditData response");
//             return response.data.data;

//         } catch (error) {
//             GlobalClass.Notify("error", "Error", error.message)
//             return { error }
//         }
//     }
// )

// const CashBank = createSlice({
//     name: 'CashBank',
//     initialState: {
//         CashBankData: [],
//         isLoading: false,
//         hasError: false,
//         ErrorMsg: {},
//         success: false,
//         CashBankAddData: [],
//         CashBankEditData: [],
//         unSavedRowsData: [],
//         isDeleted: '',
//         T41: [],
//         Data: [],
//         toggleButton: false,
//         editedRows: [],
//         AddEditDeleteApiHasError: false,
//         AddEditDeleteApiErrorMsg: {},
//     },
//     reducers: {
//         getUnSavedRows: (state, action) => {
//             if (!action.payload) {
//                 console.log("action.payload if", action.payload);
//                 state.unSavedRowsData = []
//             }
//             else {
//                 console.log("action.payload else", action.payload);
//                 state.unSavedRowsData.push(action.payload)
//             }
//         },
//         getDeletedRowStatus: (state, action) => {
//             state.isDeleted = ""
//         },
//         getData: (state, action) => {
//             console.log("getData", action.payload);
//             state.Data = action.payload
//         },
//         getEditData: (state, action) => {
//             state.CashBankEditData = [];
//         },
//         setToggleButton: (state, action) => {
//             state.toggleButton = action.payload;
//         },
//         getT41: (state, action) => {
//             state.T41 = action.payload;
//         },
//         getEditedRows: (state, action) => {
//             state.editedRows = action.payload;
//         },
//     },
//     extraReducers: (builder) => {
//         builder
//             .addCase(CashBankApi.pending, (state, action) => {
//                 state.isLoading = true;
//                 state.hasError = false;
//             })
//             .addCase(CashBankApi.fulfilled, (state, action) => {
//                 if (!action.payload?.error) {
//                     state.CashBankData = action.payload;
//                     state.success = true;
//                     state.isLoading = false;
//                     state.hasError = false;
//                 }
//                 else {
//                     state.isLoading = false;
//                     state.hasError = true;
//                     state.ErrorMsg = action.payload?.error;
//                 }
//             })
//             .addCase(CashBankApi.rejected, (state, action) => {
//                 state.hasError = true
//                 state.isLoading = false;
//             })

//             .addCase(CashBankAddApi.pending, (state, action) => {
//                 state.AddEditDeleteApiHasError = false;
//             })
//             .addCase(CashBankAddApi.fulfilled, (state, action) => {
//                 const { response, cid } = action.payload
//                 console.log("response slice", action.payload);

//                 if (!action.payload?.error) {
//                     state.AddEditDeleteApiHasError = false;
//                     state.success = false;
//                     if (!cid) {
//                         state.CashBankAddData = response.data.data;
//                     }
//                     if (cid) {
//                         state.isDeleted = response.data.status;
//                     }
//                 }
//                 else {
//                     state.AddEditDeleteApiHasError = true;
//                     state.AddEditDeleteApiErrorMsg = action.payload?.error;
//                 }

//             })
//             .addCase(CashBankAddApi.rejected, (state, action) => {
//                 state.AddEditDeleteApiHasError = true;
//             })

//             .addCase(CashBankEditApi.pending, (state, action) => {
//                 // state.isLoading = true;
//                 // state.hasError = false;
//                 state.AddEditDeleteApiHasError = false;
//             })
//             .addCase(CashBankEditApi.fulfilled, (state, action) => {
//                 if(!action.payload?.error){
//                     state.CashBankEditData = action.payload;
//                     state.AddEditDeleteApiHasError = false;
//                 }
//                 else{
//                     state.AddEditDeleteApiHasError = true;
//                     state.AddEditDeleteApiErrorMsg = action.payload?.error;
//                 }
//                 // state.isLoading = false;
//                 // state.hasError = false;
//             })
//             .addCase(CashBankEditApi.rejected, (state, action) => {
//                 // state.hasError = true
//                 // state.isLoading = false;
//                 state.AddEditDeleteApiHasError = true;
//             })
//     }
// })


// export const { getUnSavedRows, getDeletedRowStatus, getData, getEditData, setToggleButton, getEditedRows } = CashBank.actions;
// export default CashBank.reducer;