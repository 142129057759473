import React, { useEffect, useState } from "react";
import { GetUserFieldObj, TransactionApi } from "../../../utils/TransactionSlices/Transaction";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionIcon,
  Button,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import PLTextBox from "../../../PlusComponent/PLTextBox";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import PLDateBox from "../../../PlusComponent/PLDateBox";
import PLNumberBox from "../../../PlusComponent/PLNumberBox";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { createColumnHelper } from "@tanstack/react-table";
import GlobalClass from "../../../utils/GlobalClass";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import PLDataGrid from "../../../PlusComponent/PLDataGrid";
import { modals } from "@mantine/modals";
import gensetting from "../../../utils/gensetting";
import { DataSaveLoading } from "../../../utils/slices/DataSaveLoadingSlice";
import Store from "../../../utils/store";
import { ModalDelete } from "../../../utils/slices/ModalSlice";
import { GetAccountList } from "../../../utils/slices/AccountListSlice";
import PLNarration from "../../../PlusComponent/PLNarration";
import PLTextarea from "../../../PlusComponent/PLTextarea";
import PLBillToBill from "../../../PlusComponent/PLBillToBill";
import PLUserFieldBtn from "../../../PlusComponent/PLUserFieldBtn";

const JournalForm = (props) => {
  // console.log("Journal Form Props=>", props)

  const { obj } = props;

  const [columns, setColumns] = useState([]);
  const [T01Data, setT01Data] = useState([]);
  // const [rowSelection, setRowSelection] = useState({})

  const [T01Obj, setT01Obj] = useState({
    FIELD01: null,
    FIELD02: "1/26/2024 2:13:40 PM",
    FIELD03: null,
    FIELD04: null,
    FIELD05: null,
    FIELD06: null,
    FIELD09: 1.0,
    FIELD10: null,
    FIELD11: null,
    FIELD12: null,
    FIELD15: null,
    FIELD16: null,
    FIELD20: null,
    FIELD21: null,
    FIELD22: null,
    FIELD25: null,
    FIELD26: null,
    FIELD58: null,
    FIELD23: null,
    FIELD59: null,
    T41PVT: null,
    FIELD24: null,
    FIELD75: null,
    FIELD56: null,
    FIELD57: null,
    FLDUNQ: null,
    FLDAED: null,
    FLDBRC: null,
    EGKEYID: 1,
    TDSAMT: 0.0,
    MCURVISIBLE: 0.0,
    F15F16ENABLE: 0.0,
    F15F16CAP: 0.0,
    T01F05_C: null,
    T01F05_D: null,
    CACTION: "A",
    ACCCODE: null,
    VATACC: null,
    T01DUMMY: null,
    UFDUMMY: 0.0,
    ACCGRP: null,
    T41F31: null,
    BTOBDUMMY: 0.0,
    COSTDUMMY: 0.0,
  });
  const [HiddenCol, setHiddenCol] = useState({});
  const [BillToBillFilterArray, setBillToBillFilterArray] = useState({})

  const [T41Obj, setT41Obj] = useState({
    FIELD01: "",
    FIELD02: "2024-01-26T11:50:03.8218339+02:00",
    FIELD03: 0,
    FIELD04: "",
    FIELD05: "",
    FIELD06: 0,
    FIELD07: 0,
    FIELD08: 0,
    FIELD09: 0,
    FIELD10: "",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "NONE",
    FIELD14: "N",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "U0000000",
    FIELD18: 0,
    FIELD21: "O",
    FIELD22: "",
    FIELD23: "",
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD70: "",
    FIELD71: "",
    FIELD75: "0",
    FIELD99: "",
    FIELD72: "",
    FIELD20: 0,
    FIELD80: "",
    FIELD51: 0,
    FIELD34: "",
    FIELD35: "",
    FIELD36: "",
    FIELD37: "HOME-BRC",
    FIELD38: "",
    FLDJIO: "",
    FIELD39: "",
    FIELD40: "",
    FIELD41: "",
    FIELD42: "",
    FIELD43: "",
    FIELD44: "",
    FIELD45: "",
    FIELD46: "",
    FIELD47: "",
    FIELD48: "",
    FIELD49: "",
    FIELD50: "",
    FIELD52: "",
    FIELD53: "",
    T41PVT: "JR",
    FIELD54: "",
    T41VNO: "",
    CMID: 0,
    ADDID: 0,
    PRESCPATH: "",
    OSTATUS: "",
    DTYPE: "",
    DELURL: "",
    DELMBNO: "",
    FIELD55: "",
    FIELD56: "",
    T41POS: "",
    UBILTO: "",
    UGSTR: "",
    USALM: "",
    UDISP: "",
    UPAYT: "",
    UPR: "",
    UNAME: "",
    UOP: "",
    UTRA: "",
    UODP: "",
    UREMA: "",
    UEMAIL: "",
    URA: "",
    FLDUNQ: 0,
    FLDAED: "A",
    FLDBRC: "",
    PA000001: "",
    PA000002: "",
    PD000001: 0,
    PD000002: 0,
    UUASDF05: "",
    UUBAER: "",
    UUUBAER: "",
    EA00000001: "",
    EA00000002: "",
    EA00000003: "",
    EA00000004: "",
    EA00000005: "",
    EA00000006: "",
    EA00000007: "",
    EA00000008: "",
    EA00000009: "",
    EA00000010: "",
    EA00000011: "",
    EA00000012: "",
    EA00000013: "",
    EA00000014: "",
    EA00000015: "",
    ED00000001: 0,
    ED00000002: 0,
    ED00000003: 0,
    ED00000004: 0,
    ED00000005: 0,
    ED00000006: 0,
    ED00000007: 0,
    ED00000008: 0,
    ED00000009: 0,
    ED00000010: 0,
    ED00000011: 0,
    ED00000012: 0,
    ED00000013: 0,
    ED00000014: 0,
    ED00000015: 0,
    EP00000001: 0,
    EP00000002: 0,
    EP00000003: 0,
    EP00000004: 0,
    EP00000005: 0,
    EP00000006: 0,
    EP00000007: 0,
    EP00000008: 0,
    EP00000009: 0,
    EP00000010: 0,
    EP00000011: 0,
    EP00000012: 0,
    EP00000013: 0,
    EP00000014: 0,
    EP00000015: 0,
    UUASWER: "",
    UUUASWER: "",
    UCRE: "",
    UCRE2: "",
    UDCNO: "",
    UDE: "",
    UDEL: "",
    UDT: "",
    ULEN: "",
    ULRD: "",
    UOD: "",
    UODD: "",
    USAL: 0,
    UTR: "",
    UUUASD09: "",
    UUJA: "",
    UUUJBOS: "",
    UUUJOB: "",
    UUSALM: "",
    UUUOS: "",
    EA00000016: "",
    ED00000016: 0,
    EP00000016: 0,
    DSDATE: "2023-04-01T00:00:00+03:00",
    DEDATE: "2024-03-31T00:00:00+02:00",
    CACTION: "A",
    lInvType: false,
    lMTaxEnt: false,
    T92F02: "",
    CFRMTITLE: "Add Journal",
    BTOBEFF: " ",
    VATFDATE: null,
    VATTDATE: null,
    VATTYPE: null,
    CLBAL: "",
    VOUTYPE: "JRJR",
  });

  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseBtn: ""
  }

  const [DrawerObj, setDrawerObj] = useState(dra);
  const [NarrationData, setNarrationData] = useState([]);
  const [SelectedNarrationData, setSelectedNarrationData] = useState([]);

  const [dateBoxData, setDateBoxData] = useState({
    DateOne: null,
    DateTwo: null,
  });

  const [rowSelection, setRowSelection] = useState({});
  const [SelectedRow, setSelectedRow] = useState(null);
  const [SelectedRowBal, setSelectedRowBal] = useState(null);
  const [changePage, setChangePage] = useState(null);
  const [DropDownVal, setDropDownVal] = useState(null);
  const [T05DataArray, setT05DataArray] = useState([])
  const [tableEditingRow, setTableEditingRow] = useState(null)
  const [selectedDay, setSelectedDay] = useState(new Date());

  const TransactionData = useSelector(
    (state) => state.Transaction?.TransactionData
  );
  // const { isLoading } = useSelector((state) => state.Transaction);
  const { isLoading, hasError, ErrorMsg } = useSelector(
    (state) => state.Transaction
  );
  const {
    OSV,
    OS11,
    T41,
    T05,
    T05ENT,
    UFT05H,
    COPJR,
    S11,
    T01,
    T01ENT,
    M01,
    M01DT,
    P_M53NO,
    P_M53NODT,
    lEditVou
  } = useSelector((state) => state.Transaction?.TransactionData);

  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  const GetYearAndDate = useSelector((state) => state?.UserList?.YearAndDateSend)

  const UserField = useSelector(store => store?.Transaction?.UserField)

  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();

  useEffect(() => {
    if (changePage != null) {
      dispatch(
        TransactionApi({ ...obj, p0: obj?.p0 == "U" ? "E" : obj?.p0, lJournal: true, PageData: changePage })
      );
    }
  }, [obj, changePage]);

  useEffect(() => {
    if (T05) {
      setT05DataArray(T05)
    }
  }, [T05])

  useEffect(() => {

    if (OS11?.S11F01 && obj.p0 == "U") {
      dispatch(GetUserFieldObj(OS11?.S11F01))
    }
  }, [OS11])

  useEffect(() => {
    if (UserField) {
      console.log("UserField =>", [{
        "UserField": UserField
      }]);
    }
  }, [UserField])

  let stringOfNames;

  useEffect(() => {
    if (SelectedNarrationData?.length >= 0) {
      const stringifyArray = SelectedNarrationData?.map((a) => a.FIELD15);
      stringOfNames = stringifyArray.join(" ");
      console.log("stringOfNames", stringOfNames);
    }
    setT41Obj({ ...T41Obj, FIELD15: stringOfNames });
    // setT41Obj({...T41Obj,FIELD15:})
  }, [stringOfNames, SelectedNarrationData]);

  useEffect(() => {
    if (obj?.page == "04920489") {
      setChangePage("BPBP");
      setDropDownVal("BP");
    } else if (obj?.page == "04950489") {
      setChangePage("BRBR");
      setDropDownVal("BR");
    } else if (obj?.page == "04980489") {
      setChangePage("CPCP");
      setDropDownVal("CP");
    } else if (obj?.page == "05010489") {
      setChangePage("CRCR");
      setDropDownVal("CR");
    } else if (obj?.page == "05040489") {
      setChangePage("BCBC");
      setDropDownVal("BC");
    } else if (obj?.page == "05070489") {
      setChangePage("NCNC");
      setDropDownVal("NC");
    } else if (obj?.page == "05100489") {
      setChangePage("NDND");
      setDropDownVal("ND");
    } else if (obj?.page == "05130489") {
      setChangePage("JRJR");
      setDropDownVal("JR");
    }
  }, [obj]);

  useEffect(() => {
    if (T41) {
      Promise.resolve().then(() => setT41Obj(T41))
    }
  }, [T41]);

  useEffect(() => {
    if (T01) {
      console.log("t01 array =>", [{
        "T01": T01,
        "T01 Filter": T01?.filter((t) => t?.FIELD03 != "" && t?.FIELD03 != null)
      }]);
      Promise.resolve().then(() => setT01Data(T01?.filter((t) => t?.FIELD03 != "" && t?.FIELD03 != null))).then(() => {
        // setT01Obj(T01[0])
        if (obj?.p0 == "E") {
          setT01Obj(T01?.[T01?.length - 1]);
        } else {
          setT01Obj(T01?.[0])
        }
      })
    }
  }, [T01])

  useEffect(() => {
    //T01F05_D=Debit , T01F05_C=Credit
    if (T01ENT) {
      let col = [];
      T01ENT?.filter((h, index) => h.S53F00 != "H").map((c, i) => {
        // if (c.S53F00 == "C") {
        col.push(
          columnHelper.accessor(c?.S53F04, {
            // header: c?.S53F03,
            // size: 150,
            header: c.S53F07 == 0 ? "" : c.S53F03,
            size: c.S53F07 == 0 ? 0 : 150,
            Edit: ({ cell, row }) => {
              // let val = cell.getValue();
              // console.log("Cell Value=>>", cell);
              return renderInputBox(c, i, row);
            },
            Cell: ({ cell, row }) => {
              let val = cell.getValue();
              if (c.S53F02 == "JRENT05") { //Doc Date
                let newVal = (val ? val?.slice(6, 8) + "/" + val?.slice(4, 6) + "/" + val?.slice(0, 4) : "")
                return newVal
              } else if (c?.S53F02 == "JRENT02") {
                return T01Data[row?.id]?.F03NM
              } else if (c?.S53F02 == "JRENT14") {
                return T01Data[row?.id]?.F31NM
              }
              return c.S53F07 == 0 ? "" : val
            },
            Footer: () => <div>{Summary(c, i, "c")}</div>
          })
        );
        // }
        console.log("T01Ent console =>", [{ "#value": c?.S53F04, "#header": c?.S53F03, "#Input": c?.S53F14 }]);
      });
      setColumns(col);
    }
  }, [T01ENT, T01Obj]);

  const Summary = (obj, i, tag) => {
    // console.log("Summary==============>",obj);
    // let stype = "sum";
    var value = "";

    switch (obj?.S53F02) {
      case "JRENT09": // Debit Amt
        value = T01Data?.reduce((a, b) => (a = a + b?.T01F05_D), 0);
        break;
      case "JRENT10": // Credit Amt
        value = T01Data?.reduce((a, b) => (a = a + b?.T01F05_C), 0);
        break;
      default:
        value = null;
        break;
    }

    return value;
  };

  useEffect(() => {
    if (M01DT) {
      M01DT?.jData?.map((a) => {
        if (a?.FIELD01 == SelectedRow?.FIELD03) {
          setSelectedRowBal(a?.FIELD99);
        }
      });
    }
  }, [M01DT, SelectedRow]);



  const seperate = (e) => {
    var paraArr = e.split("~C~");
    // console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const NextIndexFun = (index) => {
    const nextInd = index + 1;
    console.log("nextInd=>", nextInd)
    const nextInput = document.getElementById(`T01Ent_${nextInd}`)
    console.log("nextInput=>", nextInput)

    if (nextInput) {
      nextInput.focus()
    }
  }

  // useEffect(() => {
  //   if (T01Data) {
  //     console.log("T02Data test useEffect")
  //     // Promise.resolve().then(()=>{
  //     //   if (obj?.p0 == "E") {
  //     //     setT01Obj(T01?.[T01?.length - 1]);
  //     //   } else {
  //     //     setT01Obj(T01?.[0])
  //     //   }
  //     // })
  //     Promise.resolve(console.log("T01Obj =>",T01Obj)).then(()=>setTimeout(()=>{table.setCreatingRow(true)},1000))

  //   }
  // }, [T01Data])

  const renderInputBox = (c, index, rowi) => {
    if (c?.S53F07 == 0) {
      return <Button
        id={`T01Ent_${index}`}
        variant="default"
        onFocus={() => {
          document.activeElement.blur()
          console.log("Test=>", `T02Ent_${index}`)
          HiddenButtonField(c, index, rowi)
        }}
        onClick={() => HiddenButtonField(c, index, rowi)}
        style={{ padding: "0px", margin: "0px" }}
      />
      {/* {c.S53F03}</Button> */ }
    } else {
      let setInput;
      switch (c?.S53F14) {
        case "STEPWLIST":
          setInput = (
            <PLDataGrid
              id={`T01Ent_${index}`}
              width={"500px"}
              // value={T01Obj?.[c?.S53F04]}
              value={c?.S53F02 == "JRENT02" ? { value: T01Obj[c?.S53F04], label: T01Obj?.F03NM } : T01Obj?.[c?.S53F04]}
              p1={seperate(c.S53F15)[1]}
              setEdit={(e) => {
                // setT01Obj({ ...T01Obj, [c?.S53F04]: e?.FIELD01 });
                if (c?.S53F02 == "JRENT02") { // Account name
                  console.log("STEPWLIST Account name =>", e);
                  Promise.resolve(setT01Obj({ ...T01Obj, [c?.S53F04]: e?.FIELD01, F03NM: e?.FIELD02 })).then(() => NextIndexFun(index))
                } else {
                  Promise.resolve(setT01Obj({ ...T01Obj, [c?.S53F04]: e?.FIELD01 })).then(() => NextIndexFun(index))
                }
              }}
            />
          );
          break;
        case "PLPOPUP":
          setInput = (
            <PLDataGrid
              id={`T01Ent_${index}`}
              width={"500px"}
              // value={T01Obj?.[c?.S53F04]}
              value={c?.S53F02 == "JRENT14" ? { value: T01Obj[c?.S53F04], label: T01Obj?.F31NM } : T01Obj?.[c?.S53F04]}
              p1={seperate(c.S53F15)[1]}
              setEdit={(e) => {
                // setT01Obj({ ...T01Obj, [c?.S53F04]: e?.FIELD01 });
                if (c?.S53F02 == "JRENT14") { // Cash Party
                  Promise.resolve(setT01Obj({ ...T01Obj, [c?.S53F04]: e?.FIELD01, F31NM: e?.FIELD02 })).then(() => NextIndexFun(index))
                } else {
                  Promise.resolve(setT01Obj({ ...T01Obj, [c?.S53F04]: e?.FIELD01 })).then(() => NextIndexFun(index))
                }
              }}
            />
          );
          break;
        case "COMBOBOX":
          setInput = (
            <PLComboBox
              id={`T01Ent_${index}`}
              value={T01Obj?.[c?.S53F04]}
              copno={seperate(c.S53F15)[1]}
              dispexpr={"DisplayMember"}
              valexpr={"ValueMember"}
              setEdit={(e) => {
                console.log("JournalEntry PLComboBox =>", e);
                Promise.resolve(setT01Obj({ ...T01Obj, [c?.S53F04]: e.value })).then(() => NextIndexFun(index))
                // setT01Obj({ ...T01Obj, [c?.S53F04]: e.value });

                //"JRENT10" credit amt
                //"JRENT09" debit amt
                //FIELD="FIELD06" credit debit
              }}
            />
          );
          break;
        case "TEXTBOX":
          setInput = (
            <PLTextBox
              onKeyDown={(e) => {
                if (e.key == "Tab") {
                  console.log("onKeyDown=>", e);
                  NextIndexFun(index - 1)
                }

                if (c?.S53F02 == "JRENT11" && e.key == "Enter") {
                  if (tableEditingRow == null) {
                    // handleCreateUser()
                    Promise.resolve(handleCreateUser()).then(() => setTimeout(() => {
                      if (obj?.p0 == "E") {
                        setT01Obj(T01?.[T01?.length - 1]);
                      } else {
                        setT01Obj(T01?.[0])
                      }
                      table.setCreatingRow(true)
                    }, 500))
                  } else {
                    // handleSaveUser()
                    Promise.resolve(handleSaveUser()).then(() => setTimeout(() => {
                      if (obj?.p0 == "E") {
                        setT01Obj(T01?.[T01?.length - 1]);
                      } else {
                        setT01Obj(T01?.[0])
                      }
                      table.setCreatingRow(true)
                    }, 500))
                  }
                }
              }}
              id={`T01Ent_${index}`}
              value={T01Obj?.[c?.S53F04]}
              setEdit={(e) => {
                setT01Obj({ ...T01Obj, [c?.S53F04]: e.target.value });
              }}
            />
          )
          break;
        case "NUMBOX":
          setInput = (
            <PLNumberBox
              onKeyDown={(e) => {
                if (e.key == "Tab") {
                  console.log("onKeyDown=>", e);
                  NextIndexFun(index - 1)
                }
              }}
              disabled={c?.S53F02 == "JRENT10" && T01Obj?.FIELD06 == "C" ? false :
                c?.S53F02 == "JRENT09" && T01Obj?.FIELD06 == "D" ? false : true}
              id={`T01Ent_${index}`}
              value={T01Obj?.[c?.S53F04]}
              setEdit={(e) => {
                setT01Obj({ ...T01Obj, [c?.S53F04]: e });
              }}
            />
          );
          break;
        case "DATEPICKER":
          setInput = (
            <PLDateBox
              id={`T01Ent_${index}`}
              // defaultval={T01Obj?.[c?.S53F04]}
              defaultval={T01Obj?.[c?.S53F04]?.trim() != "" ? T01Obj?.[c?.S53F04] : GetYearAndDate?.strtDate}
              dispformat="DD/MM/YYYY"
              setEdit={(e) => {
                // console.log("===>",e);

                let year = e.toLocaleString("en-IN", { year: "numeric" });
                let month = e.toLocaleString("en-IN", { month: "2-digit" });
                let day = e.toLocaleString("en-IN", { day: "2-digit" });

                let BillDate = year + month + day;

                if (BillDate?.length == 8) {
                  setT01Obj({ ...T01Obj, [c?.S53F04]: BillDate });
                }
              }}
            />
          );
          break;
        default:
          setInput = (
            <PLTextBox
              onKeyDown={(e) => {
                if (e.key == "Tab") {
                  console.log("onKeyDown=>", e);
                  NextIndexFun(index - 1)
                }
              }}
              id={`T01Ent_${index}`}
              value={T01Obj?.[c?.S53F04]}
              setEdit={(e) => {
                setT01Obj({ ...T01Obj, [c?.S53F04]: e.target.value });
              }}
            />
          );
          break;
      }
      return setInput;
    }
  };

  // useEffect(()=>{
  //   console.log("T01Obj useEffect =>",T01Obj);
  // },[T01Obj])

  const HiddenButtonField = (c, index, rowi) => {
    console.log("HiddenButtonField para =>", [{
      "c": c,
      "index": index,
      "rowi": rowi
    }])
    switch (c?.S53F02) {
      case "JRENT13":
        NextIndexFun(index)
        break;
      case "JRENT15":
        // disabled={c?.S53F02 == "JRENT10" && T01Obj?.FIELD06 == "C" ? false :
        //         c?.S53F02 == "JRENT09" && T01Obj?.FIELD06 == "D" ? false : true}
        let BilltoBillObj = {
          ...T41Obj,
          FIELD06: T01Obj?.FIELD06 == "C" ? T01Obj?.T01F05_C : T01Obj?.T01F05_D,
          AccountCode: T01Obj?.FIELD01
        }
        setDrawerObj({
          ...dra, title: <Text fw={700}>Bill to Bill</Text>, body: <>
            <PLBillToBill
              OS11Obj={OS11}
              T41ObjSend={BilltoBillObj}
              T05UserField={UFT05H}
              T05Format={T05ENT}
              // T05Data={T05DataArray}
              T05Data={BillToBillFilterArray[T01Obj?.FIELD03]}
              setT05Data={(e) => {
                setBillToBillFilterArray({ ...BillToBillFilterArray, [T01Obj?.FIELD03]: e })
                //  setT05DataArray(e)
              }}
            />
          </>, open: true, size: "50%", position: "right"
        })
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (BillToBillFilterArray) {
      Promise.resolve().then(() => {
        let newBtoBAarr = [];
        Object.keys(BillToBillFilterArray).map((obj) => {
          BillToBillFilterArray[obj]?.map((obj1) => {
            // newBtoBAarr.push(BillToBillFilterArray[obj])
            newBtoBAarr.push(obj1)
          })
        })
        return newBtoBAarr
      }).then((res) => {
        console.log("Bill to bill res =>", res);
        setT05DataArray(res)
      })
    }
  }, [BillToBillFilterArray])

  console.log("BillToBillFilterArray =>", BillToBillFilterArray);

  const BillToBillFun = () => {
    setDrawerObj({
      ...dra, title: <Text fw={700}>Bill to Bill</Text>, body: <>
        <PLBillToBill
          T41ObjSend={T41Obj}
          T05UserField={UFT05H}
          T05Format={T05ENT}
          T05Data={T05DataArray}
          setT05Data={(e) => setT05DataArray(e)}
        />
      </>, open: true, size: "50%", position: "right"
    })
  }

  // useEffect(() => {
  //   if (Object.values(rowSelection).some(value => value === true)) {
  //     // console.log("document.addEventListener", table?.getSelectedRowModel()?.flatRows);
  //     T01Data?.map((t, i) => {
  //       if (i in rowSelection) {
  //         document.addEventListener("keydown", (e) => {
  //           if (e?.key == "Enter") {
  //             Promise.resolve().then(() => {
  //               table.setEditingRow(table?.getSelectedRowModel()?.flatRows[0])
  //               setT01Obj(t)
  //               setTableEditingRow(table?.getSelectedRowModel()?.flatRows[0])
  //             })
  //           }
  //         })
  //       }
  //     })
  //   }
  // }, [T01Data, rowSelection])

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e?.key == "Escape") {
        if (tableEditingRow == null) {
          console.log("document.addEventListener =>", e);
          table.setCreatingRow(null)
        } else {
          console.log("document.addEventListener =>", e);
          table.setEditingRow(null);
          setTableEditingRow(null)
        }
      }
    })
  }, [tableEditingRow])

  const handleCreateUser = () => {
    // console.log("T01Obj==>", T01Obj);
    setT01Data([...T01Data, { ...T01Obj, FIELD09: T01Data?.length }]);
    setRowSelection({})
    table.setCreatingRow(null)
    if (obj?.p0 == "E") {
      setT01Obj(T01?.[T01?.length - 1]);
    } else {
      setT01Obj(T01?.[0])
    }
  };

  const handleSaveUser = () => {
    // console.log("T01Obj==>", T01Obj);


    let EditT01Data = [...T01Data];
    EditT01Data[tableEditingRow?.index] = T01Obj;

    console.log("tableEditingRow?.index =>", [{
      "tableEditingRow": tableEditingRow,
      "T01Obj": T01Obj,
      "EditT01Data": EditT01Data
    }]);
    setT01Data(EditT01Data);
    table.setEditingRow(null); //exit editing mode
    setTableEditingRow(null)
    setRowSelection({})

    if (obj?.p0 == "E") {
      console.log("handleSaveUser T01Obj e", T01?.[T01?.length - 1]);
      setT01Obj(T01?.[T01?.length - 1]);
    } else {
      console.log("handleSaveUser T01Obj", T01?.[0]);
      setT01Obj(T01?.[0])
    }
  };

  const openDeleteConfirmModal = (row) => {
    // modals.openConfirmModal({
    //   title: "Are you sure you want to delete?",
    //   zIndex: 401,
    //   children: (
    //     <Text>
    //       Are you sure you want to delete? This action cannot be undone.
    //     </Text>
    //   ),
    //   labels: { confirm: "Delete", cancel: "Cancel" },
    //   confirmProps: { color: "red" },
    //   onConfirm: () => {
    //     const newArr = T01Data.filter((object, i) => {
    //       return i !== row.index;
    //     });
    //     setT01Data(newArr);
    //   },
    // });


    console.log("openDeleteConfirmModal", row);
    if (window.confirm('Confirm to Delete ?')) {
      let newArray = T01Data?.filter((d, i) => i != row?.index)
      setT01Data(newArray)
    }

  };

  const handleNullT41Obj = () => {

    if (obj?.p0 == "E") {
      setT01Obj(T01?.[T01?.length - 1]);
    } else {
      setT01Obj(T01?.[0])
    }
    setTableEditingRow(null)

    // setT01Obj({
    //   FIELD01: null,
    //   FIELD02: "2024261",
    //   FIELD03: null,
    //   FIELD04: null,
    //   FIELD05: null,
    //   FIELD06: null,
    //   FIELD09: 1.0,
    //   FIELD10: null,
    //   FIELD11: null,
    //   FIELD12: null,
    //   FIELD15: null,
    //   FIELD16: null,
    //   FIELD20: null,
    //   FIELD21: null,
    //   FIELD22: null,
    //   FIELD25: null,
    //   FIELD26: null,
    //   FIELD58: null,
    //   FIELD23: null,
    //   FIELD59: null,
    //   T41PVT: null,
    //   FIELD24: null,
    //   FIELD75: null,
    //   FIELD56: null,
    //   FIELD57: null,
    //   FLDUNQ: null,
    //   FLDAED: null,
    //   FLDBRC: null,
    //   EGKEYID: 1,
    //   TDSAMT: 0.0,
    //   MCURVISIBLE: 0.0,
    //   F15F16ENABLE: 0.0,
    //   F15F16CAP: 0.0,
    //   T01F05_C: null,
    //   T01F05_D: null,
    //   CACTION: "A",
    //   ACCCODE: null,
    //   VATACC: null,
    //   T01DUMMY: null,
    //   UFDUMMY: 0.0,
    //   ACCGRP: null,
    //   T41F31: null,
    //   BTOBDUMMY: 0.0,
    //   COSTDUMMY: 0.0,
    // });
  };

  const table = useMantineReactTable({
    data: T01Data,
    columns,
    enableFilters: false,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableSorting: false,
    // enableRowSelection: true,
    positionToolbarAlertBanner: "none",
    mantineTableContainerProps: {
      sx: { maxHeight: "300px", minHeight: "300px" },
    },
    initialState: {
      density: 0,
      // columnVisibility:HiddenCol
      // columnVisibility:{EGKEYID:false}
    },
    state: {
      rowSelection
    },
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableEditing: true,
    editDisplayMode: "row",
    createDisplayMode: "row",
    // onCreatingRowSave: handleCreateUser,
    onCreatingRowSave: ({ exitCreatingMode }) => {
      if (T01Obj?.FIELD03 == "" || T01Obj?.FIELD03 == null) {
        GlobalClass.Notify("warning", "Warning", "Product Name Require to Enter")
      } else {
        handleCreateUser({ exitCreatingMode })
      }
    },
    // onEditingRowSave: handleSaveUser,
    onEditingRowSave: ({ values, table, row }) => {
      if (T01Obj?.FIELD03 == "" || T01Obj?.FIELD03 == null) {
        GlobalClass.Notify("warning", "Warning", "Product Name Require to Enter")
      } else {
        handleSaveUser({ values, table, row })
      }
    },
    onCreatingRowCancel: handleNullT41Obj,
    onEditingRowCancel: handleNullT41Obj,
    mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
      tabIndex: 0,
      onKeyUp: (e) => handleRowKeyDown(e, row),
      onClick: () => {
        // console.log("Selected Row==>", row);
        setSelectedRow(row.original);
        // setRowSelection((prev) => ({
        //   [row.id]: !prev[row.id],
        // }));
        setRowSelection((prev) => {
          // [row.id]: !prev[row.id],
          if (!prev[row.id]) {
            return { [row.id]: true }
          }
          return prev
        })
      },
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              console.log('table.setEditingRow=>', row);
              table.setEditingRow(row)
              setT01Obj(row.original)
              setTableEditingRow(row)
              // Promise.resolve(setTimeout(()=>{
              //   table.setEditingRow(row);
              // },100)).then(()=>setTimeout(()=>{
              //   setT01Obj(row.original)
              // },100)).then(()=>setTimeout(()=>{
              //   setTableEditingRow(row)
              // },100))
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        disabled={tableEditingRow != null}
        onFocus={()=>{
          table.setCreatingRow(true);
        }}
        onClick={() => {
          table.setCreatingRow(true);
        }}
        size="xs"
      >
        Add New
      </Button>
    ),
  });

  const handleRowKeyDown = (e, row) => {
    if (e?.key == "Enter") {
      console.log("handleRowKeyDown parameter =>", [{
        "e": e,
        "row": row
      }])

      if (row?.id == 'mrt-row-create') {
        return
      } else {
        table.setEditingRow(row)
        setTableEditingRow(row)
        setT01Obj(row?.original)
      }
    }

  }

  const ChangePageFun = (p) => {
    switch (p) {
      case "BP":
        setChangePage("BPBP");
        break;
      case "BR":
        setChangePage("BRBR");
        break;
      case "CP":
        setChangePage("CPCP");
        break;
      case "CR":
        setChangePage("CRCR");
        break;
      case "JR":
        setChangePage("JRJR");
        break;
      case "ND":
        setChangePage("NDND");
        break;
      default:
        break;
    }
  };

  const JournalEntrySubmit = (e) => {
    dispatch(DataSaveLoading(true));

    console.log("JournalEntrySubmit =>", [{
      "T01Data": T01Data,
      "T41Obj": T41Obj,
      "T05DataArray": T05DataArray
    }]);

    GlobalClass.Notify(
      "info",
      obj.p0 == "A" ? "Adding" : "Editing",
      "Please wait while we process your data"
    );

    let PostObj = { T01: [...T01Data], T41: e ? e : { ...T41Obj }, "T05": [...T05DataArray] };

    // console.log("ObjSend==>", PostObj);

    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj.p0 == "A" ? "A" : "E",
      cCode: obj.p0 == "A" ? changePage : obj.id,
      lJournal: "true",
      // "cOPara": "",
      cSData: JSON.stringify(PostObj),
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj.p0 == "A" ? "A" : "E",
      cCode: obj.p0 == "A" ? changePage : obj.id,
      lJournal: "true",
      // "cOPara": "",
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "SUCCESS") {
          let PaginationObj =
            Store.getState().DataSaveLoadingSlice.PaginationObj;
          Promise.resolve(dispatch(DataSaveLoading(false)))
            .then(() => {
              if (changePage != null && obj?.p0 == "A") {
                if (changePage != null) {
                  dispatch(
                    TransactionApi({ ...obj, p0: obj?.p0 == "U" ? "E" : obj?.p0, lJournal: true, PageData: changePage })
                  );
                }
              } else {
                dispatch(ModalDelete(props.index))
              }
            })
            .then(() => {
              if (changePage == "BPBP") {
                //BankPayment
                dispatch(
                  GetAccountList({
                    id: "04920489",
                    name: "Bank Payment",
                    p0: "MT",
                    p1: "T41VLISTJRBP",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["04920489"],
                  })
                );
              } else if (changePage == "BRBR") {
                //BankReceipt
                dispatch(
                  GetAccountList({
                    id: "04950489",
                    name: "Bank Receipt",
                    p0: "MT",
                    p1: "T41VLISTJRBR",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["04950489"],
                  })
                );
              } else if (changePage == "CPCP") {
                //CashPayment
                dispatch(
                  GetAccountList({
                    id: "04980489",
                    name: "Cash Payment",
                    p0: "MT",
                    p1: "T41VLISTJRCP",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["04980489"],
                  })
                );
              } else if (changePage == "CRCR") {
                //CashREceipt
                dispatch(
                  GetAccountList({
                    id: "05010489",
                    name: "Cash Receipt",
                    p0: "MT",
                    p1: "T41VLISTJRCR",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["05010489"],
                  })
                );
              } else if (changePage == "BCBC") {
                //Contra
                dispatch(
                  GetAccountList({
                    id: "05040489",
                    name: "Contra",
                    p0: "MT",
                    p1: "T41VLISTJRBC",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["05040489"],
                  })
                );
              } else if (changePage == "NCNC") {
                //CreditNote
                dispatch(
                  GetAccountList({
                    id: "05070489",
                    name: "Credit Note",
                    p0: "MT",
                    p1: "T41VLISTJRNC",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["05070489"],
                  })
                );
              } else if (changePage == "NDND") {
                //DebitNote
                dispatch(
                  GetAccountList({
                    id: "05100489",
                    name: "Debit Note",
                    p0: "MT",
                    p1: "T41VLISTJRND",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["05100489"],
                  })
                );
              } else if (changePage == "JRJR") {
                //Journal
                dispatch(
                  GetAccountList({
                    id: "05130489",
                    name: "Journal",
                    p0: "MT",
                    p1: "T41VLISTJRJR",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["05130489"],
                  })
                );
              }
            })
            .then(() =>
              GlobalClass.Notify(
                "success",
                obj.p0 == "A" ? "Added" : "Edited",
                obj.p0 == "A" ? "Added successfully" : "Edited successfully"
              )
            );
        } else if (data.status == "FAIL") {
          Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
            GlobalClass.Notify("error", data?.status, data?.message)
          );
        }
      })
      .catch((e) =>
        Promise.resolve(
          GlobalClass.Notify("error", "Error", `${e.message}`)).then(() => dispatch(DataSaveLoading(false)))
      );
  };

  // console.log("TransactionData==>", TransactionData)

  // let BalShowRow = table.getSelectedRowModel()
  // console.log("BalShowRow=>", BalShowRow);

  const RenderInputBox = (key) => {
    switch (key) {
      case "BPBP":
      case "BRBR":
      case "CPCP":
      case "BCBC":
        return (
          <>
            <Grid.Col
              span={12}
              style={{ display: "flex", padding: 0, alignItems: "center" }}
            >
              <Grid.Col span={3}>
                <Text style={{ fontSize: 12 }}>Chq. No.:</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                <PLTextBox
                  disabled={
                    key == "BPBP"
                      ? true
                      : key == "BRBR"
                        ? true
                        : key == "CPCP"
                          ? true
                          : key == "BCBC"
                            ? true
                            : false
                  }
                  value={T41Obj?.FIELD10}
                  setEdit={(e) => {
                    // console.log(e)
                    setT41Obj({ ...T41Obj, FIELD10: e.target.value });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{ display: "flex", padding: 0, alignItems: "center" }}
            >
              <Grid.Col span={3}>
                <Text style={{ fontSize: 12 }}>Chq. Date:</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                <PLDateBox
                  disabled={
                    key == "BPBP"
                      ? true
                      : key == "BRBR"
                        ? true
                        : key == "CPCP"
                          ? true
                          : key == "BCBC"
                            ? true
                            : false
                  }
                  dispformat="DD/MM/YYYY"
                  // defaultval={T41Obj?.FIELD11}
                  value={dateBoxData.DateTwo}
                  setEdit={(e) => {
                    // console.log(e)
                    let year = e.toLocaleString("en-IN", { year: "numeric" });
                    let month = e.toLocaleString("en-IN", { month: "2-digit" });
                    let day = e.toLocaleString("en-IN", { day: "2-digit" });

                    let BillDate = year + month + day;
                    if (BillDate?.length == 8) {
                      setT41Obj({ ...T41Obj, FIELD11: BillDate });
                    }
                    setDateBoxData({ ...dateBoxData, DateTwo: e });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </>
        );
        break;
      case "CRCR":
      case "NCNC":
      case "NDND":
      case "JRJR":
        return (
          <>
            <Grid.Col
              span={12}
              style={{ display: "flex", padding: 0, alignItems: "center" }}
            >
              <Grid.Col span={3}>
                <Text style={{ fontSize: 12 }}>Doc. No.:</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                <PLTextBox
                  disabled={key == "CRCR" ? true : false}
                  value={T41Obj?.FIELD10}
                  setEdit={(e) => {
                    // console.log(e)
                    setT41Obj({ ...T41Obj, FIELD10: e.target.value });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <Grid.Col span={3}>
                <Text style={{ fontSize: 12 }}>Doc. Date:</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                <PLDateBox
                  disabled={key == "CRCR" ? true : false}
                  dispformat="DD/MM/YYYY"
                  // defaultval={T41Obj?.FIELD11}
                  value={dateBoxData.DateTwo}
                  // defaultval={T41Obj?.FIELD11?.trim() != "" ? T41Obj?.FIELD11 : GetYearAndDate?.strtDate}
                  setEdit={(e) => {
                    // console.log(e)
                    let year = e.toLocaleString("en-IN", { year: "numeric" });
                    let month = e.toLocaleString("en-IN", { month: "2-digit" });
                    let day = e.toLocaleString("en-IN", { day: "2-digit" });

                    let BillDate = year + month + day;

                    if (BillDate?.length == 8) {
                      setT41Obj({ ...T41Obj, FIELD11: BillDate });
                    }
                    setDateBoxData({ ...dateBoxData, DateTwo: e });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </>
        );
        break;
      default:
        break;
    }
  };

  console.log("journal form para console =>", [{
    "T01Obj": T01Obj,
    "rowSelection": rowSelection,
    "table?.getSelectedRowModel()": table?.getSelectedRowModel()
  }]);

  return (<>{
    hasError ? <>
      <Text>{ErrorMsg?.message}</Text>
      <Button onClick={() => {
        if (changePage != null) {
          Promise.resolve(dispatch(TransactionApi({ ...obj, p0: obj?.p0 == "U" ? "E" : obj?.p0, lJournal: true, PageData: changePage }))).then(() => {
            if (obj?.p0 == "U") {
              dispatch(GetUserFieldObj(OS11?.S11F01))
            }
          })

        }

      }}>Retry</Button>
    </> :
      <>
        {SaveLoading == true || isLoading == true ? (
          GlobalClass.RenderLoader("dots")
        ) : (<>
          {
            obj?.p0 == "U" ?
              <>
                <PLUserFieldBtn
                  UserFieldArray={UserField?.UFTbl}
                  T41={T41Obj}
                  onChange={(e) => {
                    // Promise.resolve(setT41Obj(e)).then(()=>
                    JournalEntrySubmit(e)
                    // ) 
                  }}
                />
              </> :
              <>
                <Modal
                  opened={DrawerObj?.open}
                  // onClose={close}
                  withCloseButton={true}
                  fullScreen={false}//
                  // overlayProps={{ opacity: 0.5, blur: 4 }}
                  closeButtonProps={{
                    onClick: (e) => {
                      setDrawerObj(dra);
                    },
                  }}
                  title={DrawerObj?.title}
                  size={DrawerObj?.size}
                  // centered={.Centered}
                  closeOnClickOutside={true}
                  // overlayProps={m.Overlay}
                  position={DrawerObj?.position ?? 'bottom'}
                  onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
                // scrollAreaComponent={ScrollArea.Autosize}
                >
                  {DrawerObj.body}
                </Modal>
                <Paper shadow="md" radius="md" p="xs" withBorder>
                  <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: "flex" }}>
                      <Grid.Col span={4}>
                        <Grid.Col
                          span={12}
                          style={{ display: "flex", padding: 0, alignItems: "center" }}
                        >
                          <Grid.Col span={3}>
                            <Text style={{ fontSize: 12 }}>Type:</Text>
                          </Grid.Col>
                          <Grid.Col span={9}>
                            <PLComboBox
                              disabled={true}
                              value={DropDownVal}
                              data={S11}
                              dispexpr={"S11F02"}
                              valexpr={"S11F01"}
                              setEdit={(e) => {
                                // console.log(e)
                                setDropDownVal(e);
                                ChangePageFun(e);
                                // setChangePage(e)
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>

                        {changePage != "BCBC" ? (
                          <Grid.Col
                            span={12}
                            style={{
                              display: "flex",
                              padding: 0,
                              alignItems: "center",
                            }}
                          >
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>Tax Type:</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLComboBox
                                value={T41Obj.FIELD21}
                                data={COPJR}
                                dispexpr={"DisplayMember"}
                                valexpr={"ValueMember"}
                                setEdit={(e) => {
                                  // console.log("==>", e)
                                  setT41Obj({ ...T41Obj, FIELD21: e });
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : null}

                        {T41Obj?.FIELD21 == "T" ? (
                          <Grid.Col
                            span={12}
                            style={{
                              display: "flex",
                              padding: 0,
                              alignItems: "center",
                            }}
                          >
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>Nature of Payment:</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDataGrid
                                value={T41Obj?.FIELD36}
                                width={"500px"}
                                TmData={`${P_M53NO}'`}
                                // data={P_M53NODT?.jData}
                                setEdit={(e) => {
                                  console.log("Nature of Payment =>", e)
                                  setT41Obj({ ...T41Obj, FIELD36: e?.FIELD01 });
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : null}
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <Grid.Col
                          span={12}
                          style={{ display: "flex", padding: 0, alignItems: "center" }}
                        >
                          <Grid.Col span={3}>
                            <Text style={{ fontSize: 12 }}>Date:</Text>
                          </Grid.Col>
                          <Grid.Col span={7}>
                            <PLDateBox
                              dispformat="DD/MM/YYYY"
                              // defaultval={T41Obj?.FIELD02}
                              value={dateBoxData.DateOne}
                              // defaultval={T41Obj?.FIELD02?.trim() != "" ? T41Obj?.FIELD02 : GetYearAndDate?.strtDate}
                              setEdit={(e) => {
                                // console.log(e)
                                let year = e.toLocaleString("en-IN", {
                                  year: "numeric",
                                });
                                let month = e.toLocaleString("en-IN", {
                                  month: "2-digit",
                                });
                                let day = e.toLocaleString("en-IN", { day: "2-digit" });

                                let BillDate = year + month + day;

                                if (BillDate?.length == 8) {
                                  setT41Obj({ ...T41Obj, FIELD02: BillDate });
                                }
                                setDateBoxData({ ...dateBoxData, DateOne: e });
                                setSelectedDay(new Date(parseInt(year), parseInt(month) - 1, parseInt(day)))
                              }}
                            />
                          </Grid.Col>
                          <Grid.Col span={2}>
                            <Text size={12} style={{ color: "red" }}>
                              {selectedDay.toLocaleDateString("en-IN", { weekday: 'short' })}
                            </Text>
                          </Grid.Col>
                        </Grid.Col>

                        <Grid.Col
                          span={12}
                          style={{ display: "flex", padding: 0, alignItems: "center" }}
                        >
                          <Grid.Col span={3}>
                            <Text style={{ fontSize: 12 }}>Number:</Text>
                          </Grid.Col>
                          <Grid.Col span={9}>
                            <PLNumberBox
                              disabled={
                                changePage == "BPBP"
                                  ? true
                                  : changePage == "BRBR"
                                    ? true
                                    : changePage == "CPCP"
                                      ? true
                                      : changePage == "BCBC"
                                        ? true
                                        : false
                              }
                              value={T41Obj?.FIELD12}
                              setEdit={(e) => {
                                // console.log(e)
                                setT41Obj({ ...T41Obj, FIELD12: e });
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                      </Grid.Col>

                      <Grid.Col span={4}>
                        <>
                          {/* {
                                changePage == 'BPBP' || 'BRBR' || 'CPCP' || 'BCBC' ? <>
                                    <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>Chq. No.:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLTextBox
                                                value={T41Obj?.FIELD10}
                                                setEdit={(e) => {
                                                    console.log(e)
                                                    setT41Obj({ ...T41Obj, FIELD10: e.target.value })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                    <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>Chq. Date:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLDateBox
                                                dispformat="DD/MM/YYYY"
                                                // defaultval={T41Obj?.FIELD11}
                                                value={dateBoxData.DateTwo}
                                                setEdit={(e) => {
                                                    console.log(e)
                                                    let year = e.toLocaleString("en-IN", { year: "numeric" });
                                                    let month = e.toLocaleString("en-IN", { month: "2-digit" });
                                                    let day = e.toLocaleString("en-IN", { day: "2-digit" });

                                                    let BillDate = year + month + day;
                                                    setT41Obj({ ...T41Obj, FIELD11: BillDate })
                                                    setDateBoxData({ ...dateBoxData, DateTwo: e })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                </> : null
                            }

                            {
                                changePage == 'CRCR' || 'NCNC' || 'NDND' || 'JRJR' ? <>
                                    <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>Doc. No.:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLTextBox
                                                value={T41Obj?.FIELD10}
                                                setEdit={(e) => {
                                                    console.log(e)
                                                    setT41Obj({ ...T41Obj, FIELD10: e.target.value })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                    <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>Doc. Date:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLDateBox
                                                dispformat="DD/MM/YYYY"
                                                // defaultval={T41Obj?.FIELD11}
                                                value={dateBoxData.DateTwo}
                                                setEdit={(e) => {
                                                    console.log(e)
                                                    let year = e.toLocaleString("en-IN", { year: "numeric" });
                                                    let month = e.toLocaleString("en-IN", { month: "2-digit" });
                                                    let day = e.toLocaleString("en-IN", { day: "2-digit" });

                                                    let BillDate = year + month + day;
                                                    setT41Obj({ ...T41Obj, FIELD11: BillDate })
                                                    setDateBoxData({ ...dateBoxData, DateTwo: e })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                </> : null
                            } */}
                        </>
                        <>{RenderInputBox(changePage)}</>
                      </Grid.Col>
                    </Grid.Col>

                    <Grid.Col span={12}>
                      <MantineReactTable table={table} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Grid.Col span={12} style={{ padding: "10px 0px" }}>
                        <Text size={"12px"}>Balance : {SelectedRowBal}</Text>
                      </Grid.Col>
                      <Grid.Col span={12} p={0}>
                        <Grid.Col span={6}>
                          <Text size={"12px"}>Narration:</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLTextarea
                            minRows={2}
                            // value={T41Obj?.FIELD15}
                            value={table?.getSelectedRowModel()?.flatRows?.length > 0 ? table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD25 : T41Obj?.FIELD15}
                            onChange={(e) => {
                              // //console.log(e)
                              setT41Obj({ ...T41Obj, FIELD15: e?.target?.value });
                            }}
                            onKeyDown={(e) => {
                              // console.log("Narration=>", e);
                              if (e.ctrlKey && e.key === "F1") {
                                console.log("Narration call");

                                setDrawerObj({
                                  ...dra, title: <Text fw={700}>Naration</Text>, body: <>
                                    <PLNarration
                                      NarrationArray={NarrationData}
                                      selectedNarrationArray={
                                        SelectedNarrationData
                                      }
                                      // NarrationVal={T41Obj}
                                      setNarrationArray={(e) => {
                                        // setT41Obj(e)
                                        console.log("setNarrationArray", e);
                                        setNarrationData(e.NarrationData);
                                        setSelectedNarrationData(
                                          e.selectedNarration
                                        );
                                      }}
                                      DrawerOBJ={DrawerObj}
                                      setDrawerOBJ={(e) => setDrawerObj(e)}
                                    />
                                  </>, open: true, size: "50%", position: "right"
                                })
                              }
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <Grid.Col span={12}>
                        <Group spacing={"xs"} position="right">
                          <Button
                            // disabled={T01Data.length > 2 ? false : true}
                            id="okbutton"
                            style={{ height: "30px", width: "100px" }}
                            onClick={() => T01Data.length >= 1 ? JournalEntrySubmit() : GlobalClass.Notify("warning", "Warning", "Minimum 2 Account Entry Required.")}
                          >
                            Ok
                          </Button>
                          <Button style={{ height: "30px", width: "100px" }}>
                            Print
                          </Button>
                        </Group>
                      </Grid.Col>
                    </Grid.Col>
                  </Grid>
                </Paper>
              </>
          }
          {
            lEditVou == false && GlobalClass.Alert("info", props.index)
          }
        </>
        )}
      </>
  }
  </>
  );
};

export default JournalForm;
