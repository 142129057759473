import { createStyles, Header, Autocomplete, Group, Burger, rem, ScrollArea, Select, Transition, Card, HoverCard, Button, Text, Title, Loader, Grid, Progress, Modal, Alert, MediaQuery, useMantineTheme, Drawer, ActionIcon, Menu, Avatar, TextInput, Image, CopyButton, Tooltip, useMantineColorScheme, RingProgress, Center, ThemeIcon, Timeline, Popover } from '@mantine/core';
import { useDisclosure, useLocalStorage, useMediaQuery } from '@mantine/hooks';
import { IconAlertCircle, IconAlertTriangle, IconArrowDown, IconArrowsMaximize, IconArrowsMinimize, IconBrandWhatsapp, IconCalculator, IconCheck, IconChevronRight, IconCopy, IconDots, IconError404, IconHeart, IconHistory, IconLogout, IconMessage, IconMoonStars, IconPlayerPause, IconSearch, IconSettings, IconStar, IconSun, IconTrash, IconUser } from '@tabler/icons-react';
import React, { useContext, useEffect, useState } from 'react';
import Sidebar, { NavbarName } from './sidebar';
import { useDispatch, useSelector } from 'react-redux';
import Localize from '../utils/rc';
import PLComboBox from '../PlusComponent/PLComboBox';
import YearAndDate from '../MyComponent/YearAndDate';
import Store from '../utils/store';
import { SetCmpNumber, GetUserList, GetMenuList, GetAddCmpEnt } from '../utils/slices/UserListSlice';

import { AnyAction } from '@reduxjs/toolkit';
import { modals } from '@mantine/modals';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
// import { ReactDOM } from 'react';
import ReactDOM from 'react-dom';
import { GetYearDateUrl } from '../utils/slices/CompanySetupSlice';
import { GetLanguageData } from '../utils/slices/LanguageSlice';
import { ModalFunction } from '../utils/slices/ModalSlice';
import PLDateBox from '../PlusComponent/PLDateBox';
import { ClearTabs, TabAdd } from '../utils/slices/TabList';
import AddNewCompanyForm from '../FunctionsCall/ChangeCompany/AddNewCompanyForm';
import { IconSwitchHorizontal } from '@tabler/icons-react';
import PLTextBox from '../PlusComponent/PLTextBox';
import PLNarration from '../PlusComponent/PLNarration';
import { GetAllForamt, GetAllForamtVersion, GetTMFormat } from '../utils/slices/AccountListSlice';
import { LogoutUser } from '../utils/Auth/LoginSlices';
import { fetchDataFromDB } from '../IndexedDB/IndexedDB';
import GlobalClass from '../utils/GlobalClass';
import { ChangeCompanyTable } from '../FunctionsCall/ChangeCompany/ChangeCompanyTable';
import SessionSetting from './SessionSetting';
import useWindowDimensions from '../utils/UseWindowDimensions';
import gensetting from '../utils/gensetting';
import MainMenu from './Menu';
import AppData from '../App.json';
import Changelog from './Changelog';
import WhatsAppPopover from '../MyComponent/WhatsappPopup';
const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
  },

  inner: {

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  innermenu: {

    display: 'flex',
    justifyContent: 'flex-start',
    marginRight:10,
    alignItems: 'center',
  },
  links: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  search: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },
}));

// interface HeaderSearchProps {
//   links: { link: string; label: string }[];
//   opened: boolean,
//   setOpened: Function
// }

export default function DefaultHeader({ links, opened, setOpened }) {

  const theme = useMantineTheme();
  let GetLanguage = new Localize();
  const selected = useSelector((state) => state.TabList.selectedMenu);
  // const [opened, { toggle }] = useDisclosure(false);
  const [companyModal, cmpAction] = useDisclosure(false);
  const { classes } = useStyles();
  // const [opened, { open, close }] = useDisclosure(false);
  const [selectedCmp, setSelectedCmp] = useState({ cmpNo: "", cmpName: "" });
  const [cmpLoading, setCmpLoading] = useState(false);
  const [columns, setColumns] = useState([])
  const [progressBar, setProgressBar] = useState(0);
  const [Loadingmsg, setLoadingmsg] = useState("");
  // const UserListData = useSelector((state: any) => state.UserList?.UserListData);
  const { isLoading, UserListData, isMenuLoading, CmpNumber, hasError: UserListHasError, ErrorMsg: UserListError } = useSelector((state) => state.UserList);

  const dispatch = useDispatch();
  // const langisLoading = useSelector((state) => state.Language?.isLoading);
  const { hasError: LangError, ErrorMsg: LangErrormsg, isLoading: langisLoading } = useSelector((state) => state.Language);    // Vara Pooja
  const { hasError: CompanySetupHasError, ErrorMsg: CompanySetupErrormsg, isLoading: yrisLoading } = useSelector((state) => state.CompanySetup);   // Vara Pooja

  // const yrisLoading = useSelector((state) => state.CompanySetup?.isLoading);
  // const CompanySetupHasError = useSelector(state => state?.CompanySetup?.hasError)
  const [LanguageData, setLanguageData] = useState([]);

  const isGetAllFrmtApiLoading = useSelector(store => store.AccountList.isGetAllFrmtApiLoading)
  const GetAllForamtVersionData = useSelector(store => store.AccountList.GetAllForamtVersionData)
  const [GetAllForamtData, setGetAllForamtData] = useState();
  const [flag, setFlag] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedCmpObj, setSelectedCmpObj] = useState({})

  const [FullScreen, setFullScreen] = useState(false);
  const [showDate, setShowDate] = useState(null)

  const {
    hasError: accHasError,
    ErrorMsg: accErrorMsg,
    isLoading: accIsLoading,
  } = useSelector((state) => state.AccountList);

  // const [sessionSetting, setSessionSetting] = useLocalStorage({
  //   key: 'session_setting',
  //   defaultValue: "null",
  //   getInitialValueInEffect: true,
  // })

  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseBtn: ""
  }

  const [DrawerObj, setDrawerObj] = useState(dra);

  const obj = {
    "id": "",
    "name": "",
    "type": "A",
    "p0": "",
    "p1": "",
    "p2": "",
    "p3": ""
  }

  
  // useEffect(()=>{
  //   console.log("GetUserList api CALL", UserListData)
  // },[UserListData])

  // useEffect(()=>{
  //   if(sessionSetting){
  //     console.log("header sessionSetting=>",sessionSetting);
  //   }
  // },[sessionSetting])

  // useEffect(()=>{
  //   if(UserListData){
  //     console.log("UserListData",UserListData);
  //   }
  // },[UserListData])

  useEffect(() => {
    if (accHasError) {
      GlobalClass.Notify("error", "Error: " + accErrorMsg.code, accErrorMsg.message);
    }
  }, [accHasError]);

  useEffect(() => {
    if (UserListHasError) {
      
      GlobalClass.Notify(
        "error",
      'Error: ' + UserListError?.code,
      UserListError?.message,
      false,
      3000)
    }
  }, [UserListHasError])
  useEffect(() => {
   
    setSelected();
  }, [CmpNumber,sessionStorage.getItem("cmp")])
  useEffect(() => {
    dispatch(GetUserList({ Action:"A" }))
  }, [])

  const setSelected = (id = "") => {
    let cmp = sessionStorage.getItem("cmp");
    if(!UserListHasError){
      if (cmp && UserListData != '' && UserListData?.cmpList && UserListData?.cmpList.length > 0) {
      setCmpLoading(true);
      setLoadingmsg("Company Data")
      // GlobalClass.Notify("info","Loading","Company Data",true,false,"cmp")
      cmpAction.open();
      var data = UserListData?.cmpList?.filter((e) => e["cmpNo"] == cmp ? e : "");
      setSelectedCmp(data[0]);
      dispatch(SetCmpNumber(cmp))
      // console.log("selected", data);

    } else {
      // dispatch(ClearTabs(''))
      cmpAction.open();
    }}

  }
 
  const items = links?.map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={classes.link}
      onClick={(event) => event.preventDefault()}
    >
      {link.label}
    </a>
  ));

  // const handleAdd = () => {
  //   Store.dispatch(
  //     ModalFunction({
  //       onclose: () => { },
  //       MTitle: <Text weight={500}>Add New Company</Text>,
  //       MAction: true,
  //       MSize: '70%',
  //       MBody: (i) => (
  //         <AddNewCompanyForm action={"A"} index={i} />
  //       ),
  //       MClose: true,
  //       Overlay: {
  //         backgroundOpacity: 0.8,
  //         blur: 5,
  //       },
  //       MCentered: true,
  //       closeOnClickOutside: true,
  //     })
  //   )
  // }

  // const handleEdit = () => {
  //   Store.dispatch(
  //     ModalFunction({
  //       onclose: () => { },
  //       MTitle: <Text weight={500}>Edit Company</Text>,
  //       MAction: true,
  //       MSize: '70%',
  //       MBody: (i) => (
  //         <AddNewCompanyForm
  //           TableRow={table.getSelectedRowModel().flatRows[0].original}
  //           action={"E"}
  //           index={i}
  //         />
  //       ),
  //       MClose: true,
  //       Overlay: {
  //         backgroundOpacity: 0.8,
  //         blur: 5,
  //       },
  //       MCentered: true,
  //       closeOnClickOutside: true,
  //     })
  //   )
  // }

  // const handleDelete=()=>{
  //   // dispatch(GetAddCmpEnt({ cmpNo: TableRow?.cmpNo, action: "E" }))

  //   let TableRow = table.getSelectedRowModel().flatRows[0].original

  //   if (window.confirm('Confirm to Delete ?')) {
  //     Promise.resolve(GlobalClass.Notify("info", "Info: " + "Delete Progress", "Please Wait Fetching Data")).then(() => Store.dispatch(GetAddCmpEnt({ cmpNo: TableRow?.cmpNo, action: "D" })))
  //   }
  // }

  // const table = useMantineReactTable({ //Nairin Salot
  //   data: UserListData?.cmpList && UserListData?.cmpList.length > 0 ? UserListData?.cmpList : [],
  //   columns,
  //   enableTopToolbar: false,
  //   initialState: {
  //     density: "5px",
  //   },
  //   state: {
  //     rowSelection
  //   },
  //   mantineTableContainerProps: { sx: { height: 400, width: 900 } },
  //   enableRowVirtualization: false,
  //   enableSelectAll: false,
  //   enableMultiRowSelection: false,
  //   enableColumnOrdering: false,
  //   enableGlobalFilter: false,
  //   enableGrouping: false,
  //   enableDensityToggle: false,
  //   enableColumnActions: false,
  //   enablePagination: false,
  //   enableTableFooter: false,
  //   enableBottomToolbar: false,
  //   layoutMode: 'grid',
  //   mantineTableBodyRowProps: ({ row }) => ({
  //     onClick: () => {
  //       setRowSelection((prev) => ({
  //         [row.id]: !prev[row.id],
  //       }))
  //     },
  //     onDoubleClick: () => {
  //       console.log("row", row);

  //       if (sessionStorage.getItem("cmp") == row.getValue("cmpNo")) {
  //         // modals.closeAll();
  //         cmpAction.close();
  //         // setCmpLoading(false);
  //       } else {
  //         sessionStorage.setItem("cmp", row.getValue("cmpNo"));
  //         setSelected(row.getValue("cmpNo"));
  //         dispatch(SetCmpNumber(row.getValue("cmpNo")))
  //         setCmpLoading(true);
  //         modals.closeAll();
  //         dispatch(ClearTabs(''))
  //       }

  //     },
  //     sx: { cursor: 'pointer' },
  //   }),
  // });




  useEffect(() => {
    if (cmpLoading) {
      dispatch(GetLanguageData())
      setLoadingmsg("Language Data")
      // Promise.resolve(dispatch(GetLanguageData())).then(()=>dispatch(GetUserList({ Action:"A" })))
      // GlobalClass.Notifyupdate("info","Loading","Language Data",true,false,"cmp")
      // GlobalClass.Notifyupdate("info","Loading","Language Data",true,false,"cmp")
    }
  }, [cmpLoading])

  useEffect(() => {
    if (cmpLoading && !langisLoading && !LangError) {
      
      var i = 0;
      var intv = setInterval(() => {
        if (i < 25) {
          setProgressBar(i + 1);
          i++;
        }
        else {
          clearInterval(intv)
          setLanguageData(GetLanguage.GetLanguageList());
          setLoadingmsg("Year Data")
          // GlobalClass.Notifyupdate("info","Loading","Year Data",true,false,"cmp")
          dispatch(GetYearDateUrl())
        }
      }, 50)
    }
    else{
      if(LangError){
        GlobalClass.Notifyupdate(
          "error",
          'Loading Error',
          LangErrormsg,
          false,
          3000,
          'cmp');
      }
    }
  }, [langisLoading])

  useEffect(() => {
    // console.log("yrisLoading",yrisLoading);

    if (cmpLoading && !yrisLoading && !CompanySetupHasError) {

      var i = 25;
      var intv = setInterval(() => {
        if (i < 50) {
          setProgressBar(i + 1);
          i++;
        }
        else {
          clearInterval(intv)
          setLoadingmsg("Menu Data")
          // GlobalClass.Notifyupdate("info","Loading","Menu Data",true,false,"cmp")
          dispatch(GetMenuList());
        }
      }, 50)
    }
    else {
      if (CompanySetupHasError) {
        GlobalClass.Notifyupdate(
          "error",
           'Loading Error',
            CompanySetupErrormsg,
             false,
              3000,
               'cmp');
      }
    }
  }, [yrisLoading])

  useEffect(() => {
    if (cmpLoading && !isMenuLoading && !UserListHasError) {

      var i = 50;
      var intv = setInterval(() => {
        if (i < 75) {
          setProgressBar(i + 1);
          i++;
        }
        else {
          clearInterval(intv)
          // setCmpLoading(false)
          // setProgressBar(0);
          // cmpAction.close();
          // dispatch(GetAllForamt());
          // GlobalClass.Notifyupdate("info","Loading","Other Essential Data",true,false,"cmp")
          setLoadingmsg("Other Essential Data")
          const fetchData = async () => {
            const S60 = await fetchDataFromDB("S60");
            const S60C = await fetchDataFromDB("S60C");
            setGetAllForamtData({ S60, S60C });

            if (Object.values(S60).length > 0 && Object.values(S60C)) {
              dispatch(GetAllForamtVersion());
            }
            else {
              dispatch(GetAllForamt());
            }
          };
          fetchData();

        }
      }, 50)
    }
  }, [isMenuLoading])

  useEffect(() => {
    if (GetAllForamtVersionData && !accHasError) {
      setLoadingmsg("Other Essential Data")
      // GlobalClass.Notifyupdate("info","Loading","Other Essential Data",true,false,"cmp")
      getFormatVersionCheck()
    }
  }, [GetAllForamtVersionData])

  const getFormatVersionCheck = async () => {


    if (GetAllForamtData && GetAllForamtVersionData) {
      let fl = false;

      // let updatedData = GetAllForamtVersionData?.S60?.map((item, index) => {
      //   if (index < 10) {  // Check if the current index is less than 20
      //     return {
      //       ...item,         // Copy all existing properties
      //       S62VER: 4      // Update S62VER property
      //     };
      //   }
      //   return item;  // For items beyond the first 20, return as-is
      // });

      // console.log("updatedData", updatedData);

      let ary = [];

      const Result = Promise.resolve(GetAllForamtVersionData?.S60?.map(async (item, index) => {
        GetAllForamtData?.S60?.map(async (element, i) => {
          // if (index === 0 && i === 0 || index === 1 && i === 1 || index === 2 && i === 2 ) {
          //   dispatch(GetTMFormat({ ...obj, p1: item.S62F02 }));
          //   fl = true;
          // }

          if (item.S62F01 === element.S62F01 && item.S62F02 === element.S62F02) {
            if (item.S62VER !== element.S62VER) {
              ary.push({ ...obj, p1: element.S62F01, p2: element.S62F02, isTmFormatNeeded: true })
              // dispatch(GetTMFormat());
              fl = true;
            }
          }
        })
      }))

      const Result1 = Promise.resolve(GetAllForamtVersionData?.S60C?.map(async (item) => {
        GetAllForamtData?.S60C?.map(async (element) => {
          if (item.S62F01 === element.S62F01 && item.S62F02 === element.S62F02) {
            if (item.S62VER !== element.S62VER) {
              ary.push({ ...obj, p1: element.S62F01, p2: element.S62F02, isTmFormatNeeded: true })
              // dispatch(GetTMFormat());
              fl = true;
            }
          }
        })
      }))

      Promise.all([Result, Result1]).then(async (values) => {

        //  then ma logic implement krvanu kidhu jo error ave to catch ma jay 

        async function callApiForEachItem(ary, maxRetries = 3) {
          if (ary.length > 0) {

            const processItem = async (obj) => {
              let attempt = 1;
              let success = false;

              while (attempt < maxRetries) {

                // console.log("object", obj?.p1, obj?.p2);
                try {
                  let StrtDt, EndDt;

                  const seprate = (e) => {
                    var paraArr = e.split('T');
                    var paraArr1 = paraArr[0].replaceAll("-", "");
                    return paraArr1;
                  }

                  if (obj?.StrtDt && obj?.EndDt) {
                    StrtDt = seprate(obj?.StrtDt);
                    EndDt = seprate(obj?.EndDt);
                  }

                  let param = JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cWhere": "",
                    "cRepId": obj?.p1 ?? "T_M01",
                    "cFmtID": obj?.p2 ?? "TFORM0000001",
                    "StrtDt": StrtDt ?? obj?.StrtDt?.replaceAll("-", "") ?? "20210401",
                    "EndDt": EndDt ?? obj?.EndDt?.replaceAll("-", "") ?? "20220331",
                  });

                  const requestOptions = {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                  };

                  const response = await fetch(GlobalClass.ApiUrl + GlobalClass.GetTMFormat + "?pa=" + param, requestOptions);
                  const data = await response.json();

                  if (data.status === 'SUCCESS') {
                    // console.log("success");
                    success = true;
                    break;
                  }
                  else {
                    console.error(`API call failed with status: ${data.status}. Retrying...`);
                  }
                } catch (e) {
                  console.error(`API call failed with exception: ${e.message}. Retrying...`);
                }

                attempt++;
              }

            };

            for (const obj of ary) {
              // console.log("obj", obj);
              await processItem(obj);
            }

            setFlag(true);
          }
          else {
            if (!fl) {
              var i = 75;
              var intv = setInterval(() => {
                if (i < 100) {
                  setProgressBar(i + 1);
                  i++;
                }
                else {
                  clearInterval(intv)
                  setCmpLoading(false)
                  // setLoadingmsg("")
                  // GlobalClass.Notifyupdate("success","Complete","Data Loaded",false,3000,"cmp")
                  setProgressBar(0);
                  cmpAction.close();

                }
              }, 50)
            }
          }
        }

        callApiForEachItem(ary)

      });

      // await Promise.all(
      //   GetAllForamtVersionData?.S60?.map(async (item, index) => {
      //     await Promise.all(
      //       GetAllForamtData?.S60?.map(async (element, i) => {
      //         // if (index === 0 && i === 0 || index === 1 && i === 1 || index === 2 && i === 2 ) {
      //         //   dispatch(GetTMFormat({ ...obj, p1: item.S62F02 }));
      //         //   fl = true;
      //         // }
      //         if (item.S62F01 === element.S62F01 && item.S62F02 === element.S62F02) {
      //           if (item.S62VER !== element.S62VER) {
      //             dispatch(GetTMFormat({ ...obj, p1: item.S62F02 }));
      //             fl = true;
      //           }
      //         }
      //       })
      //     );
      //   })
      // );

      // await Promise.all(
      //   GetAllForamtVersionData?.S60C?.map(async (item) => {
      //     await Promise.all(
      //       GetAllForamtData?.S60C?.map(async (element) => {
      //         if (item.S62F01 === element.S62F01 && item.S62F02 === element.S62F02) {
      //           if (item.S62VER !== element.S62VER) {
      //             fl = true;
      //           }
      //         }
      //       })
      //     );
      //   })
      // );

      // setFlag(fl);
      // if (!fl) {
      //   var i = 75;
      //   var intv = setInterval(() => {
      //     if (i < 100) {
      //       setProgressBar(i + 1);
      //       i++;
      //     }
      //     else {
      //       clearInterval(intv)
      //       setCmpLoading(false)
      //       setProgressBar(0);
      //       cmpAction.close();
      //     }
      //   }, 50)
      // }
    }
  };

  useEffect(() => {
    if (flag === true) {
      dispatch(GetAllForamt())
    }
  }, [flag])

  useEffect(() => {
    if (cmpLoading && !isGetAllFrmtApiLoading ) { //&& !LangError
      setLoadingmsg("")
      // GlobalClass.Notifyupdate("success","Complete","Data Loaded",false,3000,"cmp")
      var i = 75;
      var intv = setInterval(() => {
        if (i < 100) {
          setProgressBar(i + 1);
          i++;
        }
        else {
          clearInterval(intv)
          setCmpLoading(false)
          setProgressBar(0);
          cmpAction.close();

        }
      }, 50)
    }
  }, [isGetAllFrmtApiLoading])

  // useEffect(() => {
  //   if (cmpLoading && !isGetAllFrmtApiLoading) {
  //     setCmpLoading(false)
  //     setProgressBar(0);
  //     cmpAction.close();
  //   }
  // }, [isGetAllFrmtApiLoading])
  const { height, width } = useWindowDimensions();

  const ModalContent = () => { //Nairin Salot
    
    if(UserListHasError){

      console.log("UserListError when true =>",UserListError);
      return <Center h={height * 0.8} width={width} mx="auto">
      <Alert icon={<IconAlertCircle size="1rem" />} title="Error in Loading Company Data" color="red">
        {LangErrormsg ? LangErrormsg : CompanySetupErrormsg ? CompanySetupErrormsg : UserListError ? UserListError : accErrorMsg}<br />
        <Button variant="filled" fullWidth color='indigo' size="xs" compact onClick={() => {
          dispatch(GetUserList({ Action:"A" }))
        }}>Retry</Button>
      </Alert>
    </Center> 
    }else{
      // if(UserListHasError){
      //   console.log("UserListError when false =>",UserListError);
      // }
      if (cmpLoading) {
        //when cmpLoading true
        return <Center h={height * 0.8} width={width} mx="auto">
          {
            (LangError || CompanySetupHasError || UserListHasError || accHasError) ?
              <>
                <Alert icon={<IconAlertCircle size="1rem" />} title="Error in Loading Company Data" color="red">
                  {LangErrormsg ? LangErrormsg : CompanySetupErrormsg ? CompanySetupErrormsg : UserListError ? UserListError : accErrorMsg}<br />
                  <Button variant="filled" fullWidth color='indigo' size="xs" compact onClick={() => {
                    sessionStorage.clear();
                    window.location.reload();
                  }}>Retry</Button>
                </Alert>
              </> :
              <Grid gutter={4} style={{ borderWidth: 1, borderRadius: 8, borderColor: 'red' }}>
                {!LangError ?
                  <>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      {/* Loading Company Data...{progressBar}% */}
                      {<RingProgress
                        sections={[{ value: progressBar, color: progressBar < 100 ? 'blue' : 'teal' }]}
                        label={
                          progressBar < 100 ? <Text color="blue" weight={700} align="center" size="xl">
                            {progressBar}%
                          </Text> : <Center>
                            <ThemeIcon color="teal" variant="light" radius="xl" size="xl">
                              <IconCheck size={22} />
                            </ThemeIcon>
                          </Center>
                        }
                      />}
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Text color="gray" align="center" size="sm">Hang tight! We're crunching the numbers to keep your finances in check.</Text>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Text color="dark" align="center" size="sm"> Loading {Loadingmsg} please wait...</Text><Loader size={'xs'} />
                    </Grid.Col>
                  </> :
                  <>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>{LangErrormsg}</Grid.Col>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button variant="filled" color='indigo' size="xs" compact onClick={() => {
                        sessionStorage.clear();
                        window.location.reload();
                      }}>Retry</Button>
                    </Grid.Col>
  
                  </>}
                {/* <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}><Loader /></Grid.Col> */}
                {/* {!LangError && <Grid.Col span={12}>
            <Progress aria-label={progressBar + "%"} size={"lg"} radius="md" value={progressBar} striped animate />
          </Grid.Col>} */}
              </Grid>
          }
        </Center>
      } else {
        //when cmpLoading false
        
        return isLoading ? GlobalClass.RenderLoader('dots') : <ChangeCompanyTable cmpAction={cmpAction} />
        {/* <MantineReactTable table={table} />
          <Group style={{ marginTop: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button variant='light' onClick={() => handleAdd()} compact>New</Button>
            <Button variant='light' onClick={() => table.getSelectedRowModel().flatRows.length > 0 ? handleEdit() : GlobalClass.Notify("info", "Info", "Please Select Company")} compact>Edit</Button>
            <Button variant='light' onClick={() => table.getSelectedRowModel().flatRows.length > 0 ? handleDelete() : GlobalClass.Notify("info", "Info", "Please Select Company")} compact>Delete</Button>
          </Group> */}
  
      }
    }

    
  }

  const SessionSettingFun=()=>{
    setDrawerObj({
      ...dra,
      title: <Text  fw={700}>Session Setting</Text>,
      body: <>
      <SessionSetting />
      </>,
      open: true,
      size: "md",
      position: "right"
    })
  }

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === 'dark';
  // console.log("UserListData===>",UserListData);
  const mhook = useMediaQuery('(min-width:64em)')
  const [changelogopened, setchangelog] = useDisclosure(false);
  return (
    <>
      <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={true}
        fullScreen={false}//
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: (e) => {
            setDrawerObj(dra);
          },
        }}
        title={DrawerObj?.title}
        size={DrawerObj?.size}
        // centered={.Centered}
        closeOnClickOutside={true}
        // overlayProps={m.Overlay}
        position={DrawerObj?.position ?? 'bottom'}
        onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
      // scrollAreaComponent={ScrollArea.Autosize}
      >
        {DrawerObj.body}
      </Modal>

      <Modal
        title={cmpLoading ? null : UserListHasError ? null : <Text fw={700}>Select Company</Text>}
        opened={companyModal}
        onClose={cmpAction.close}
        closeOnClickOutside={false}
        fullScreen={UserListHasError?UserListHasError:cmpLoading}
        closeOnEscape={sessionStorage.getItem("cmp")?true:false}
        centered={true}
        withCloseButton={sessionStorage.getItem("cmp")?true:false}
        size={'50%'}
      >
        {ModalContent()}
      </Modal>
      <Drawer opened={changelogopened} onClose={setchangelog.close} title="ChangeLog" overlayProps={{ opacity: 0.5, blur: 4 }} position='right'>
      <Changelog/>
      </Drawer>
      <Header height={mhook ? 80 : 40} style={{ padding: 2 }} className={classes.header}>
        <div className={classes.inner}>
          <Group>
            <Group mr="xl">
              <MediaQuery largerThan="md" styles={{ display: 'none' }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                />
              </MediaQuery>

            </Group>
            {/* <Burger opened={opened} onClick={toggle} size="sm" /> */}
            {/* <h3>
              {selected}
            </h3> */}
            <Group style={{ filter: colorScheme == "dark" ? "grayscale(100%) invert(1);" : "" }}>
              <Image
                src='logo1.svg'
                height={35}
                sx={{ filter: colorScheme == "dark" ? "grayscale(100%) invert(1);" : "" }}
              />
            </Group>
            <MediaQuery smallerThan={"md"} styles={{ display: 'none' }}>
              <Group>
                {/* <MainMenu opened setOpened={()=>{setOpened(!opened)}} /> */}
                  <Group>
                {
                  // isLoading == true ? <Text>Loading...</Text> :

                  cmpLoading == false && isLoading == false && UserListData != '' && UserListData?.cmpList != '' && selectedCmp?.cmpNo &&
                  <Button variant="outline" color='gray' size="xs" compact
                    onClick={() => //Nairin Salot
                    // cmpAction.open()
                    
                    {
                      dispatch(GetUserList({ Action:"A" }))
                      cmpAction.open();
                      // return modals.open({
                      //   title: <Text fw={700}>Select Company</Text>,
                      //   size: "60%",
                      //   centered: true,
                      //   closeOnClickOutside: false,
                      //   closeOnEscape: sessionStorage.getItem("cmp")?true:false,
                      //   withCloseButton: sessionStorage.getItem("cmp")?true:false,
                      //   children: <ChangeCompanyTable />,
                      //   zIndex: "100"
                      // });
                    }
                    }
                  >
                    {selectedCmp?.cmpNo + " : " + selectedCmp?.cmpName}
                  </Button>

                }
              </Group>

                {selectedCmp?.cmpNo && cmpLoading == false && <Group>
                  {/* <Text>Test</Text> */}
                  <YearAndDate />
                </Group>}
                {/* <Popover width={200} position="bottom" withArrow shadow="md">
      <Popover.Target>
      <ActionIcon color="green" size="xl" variant="transparent">
                  <IconBrandWhatsapp/>
                </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Text size="sm">This is uncontrolled popover, it is opened when button is clicked</Text>
      </Popover.Dropdown>
    </Popover> */}
              <WhatsAppPopover/>
                </Group>
            </MediaQuery>
          </Group>
          <Group>
           

            <Group style={{ marginRight: 15 }}>
              <Menu
                withArrow
                arrowPosition='center'
                width={300}
                position="bottom"
                transitionProps={{ transition: 'slide-down' }}
                withinPortal
              >
                <Menu.Target>
                  <Button 
                  variant="light" 
                  // rightIcon={<ActionIcon variant="transparent">
                  //   <IconArrowDown />
                  // </ActionIcon>}
                  leftIcon={<ActionIcon variant="transparent">
                    <IconUser />
                  </ActionIcon>}
                  color="gray">
                    
                    <div>
                      <Text variant='text' lh={1} fz={"sm"} fw={700} p={0} m={0}>{UserListData?.userNm}</Text>
                      <Text variant='text' lh={1} fz={"xs"} c="dimmed" p={0} m={0}>{UserListData?.CustName}</Text>
                    </div>
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  {/* <Menu.Item
                    rightSection={
                      <IconChevronRight style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                    }
                  >
                     <Group>
                       <Avatar
                        radius="xl"
                        src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-7.png"
                      /> 

                      <div>
                        <Text fw={500}>Akash Kothari</Text>
                        <Text size="xs" c="dimmed">
                          aakash123kothari@gmail.com
                        </Text>
                      </div>
                    </Group> 
                  </Menu.Item> */}
                  <Menu.Item
                    onClick={(e) => { e.preventDefault() }}
                    rightSection={
                      <CopyButton value={UserListData?.CustID} timeout={2000}>
                        {({ copied, copy }) => (
                          <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                            <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                              {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                            </ActionIcon>
                          </Tooltip>
                        )}
                      </CopyButton>
                    }
                  >
                    <Group>
                      <Text fw={500} fz={'xs'}>Cust ID:</Text>
                      <Text fz={'xs'}>{UserListData?.CustID}</Text>
                    </Group>
                  </Menu.Item>

                  <Menu.Divider />
                  <Menu.Label>Settings</Menu.Label>
                  {/* <Menu.Item
                    onClick={() => toggleColorScheme()}
                    rightSection={
                      <ActionIcon
                        variant="outline"
                        color={dark ? 'yellow' : 'blue'}

                        title="Toggle color scheme"
                      >
                        {dark ? <IconSun size="1.1rem" /> : <IconMoonStars size="1.1rem" />}
                      </ActionIcon>
                    }
                  >
                    <Text fw={500} fz={'xs'}>Change Theme</Text>
                  </Menu.Item> */}
                  {cmpLoading == false && isLoading == false && UserListData != '' && UserListData?.cmpList != '' && selectedCmp?.cmpNo && <Menu.Item
                    rightSection={
                      <Button variant="outline" color='gray' size="xs" compact
                        onClick={() => //Nairin Salot
                        // cmpAction.open()
                        {
                          dispatch(GetUserList({ Action:"A" }));
                          cmpAction.open();
                          // return modals.open({
                          //   title: <Text fw={700}>Change Company</Text>,
                          //   size: "xl",
                          //   centered: true,
                          //   closeOnClickOutside: false,
                          //   closeOnEscape: false,
                          //   withCloseButton: false,
                          //   children: <ChangeCompanyTable />,
                          //   zIndex: "100"
                          // });
                        }
                        }
                        style={{whiteSpace:'initial'}}
                      >
                        {selectedCmp?.cmpNo + " : " + selectedCmp?.cmpName}
                      </Button>

                    }
                  >
                    <Text fw={500} fz={'xs'}>Company: </Text>
                  </Menu.Item>}

                  {selectedCmp?.cmpNo && cmpLoading == false && <Menu.Item rightSection={<YearAndDate />}>
                    {/* <Text>Test</Text> */}
                    <Text fw={500} fz={'xs'}>F.Y. : </Text>
                  </Menu.Item>}
                  {LanguageData?.length > 1 && cmpLoading == false && <Menu.Item
                    rightSection={
                      <Select
                        defaultValue={LanguageData?.length > 0 ? GetLanguage.GetLanguage() : 'en'}
                        compact
                        onChange={(e) => {
                          localStorage.setItem("userlang", e);
                        }}
                        data={LanguageData}
                        type='button'
                        style={{ width: 75 }}
                      />
                    }
                  >
                    <Text fw={500} fz={'xs'}>Language : </Text>
                  </Menu.Item>}

                  <Menu.Item
                    onClick={() =>SessionSettingFun()}
                    rightSection={<Button variant="outline" color='gray' compact size="xs">Change Setting</Button>}
                  >
                    <Text fw={500} fz={'xs'}>Customization</Text>
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    onClick={() => {
                      // dispatch(LogoutUser());
                      setchangelog.open()
                    }}
                    icon={<IconHistory style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                  >
                    ChangeLog
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    onClick={() => {
                      dispatch(LogoutUser());
                    }}
                    icon={<IconLogout style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                  >
                    Logout
                  </Menu.Item>

                </Menu.Dropdown>
              </Menu>
            </Group>

          </Group>
        </div>
        <MediaQuery smallerThan={"md"} styles={{ display: 'none' }}>
        <div className={classes.innermenu}>
          
            <MainMenu opened setOpened={()=>{setOpened(!opened)}} />
        </div>
        </MediaQuery>
      </Header>
      {/* <Header height={80} style={{ padding: 2 }} className={classes.header}> */}
        
      {/* </Header> */}
      </>
  );
}