import { ActionIcon, Button, Checkbox, Flex, Grid, Group, Tooltip } from '@mantine/core'
import { createColumnHelper } from '@tanstack/react-table'
import { MRT_EditRowModal, MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import React, { useEffect, useState } from 'react'
import PLTextBox from './PLTextBox'
import { IconEdit, IconTrash } from '@tabler/icons-react'
import { modals } from '@mantine/modals'
import PLDataGrid from './PLDataGrid'
import { useDispatch, useSelector } from 'react-redux'
import { GetAccountList, GetTMFormat } from '../utils/slices/AccountListSlice'
import GlobalClass from '../utils/GlobalClass'
import ButtonPanel from './ButtonPanel'

const PLNarration = (props) => {

    const { Flag, NarrationArray, setNarrationArray, selectedNarrationArray, DrawerOBJ, setDrawerOBJ } = props;

    const [columns, setColumns] = useState([])
    const [NarrationData, setNarrationData] = useState([])
    const [tableEditingRow, setTableEditingRow] = useState(null)
    const [NarrationObj, setNarrationObj] = useState({
        FIELD00: false,
        FIELD01: ""
    })
    const [checked, setChecked] = useState(false);

    const [rowSelection, setRowSelection] = useState({})
    const [rowSelection1, setRowSelection1] = useState({})


    const columnHelper = createColumnHelper()
    const dispatch = useDispatch();

    const OS11 = useSelector((state) => state.Transaction?.TransactionData?.OS11);

    const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)

    useEffect(() => {
        console.log("Flag =>", Flag);
        // if (Flag) {
        Promise.resolve(dispatch(GetAccountList({
            "id": "P_M35",
            "name": "",
            "type": "A",
            "p0": "P_M35",
            "p1": "P_M35",
            "cWhere":`M35.FIELD02 LIKE '%${OS11?.S11F01 ?? ""}%'`,
            "p2": "",
            "p3": "",
            "CoPara": OS11?.S11F01
        }))).then(() => dispatch(GetTMFormat({
            "id": "P_M35",
            "name": "",
            "type": "A",
            "p0": "P_M35",
            "p1": "P_M35",
            "p2": "",
            "p3": ""
        })))
        // }
    }, [])

    useEffect(() => {
        if (accList["P_M35"] && tmList["P_M35"]) {
            console.log("PLnarration P_M35 =>", [{
                'accList["P_M35"]': accList,
                'tmList["P_M35"]': tmList
            }]);
        }
    }, [accList, tmList])

    useEffect(() => {
        if (accList["P_M35"]) {
            setNarrationData(accList["P_M35"])
        }
    }, [accList["P_M35"]])

    

    useEffect(() => {
        if (tmList["P_M35"]) {
            let col = [];
            // col.push(
            //     columnHelper.accessor("Select", {
            //         header: "Select",
            //         // enableHiding: true,
            //         // enableColumnFilter: d?.lSearchCol,
            //         // enableSorting: d?.lColOrder,
            //         size: 50,
            //         // minSize: 0,
            //         // maxSize: d?.ColWidth,
            //         Edit: ({ row, cell }) => {
            //             let value = cell.getValue();
            //             setEdit("Select", row, cell,value)
            //             //   console.log("val",row,cell);
            //             //   if(i==0){
            //             //     return <Checkbox
            //             //     checked={checked}
            //             //     onChange={(event) => setChecked(event.currentTarget.checked)}
            //             //   />
            //             //   }

            //         },
            //         Cell: ({ row, cell }) => {
            //             let value = cell.getValue();
            //             // if (i == 0) {
            //             return <Checkbox
            //                 checked={checked}
            //                 // onChange={(event) =>{
            //                 //     console.log("event =>",event);
            //                 //      setChecked(event.currentTarget.checked)
            //                 //     }}
            //             />
            //             // }
            //             // return value;
            //         }
            //     })
            // );
            let selectCol = {
                "cColID": "E0001",
                "cS63ID": "E0001",
                "cS61ID": "E0001",
                "lBand": false,
                "BandHeader": "",
                "aMGH": null,
                "aMGF": null,
                "aChildCol": null,
                "aChildBand": null,
                "lMGH": false,
                "lMGF": false,
                "cRType": "R",
                "ColCap": "Select",
                "DataType": "C",
                "DispExpr": "FIELD00",
                "SummExpr": "",
                "cSpFlg": "",
                "ColType": "F",
                "nColNo": 1,
                "nRowNo": 1,
                "nGrpNo": 0,
                "nColSNo": 0,
                "nColENo": 0,
                "nLGrpCnt": 0,
                "lUnBound": false,
                "ColWidth": 40,
                "ColDec": 0,
                "lWidthInPer": false,
                "ActWidth": 0,
                "lS61Rec": true,
                "lLeaf": true,
                "cSType": "N",
                "lStype": false,
                "TLevel": "N",
                "GTLevel": "N",
                "lNonZero": false,
                "ColClick": "",
                "lCode": "02",
                "FontName": "",
                "FontSize": 0,
                "FontStyle": "",
                "FontCase": "",
                "DateSep": "",
                "DateFrm": "",
                "ForeColor": "",
                "BackColor": "",
                "FooterForeColor": "",
                "FooterBackColor": "",
                "ColDispIn": "D",
                "lFreezeCol": false,
                "lSearchCol": true,
                "SearchFld": "",
                "DispCond": "",
                "cColProp": "",
                "cColScopeType": "A",
                "cColScopeGpID": "",
                "cSupFld": "",
                "cStretch": "Y",
                "lColOrder": true,
                "lMColGrp": false,
                "DTCodeFld": "",
                "MTblName": "",
                "MCodeFld": "",
                "MDispFld": "",
                "MFilter": "",
                "OrdExpr": "",
                "GrpExpr": "",
                "S63Format": "",
                "DispFormat": "",
                "cPageDet": "D",
                "lNoGHDisp": false,
                "lNoGFDisp": false,
                "cLineType": "N",
                "lGrpAsCol": false,
                "lGrpObj": false,
                "lGrpSumm": false,
                "lVisible": true,
                "lMutiRow": false,
                "SortOrder": "Y",
                "HAlign": "0",
                "DAlign": "0",
                "FAlign": "0",
                "TGroupSummary": [],
                "cImgType": "",
                "cImgStyle": "2",
                "nImgH": 30,
                "nImgW": 30,
                "CustExpr": null,
                "CssClass": null,
                "VALUECHECKED": null,
                "VALUEUNCHECKED": null,
                "VALUETYPE": null
            }

            let aDCol = [...tmList["P_M35"]?.oDCFrmt?.aDCol, selectCol]
            // tmList["P_M35"]?.oDCFrmt?.
            aDCol?.map((d, i) => {
                console.log("d", d);
                col.push(
                    columnHelper.accessor(d?.DispExpr, {
                        header: d?.ColCap,
                        enableHiding: true,
                        enableColumnFilter: d?.lSearchCol,
                        enableSorting: d?.lColOrder,
                        size: d?.ColWidth,
                        minSize: 0,
                        maxSize: d?.ColWidth,
                        enableEditing: false,
                        Cell: ({ row, cell }) => {
                            let value = cell.getValue();
                            if (i == 1) {
                                return <Checkbox
                                    checked={NarrationData[row.id]?.FIELD00}
                                    onChange={(event) => {
                                        console.log("event =>", event);
                                        let narr = [...NarrationData]
                                        let obj = narr[row.id]
                                        narr[row.id] = { ...row?.original, FIELD00: !NarrationData[row.id]?.FIELD00 }
                                        setNarrationData(narr)
                                    }}
                                />
                            }
                            return value;
                        },
                        // Edit: ({ row, cell }) => {
                        //     console.log(row);

                        //     let value = cell.getValue();
                        //     if (i == 1) {
                        //         return <Checkbox
                        //             checked={value}
                        //             onChange={(event) => {
                        //                 console.log("event =>", event);
                        //                 // setChecked(event.currentTarget.checked)
                        //                 // setNarrationObj({...NarrationObj,FIELD00:!value}
                        //                 let narr = [...NarrationData]
                        //                 let obj = narr[row.id]
                        //                 narr[row.id] = { ...row?.original, FIELD00: !NarrationData[row.id]?.FIELD00 }
                        //                 setNarrationData(narr)
                        //                 table.setEditingCell(null)

                        //             }}
                        //         />
                        //     }
                        //     return value;

                        // }
                    })
                );
            })
            setColumns(col)
        }
    }, [tmList["P_M35"], NarrationObj, NarrationData])



    useEffect(() => {
        if (selectedNarrationArray) {
            let newObj = []
            console.log("selectedNarrationArray=>", selectedNarrationArray);
            newObj = NarrationData?.map((obj1, i) => {
                const matchFound = selectedNarrationArray?.some(obj2 => obj1.FIELD01 === obj2.FIELD01)

                return {
                    ...obj1,
                    FIELD00: matchFound ? true : false
                }
            })
            // return setRowSelection(newObj)
            setNarrationData(newObj)
        }
    }, [selectedNarrationArray,tmList["P_M35"]])



    // useEffect(() => {
    //     let col = [];
    //     col.push(
    //         columnHelper.accessor("FIELD15", {
    //             header: "Narration Help",
    //             Edit: ({ row, cell }) => {
    //                 return <PLTextBox
    //                     onKeyDown={(e) => {
    //                         if (e.key == "Enter") {
    //                             // console.log("Enter key Press",e)
    //                             if (tableEditingRow == null) {
    //                                 hendleSaveData()
    //                             } else {
    //                                 handleEditData()
    //                             }
    //                         }
    //                     }}
    //                     value={NarrationObj.FIELD15}
    //                     setEdit={(e) => {
    //                         setNarrationObj({ ...NarrationObj, FIELD15: e.target.value })
    //                     }}
    //                 />
    //             }
    //         })
    //     )
    //     setColumns(col)
    // }, [NarrationObj])

    //Add
    const hendleSaveData = () => {
        setNarrationData([...NarrationData, NarrationObj])
        // setNarrationArray([...NarrationArray, NarrationObj])
        table.setCreatingRow(null)
        setNarrationObj({
            FIELD01: ""
        })
    }

    //Edit
    const handleEditData = () => {
        // setNarrationArray([...NarrationArray, NarrationObj])

        let NewArray = [...NarrationData]
        NewArray[tableEditingRow?.index] = NarrationObj
        setNarrationData(NewArray)
        table.setEditingRow(null)
        setTableEditingRow(null)
        setNarrationObj({
            FIELD01: ""
        })
    }

    //Delete
    const openDeleteConfirmModal = (row) => {

        console.log("openDeleteConfirmModal", row);
        let newArray = NarrationData.filter((d, i) => i != row.index)
        setNarrationData(newArray)
    }

    const handleNullObj = () => {
        setNarrationObj({
            FIELD01: ""
        })
    }

    // console.log("");

    const table = useMantineReactTable({
        data: NarrationData,
        columns,
        positionToolbarAlertBanner: "bottom",
        enableRowSelection: false,
        // enableRowSelection: (row) => {
        //     // console.log(row)
        //     if (row.id == 'mrt-row-create') {
        //         return false
        //     } else {
        //         return true
        //     }
        // },
        // enableTableHead:false,
        // enableMultiRowSelection:rowSelection?true:false,

        enableTopToolbar: false,
        enableBottomToolbar: true,
        enableTableFooter: true,
        enableColumnActions: false,
        enableHiding: false,
        enableSorting: false,
        enableFilters: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        mantineTableContainerProps: { sx: { maxHeight: "200px", minHeight: "200px" } },
        onRowSelectionChange: setRowSelection,
        state: {
            rowSelection,
            // rowSelection:rowSelection1
        },
        initialState: {
            density: "0px"
        },
        editDisplayMode: "cell",
        // createDisplayMode: 'row',
        enableEditing: true,
        onCreatingRowSave: hendleSaveData,
        onEditingRowSave: handleEditData,
        onCreatingRowCancel: handleNullObj,
        onEditingRowCancel: handleNullObj,
        renderRowActions: ({ row, table }) => (
            <Flex gap="md">
                <Tooltip label="Edit">
                    <ActionIcon onClick={() => {
                        table.setEditingRow(row)
                        console.log('table.setEditingRow=>', row.original);
                        setNarrationObj(row.original);
                        setTableEditingRow(row)
                    }}>
                        <IconEdit size={'20px'} />
                    </ActionIcon>
                </Tooltip>
                <Tooltip label="Delete">
                    <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
                        <IconTrash size={'20px'} />
                    </ActionIcon>
                </Tooltip>
            </Flex>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                onClick={() => {
                    table.setCreatingRow(true);
                }}
                size='xs'
            >
                Add New
            </Button>
        ),
        renderBottomToolbar: ({ table }) => (
            <ButtonPanel
                data={tmList["P_M35"]?.oS60?.DTHKey}
                Skey={table.getSelectedRowModel().flatRows[0]}
                from={"Narration"}
                S11F01={OS11?.S11F01}
            />
        ),
        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
            tabIndex: 0,
            onClick: () => {
                setRowSelection((prev) => ({
                    [row?.id]: !prev[row?.id],
                }))
            },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
        }),
    })

    const SaveNarrationData = () => {
        let newArray = []

        NarrationData.map((n, i) => {
            // if (i in rowSelection) {
            //     newArray.push(n)
            // }
            if (n?.FIELD00 && n?.FIELD00 == true) {
                newArray.push(n)
            }
        })
        // console.log("newArray===>", newArray);

        Promise.resolve(setNarrationArray({ NarrationData: NarrationData, selectedNarration: newArray })).then(() => setDrawerOBJ({ ...DrawerOBJ, open: false }))
    }

    // console.log("state: { rowSelection }==>", rowSelection);

    return (<>
        {
            AccNameisLoading["P_M35"] ? GlobalClass.RenderLoader("dots") :
                <Grid>
                    <Grid.Col span={12}>
                        <MantineReactTable table={table} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Group spacing={'xs'} position='center'>
                            <Button size='sm' variant='filled' compact onClick={() => SaveNarrationData()}>
                                OK
                            </Button>

                            {/* <Button size='sm' variant='light' compact>
                        Add
                    </Button>
                    <Button size='sm' variant='light' compact>
                        Edit
                    </Button>
                    <Button size='sm' variant='light' compact>
                        Delete
                    </Button> */}
                        </Group>
                    </Grid.Col>
                </Grid>
        }
    </>)
}

export default PLNarration