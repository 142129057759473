import {
  ActionIcon,
  Button,
  Center,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  Spoiler,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import PLDataGrid from "../../../PlusComponent/PLDataGrid";
import PLDateBox from "../../../PlusComponent/PLDateBox";
import PLTextBox from "../../../PlusComponent/PLTextBox";
import { useDispatch, useSelector } from "react-redux";
import Store from "../../../utils/store";
import GlobalClass from "../../../utils/GlobalClass";
import {
  BillFormChallanPartyAc,
  GetUserFieldObj,
  TransactionApi,
} from "../../../utils/TransactionSlices/Transaction";
import { DataSaveLoading } from "../../../utils/slices/DataSaveLoadingSlice";
import gensetting from "../../../utils/gensetting";
import { ModalDelete } from "../../../utils/slices/ModalSlice";
import {
  GetAccountList,
  GetTMFormat,
} from "../../../utils/slices/AccountListSlice";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import PLNumberBox from "../../../PlusComponent/PLNumberBox";
import PLNarration from "../../../PlusComponent/PLNarration";
import SICasePartyDetail from "../../SaleEntry/SICasePartyDetail";
import {
  GetBillFromChallanData,
  GetExpenseData,
  GetInvTypeDropDown,
} from "../../../utils/TransactionSlices/TSalesSlices";
import T02Table from "../../T02/T02Table";
import {
  RefreshIOrderPopUp,
  RefreshIssuePopUp,
} from "../../../utils/TransactionSlices/PendingIssueSlice";
import ExpenseEntryForm from "../../SaleEntry/ExpenseEntryForm";
import PLBillToBill from "../../../PlusComponent/PLBillToBill";
import PLTextarea from "../../../PlusComponent/PLTextarea";
import SIPendingChallanTable from "../../SaleEntry/SIPendingChallanTable";
import {
  AssignItemDefault,
  CalCFIPromise,
  MidCalc,
  RecalcFun,
  RetTaxAcc,
} from "../../SaleEntry/AssignItemDefaultFun";
import FormatListTable from "../../SMSEmailWpBtn/FormatListTable";
import PLUserFieldBtn from "../../../PlusComponent/PLUserFieldBtn";
import PLPrint from "../../../FunctionsCall/Print/PLPrint";

export default function BillFormChallanModal(props) {
  let editData = props.obj;
  let editId = editData?.id;
  let p0 = props.obj.p0;
  let page = props.obj.page;
  let { index, obj } = props;
  const partyRef = useRef(null);
  const formRef = useRef(null);
  const invRef = useRef(null);
  const proNameRef = useRef(null);
  const jobRef = useRef(null);
  const vouDRef = useRef(null);
  const vouNoRef = useRef(null);

  const billId = document.getElementById("#billId");
  const [ExpenseArrayT02, setExpenseArrayT02] = useState([]);
  const [group, setGroup] = useState({
    FIELD01: "JJPUBHZBH3EN",
    FIELD02: "20240331",
    FIELD03: 0,
    FIELD04: "",
    FIELD05: "",
    FIELD06: "0",
    FIELD07: "0",
    FIELD08: 0,
    FIELD09: 0,
    FIELD10: "",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "",
    FIELD14: "N",
    FIELD15: "",
    FIELD16: "D",
    FIELD17: "",
    FIELD18: 0,
    FIELD21: "",
    FIELD22: "",
    FIELD23: "",
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD34NM: "",
    FIELD70: "",
    FIELD71: "",
    FIELD75: "",
    FIELD99: "",
    FIELD72: "",
    FIELD20: 0,
    FIELD80: "",
    FIELD51: 0,
    FIELD34: "",
    FIELD35: "",
    FIELD36: "",
    FIELD37: "",
    FIELD38: "",
    FLDJIO: "",
    FIELD39: "",
    FIELD40: "",
    FIELD41: "",
    FIELD42: "",
    FIELD43: "",
    FIELD44: "",
    FIELD45: "",
    FIELD46: "",
    FIELD47: "",
    FIELD48: "",
    FIELD49: "",
    FIELD50: "",
    FIELD52: "",
    FIELD53: "",
    T41PVT: "JJ",
    FIELD54: "",
    T41VNO: "",
    CMID: 0,
    ADDID: 0,
    PRESCPATH: "",
    OSTATUS: "",
    DTYPE: "",
    DELURL: "",
    DELMBNO: "",
    FIELD55: "",
    FIELD56: "",
    T41POS: "",
    FLDUNQ: 0,
    FLDAED: "A",
    FLDBRC: "",
    UU73: "",
    EA00000001: "",
    EA00000002: "",
    EA00000003: "",
    EA00000004: "",
    EA00000005: "",
    EA00000006: "",
    EA00000007: "",
    EA00000008: "",
    EA00000009: "",
    EA00000010: "",
    EA00000011: "",
    EA00000012: "",
    EA00000013: "",
    EA00000014: "",
    EA00000015: "",
    ED00000001: 0,
    ED00000002: 0,
    ED00000003: 0,
    ED00000004: 0,
    ED00000005: 0,
    ED00000006: 0,
    ED00000007: 0,
    ED00000008: 0,
    ED00000009: 0,
    ED00000010: 0,
    ED00000011: 0,
    ED00000012: 0,
    ED00000013: 0,
    ED00000014: 0,
    ED00000015: 0,
    EP00000001: 0,
    EP00000002: 0,
    EP00000003: 0,
    EP00000004: 0,
    EP00000005: 0,
    EP00000006: 0,
    EP00000007: 0,
    EP00000008: 0,
    EP00000009: 0,
    EP00000010: 0,
    EP00000011: 0,
    EP00000012: 0,
    EP00000013: 0,
    EP00000014: 0,
    EP00000015: 0,
    PA000001: "",
    PA000002: "",
    PD000001: 0,
    PD000002: 0,
    UBILTO: "",
    UDISP: "",
    UEMAIL: "",
    UGSTR: "",
    ULEN: "",
    ULRD: "",
    UNAME: "",
    UOD: "",
    UODD: "",
    UODP: "",
    UOP: "",
    UPAYT: "",
    UPR: "",
    URA: "",
    UREMA: "",
    UREST6: "",
    USAL: 0,
    USALM: "",
    UTR: "",
    UTRA: "",
    UUAASS01: "",
    UUASWER: "",
    UUFR4: "",
    UUJA: "",
    UUJBWID: "",
    UUJWTRID: "",
    UUPRDID: "",
    UUPRODC: "",
    UUPRTR: "",
    UUSALM: "",
    UUT: "",
    UUT78: "",
    UUTPR: "",
    UUUASD09: "",
    UUUASWER: "",
    UUUJBOS: "",
    UUUJOB: "",
    UUUOS: "",
    UU05: "",
    UU87: "",
    UUISSUID: "",
    UUE: "",
    EA00000016: "",
    ED00000016: 0,
    EP00000016: 0,
    EA00000017: "",
    ED00000017: 0,
    EP00000017: 0,
    EA00000018: "",
    ED00000018: 0,
    EP00000018: 0,
    EA00000019: "",
    ED00000019: 0,
    EP00000019: 0,
    EA00000020: "",
    ED00000020: 0,
    EP00000020: 0,
    EA00000021: "",
    ED00000021: 0,
    EP00000021: 0,
    EA00000022: "",
    ED00000022: 0,
    EP00000022: 0,
    UUABCD: "",
    UUGFEJH: "",
    UUTEST56: "",
    EA00000023: "",
    ED00000023: 0,
    EP00000023: 0,
    DSDATE: "2023-04-01T00:00:00+03:00",
    DEDATE: "2024-03-31T00:00:00+02:00",
    CACTION: "A",
    lInvType: true,
    lMTaxEnt: true,
    T92F00: "",
    CFRMTITLE: "Add Jobwork Bill (Issue)",
    BTOBEFF: "C",
    M01BAL: "0",
    M01GRP: "",
    M01OPPGRP: "",
    M01CT: "",
    F07ACC: "JE",
    LBLF08AMT: "0.00   ",
    LBLF07AMT: "0.00   ",
    LBLF08SYMB: null,
    LBLF07SYMB: null,
  });
  const [t02Obj, setT02Obj] = useState({
    FIELD01: "",
    FIELD02: "",
    FIELD03: "",
    FIELD04: "",
    FIELD05: "",
    FIELD06: 0.0,
    FIELD07: 0.0,
    FIELD08: 0.0,
    FIELD09: 0.0,
    FIELD10: 0.0,
    FIELD11: "",
    FIELD12: "",
    FIELD56: "",
    FIELD13: "",
    FIELD14: "",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: "",
    FIELD19: "",
    FIELD20: "",
    FIELD21: 0.0,
    FIELD22: 0.0,
    FIELD23: 0.0,
    FIELD24: "",
    FIELD25: 0.0,
    FIELD26: "",
    FIELD27: "",
    FIELD28: false,
    FIELD29: 0.0,
    FIELD30: "",
    FIELD31: 0.0,
    FIELD32: 0.0,
    FIELD33: 0.0,
    FIELD41: "",
    FIELD50: 0.0,
    FIELD51: "",
    FIELD52: 0.0,
    FIELD71: "",
    FIELD81: 0.0,
    FIELD82: "",
    FIELD83: "",
    FIELD84: "",
    FIELD85: "",
    FIELD86: "",
    FIELD61: 0.0,
    FIELD62: 0.0,
    FIELD63: "",
    FIELD64: 0.0,
    FIELD65: "",
    FIELD66: 0.0,
    FIELD67: 0.0,
    FIELD72: "",
    FIELD68: "",
    FIELD69: "",
    FIELD53: "",
    FIELD54: 0.0,
    FIELD55: 0.0,
    FIELD091: 1.0,
    FIELD58: "",
    FIELD57: "",
    FIELD60: 0.0,
    T41PVT: "",
    FIELD87: 0.0,
    FIELD88: "",
    FLDUNQ: 0,
    FLDAED: "",
    FLDBRC: "",
    FIELD90: 0.0,
    IA00000001: "",
    IA00000002: "",
    IA00000003: "",
    IA00000004: "",
    IA00000005: "",
    IA00000006: "",
    IA00000007: "",
    IA00000008: "",
    IA00000009: "",
    IA00000010: "",
    IA00000011: "",
    IA00000012: "",
    IA00000013: "",
    IA00000014: "",
    IA00000015: "",
    IA00000016: "",
    IA00000017: "",
    IA00000018: "",
    IA00000019: "",
    IA00000020: "",
    IA00000021: "",
    IA00000022: "",
    IA00000023: "",
    IA00000024: "",
    IA00000025: "",
    ID00000001: 0.0,
    ID00000002: 0.0,
    ID00000003: 0.0,
    ID00000004: 0.0,
    ID00000005: 0.0,
    ID00000006: 0.0,
    ID00000007: 0.0,
    ID00000008: 0.0,
    ID00000009: 0.0,
    ID00000010: 0.0,
    ID00000011: 0.0,
    ID00000012: 0.0,
    ID00000013: 0.0,
    ID00000014: 0.0,
    ID00000015: 0.0,
    ID00000016: 0.0,
    ID00000017: 0.0,
    ID00000018: 0.0,
    ID00000019: 0.0,
    ID00000020: 0.0,
    ID00000021: 0.0,
    ID00000022: 0.0,
    ID00000023: 0.0,
    ID00000024: 0.0,
    ID00000025: 0.0,
    IP00000001: 0.0,
    IP00000002: 0.0,
    IP00000003: 0.0,
    IP00000004: 0.0,
    IP00000005: 0.0,
    IP00000006: 0.0,
    IP00000007: 0.0,
    IP00000008: 0.0,
    IP00000009: 0.0,
    IP00000010: 0.0,
    IP00000011: 0.0,
    IP00000012: 0.0,
    IP00000013: 0.0,
    IP00000014: 0.0,
    IP00000015: 0.0,
    IP00000016: 0.0,
    IP00000017: 0.0,
    IP00000018: 0.0,
    IP00000019: 0.0,
    IP00000020: 0.0,
    IP00000021: 0.0,
    IP00000022: 0.0,
    IP00000023: 0.0,
    IP00000024: 0.0,
    IP00000025: 0.0,
    IT00000001: "",
    IT00000002: "",
    IT00000003: "",
    IT00000004: "",
    IT00000005: "",
    IT00000006: "",
    IT00000007: "",
    IT00000008: "",
    IT00000009: "",
    IT00000010: "",
    IT00000011: "",
    IT00000012: "",
    IT00000013: "",
    IT00000014: "",
    IT00000015: "",
    IT00000016: "",
    IT00000017: "",
    IT00000018: "",
    IT00000019: "",
    IT00000020: "",
    IT00000021: "",
    IT00000022: "",
    IT00000023: "",
    IT00000024: "",
    IT00000025: "",
    UAP: 0.0,
    UBA: "",
    UDDATE: "",
    UDIS: 0.0,
    UDISC: 0.0,
    UGRO: 0.0,
    UISSUERA: 0.0,
    UMUR: 0.0,
    UPE: 0.0,
    URATE: 0.0,
    URE: "",
    UREMARK: "",
    IA00000026: "",
    ID00000026: 0.0,
    IP00000026: 0.0,
    IT00000026: "",
    UUWQ: "",
    USERFLD: "",
    F03NM: "",
    F20NM: "",
    CATNM: "",
    GRPNM: "",
    CATCD: "",
    GRPCD: "",
    USERFLDB: "",
    SRDETAIL: "",
    EXPDUMMY: " ",
    BARCODE: "",
    OLDPRD: "",
    OLDQTY: 0.0,
    OQTY2: 0.0,
    OLDRATE: 0.0,
    LADDNEW: "Y",
    PRICELIST: " ",
    _BATSTOCK: " ",
    _LOCWISE: " ",
    LDUALSTK: " ",
    SRNOSTK: " ",
    MRPSTK: " ",
    RORI: " ",
    ORDDUMMY: " ",
    JODUMMY: " ",
    SRIDUMMY: " ",
    SRRDUMMY: " ",
    SRIDUMMYF: " ",
    SRRDUMMYF: " ",
    SRISSCD: "",
    OLDSRNO: "",
    SCNOCL: "",
    SCNOCL1: "",
    MANUDATE: "",
    EXPDATE: "",
    T02F08EXP: 0.0,
    _EXPTOTALAMT: 0.0,
  });
  const [t02Data, setT02Data] = useState([]);
  const [flagCopy,setFlagCopy] = useState(false);
  const [t02DataENT, setT02DataENT] = useState([]);
  const [tableExpenseVal, setTableExpenseVal] = useState(0);
  const [T05DataArray, setT05DataArray] = useState([]);
  const [ExpenseArrayT41, setExpenseArrayT41] = useState([]);
  const [editT41, setEditT41] = useState({});
  const [editT02, setEditT02] = useState({});
  const [M01PRow, setM01PRow] = useState(null);
  const [billAmt, setBillAmt] = useState(0);
  const [partyData, setPartyData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [creatingModeOn, setCreatingModeOn] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [pagecCode, setPagecCode] = useState("");
  const [challanData, setChallanData] = useState([]);
  const [selectedChallan, setSelectedChallan] = useState([]);
  // const [pendingIssue, setPendingIssue] = useState([]);
  
  const [FooterExpenseObj, setFooterExpenseObj] = useState({});
  const [FooterExpenseFlag, setFooterExpenseFlag] = useState(false);
  const [HeaderExpenseFlag, setHeaderExpenseFlag] = useState(false);
  const [updtFlag, setupdtFlag] = useState(false);
  const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
    BillNoVal: "",
    TextInput: false,
    DataGridInput: true,
  });
  const [dayVal, setDayVal] = useState("");
  const [fieldDisable, setFieldDisable] = useState({
    // lInvType: '',
    lMTaxEnt: "",
    Doc_No: "",
    Doc_Date: "",
    Delivery_at1: "",
    Delivery_at2: "",
    Delivery_at3: "",
    Applicable: "",
    T41_Field45_Render: "",
  });
  const [date, setDate] = useState(new Date());
  const [NarrationData, setNarrationData] = useState([]);
  const [SelectedNarrationData, setSelectedNarrationData] = useState([]);
  const [selectedProductObj, setSelectedProductObj] = useState({});
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseButton: null,
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [ExpenseArray, setExpenseArray] = useState({
    dtExp: null,
    dtExpDef: null,
    dtExp_I: null,
    dtExpDef_I: null,
    M01SP: null,
    M01SPDT: null,
  });
  const [tableData, setTableData] = useState(null);
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const { oYear } = useSelector((state) => state?.Language?.General);
  const CasePartyRequired = useSelector(
    (state) => state?.TSalesSlices?.CasePartyRequired
  );
  const dataEnt = useSelector((state) => state?.Transaction?.TransactionData);
  const ExpenseData = useSelector((state) => state?.TSalesSlices?.ExpenseData);
  const PendingChallanData = useSelector(
    (state) => state?.TSalesSlices?.PendingChallanData
  );
  const InvTypeDropDown = useSelector(
    (state) => state?.TSalesSlices?.InvTypeDropDown
  );
  const BillFormChallanPartyAcData = useSelector(
    (state) => state.Transaction?.BillFormChallanPartyAcData
  );
  const { isUserFieldLoading, UserField } = useSelector(
    (store) => store?.Transaction
  );
  const {
    isLoading: isLoadingEnt,
    hasError: hasErrorEnt,
    ErrorMsg,
  } = useSelector((state) => state?.Transaction);
  const GetYearAndDate = useSelector(
    (state) => state?.UserList?.YearAndDateSend
  );

  const {
    AccountListData: accList,
    TMFormatData: tmList,
    isLoading: AccNameisLoading,
  } = useSelector((state) => state.AccountList);
  const copyRow = useSelector((state) => state?.GetPendingIssue?.copyRow);
  // const { JWListData, hasError, isLoading, errorMsg, JWOrderData } =
  // useSelector((state) => state?.GetPendingIssue);

  const dispatch = useDispatch();
  // let dataEnt = formVouData;
  const T02 = dataEnt?.T02;
  const OSV = dataEnt?.OSV;
  const OS11 = dataEnt?.OS11;
  const M01D = dataEnt?.M01D;
  const M01DT = dataEnt?.M01DT;
  const M45I = dataEnt?.M45I;
  const M45IDT = dataEnt?.M45IDT;
  const T05ENT = dataEnt?.T05ENT;
  const T05 = dataEnt?.T05;
  const COP8 = dataEnt?.COP8;
  const M01PDT = dataEnt?.M01PDT;
  const M01PRT = dataEnt?.M01PRT;
  const M01P = dataEnt?.M01P;
  const P_M31JDT = dataEnt?.P_M31JDT;
  const P_M31J = dataEnt?.P_M31J;
  const UFT41H = dataEnt?.UFT41H;
  const T92 = dataEnt?.T92;
  const T92DT = dataEnt?.T92DT;
  const T41 = dataEnt?.T41;
  const UFT05H = dataEnt?.UFT05H;
  const dtM14DT = dataEnt?.dtM14;
  const dtM51DT = dataEnt?.dtM51;
  const dtM52DT = dataEnt?.dtM52;
  const dtM46DT = dataEnt?.dtM46;
  const lEditVou = dataEnt?.lEditVou;
  let UFT41Hval = UFT41H?.filter((v) => v?.S13F87 == "HEADER");
  let UFT41Fval = UFT41H?.filter((v) => v?.S13F87 == "FOOTER");
  let ExpenseObj = {
    OS11,
    OSV,
    oYear,
    M01SP: ExpenseArray?.M01SP,
    M01SPDT: ExpenseArray?.M01SPDT,
    dtM14DT,
    dtM51DT,
    dtM52DT,
    dtM46DT,
    dtExp: ExpenseArray?.dtExp,
    dtExpDef: ExpenseArray?.dtExpDef,
    dtExp_I: ExpenseArray?.dtExp_I,
    dtExpDef_I: ExpenseArray?.dtExpDef_I,
    InvTypeDropDown,
    ItemCode: selectedProductObj?.FIELD03,
    selectedProductObj,
    T02Obj: { ...t02Obj, VorC: group?.FIELD45 },
    T41Obj: group,
    T02Data: t02Data,
  };
  useEffect(() => {
    setExpenseArray({
      ...ExpenseArray,
      dtExp: ExpenseData?.dtExp,
      dtExpDef: ExpenseData?.dtExpDef,
      dtExp_I: ExpenseData?.dtExp_I,
      dtExpDef_I: ExpenseData?.dtExpDef_I,
      M01SP: ExpenseData?.M01SP,
      M01SPDT: ExpenseData?.M01SPDT?.jData,
    });
  }, [ExpenseData]);
  const [cPrtcode, setcPrtcode] = useState("");
  useEffect(() => {
    console.log(group?.FIELD31, "group.FIELD31", group?.FIELD04);
    if (group?.FIELD31 == "") {
      setcPrtcode(group?.FIELD04);
    } else {
      setcPrtcode(group?.FIELD31);
    }
  }, [group?.FIELD31, group?.FIELD04]);

  useEffect(() => {
    if (t02Data) {
      if (ExpenseArray?.dtExp && ExpenseArray?.dtExp_I) {
        console.log("HeaderExpenseFlag", HeaderExpenseFlag);
        new Promise((resolve) => {
          let fun = CalCFIPromise({ ...ExpenseObj, Flag: HeaderExpenseFlag })
          resolve(fun)
        }).then((res) => {
          console.log("CalCFIPromiseResponse =>", res);
          if (HeaderExpenseFlag) {
            setT02Data(res?.T02CalCFIData)
            new Promise((resolve) => {
              let dt = MidCalc({ ...ExpenseObj, T41Obj: res?.T02CalAarray })
              // console.log("ExpenseObj T41Object=>", ExpenseObj);
              resolve(dt)
            }).then((res2) => {
              setGroup(res2?.T41Object)
            })
          } else {
            setGroup(res)
          }

          setHeaderExpenseFlag(false)
        })
      }else{
        new Promise((resolve) => {
          let dt = MidCalc(ExpenseObj)
          // console.log("ExpenseObj T41Object=>", ExpenseObj);
          resolve(dt)
        }).then((res2) => {
          setGroup(res2?.T41Object)
        })
      }

    }
  }, [t02Data, InvTypeDropDown,ExpenseArray, FooterExpenseFlag, HeaderExpenseFlag])



  const HandleHistoryBtn = () => {
    console.log(cPrtcode, "cPrtcode-");
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>History</Text>,
      body: (
        <>
          <PLDataGrid
            width={"500px"}
            TmData={"P_T41"}
            cWhere={`T41.FIELD04 LIKE '%${
              group?.FIELD04 ?? ""
            }%' OR T41.FIELD31 LIKE '%${group?.FIELD31 ?? group?.FIELD04}%'`}
            setEdit={(e) => {
              console.log("HandleHistoryBtn=>", e);
            }}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  };
  useEffect(() => {
    // console.log(page, "04110399-", updtFlag);
    // if (obj && pagecCode != "") {
    if (obj) {
      if (page === "04110399" || page === "20442041" || page == "04320417") {
        Promise.resolve(
          Store.dispatch(
            TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 })
          )
        )
          .then(() =>
            dispatch(
              GetAccountList({
                id: "T_M32C",
                name: "",
                type: "A",
                p0: "T_M32C",
                p1: "T_M32C",
                p2: "",
                p3: "",
              })
            )
          )
          .then(() =>
            dispatch(
              GetTMFormat({
                id: "T_M32C",
                name: "",
                type: "A",
                p0: "T_M32C",
                p1: "T_M32C",
                p2: "",
                p3: "",
              })
            )
          ).then(()=>{
            dispatch(
              GetTMFormat({
                id: "P_M21",
                name: "",
                type: "A",
                p0: "P_M21",
                p1: "P_M21",
                p2: "",
                p3: "",
              })
            )
          });
      } else if (obj?.id == "04080399") {
        Promise.resolve(
          Store.dispatch(
            TransactionApi({
              p0: "A",
              p1: "J!",
              p2: "",
              page: obj?.id,
              text: "Add",
            })
          )
        )
          .then(() =>
            dispatch(
              BillFormChallanPartyAc({
                p0: "A",
                p1: "",
                p2: "",
                page: obj?.id,
                text: "Add",
              })
            )
          )

          .then(() =>
            dispatch(
              GetAccountList({
                id: "T_M32C",
                name: "",
                type: "A",
                p0: "T_M32C",
                p1: "T_M32C",
                p2: "",
                p3: "",
              })
            )
          )
          .then(() =>
            dispatch(
              GetTMFormat({
                id: "T_M32C",
                name: "",
                type: "A",
                p0: "T_M32C",
                p1: "T_M32C",
                p2: "",
                p3: "",
              })
            )
          ).then(()=>{
            dispatch(
              GetAccountList({
                id: "P_M21",
                name: "",
                type: "A",
                p0: "P_M21",
                p1: "P_M21",
                p2: "",
                p3: "",
              })
            )
          });
      } else if (obj?.id == "20452041") {
        Promise.resolve(
          Store.dispatch(
            TransactionApi({
              p0: "A",
              p1: "J2",
              p2: "",
              page: obj?.id,
              text: "Add",
            })
          )
        )
          .then(() =>
            dispatch(
              BillFormChallanPartyAc({
                p0: "A",
                p1: "",
                p2: "",
                page: obj?.id,
                text: "Add",
              })
            )
          )

          .then(() =>
            dispatch(
              GetAccountList({
                id: "T_M32C",
                name: "",
                type: "A",
                p0: "T_M32C",
                p1: "T_M32C",
                p2: "",
                p3: "",
              })
            )
          )
          .then(() =>
            dispatch(
              GetAccountList({
                id: "T_M32C",
                name: "",
                type: "A",
                p0: "T_M32C",
                p1: "T_M32C",
                p2: "",
                p3: "",
              })
            )
          ).then(()=>{
            dispatch(
              GetAccountList({
                id: "P_M21",
                name: "",
                type: "A",
                p0: "P_M21",
                p1: "P_M21",
                p2: "",
                p3: "",
              })
            )
          });
      } else if (obj?.id == "04290417") {
        Promise.resolve(
          Store.dispatch(
            TransactionApi({
              p0: "A",
              p1: "J1",
              p2: "",
              page: obj?.id,
              text: "Add",
            })
          ).then(() =>
            dispatch(
              BillFormChallanPartyAc({
                p0: "A",
                p1: "",
                p2: "",
                page: obj?.id,
                text: "Add",
              })
            )
          )
        )

          .then(() =>
            dispatch(
              GetAccountList({
                id: "T_M32C",
                name: "",
                type: "A",
                p0: "T_M32C",
                p1: "T_M32C",
                p2: "",
                p3: "",
              })
            )
          )
          .then(() =>
            dispatch(
              GetAccountList({
                id: "T_M32C",
                name: "",
                type: "A",
                p0: "T_M32C",
                p1: "T_M32C",
                p2: "",
                p3: "",
              })
            )
          ).then(()=>{
            dispatch(
              GetAccountList({
                id: "P_M21",
                name: "",
                type: "A",
                p0: "P_M21",
                p1: "P_M21",
                p2: "",
                p3: "",
              })
            )
          });
      }
    }
  }, [obj]);
  useEffect(() => {
    let date = group?.FIELD02;
    // console.log(date, "group?.FIELD02");
    if (date?.length > 0) {
      const year = parseInt(date.substr(0, 4));
      const month = parseInt(date.substr(4, 2)) - 1; // Month is zero-based in JavaScript Date
      const day = parseInt(date.substr(6, 2));
      const dateObject = new Date(year, month, day);
      // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const dayOfWeek = dateObject.toLocaleString("en-US", { weekday: "long" });
      setDayVal(dayOfWeek?.slice(0, 3));
    }
  }, [group?.FIELD02]);
  useEffect(() => {
    if (page == "04110399" || obj.id == "04080399") {
      setPagecCode("JJJJ");
    } else if (page == "04320417" || obj.id == "04290417") {
      setPagecCode("JZJZ");
    } else if (page == "20442041" || obj.id == "20452041") {
      setPagecCode("JJJJ");
    }
  }, [obj.page, obj.id]);
  useEffect(() => {
    if (T05) {
      setT05DataArray(T05);
    }
  }, [T05]);
  // console.log(accList, "accListll");
  useEffect(() => {
    // let newPartyData = M01PDT?.filter((v) => v?.FIELD07 == "CS");
    // console.log(group?.FIELD16, "newPartyData");
    // console.log(group?.FIELD16, "M01PDT--");
    if (M01PDT && (M01PDT?.jData?.length > 0 || M01PDT?.length > 0)) {
      if (M01PDT?.jData?.length > 0) {
        if (group?.FIELD16 === "C") {
          setPartyData(M01PDT?.jData?.filter((v) => v?.FIELD07 == "CS"));
        } else {
          setPartyData(M01PDT?.jData?.filter((v) => v?.FIELD07 == "PR"));
        }
      } else if (M01PDT?.length > 0) {
        if (group?.FIELD16 === "C") {
          // console.log(M01PDT , "M01PDT?.length")

          setPartyData(M01PDT?.filter((v) => v?.FIELD07 == "CS"));
        } else {
          setPartyData(M01PDT?.filter((v) => v?.FIELD07 == "PR"));
        }
      }
    } else {
      setPartyData([]);
    }
  }, [group?.FIELD16, M01PDT]);
  // console.log(partyData , "M01PDT--")
  const rowSum = t02Data.reduce((a, b) => {
    if (b?.FIELD05 == "C") a = a + (b?.FIELD08 || 0);
    else if (b?.FIELD05 == "D") a = a - (b?.FIELD08 || 0);
    return a;
  }, 0);
  useEffect(() => {
    // console.log(oYear?.cWDate, "dataEntcWDate");
    // setGroup({ ...dataEnt?.T41, FIELD02: new Date(oYear?.cWDate) });
    const dateWithSlashes = oYear?.cWDate;
    const dateWithoutSlashes = dateWithSlashes.replace(/\//g, ""); // Replace slashes globally
    // console.log(dateWithoutSlashes, "dataEntcWDate", dateWithSlashes); // Output: "20240320"
    let { startDate } = DateToString(new Date());
    console.log(startDate, "startDatedataEnt");

    setGroup({
      ...dataEnt?.T41,
      FIELD02: dateWithoutSlashes,
      FIELD11: startDate,
    });
    setT02DataENT(dataEnt?.T02ENT);
  }, [dataEnt]);
  let stringOfNames;
  // console.log(ExpenseData, "ExpenseData-", dataEnt?.T41?.FIELD13);

  useEffect(() => {
    if (SelectedNarrationData.length > 0) {
      const stringifyArray = SelectedNarrationData?.map((a) => a.FIELD01);
      stringOfNames = stringifyArray.join(" ");
      // console.log("stringOfNames", stringOfNames);
    }
    setGroup({
      ...group,
      FIELD15:
        group?.FIELD15 != ""
          ? group?.FIELD15 + " " + stringOfNames
          : stringOfNames,
    });
    // setGroup({...T41Obj,FIELD15:})
  }, [stringOfNames, SelectedNarrationData]);

  useEffect(() => {
    if (obj.p0 == "E") {
      setM01PRow(M01PRT);
    }
  }, [M01PRT]);

  useEffect(()=>{
    console.log("creationModeOn",creatingModeOn);
  },[creatingModeOn]);

  useEffect(()=>{
    console.log("editingRow",editingRow);
  },[editingRow]);
  

  useEffect(()=>{
    console.log("selectedProductObj",selectedProductObj);
  },[selectedProductObj]);


  useEffect(() => {
    // InvTypeDropDown
    // console.log("ExpenseArray useEffect=>", ExpenseArray)
    
    if (t02Obj?.FIELD03 && !editingRow && selectedProductObj!=null) {
      // let ExpenseObj = { OS11, OSV, oYear, M01SP: ExpenseArray?.M01SP, M01SPDT: ExpenseArray?.M01SPDT, dtM14DT, dtM51DT, dtM52DT, dtM46DT, dtExp: ExpenseArray?.dtExp, dtExpDef: ExpenseArray?.dtExpDef, dtExp_I: ExpenseArray?.dtExp_I, dtExpDef_I: ExpenseArray?.dtExpDef_I, InvTypeDropDown, ItemCode: selectedProductObj?.FIELD03, selectedProductObj, T02Obj, T41Obj }
  //  let p = RetTaxAcc("TS", false, "FIELD16", 0, "", t02Obj, false, ExpenseObj);
  //  console.log("p of field16",p);
  //     // console.log('RetTaxAcc("TS", false, "FIELD16", 0, "", T02Obj, false, ExpenseObj) =>', RetTaxAcc("TS", false, "FIELD16", 0, "", t02Obj, false, ExpenseObj));
      // setTimeout(() => {
      //   Promise.resolve(
      //     setT02Obj({
      //     ...t02Obj,
      //     FIELD16: p,
      //   })
      //   )
      // }, 500)
console.log("selected Product Obj",ExpenseObj);
  new Promise((resolve)=>{
    let p = RetTaxAcc("TS", false, "FIELD16", 0, "", {...t02Obj, VorC: group?.FIELD45}, false, ExpenseObj);
    console.log("ppppppp here",p);
    resolve(p)
  }).then((res)=>{
    setTimeout(()=>{
      console.log("RetTaxAcc Response=>",[{"res":res}]);
      setT02Obj({
        ...t02Obj,
        FIELD16: res,
      })
    },500)
  })
      // Promise.resolve(  //Commented
      //   setT02Obj({
      //     ...t02Obj,
      //     FIELD16: RetTaxAcc(
      //       "TS",
      //       false,
      //       "FIELD16",
      //       0,
      //       "",
      //       t02Obj,
      //       false,
      //       ExpenseObj
      //     ),
      //   })
      // ).then(() => {
      //   // let newObj = {
      //   //   dtExpItemWise: AssignItemDefault(ExpenseObj).dtExpArr,
      //   //   newT02Array: AssignItemDefault(ExpenseObj).T02Object
      //   // }
      //   // console.log("newObj =>", newObj)

      //   setTimeout(() => {
      //     Promise.resolve(
      //       setExpenseArrayT02(AssignItemDefault(ExpenseObj).dtExpArr)
      //     ).then(() => setT02Obj(AssignItemDefault(ExpenseObj).T02Object));
      //   }, 500);
      // });

      // Promise.resolve(setExpenseArrayT02(AssignItemDefault(ExpenseObj).dtExpArr)).then(()=>setT02Obj(AssignItemDefault(ExpenseObj).T02Object))
    }
  }, [t02Obj?.FIELD03]);

  useEffect(() => {
    // FIELD45: e.FIELD20, FIELD13
    
    if (t02Obj?.FIELD16 && !editingRow) {
      new Promise((resolve, reject) => {
        console.log("ExpenseObj",ExpenseObj);
        let dt = AssignItemDefault(ExpenseObj);
        console.log("DT OF FIELD16",dt);
        resolve(dt);
      })
        .then((expdt) => {
          console.log("AssignItemDefault event =>", expdt);
          setExpenseArrayT02(expdt?.dtExpArr);
          setT02Obj(expdt?.T02Object)
        })
        .then(() => {
          return RecalcFun(group, "", false, "", ExpenseObj)
        }).then((res) => {
          // Promise.resolve(SetFieldObj(res?.T02CalAarray)).then(() => setDtExpTableField(res?.ExpenseCalArray))
          Promise.resolve(setExpenseArrayT41(res?.ExpenseCalArray)).then(() => setGroup(res?.T02CalAarray))
        })
    }
  }, [t02Obj?.FIELD16]);

  useEffect(() => {
    if (t02Data) {
      Promise.resolve()
        .then(() => {
          return MidCalc(ExpenseObj);
        })
        .then((res) => {
          // console.log("MidCalc(ExpenseObj).oT41D =>", res);
          setGroup(res?.T41Object);
          return RecalcFun(res?.T41Object, "", false, "", ExpenseObj);
        })
        .then((res) => {
          console.log("RecalcFun response =>", res);
        });
    }
  }, [t02Data]);

  // useEffect(() => {
  //   // console.log("setBillAmt isVisible", t02Data);
  //   if (isVisible)
  //     setBillAmt(t02Data?.reduce((a, b) => (a = a + b?.FIELD08), 0));
  // }, [isVisible, t02Data]);
  useEffect(() => {
    // console.log(group?.FIELD02, "group?.FIELD04.length");
    if (
      (obj?.id == "20452041" ||
        obj?.id == "04080399" ||
        obj?.id == "04290417") &&
      group?.FIELD02?.length > 0 &&
      group?.FIELD04?.length > 0 &&
      group?.FIELD34?.length > 0
    ) {
      // console.log(pagecCode, "group?.FIELD02")
      dispatch(
        GetBillFromChallanData({
          code: group?.FIELD04,
          date: group?.FIELD02,
          pRc: group?.FIELD34,
          page: pagecCode,
          ctype: obj?.id == "20452041" ? "SJ" : "",
        })
      );
    }
  }, [obj?.id, group?.FIELD02, group?.FIELD04, group?.FIELD34]); //pending

  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);
  const seperate = (e) => {
    var paraArr = e.split("~C~");
    console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };
  function UFT41HFieldRenderer(e) {
    if (e == "Header") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((h) => {
          // console.log("User Field=>", h?.S13F14, h?.S13F07, h?.S13F02);
          if (h?.S13F87 == "HEADER") {
            //b?.S13F14
            //console.log("UFT41HRenderHeader==>",h);
            return (
              <>
                {h?.S13F14 == "TEXTBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "NUMBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLNumberBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "PLPOPUP" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDataGrid
                        width={"500px"}
                        name={h.S13F02}
                        value={group?.[h?.S13F02]}
                        p0={seperate(h.S13F09)[0]}
                        p1={seperate(h.S13F09)[1]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e);
                          setGroup({ ...group, [h?.S13F02]: e?.FIELD01 });
                          // M01handlechange({ id: item.S13F02, value: e })
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "COMBOBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        value={group?.[h?.S13F02]}
                        copno={seperate(h?.S13F09)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e?.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "DATEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        defaultval={
                          group?.[h?.S13F02]?.trim() != ""
                            ? group?.[h?.S13F02]
                            : GetYearAndDate?.strtDate
                        }
                        dispformat="DD/MM/YYYY"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          let { startDate } = DateToString(e);
                          if (startDate?.length == 8) {
                            setGroup({ ...group, [h?.S13F02]: e });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "TIMEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        // value={new Date(group?.[h?.S13F02]).getTime()}
                        dispformat="DD/MM/YYYY"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "N" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "H" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "I" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "A" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}
              </>
            );
          }
        })
      );
    } else if (e == "Footer") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((f) => {
          if (f?.S13F87 == "FOOTER") {
            //b?.S13F14
            return (
              <>
                {f?.S13F14 == "TEXTBOX" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "NUMBOX" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLNumberBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "PLPOPUP" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDataGrid
                          width={"500px"}
                          value={group?.[f?.S13F02]}
                          // p0={seperate(f.S13F09)[0]}
                          p1={seperate(f.S13F09)[1]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e?.FIELD01 });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "COMBOBOX" && (
                  <>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                     
                      <PLComboBox
                        value={group?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col> */}
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLComboBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "DATEPICKER" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDateBox
                          defaultval={
                            group?.[f?.S13F02]?.trim() != ""
                              ? group?.[f?.S13F02]
                              : GetYearAndDate?.strtDate
                          }
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            let { startDate } = DateToString(e);
                            if (startDate?.length == 8) {
                              setGroup({ ...group, [f?.S13F02]: e });
                            }
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    
                      <PLDateBox
                        // value={new Date(group?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f?.S13F14 == "TIMEPICKER" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDateBox
                          // value={new Date(group?.[f?.S13F02]).getTime()}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                     
                      <PLDateBox
                        // value={new Date(group?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "N" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                 
                      <PLTextBox
                        value={group?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "H" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "I" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "A" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}
              </>
            );
          }
        })
      );
    }
  }
  const BillToBillTableFun = () => {
    // document.getElementById("okbutton").focus();

    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Bill to Bill</Text>,
      onClickCloseButton: () => setDrawerObj({ ...DrawerObj, open: false }),
      body: (
        <>
          <PLBillToBill
            T41ObjSend={group}
            T05UserField={UFT05H}
            T05Format={T05ENT}
            T05Data={T05DataArray}
            setT05Data={(e) => setT05DataArray(e)}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  };
  const CasePartyDetailForm = () => {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Case Party Detail</Text>,
      body: (
        <SICasePartyDetail
          FIELD32={(e) => {
            // console.log("group.FIELD32 EVENT===>", e);
            setGroup({ ...group, FIELD32: e });
          }}
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => {
            setDrawerObj(e);
          }}
        />
      ),
      open: true,
      size: "md",
      position: "right",
    });
    // modals.openContextModal({
    //   modal: "SICasePartyDetail",
    //   title: <Text fw={700}>Case Party Detail</Text>,
    //   zIndex: 300,
    //   size: "lg",
    //   withCloseButton: true,
    //   innerProps: {
    //     modalBody: (
    //       <>
    //         <SICasePartyDetail
    //           FIELD32={(e) => {
    //             // console.log("group.FIELD32 EVENT===>", e);
    //             setGroup({ ...group, FIELD32: e });
    //           }}
    //         />
    //       </>
    //     ),
    //   },
    // });
  };
  function DateToString(e) {
    // console.log(e, "Datetostring");
    let year = e.toLocaleString("en-IN", { year: "numeric" });
    let month = e.toLocaleString("en-IN", {
      month: "2-digit",
    });
    let day = e.toLocaleString("en-IN", { day: "2-digit" });

    let formatedStartDate = day + "/" + month + "/" + year;
    let startDate = year + month + day;
    return { formatedStartDate, startDate };
  }
  const PendingChallanFun = () => {
    // console.log("Pending challan call");
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Pending Challan</Text>,
      body: (
        <>
          <SIPendingChallanTable
            ChallanData={PendingChallanData?.dtchln}
            ChallanFormat={PendingChallanData?.T02C}
            selectedChallanArray={selectedChallan}
            DrawerOBJ={DrawerObj}
            setDrawerOBJ={(e) => setDrawerObj(e)}
            selectedPendingChallan={(e) => {
              // console.log("selectedPendingChallan=>", e);
              if (e?.selectedChallan?.length > 0) {
                let newArr = [];
                e?.selectedChallan?.map((c, i) => {
                  newArr.push({
                    ...c,
                    FIELD13: c?.FIELD01 + String(c?.FIELD09).substring(0, 4),
                  });
                });
                setT02Data(newArr);
              }
              setChallanData(e?.challanData);
              setSelectedChallan(e?.selectedChallan);
            }}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  };
  useEffect(() => {
    if (T41 && OSV && oYear && OS11) {
      setFieldDisable({
        ...fieldDisable,
        // lInvType: T41?.lInvType,
        lMTaxEnt: T41?.lMTaxEnt,
        // Doc_No: OSV?._DOCNODATE,
        // Doc_Date: OSV?._DOCNODATE,
        Delivery_at1: oYear?.OSC?._ECOMDLADD,
        Delivery_at2: oYear?.OSC?._GSTSETUP,
        Delivery_at3: OS11?.S11F21,
        Applicable: oYear?.OSC?._GR1RTTAX,
      });
    }
  }, [T41, OSV, oYear, OS11]);
  useEffect(() => {
    if (creatingModeOn == true) {
      setT02Obj({
        ...t02Obj,
        FIELD05: "R,C".includes(OS11?.S11F22) ? "C" : "D",
      });
    }
  }, [creatingModeOn, OS11?.FIELD22]);
  useEffect(() => {
    console.log(OS11?.S11F01, "OS11?.S11F01=");
    if (OS11?.S11F01 && obj?.p0 == "U") {
      dispatch(GetUserFieldObj(OS11?.S11F01));
    }
  }, [OS11?.S11F01, obj]);

  let compareTwoArrayOfObjects = (editT02, t02Data) => {
    return (
      editT02?.length === t02Data?.length &&
      editT02?.every((v) =>
        t02Data?.some((c) => Object.keys(v).every((key) => v[key] === c[key]))
      )
    );
  };
  useEffect(() => {
    if (BillSeriesHideShow.BillNoVal.length <= 0) {
      setBillSeriesHideShow({
        ...BillSeriesHideShow,
        DataGridInput: true,
        TextInput: false,
      });
    }
  }, [BillSeriesHideShow.BillNoVal]);
  const GetExpenseFunction = () => {
    if (obj.p0 == "E") {
      // console.log(obj.p0, "obj.p0");
      dispatch(GetInvTypeDropDown(dataEnt?.T41?.FIELD13));
      dispatch(
        GetExpenseData({
          action: "A",
          code: pagecCode,
          invCode: dataEnt?.T41?.FIELD13,
        })
      );
    }
  };
  // useEffect(() => {
  //   // FIELD45: e.FIELD20, FIELD13
  //   if (t02Obj?.FIELD16) {
  //     Promise.resolve()
  //       .then(() => {
  //         return RecalcFun(group, "", false, "", ExpenseObj);
  //       })
  //       .then((res) => {
  //         console.log("Footer Expense Response =>", res);
  //         // Promise.resolve(SetFieldObj(res?.T02CalAarray)).then(() => setDtExpTableField(res?.ExpenseCalArray))
  //         Promise.resolve(setExpenseArrayT41(res?.ExpenseCalArray)).then(() =>
  //           setGroup(res?.T02CalAarray)
  //         );
  //       });
  //   }
  // }, [t02Obj?.FIELD16]);
  useEffect(() => {
    if (updtFlag == true && dataEnt && dataEnt?.T41) {
      GetExpenseFunction();
    }
  }, [updtFlag]);
  function dispatchFunction(){
    Promise.resolve(Store.dispatch(DataSaveLoading(false)))
    .then(() => {
      // Store.dispatch(ModalDelete(index));
      Store.dispatch(GetInvTypeDropDown(null));
      if (p0 == "A") {
        if (obj?.id == "04080399") {
          Promise.resolve(
            Store.dispatch(
              TransactionApi({
                p0: "A",
                p1: "J!",
                p2: "",
                page: obj?.id,
                text: "Add",
              })
            )
          ).then(() =>
            dispatch(
              BillFormChallanPartyAc({
                p0: "A",
                p1: "",
                p2: "",
                page: obj?.id,
                text: "Add",
              })
            )
          );
        } else if (obj?.id == "20452041") {
          Promise.resolve(
            Store.dispatch(
              TransactionApi({
                p0: "A",
                p1: "J2",
                p2: "",
                page: obj?.id,
                text: "Add",
              })
            )
          ).then(() =>
            dispatch(
              BillFormChallanPartyAc({
                p0: "A",
                p1: "",
                p2: "",
                page: obj?.id,
                text: "Add",
              })
            )
          );
        } else if (obj?.id == "04290417") {
          Promise.resolve(
            Store.dispatch(
              TransactionApi({
                p0: "A",
                p1: "J1",
                p2: "",
                page: obj?.id,
                text: "Add",
              })
            ).then(() =>
              dispatch(
                BillFormChallanPartyAc({
                  p0: "A",
                  p1: "",
                  p2: "",
                  page: obj?.id,
                  text: "Add",
                })
              )
            )
          );
        } else {
          Promise.resolve(Store.dispatch(TransactionApi(obj)));
        }
      } else {
        Store.dispatch(ModalDelete(index));
      }
    })
    .then(() => {
      
      setBillAmt(0);
      setBillSeriesHideShow({
        ...BillSeriesHideShow,
        BillNoVal: "",
      });
      // rowSum = 0;
      setM01PRow(null);
    })
    .then(() => {
      Store.dispatch(RefreshIssuePopUp({}));
    })
    .then(() => {
      Store.dispatch(RefreshIOrderPopUp({}));
    });

    let PaginationObj =
                Store.getState().DataSaveLoadingSlice.PaginationObj;
              // console.log(index, "ModalDelete");
              // Store.dispatch(ModalDelete(index));
              if (page === "04110399") {
                Promise.resolve(
                  Store.dispatch(
                    GetAccountList({
                      id: "04110399",
                      name: "Job work Bill",
                      p0: "MT",
                      p1: "TXNVOULISTJJ",
                      p2: "",
                      p3: "",
                      type: "A",
                      pagination: PaginationObj["04110399"],
                    })
                  )
                );
              } else if (page === "20442041") {
                Store.dispatch(
                  GetAccountList({
                    id: "20442041",
                    name: "Sub JobWork Bill",
                    p0: "MT",
                    p1: "TXNVOULISTSJ",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["20442041"],
                  })
                );
              } else if (page === "04320417") {
                Store.dispatch(
                  GetAccountList({
                    id: "04320417",
                    name: "Job work Bill",
                    p0: "MT",
                    p1: "TXNVOULISTJZ",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["04320417"],
                  })
                );
              }
  }
  function OnPrint() {
    Promise.resolve(dispatch(DataSaveLoading(false))).then(() => {
      setDrawerObj({
        ...dra,
        title: <Text fw={700}>Print Voucher</Text>,
        onClickCloseButton: () => {
          // setDrawerObj(dra);
          Promise.resolve(
            setDrawerObj({
              ...DrawerObj,
              open: false,
            })
          ).then(() => dispatchFunction());
        },
        onClose: () => {
          // setDrawerObj(dra);
          Promise.resolve(
            setDrawerObj({
              ...DrawerObj,
              open: false,
            })
          ).then(() => dispatchFunction());
        },
        body: (
          <>
            <PLPrint data={obj} />
          </>
        ),
        open: true,
        size: "sm",
        position: "right",
      });
    });
  }
  function OnWhtsUp() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>WhatsApp </Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        console.log(OSV._ONLINEVOU, "OSV._ONLINEVOU");
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      onClose: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      body: (
        <>
          <FormatListTable
            obj={{ ...obj, p0: "W", text: "WhatsApp" }}
            index={props.index}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  }
  function OnSMS() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>SMS </Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        console.log(OSV._ONLINEVOU, "OSV._ONLINEVOU");
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      onClose: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      body: (
        <>
          <FormatListTable
            obj={{ ...obj, p0: "S", text: "SMS" }}
            index={props.index}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  }
  function OnEmail() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>EMail </Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        console.log(OSV._ONLINEVOU, "OSV._ONLINEVOU");
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEWAP == "Y") {
              OnWhtsUp();
            } else if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      onClose: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEWAP == "Y") {
              OnWhtsUp();
            } else if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      body: (
        <>
          <FormatListTable
            obj={{ ...obj, p0: "E", text: "EMail" }}
            index={props.index}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  }
  function handleOkButton(e) {
    // console.log("OK BTN", t02Data, group, "t02Obj--", t02Obj);
    // if (t02Data?.length < 2 ) {

    if (
      group?.FIELD04 == ""
      // || t02Data?.length < 1
    ) {
      // console.log("OK BTN", t02Data, group, "t02Obj--", t02Obj);

      GlobalClass.Notify(
        "warning",
        "Validation error",
        "Please fill out all the required fields"
      );
    } else {
      // const sortedEditT41 = JSON.stringify(editT41);
      // const sortedGroup = JSON.stringify(group);
      // // console.log(
      // //   "Are these passed in array of objects equal? : " + sortedEditT41
      // // );
      // if (
      //   sortedEditT41 === sortedGroup &&
      //   compareTwoArrayOfObjects(editT02, t02Data)
      // ) {
      //   Store.dispatch(ModalDelete(index));
      // }
      let newT02 = T02?.filter((v, i) => v?.FIELD03 !== "");
      if (
        obj.p0 == "E" &&
        compareTwoArrayOfObjects(newT02, t02Data) &&
        JSON.stringify(T41) === JSON.stringify(group)
      ) {
        Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
          dispatch(ModalDelete(props.index))
        );
        return;
      } else {
        //if sms -> sms modal then post otherwise direct post
        Promise.resolve(Store.dispatch(DataSaveLoading(true))).then(() =>
          GlobalClass.Notify(
            "info",
            p0 == "A" ? "Adding" : "Editing",
            "Please wait while we process your data"
          )
        );
        // console.log(group?.FIELD07, "group?.FIELD07");
        let newT41 = {
          ...group,
          // FIELD06: group?.FIELD07 == 0 ? billAmt : group?.FIELD07,
          FIELD06: group?.FIELD07 == 0 ? rowSum : group?.FIELD07,
        };
        let dataToSend = {
          T41: e ? e : { ...newT41 },
          T02: t02Data,
          T05: [...T05DataArray],
        };

        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: props ? p0 : "A",
          cCode: p0 == "E" ? editId : pagecCode,
          cOPara:
            page == "20442041" // Sub-JobworkBill
              ? "CTYPE~C~SJ"
              : // : page == "20432041" //Receipt
                // ? "CTYPE~C~JC"
                // : page == "20422041" //Issue
                // ? "CTYPE~C~JD"
                "",
          cSData: JSON.stringify(dataToSend),
        };
        console.log(dataToSend, "data Num");
        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: p0 == "E" ? p0 : "A",
          cCode: p0 == "E" ? editId : pagecCode,
          cOPara:
            page == "20442041" // Sub-JobworkBill
              ? "CTYPE~C~SJ"
              : "",
        });

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        // console.log(dataToSend, "param Stock Journal");
        // try {
        fetch(
          GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
          requestOptions
        )
          .then((response) => {
            // console.log(!response.ok, "response.ok");
            if (!response?.ok) {
              throw new Error(`HTTP error! Status: ${response?.status}`);
            } //This block give more detail info about error other then 2xx status codes.
            return response?.json();
          })
          .then((data) => {
            // console.log("api data=>", data);
            // console.log("api data status=>", data?.status);

            if (data?.status == "SUCCESS") {
              GlobalClass.Notify(
                "success",
                p0 == "A" ? "Added" : "Edited",
                p0 == "A" ? "Added successfully" : "Edited successfully"
              );

              Promise.resolve(
                OSV._ONLINEMAL == "Y" || OSV._ONLINEMAL == "L"
                  ? Promise.resolve(dispatch(DataSaveLoading(false))).then(
                      () => {
                        OnEmail();
                      }
                    )
                  : OSV._ONLINEWAP == "Y" || OSV._ONLINEWAP == "L"
                  ? Promise.resolve(dispatch(DataSaveLoading(false))).then(
                      () => {
                        OnWhtsUp();
                      }
                    )
                  : OSV._ONLINESMS == "Y" || OSV._ONLINESMS == "L"
                  ? Promise.resolve(dispatch(DataSaveLoading(false))).then(
                      () => {
                        OnSMS();
                      }
                    )
                  : Promise.resolve(
                      setDrawerObj({ ...DrawerObj, open: false })
                    ).then(() => {
                      if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                        OnPrint();
                      } else {
                        dispatchFunction();
                      }
                    })
              );
            } else if (data?.status == "FAIL") {
              Promise.resolve(Store.dispatch(DataSaveLoading(false))).then(
                () => {
                  setT02Data(t02Data);
                  GlobalClass.Notify("error", data?.status, data?.message);
                }
              );
            }
          })
          .catch((e) => {
            // console.log("inside catch", e);
            GlobalClass.Notify("error", "Error", `${e.message}`);
            Promise.resolve(Store.dispatch(DataSaveLoading(false))).then(() => {
              setT02Data(t02Data);
            });
          });
      }
    }
  }

  const spoilerControlRef = useRef(null);
  return (
    <>
      {SaveLoading == true ||
      isLoadingEnt ||
      AccNameisLoading["T_M32C"] ||
      isUserFieldLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : obj?.p0 == "U" ? (
        <>
          <PLUserFieldBtn
            UserFieldArray={UserField?.UFTbl}
            T41={group}
            onChange={(e) => {
              // Promise.resolve(setGroup(e)).then(()=>
              handleOkButton(e);
              // )
            }}
          />
        </>
      ) : (
        <div ref={formRef}>
           <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={true}
        fullScreen={false} //
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: (e) => {
            if (typeof DrawerObj?.onClickCloseButton == "function") {
              DrawerObj?.onClickCloseButton();
            } else {
              setDrawerObj(dra);
            }
          },
        }}
        title={DrawerObj?.title}
        size={DrawerObj?.size}
        // centered={.Centered}
        closeOnClickOutside={true}
        // overlayProps={m.Overlay}
        position={DrawerObj?.position ?? "bottom"}
        onClose={
          typeof DrawerObj?.onclose == "function"
            ? DrawerObj?.onclose
            : () => {
                setDrawerObj(dra);
              }
        }
        // scrollAreaComponent={ScrollArea.Autosize}
      >
        {DrawerObj.body}
      </Modal>
          <Paper shadow="md" radius="md" p="xs" withBorder>
            <Grid gutter={4}>
              <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                <Grid.Col span={4} style={{ alignItems: "center", padding: 0 }}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Cash/Debit:</Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <PLComboBox
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        autoFocus={p0 == "A" ? true : false} //Initially opened ma UI issue awe 6 after Ok btn atle autofocus use kryu 6.
                        value={group?.FIELD16}
                        data={COP8 || []}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        setEdit={(e) => {
                          //console.log("Case/Debit=>", e)
                          Promise.resolve(
                            setGroup({ ...group, FIELD16: e })
                          ).then(() => {
                            partyRef?.current?.handlePopoverToggle();
                          });
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12, color: "red" }}>
                        {M01PRow?.FIELD37 == "R"
                          ? "Registered"
                          : M01PRow?.FIELD37 == "U"
                          ? "Unregistered"
                          : M01PRow?.FIELD37 == "C"
                          ? "Consumer"
                          : M01PRow?.FIELD37 == "M"
                          ? "Composition"
                          : null}
                      </Text>
                      {/*R-Registered/U-Unregitered/C-Consumer/M-Composition*/}
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Party A/c:</Text>
                    </Grid.Col>
                    <Grid.Col span={9} style={{ overflow: "hidden" }}>
                      {page == "04320417" || page == "04110399" ? (
                        <PLDataGrid
                          ref={partyRef}
                          width={"500px"}
                          value={{
                            value: group?.FIELD04,
                            label: group?.FIELD04NM,
                          }}
                          TmData={M01P}
                          // form={true}
                          // data={M01PDT}
                          // data={BillFormChallanPartyAcData?.M01PDT}
                          setEdit={(e) => {
                            // //console.log(e)
                            Promise.resolve(
                              setGroup({
                                ...group,
                                FIELD04: e?.FIELD01,
                                FIELD04NM: e?.FIELD02,
                              })
                            ).then(() => {
                              invRef?.current?.handlePopoverToggle();
                            });
                            // M01PDT?.map((d) => {
                            //   if (d?.FIELD01 == e.FIELD01) {
                            setM01PRow(e);
                            // }
                            // });
                          }}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          // handleClose={(e)=>//console.log(e)}
                        />
                      ) : (
                        <PLDataGrid
                          ref={partyRef}
                          width={"500px"}
                          value={group?.FIELD04}
                          TmData={M01P}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          // form={true}
                          // data={M01PDT}
                          data={BillFormChallanPartyAcData?.M01PDT}
                          setEdit={(e) => {
                            // //console.log(e)
                            Promise.resolve(
                              setGroup({
                                ...group,
                                FIELD04: e?.FIELD01,
                                FIELD04NM: e?.FIELD02,
                              })
                            ).then(() => {
                              invRef?.current?.handlePopoverToggle();
                            });
                            setM01PRow(e);
                            // }
                            // });
                          }}
                          // handleClose={(e)=>//console.log(e)}
                        />
                      )}

                      {CasePartyRequired?.DropDownValue == "D" &&
                      group?.FIELD16 == "C" ? (
                        <Button
                          variant="default"
                          fullWidth
                          style={{
                            padding: "0px 5px",
                            display: "flex",
                            alignItems: "flex-start",
                            fontWeight: "normal",
                            fontSize: "12px",
                          }}
                          onClick={() => CasePartyDetailForm()}
                        >
                          {CasePartyRequired?.CasePartyFormName}
                        </Button>
                      ) : null}
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>{/*Blank Space */}</Grid.Col>
                    <Grid.Col span={9}>
                      <Text style={{ fontSize: 12 }}>
                        {M01PRow != null ? M01PRow?.FIELD17 : null}
                      </Text>
                      {/*City Name*/}
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={6}>
                      <Button
                        size="xs"
                        variant="light"
                        onClick={() => HandleHistoryBtn()}
                      >
                        History
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Text style={{ fontSize: 12 }}>
                        Bal :{M01PRow != null ? M01PRow?.FIELD99 : null} DB
                      </Text>
                    </Grid.Col>
                  </Grid.Col>

                  {page == "04320417" || obj?.id == "04290417" ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Delivery At:</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        {/* {fieldDisable.Delivery_at1 == "Y" &&
                fieldDisable.Delivery_at2 == "Y" &&
                fieldDisable.Delivery_at3 == "D" ? ( */}
                        <PLDataGrid
                          width={"500px"}
                          value={{
                            value: group?.FIELD39,
                            label: group?.FIELD39NM,
                          }}
                          TmData={M01D}
                          // form={false}
                          disabled={
                            fieldDisable.Delivery_at1 != "Y" &&
                            fieldDisable.Delivery_at2 != "Y" &&
                            fieldDisable.Delivery_at3 != "D"
                          }
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          data={M01DT?.jData}
                          setEdit={(e) => {
                            // //console.log(e)
                            Promise.resolve(
                              setGroup({
                                ...group,
                                FIELD39: e?.FIELD01,
                                FIELD39NM: e?.FIELD02,
                              })
                            ).then(() => {
                              if (
                                // obj?.id == "04080399" ||
                                // obj?.id == "20452041" ||
                                obj?.id == "04290417" &&
                                PendingChallanData?.dtchln?.length > 0
                              ) {
                                PendingChallanFun();
                              }
                            });
                          }}
                        />

                        {/* : (
                  <PLTextBox disabled={true} placeholder={"Select"} />
                )} */}
                      </Grid.Col>
                    </Grid.Col>
                  ) : (
                    ""
                  )}
                </Grid.Col>

                <Grid.Col span={4} style={{ alignItems: "center", padding: 0 }}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Inv Type:</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      {/* {
                                            fieldDisable?.lInvType == true ? */}
                      <PLDataGrid
                        ref={invRef}
                        width={"500px"}
                        value={group?.FIELD13}
                        TmData={M45I}
                        data={M45IDT}
                        disabled={T41?.lInvType == false}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          console.log("Inv Type======>", e);
                          Promise.resolve(
                            M45IDT?.map((d) => {
                              if (d?.FIELD01 == e?.FIELD01) {
                                setGroup({
                                  ...group,
                                  FIELD45: d?.FIELD20,
                                  FIELD13: e?.FIELD01,
                                });
                                // PortFieldHideShow(d.FIELD20);
                              }
                            })
                          )
                            .then(() => Store.dispatch(GetInvTypeDropDown(e)))
                            .then(() =>
                              Promise.resolve(
                                Store.dispatch(
                                  GetExpenseData({
                                    action: "A",
                                    code: pagecCode,
                                    invCode: e.FIELD01,
                                  })
                                )
                              ).then(() => {
                                group?.lMTaxEnt
                                  ? proNameRef?.current?.handlePopoverToggle()
                                  : jobRef?.current?.handlePopoverToggle();
                              })
                            );
                          // DtExpFooterFunction(e.FIELD01);
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Jobwork A/c:</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      {/* {fieldDisable?.lMTaxEnt == true ? (
                  <PLTextBox disabled={true} placeholder={"Select"} />
                ) : ( */}
                      <PLDataGrid
                        ref={jobRef}
                        width={"500px"}
                        value={group?.FIELD05}
                        disabled={group?.lMTaxEnt}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, FIELD05: e.FIELD01 });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Process Name</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDataGrid
                        ref={proNameRef}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        width={"500px"}
                        value={{
                          value: group?.FIELD34,
                          label: group?.FIELD34NM,
                        }}
                        TmData={P_M31J}
                        data={P_M31JDT?.jData}
                        setEdit={(e) => {
                          // console.log(e, "length-");
                          Promise.resolve(
                            setGroup({
                              ...group,
                              FIELD34: e?.FIELD01,
                              FIELD34NM: e?.FIELD02,
                            })
                          ).then(() => {
                            vouDRef?.current?.focus();
                          });
                        }}
                      />
                      {/* <PLComboBox
                        value={group?.FIELD34}
                        TmData={P_M31J}
                        data={P_M31JDT?.jData}
                        dispexpr="FIELD02"
                        valexpr="FIELD01"
                        setEdit={(e) => {
                          // //console.log(e)
                          setGroup({ ...group, FIELD34: e });
                        }}
                      /> */}
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={4} style={{ alignItems: "center", padding: 0 }}>
                  {obj?.id === "04080399" || //sub and jwO
                  page === "04110399" ||
                  obj?.id === "20452041" ||
                  page === "20442041" ? (
                    <>
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Vou. Date:</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLDateBox
                            ref={vouDRef}
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            dispformat="DD/MM/YYYY"
                            disabled={OSV?._DOCNODATE != "Y"}
                            defaultval={group?.FIELD11?.trim()}
                            setEdit={(e) => {
                              let { startDate } = DateToString(e);
                              // console.log(startDate , "startDate", e)
                              if (startDate?.length == 8) {
                                Promise.resolve(
                                  setGroup({ ...group, FIELD11: startDate })
                                ).then(() => {
                                  vouNoRef?.current?.handlePopoverToggle();
                                });
                              }
                              setDate(e);
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col span={1}>
                          {date?.toString()?.slice(0, 3)}
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Voucher No:</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          {p0 == "E" ? (
                            <PLTextBox
                              onKeyDown={(e) => {
                                GlobalClass.formNextInput(e, formRef);
                              }}
                              value={group?.FIELD12}
                              setEdit={(e) => {
                                setGroup({
                                  ...group,
                                  T92F00: "",
                                  FIELD12: e.target.value,
                                });
                              }}
                            />
                          ) : (
                            <>
                              {T92 ? (
                                <>
                                  {BillSeriesHideShow?.DataGridInput == true &&
                                    (!group?.T92F00 ? (
                                      <PLDataGrid
                                        ref={vouNoRef}
                                        onKeyDown={(e) => {
                                          GlobalClass.formNextInput(e, formRef);
                                        }}
                                        width={"500px"}
                                        position={"unset"}
                                        value={group?.FIELD12}
                                        valexpr={"FIELD00"}
                                        TmData={T92}
                                        // data={T92DT ?? []}
                                        setEdit={(e) => {
                                          // console.log(e?.FIELD00, "FIELD00");
                                          Promise.resolve(
                                            setGroup({
                                              ...group,
                                              T92F00: e.FIELD00,
                                              FIELD12: "",
                                            })
                                          ).then(() => {
                                            billId?.focus();
                                            if (e?.FIELD02) {
                                              let letr = e.FIELD02;
                                              // console.log("num=>", num);
                                              let num = parseInt(e.FIELD03) + 1;
                                              setBillSeriesHideShow({
                                                ...BillSeriesHideShow,
                                                DataGridInput: false,
                                                TextInput: true,
                                                BillNoVal: letr + num,
                                              });
                                            }
                                          });
                                        }}
                                      />
                                    ) : (
                                      <PLTextBox
                                        value={group?.FIELD12}
                                        // value={T41Obj?.T92F00}
                                        setEdit={(e) => {
                                          // console.log("setEdit=>", e);
                                          // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                          setGroup({
                                            ...group,
                                            T92F00: "",
                                            FIELD12: e.target.value,
                                          });
                                        }}
                                      />
                                    ))}
                                  {BillSeriesHideShow?.TextInput == true && (
                                    <PLTextBox
                                      onKeyDown={(e) => {
                                        GlobalClass.formNextInput(e, formRef);
                                      }}
                                      value={BillSeriesHideShow?.BillNoVal}
                                      setEdit={(e) => {
                                        // //console.log(e)

                                        setBillSeriesHideShow({
                                          ...BillSeriesHideShow,
                                          BillNoVal: e.target.value,
                                        });
                                        setGroup({
                                          ...group,
                                          T92F00: "",
                                          FIELD12: e.target.value,
                                        });
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <PLTextBox
                                  onKeyDown={(e) => {
                                    GlobalClass.formNextInput(e, formRef);
                                  }}
                                  value={BillSeriesHideShow?.BillNoVal}
                                  // value={T41Obj?.T92F00}
                                  setEdit={(e) => {
                                    console.log("setEdit=>", e);
                                    setBillSeriesHideShow({
                                      ...BillSeriesHideShow,
                                      BillNoVal: e.target.value,
                                    });
                                    setGroup({
                                      ...group,
                                      T92F00: "",
                                      FIELD12: e.target.value,
                                    });
                                  }}
                                />
                              )}
                            </>
                          )}
                          {/* <PLNumberBox
                      value={group?.FIELD10}
                      setEdit={(e) => {
                        console.log(e, "FIELD10 --");
                        setGroup({ ...group, FIELD10: e });
                      }}
                    /> */}
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Bill No:</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          {/* <PLDataGrid
                            width={"500px"}
                            position={"unset"}
                            // width={350}
                            dispexpr={"FIELD02"}
                            valexpr={"FIELD00"}
                            value={group?.FIELD12}
                            TmData={T92}
                            data={T92DT}
                            setEdit={(e) => {
                              // //console.log(e)
                              setGroup({ ...group, FIELD12: e.FIELD01 });
                            }}
                          /> */}
                          <PLTextBox
                            id="#billId"
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            value={group?.FIELD10}
                            disabled={obj?.id === "20452041"}
                            setEdit={(e) => {
                              setGroup({
                                ...group,
                                // T92F00: "",
                                FIELD10: e.target.value,
                              });
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Bill Date:</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLDateBox
                            defaultval={group?.FIELD02}
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            dispformat="DD/MM/YYYY"
                            setEdit={(e) => {
                              let { startDate } = DateToString(e);
                              // console.log("Bill Date==>", e, startDate);
                              new Promise((resolve) => {
                                if (startDate?.length == 8) {
                                  resolve(
                                    setGroup({ ...group, FIELD02: startDate })
                                  );
                                }
                              }).then(() => {
                                // console.log(
                                //   PendingChallanData,
                                //   "PendingChallanData"
                                // );
                                if (
                                  (obj?.id == "04080399" ||
                                    obj?.id == "20452041") &&
                                  //   || obj?.id == "04290417"
                                  PendingChallanData?.dtchln?.length > 0
                                ) {
                                  PendingChallanFun();
                                }
                              });
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </>
                  ) : (
                    //JWI
                    <>
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Bill Date</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLDateBox
                            defaultval={group?.FIELD02}
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            dispformat="DD/MM/YYYY"
                            setEdit={(e) => {
                              let { startDate } = DateToString(e);
                              // console.log("Bill Date==>", e, startDate);

                              if (startDate?.length == 8) {
                                setGroup({ ...group, FIELD02: startDate });
                              }
                              setDate(e);
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col span={1}>{dayVal}</Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Bill No:</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLTextBox
                            value={group?.FIELD12}
                            disabled={obj?.id === "20452041"}
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            setEdit={(e) => {
                              setGroup({
                                ...group,
                                // T92F00: "",
                                FIELD12: e.target.value,
                              });
                            }}
                          />
                          {/* {T92?.length < 1 ? (
                            <PLTextBox
                              value={group?.FIELD12}
                              setEdit={(e) => {
                                setGroup({
                                  ...group,
                                  T92F00: "",
                                  FIELD12: e.target.value,
                                });
                              }}
                            />
                          ) : (
                            <>
                              {BillSeriesHideShow?.DataGridInput == true && (
                                <PLDataGrid
                                  width={"500px"}
                                  position={"unset"}
                                  value={group?.FIELD12}
                                  valexpr={"FIELD00"}
                                  TmData={T92}
                                  data={T92DT}
                                  setEdit={(e) => {
                                    console.log(e?.FIELD00, "FIELD00");
                                    Promise.resolve(
                                      setGroup({
                                        ...group,
                                        T92F00: e.FIELD00,
                                        FIELD12: "",
                                      })
                                    ).then(() => {
                                      if (e?.FIELD02) {
                                        var num = e.FIELD02.match(/\d+/g);
                                        var letr =
                                          e.FIELD02.match(/[a-zA-Z]+/g);
                                        console.log(
                                          "num=>",
                                          num,
                                          letr + (parseInt(num ? num : 0) + 1)
                                        );

                                        setBillSeriesHideShow({
                                          ...BillSeriesHideShow,
                                          DataGridInput: false,
                                          TextInput: true,
                                          BillNoVal:
                                            letr +
                                            (
                                              parseInt(num ? num : 0) + 1
                                            ).toString(),
                                        });
                                      }
                                    });
                                  }}
                                />
                              )}
                              {BillSeriesHideShow?.TextInput == true && (
                                <PLTextBox
                                  value={BillSeriesHideShow?.BillNoVal}
                                  setEdit={(e) => {
                                    // //console.log(e)
                                    setGroup({
                                      ...group,
                                      T92F00: "",
                                      FIELD12: e.target.value,
                                    });
                                  }}
                                />
                              )}
                            </>
                          )} */}
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Doc. No.</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLNumberBox
                            width={"500px"}
                            position={"unset"}
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            // width={350}
                            disabled={OSV?._DOCNODATE != "Y"}
                            value={group?.FIELD10}
                            // TmData={T92}
                            // data={T92DT}
                            setEdit={(e) => {
                              // //console.log(e)
                              setGroup({ ...group, FIELD10: e });
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Doc Date</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLDateBox
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            dispformat="DD/MM/YYYY"
                            disabled={OSV?._DOCNODATE != "Y"}
                            defaultval={group?.FIELD11?.trim()}
                            setEdit={(e) => {
                              // console.log("e", e);
                              let { startDate } = DateToString(e);
                              if (startDate?.length == 8) {
                                setGroup({ ...group, FIELD11: startDate });
                              }
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </>
                  )}
                </Grid.Col>
              </Grid.Col>
              {/* Header User Field  */}
            </Grid>
            {UFT41H?.length > 0 && UFT41Hval?.length > 0 ? (
              <>
                <Divider
                  my="xs"
                  label="User Fields"
                  labelPosition="center"
                  labelProps={{ component: "strong" }}
                />
                <Spoiler
                  maxHeight={80}
                  showLabel="Show more"
                  hideLabel="Hide"
                  controlRef={spoilerControlRef}
                >
                  <Grid gutter={4}>
                    {/* <Grid.Col span={12} style={{ display: "flex" }}> */}
                    {UFT41HFieldRenderer("Header")}
                    {/* </Grid.Col> */}
                  </Grid>
                </Spoiler>
              </>
            ) : (
              ""
            )}
            <Grid gutter={4}>
              <Grid.Col span={12} my={7}>
                {/* <MantineReactTable table={table} /> */}
                <T02Table
                  accList={accList}
                  tmList={tmList}
                  dataEnt={dataEnt}
                  obj={obj}
                  setTableData={setTableData}
                  setT02Data={setT02Data}
                  t02Data={t02Data}
                  setIsVisible={setIsVisible}
                  setCreatingModeOn={setCreatingModeOn}
                  setEditingRow={setEditingRow}
                  editingRow={editingRow}
                  t41={group}
                  t02Obj={t02Obj}
                  setT02Obj={setT02Obj}
                  setTableExpenseVal={setTableExpenseVal}
                  setSelectedProductObj={setSelectedProductObj}
                  selectedProductObj={selectedProductObj}
                  ExpenseData={
                    ExpenseData instanceof Array || ExpenseData == undefined
                      ? []
                      : ExpenseData
                  }
                  setGroup={setGroup}
                  InvTypeDropDown={InvTypeDropDown}
                  group={group}
                  ExpenseObj={ExpenseObj}
                  flagCopy={flagCopy}
                  // JWListData={JWListData}
                  // JWOrderData={JWOrderData}
                />
              </Grid.Col>
            </Grid>

            <Center h={50}>
              <Button
                size="xs"
                compact
                onClick={() => {
                  console.log(
                    copyRow?.original,
                    "tableData-",
                    copyRow,
                    creatingModeOn
                  );
                  if (
                    !creatingModeOn
                  ) {
                    setHeaderExpenseFlag(true)
                    setFlagCopy(true);
                  }
                }}
              >
                Copy
              </Button>
            </Center>

            {/* <Divider my={2} /> */}
            <Grid gutter={4}>
              <Grid.Col span={12} style={{ display: "flex" }}>
                <Grid.Col span={8}>
                  {/* <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                    NOS-NUMBERS 000000.0000 Qty-2
                  </Text> */}
                </Grid.Col>

                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                    flexWrap: "wrap",
                  }}
                >
                  <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                    Item Amount :
                  </Text>

                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    {isVisible ? (
                      <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                        {/* {billAmt} */}
                        {rowSum}
                        DB
                      </Text>
                    ) : (
                      ""
                    )}
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
              <Grid.Col span={12} style={{ display: "flex" }}>
                <Grid.Col
                  span={6}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: 0,
                    justifyContent: "space-evenly",
                  }}
                >
                  <Grid.Col span={12}>
                    {UFT41Fval?.length > 0 ? (
                      <>
                        <>{UFT41HFieldRenderer("Footer")}</>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                    flexWrap: "wrap",
                  }}
                >
                  <Grid.Col
                    span={12}
                    style={{
                      border: "1px solid gray",
                      height: "200px",
                      overflow: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    {/* Footer Expense */}

                    <ExpenseEntryForm
                      // ProductObj={selectedProductObj}
                      ExpenseObj={ExpenseObj}
                      group={group}
                      isExpense={false}
                      expenseArr={
                        ExpenseArrayT41?.length > 0
                          ? ExpenseArrayT41
                          : ExpenseData?.dtExp
                      }
                      expenseArrDef={
                        ExpenseData instanceof Array ||
                        ExpenseData === undefined
                          ? []
                          : ExpenseData?.dtExpDef
                      }
                      M01PData={ExpenseData?.M01SPDT?.jData}
                      M01Format={ExpenseData?.M01SP}
                      valueSaveObj={group}
                      // TableExpenseValue={tableExpenseVal}
                      TableExpenseValue={rowSum}
                      TableExpenseArr={t02Data}
                      onChange={(e) => {
                        new Promise(() => {
                          setGroup(e?.ObjT41)
                          setT02Data(e?.ArrT02)
                        }).then(() => setTimeout(() => {
                          setFooterExpenseFlag(!FooterExpenseFlag)
                        }, 200))
                        // setFooterExpenseObj(e)
                        // setExpenseRowSum(e.FIELD07)
                        // console.log("TableExpenseEntry=>", e);
                      }}
                      DrawerOBJ={DrawerObj}
                      setDrawerOBJ={(e) => setDrawerObj(e)}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>

              {/* Footer User Field */}

              {/* <Grid.Col span={6}>{UFT41HFieldRenderer("Footer")}</Grid.Col> */}
            </Grid>
            <Grid gutter={4}>
              <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                <Grid.Col span={4} style={{ alignItems: "center", padding: 0 }}>
                  <Grid.Col span={10}>
                    <Text style={{ fontSize: 12 }}>Narration:</Text>
                  </Grid.Col>
                  <Grid.Col span={12} display={"flex"}>
                    <Grid.Col span={9}>
                      <PLTextarea
                        minRows={2}
                        value={group?.FIELD15}
                        onChange={(e) => {
                          // //console.log(e)
                          setGroup({ ...group, FIELD15: e.target.value });
                        }}
                        onKeyDown={(e) => {
                          // console.log("Narration=>", e.shiftKey);
                          GlobalClass.formNextInput(e, formRef);
                          if (e.ctrlKey && e.key === "F1") {
                            //console.log("Narration call");
                            setDrawerObj({
                              ...dra,
                              title: <Text fw={700}>Naration</Text>,
                              body: (
                                <PLNarration
                                  NarrationArray={NarrationData}
                                  Flag={
                                    SelectedNarrationData?.length > 0
                                      ? false
                                      : true
                                  }
                                  selectedNarrationArray={SelectedNarrationData}
                                  // NarrationVal={T41Obj}
                                  setNarrationArray={(e) => {
                                    // setGroup(e)
                                    console.log("setNarrationArray", e);
                                    setNarrationData(e.NarrationData);
                                    setSelectedNarrationData(
                                      e.selectedNarration
                                    );
                                  }}
                                  DrawerOBJ={DrawerObj}
                                  setDrawerOBJ={(e) => setDrawerObj(e)}
                                />
                              ),
                              open: true,
                              size: "md",
                              position: "right",
                            });
                          }
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={3} style={{ display: "flex" }}>
                      {/* <Grid.Col span={6} p={0}>
                                            <Button size='xs' variant='light' compact>Scheme Off</Button>
                                        </Grid.Col> */}
                      <Grid.Col span={6}>
                        <Button
                          hidden
                          size="xs"
                          variant="light"
                          compact
                          onFocusCapture={() => {
                            // console.log("Bill to Bill call");
                            // if (T05ENT) {
                            //   BillToBillTableFun();
                            // }
                          }}
                          onClick={() => (T05ENT ? BillToBillTableFun() : null)}
                        >
                          Bill to Bill
                        </Button>
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Button hidden size='sm' variant='light' compact onFocusCapture={() => { document.getElementById('okbutton').focus(); BillToBillTableFun() }} onClick={() => BillToBillTableFun()}>Bill to Bill</Button> */}
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "flex-end",
                    // padding: 0,
                  }}
                >
                  <Group spacing={"xs"} position="center">
                    <Button
                      size="xs"
                      id="okbutton"
                      disabled={
                        creatingModeOn
                        //  || t02Data?.length < 1
                      }
                      onClick={() => handleOkButton()}
                    >
                      Ok
                    </Button>
                    <Button size="xs">Print</Button>
                  </Group>
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // padding: 0,
                      flexWrap: "wrap",
                    }}
                  >
                    <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                      Bill Amount:
                    </Text>

                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      {  (
                        <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                          {group?.FIELD06 == 0
                            ? group?.FIELD07
                            : group?.FIELD06}
                          DB
                        </Text>
                      )}
                    </Grid.Col>
                  </Grid.Col>
                  {/* <Text
                    style={{ fontSize: 12, fontWeight: "bold", padding: 0 }}
                  >
                    Bill Amount:
                  </Text>
                  {isVisible ? (
                    <Text
                      style={{ fontSize: 12, fontWeight: "bold", padding: 0 }}
                    >
                      {group?.FIELD07} CR
                    </Text>
                  ) : (
                    ""
                  )} */}
                </Grid.Col>
                {/* <Grid.Col
                      span={7}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      
                    </Grid.Col> */}
              </Grid.Col>
            </Grid>
          </Paper>
          {lEditVou == false && <> {GlobalClass.Alert("info", props.index)}</>}
        </div>
      )}
    </>
  );
}
