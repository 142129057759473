import {
  Button,
  Drawer,
  Grid,
  Group,
  Modal,
  Text,
  Textarea,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLTextBox from "../../PlusComponent/PLTextBox";
import FieldValueList from "./FieldValueList";
import PLTextarea from "../../PlusComponent/PLTextarea";
import { useSelector } from "react-redux";
import Store from "../../utils/store";
import {
  GetEMAILData,
  GetSMSData,
  GetWhatsAppData,
  PostEMAILdata,
  PostSMS,
  PostWhatsApp,
} from "../../utils/TransactionSlices/MessageBtnSlice";
import GlobalClass from "../../utils/GlobalClass";
import gensetting from "../../utils/gensetting";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import {
  GetVouchNum,
  GetVouchNumData,
} from "../../utils/slices/VoucherNumSlice";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";

export default function FormatForm(props) {
  console.log(props, "FormatForm");
  const [opened, setOpened] = useState(false);
  const [msg, setMsg] = useState("");
  const [group, setGroup] = useState({
    FIELD01: "",
    FIELD02: "",
    FIELD03: "",
    FIELD04: "",
    FIELD05: "",
    FIELD06: "",
    FLDUNQ: 0,
    FLDAED: "A",
    FLDBRC: "",
  });
  const [fileType, setFileType] = useState([
    // { label: "PDF File", value: "P" },
    // { label: "XML File", value: "X" }
  ]);
  const { obj, index, tabObj } = props;
  let p0 = obj?.BtnPnlObj?.p0;
  let ID = obj?.BtnPnlObj?.id;
  let text = obj?.BtnPnlObj?.text ?? obj?.text;

  function fieldVal() {
    setOpened(true);
  }
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const { msgData, isLoading } = useSelector((state) => state?.MessageBtnData);

  useEffect(() => {
    console.log(obj, "obj--", text, obj?.id);
    // console.log(tabObj, "tabObj-",obj, text)
    if (text == "SMS") {
      if (obj?.p0 == "E")
        Store.dispatch(GetSMSData({ p0: obj?.p0, id: obj?.id }));
      else Store.dispatch(GetSMSData({ p0: "A", id: "" }));
    } else if (text == "EMail") {
      if (obj?.p0 == "E")
        Store.dispatch(GetEMAILData({ p0: obj?.p0, id: obj?.id }));
      else Store.dispatch(GetEMAILData({ p0: "A", id: "" }));
    } else if (text == "WhatsApp") {
      if (obj?.p0 == "E")
        Store.dispatch(GetWhatsAppData({ p0: obj?.p0, id: obj?.id }));
      else Store.dispatch(GetWhatsAppData({ p0: "A", id: "" }));
    }
  }, [obj, text]);
  const postMsg = () => {
    console.log(group, "group--", p0, obj?.p0, text);
    let isEdited = false;
    if (group?.FIELD03 == "") {
      GlobalClass.Notify("error", "Blank Name Is Not Allowed")
      
    } else if(group?.FIELD04 == ""){
      GlobalClass.Notify("warning", `${text} Text Cannot be empty`)

    }
    
    else {
    Promise.resolve(Store.dispatch(DataSaveLoading(true))).then(() =>
      GlobalClass.Notify(
        "info",
        obj?.p0 == "A" ? "Adding" : "Editing",
        "Please wait while we process your data"
      )
    );
    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0 == "E" ? obj?.p0 : "A",
      cCode: obj?.p0 == "E" ? ID : "",
      // cAction: "A",
      // cCode: "",
      cSData: JSON.stringify(group),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    if (text == "EMail") {
      Promise.resolve(Store.dispatch(PostEMAILdata(requestOptions))).then(
        (data) => {
          // console.log(data, "requestOptions-Inform")
          if (data.meta.requestStatus == "fulfilled") {
            Promise.resolve(Store.dispatch(ModalDelete(index))).then(() => {
              Store.dispatch(DataSaveLoading(false));

              Promise.resolve(
                Store.dispatch(GetVouchNumData({ RepId: "P_EMAIL" }))
              ).then(() => {
                Store.dispatch(GetVouchNum({ RepId: "P_EMAIL" }));
              }) .then(()=>{
                GlobalClass.Notify(
                  "success",
                  obj?.p0 == "A" ? "Added" : "Edited",
                  obj?.p0 == "A" ? "Added successfully" : "Edited successfully"
                );
              });
            });
            
          }
        }
      );
    } else if (text == "WhatsApp") {
      Promise.resolve(Store.dispatch(PostWhatsApp(requestOptions))).then(
        (data) => {
          // console.log(data, "requestOptions-Inform")
          if (data.meta.requestStatus == "fulfilled") {
            Promise.resolve(Store.dispatch(ModalDelete(index))).then(() => {
              Store.dispatch(DataSaveLoading(false));

              Promise.resolve(
                Store.dispatch(GetVouchNumData({ RepId: "P_WAPP" }))
              ).then(() => {
                Store.dispatch(GetVouchNum({ RepId: "P_WAPP" }));
              }) .then(()=>{
                GlobalClass.Notify(
                  "success",
                  obj?.p0 == "A" ? "Added" : "Edited",
                  obj?.p0 == "A" ? "Added successfully" : "Edited successfully"
                );
              });;
            });
           
          }
        }
      );
    } else if (text == "SMS") {
      Promise.resolve(Store.dispatch(PostSMS(requestOptions))).then((data) => {
        // console.log(data, "requestOptions-Inform")
        if (data.meta.requestStatus == "fulfilled") {
          Promise.resolve(Store.dispatch(ModalDelete(index))).then(() => {
            Store.dispatch(DataSaveLoading(false));
            Promise.resolve(
              Store.dispatch(GetVouchNumData({ RepId: "P_SMS" }))
            ).then(() => {
              Store.dispatch(GetVouchNum({ RepId: "P_SMS" }));
            }) .then(()=>{
              GlobalClass.Notify(
                "success",
                obj?.p0 == "A" ? "Added" : "Edited",
                obj?.p0 == "A" ? "Added successfully" : "Edited successfully"
              );
            });;
          });
         
        }
      });
    }
    }
  };

  useEffect(() => {
    console.log(msgData, "msgData==S", text);

    if (msgData?.sms) setGroup(msgData?.sms);
  }, [msgData?.sms]);
  useEffect(() => {
    console.log(msgData, "msgData==E", text);
    if (msgData?.whatsapp) setGroup(msgData?.whatsapp);
  }, [msgData?.whatsapp]);
  useEffect(() => {
    console.log(msgData, "msgData==W", text);
    if (msgData?.email) setGroup(msgData?.email);
  }, [msgData?.email]);

  let stringOfMsg;

  useEffect(() => {
    if (msg?.length > 0) {
      stringOfMsg = msg;
      console.log(group?.FIELD04, "group?.FIELD04-");
      setGroup((prevGroup) => ({
        ...prevGroup,
        FIELD04:
          prevGroup?.FIELD04 && prevGroup.FIELD04.trim() !== ""
            ? `${prevGroup.FIELD04} ${stringOfMsg}`
            : stringOfMsg,
      }));
    }
    // setT41Obj({...T41Obj,FIELD15:})
  }, [stringOfMsg, msg]);

  console.log(obj, "msgData-", group);
  return (
    <>
      {isLoading || SaveLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <>
          <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            title={<Text fw={700}>Format</Text>}
            padding="xl"
            size="lg"
            position="right"
            // open="true"
          >
            <FieldValueList obj={obj} setOpened={setOpened} setMsg={setMsg} />
          </Modal>
          <Grid gutter={4}>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Format Name</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLTextBox
                  value={group?.FIELD03}
                  setEdit={(e) => {
                    //console.log(e)
                    setGroup({ ...group, FIELD03: e.target.value });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            {text == "SMS" ? (
              ""
            ) : (
              <>
                {text == "WhatsApp" ? (
                  ""
                ) : (
                  <>
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>CC</Text>
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLTextBox
                          value={group?.FIELD06}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, FIELD06: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>BCC</Text>
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLTextBox
                          value={group?.FIELD07}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, FIELD07: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Subject</Text>
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLTextBox
                          value={group?.FIELD05}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, FIELD05: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                <Grid.Col
                  span={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid.Col span={6}>
                    <Text style={{ fontSize: 12 }}>Format List</Text>
                  </Grid.Col>
                  <Grid.Col span={5}>
                    <PLComboBox disabled />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Grid.Col span={5}>
                    <Text style={{ fontSize: 12 }}>File Type</Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLComboBox
                      data={[
                        { Field01: "PDF File", Field02: "P" },
                        { Field01: "XML File", Field02: "X" },
                      ]}
                      dispexpr="Field01"
                      valexpr="Field02"
                      value={fileType}
                      setEdit={(e) => {
                        setFileType(e);
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </>
            )}
            {text == "WhatsApp" ? (
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Grid.Col span={4}>
                  <Text style={{ fontSize: 12 }}>Language</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <PLComboBox
                    disabled
                    placeholder="English"
                    defaultValue="English"
                  />
                </Grid.Col>
              </Grid.Col>
            ) : (
              ""
            )}
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>
                  {text == "SMS" ? "SMS Text" : "Message"}
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLTextarea
                  value={group?.FIELD04}
                  onChange={(e) => {
                    console.log(e?.target?.value, "onChange");
                    setGroup({
                      ...group,
                      FIELD04: e?.target?.value,
                    });
                    // setMsg(e?.target?.value);
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent:
                  text == "WhatsApp" ? "space-between" : "flex-end",
              }}
            >
              {/* <Group spacing={"xs"} position="center" mt={5} mb={5}> */}
              {/* <Button
              size="xs"
            //   onClick={() => callCustomCol()}
              // disabled={activeBtn ? false : true}
            >
              Custom Column
            </Button> */}
              <Button
                size="xs"
                onClick={() => fieldVal()}
                mr={3}
                // disabled={activeBtn ? true : false}
                // className={classes.button}
              >
                Field Value
              </Button>
              <Button
                size="xs"
                onClick={() => postMsg()}
                // disabled={activeBtn ? true : false}
                // className={classes.button}
              >
                Save
              </Button>
              {/* </Group> */}
            </Grid.Col>
          </Grid>
        </>
      )}
    </>
  );
}
