import React, { useEffect, useState } from 'react'
import PLComboBox from '../../PlusComponent/PLComboBox'
import { Button, Grid, Group, Paper, Textarea } from '@mantine/core'
import Store from '../../utils/store'
import { GetButtonLoading } from '../../utils/TransactionSlices/EWayBillSlice'
import { useSelector } from 'react-redux'

const CancelEWayBill = (props) => {
    const { G04Obj, setG04Obj, DrawerObjSend, setDrawerObjSend, COPGCNSend, oYearSend, handleSaveEwayBill } = props
    const ButtonLoading = useSelector((state) => state.EWayBill?.ButtonLoading)
    const [cancelObj, setCancelObj] = useState({})

    useEffect(() => {
        if (G04Obj) {
            setCancelObj(G04Obj)
        }
    }, [G04Obj])

    const cancelEWayBilFun = () => {
        setG04Obj(cancelObj);
    };

    const handleButtonClick = (type) => {
        cancelEWayBilFun();
        handleSaveEwayBill(type);
    };

    return (
        <Paper shadow="md" radius="md" p="xs" withBorder>
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={2} style={{ fontSize: 12 }}>Reason</Grid.Col>
                    <Grid.Col span={10}>
                        <PLComboBox
                            data={COPGCNSend}
                            value={cancelObj?.G04F54}
                            valexpr={"ValueMember"}
                            dispexpr={"DisplayMember"}
                            setEdit={(e) => {
                                setCancelObj({ ...cancelObj, G04F54: e })
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={2} style={{ fontSize: 12 }}>Remarks</Grid.Col>
                    <Grid.Col span={10}>
                        <Textarea
                            minRows={2}
                            value={cancelObj?.G04F31}
                            onChange={(e) => {
                                setCancelObj({ ...cancelObj, G04F31: e.target.value })
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
                <Grid.Col
                    span={12}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Group>
                        <Button
                            loading={ButtonLoading?.cancel}
                            style={{ height: "30px" }}
                            size="sm"
                            onClick={() => {
                                // setButtonLoading({ ...buttonLoading, cancel: true })
                                handleButtonClick("C")
                                Store.dispatch(GetButtonLoading({ ...ButtonLoading, cancel: true }))
                            }}
                        >WEB</Button>
                        <Button
                        loading={ButtonLoading?.generateQr}
                            disabled={oYearSend?.OSC?._EWBSMS == "N" ? true : false}
                            style={{ height: "30px" }}
                            size="sm"
                            onClick={() => {
                                handleButtonClick("Q")
                                Store.dispatch(GetButtonLoading({ ...ButtonLoading, generateQr: true }))
                            }}
                        >Generate QR</Button>
                    </Group>
                </Grid.Col>
            </Grid>
        </Paper>
    )
}

export default CancelEWayBill