import { Alert, Button, Grid, Loader, Overlay, Skeleton } from "@mantine/core";
import gensetting from "./gensetting";
import { notifications } from "@mantine/notifications";
import { IconAlertCircleFilled, IconAlertTriangle, IconBug, IconCheck, IconCircleXFilled, IconInfoCircle, IconInfoCircleFilled, IconMessageCircleExclamation, IconThumbUpFilled } from "@tabler/icons-react";
import { IconAlertSmall } from "@tabler/icons-react";
import { IconAlertCircle } from "@tabler/icons-react";
import { useMantineReactTable } from "mantine-react-table";
import { modals } from "@mantine/modals";
import Store from "./store";
import { ModalDelete, ModalFunction } from "./slices/ModalSlice";

export default class GlobalClass {
  static Domain = "https://kishanlive.in/eplus/";
  static ApiUrl = this.Domain + "api/";
  static GetMenu = "common/GetMenu";
  static GetPdProps = "template/getpdprops";
  static GetCapJson = "common/getcapjson";
  static GenData = "template/GenData";
  static GetTMFormat = "template/GetTMFormat";
  static GetAllForamt = "Template/GetAllForamt";
  static GetAllForamtVersion = "Template/GetAllForamtVersion"
  static GetGeneral = "common/GetGeneral";
  static GetCop = "common/getcop";
  static Getm51ent = "m51/Getm51ent";
  // static PostM51 = "m51/postm51ent";
  static Getm11ent = "m11/Getm11ent";
  static PostM11 = "m11/postm11ent";
  static GetM02ent = "m02/Getm02ent";
  static PostM02 = "m02/postm02ent";
  static GetM32GR = "m32/getm32gr"; // Product Group
  static GetM32CT = "m32/getm32ct"; //Product Category
  static PostM32 = "m32/postm32ent";//Product Group/Category
  static GetM32LC = "M32/GetM32LC"; //Location
  static GetM01 = "M01/GetM01Ent";
  static PostM01 = "M01/PostM01Ent";
  static GetM21 = "M21/GetM21Ent"; // Product List
  static PostM21 = "M21/PostM21Ent"; //Product List 
  static GetM46Exp = "M46/GetM46Exp" //Itemwise Expense
  static GetM46Ent = "M46/GetM46Ent" //Itemwise Expense
  static PostM46Ent = "M46/PostM46Ent" //Itemwise Expense
  static GetM35HSN = "M35/GetM35HSN" // HSN Code
  static PostM35HSN = "M35/PostM35HSN" // HSN Code
  static GETHSNHELP ="M35/GETHSNHELP" // HSN Code Help
  static GetM35SU = "M35/GetM35SU" //Sale 
  static PostM35SU = "M35/PostM35SU" // Sale
  static GetM35PU = "M35/GetM35PU" // Purchase
  static PostM35PU = "M35/PostM35PU" // Purchase
  static GetCMPSetup = "F02/GetF02Ent";
  static PostCMPSetup = "F02/PostF02Ent"; // CMP setup Post
  static GetYearDet = "Common/GetYearDet";
  static M41UFList = "M41UF/M41UFList"; //CMStup -> USER Field List
  static M41UMList = "M41UM/M41UMList" //CMStup -> USER Master List
  static M41UMField = "M41UM/M41UMFieldEnt"
  static PostM41UMEnt = "M41UM/PostM41UMEnt"; //CMStup -> USER Master Post // User Field
  static GetEnt = "M41UF/M41UFEnt"; // User Field
  static PostM41UFEnt = "M41UF/PostM41UFEnt"; // User Field
  static GetCorpDet = "Common/GetCorpDet";
  static GetM11 = "M11/GetM11Ent"; //Account Group
  static PostM11Ent = "M11/PostM11Ent"; //Account Group
  static GetM51 = "M51/GetM51Ent"; //GST Master and Tax Master
  static PostM51 = "M51/PostM51Ent"; //GST Master and Tax Master
  static PostM32Ent = "m32/postm32ent"; // Loaction List 
  static GetM24Ent = "M24/GetM24Ent"; // Price List
  static GetGenData = "Template/GenData"; //TDS Master
  static PostM24Ent = "M24/PostM24Ent"; // Price List
  static PostM01Ent = "M01/PostM01Ent"
  static GetStockRate = "M21/GetStockRate";  //Stock Rate
  static PostStockRate = "M21/PostStockRate" //Stock Rate
  static GetM52 = "M52/GetM52List";  //Sales Setup
  static GetM52Ent = "M52/GetM52Ent"; //Sales Setup
  static PostM52Ent = "M52/PostM52Ent"; //Sales Setup
  static GetG02Ent = "G02/GetG02Ent";
  static PostG02Ent = "G02/PostG02Ent";
  static M25List = "M25/GetM25List";   //Price List
  static PostM25Ent = "M25/PostM25Ent"  // Price List
  static GetM71Ent = "M71/GetM71Ent"; // Process Master
  static PostM71Ent = "M71/PostM71Ent"; // Process Master
  static GetVouEnt = "Vou/GetVouEnt"; //Bank-Cash Payment/Receipt //Opening Stock // Transaction > JobWork Opening // Production
  static PostVouEnt = "Vou/PostVouEnt" //Bank-Cash Payment/Receipt  //Opening Stock // JobWork Opening // Production
  static GetM31CT = "M31/GetM31CT" //City List
  static PostM31CT = "M31/PostM31Ent" //City List Area List
  static GetM31AR = "M31/GetM31AR" //Area List
  static GetM31CR = "M31/GetM31CR"; // Account Category
  static PostM31Ent = "M31/PostM31Ent"; // Account Category
  // static M41UMList = "M41UM/M41UMList"; //UserMaster
  // static PostM41UMEnt = "M41UM/PostM41UMEnt"; //UserMaster
  static M41UMFieldEnt = "M41UM/M41UMFieldEnt";//UserMaster
  static GetVouList = "Quick/GetVouList" // Quick Entry (Cash/Bank)
  static QuickGetVouEnt = "Quick/GetVouEnt" // Quick Entry (Cash/Bank)
  static QuickPostVouEnt = "Quick/PostVouEnt" // Quick Entry (Cash/Bank)
  static GetAutoBillData = "Vou/GetAutoBillData" // AutoBillForm data (Transaction/SE)
  static PostAutoVouList = "Vou/GetAutoVouList" // AutoBill Post data
  static MakeAutoBill = "Vou/MakeAutoBill" // AutoBill Post pending bill data
  static GetM45List = "M45/GetM45List"; //Voucher SetUp - M45 : invoice Type / Expense 
  static GetM45Ent = "M45/GetM45Ent"; //Voucher SetUp  - M45 : invoice Type / Expense
  static PostM45Ent = "M45/PostM45Ent"; //Voucher SetUp  - M45 : invoice Type / Expense
  static GetT92Field = "T92/GetT92Ent"; // Voucher Number Field
  static PostT92Ent = "T92/PostT92Ent"; // Voucher Number Field
  static BatchLog = "Vou/Batch_Log"; // Transaction > sales 
  static GetPLData = "Vou/GetPLData"; // Transaction > sales
  static GetM14Ent = "M14/GetM14Ent"; //Taxclass Form Data
  static GetJWList = "Vou/GetJWList";
  static InvExpData = "Vou/InvExpData"; //Transaction > Expense Object Call
  static GetF02MsgType = "F02/GetF02MsgType"; // Setup > Company Setup/General Setup/ASMS
  static F02ReqQtyDetails = "F02/F02ReqQtyDetails"; // setup/company setup/useroptions/entry option/Required Quantity Details
  static GetF02PIDData = "F02/GetF02PIDData"; // setup > company setup
  static F02AlertMsgVouchers = "F02/F02AlertMsgVouchers"; //setup>company setup>general setup>ASMS>Alert SMS for Vouchers
  static GetT92PrefixSuffix = "T92/GetT92PrefixSuffix"; // setup>advance setup>sr.no.option>common prefix/suffix
  static PostT92PrefixSuffix = "T92/PostT92PrefixSuffix"; // common prefix/suffix
  static F02AutoUpld_EInvBillType = "F02/F02AutoUpld_EInvBillType"; // setup/company setup/GST/E-invoice required/EINVTYP
  static F02BarcodeType = "F02/F02BarcodeType"; // setup/company setup/barcode setup/barcode setup/barcode type
  static F02BillType = "F02/F02BillType"; // setup/company setup/point of sale/entry option/bill type
  static GetT95UsrFildAutoNo = "T95/GetT95UsrFildAutoNo"; // setup/company setup/user option/user option/User Field Auto Number
  static PostT95UsrFildAutoNo = "T95/PostT95UsrFildAutoNo"; // User Field Auto Number Post
  static F02MultiBarcodeSetup = "F02/F02MultiBarcodeSetup"; // setup/company setup/barcode setup/ barcode setup/multi barcode setup
  static F02BrokerReq = "F02/F02BrokerReq"; // setup/company setup/user option/entry option/broker required
  static GetWMKEnt = "WMK/GetWMKEnt"; // setup/company setup/report setup/water mark/water mark list
  static PostWMKEnt = "WMK/PostWMKEnt"; // Post Water Mark list
  static F02TDS_TCSDetails = "F02/F02TDS_TCSDetails"; // setup/company setup/TDS_TCS setup/TDS_TCS setup/TDS_TCS Detail/TDS_TCS Detail
  static PostF02TDS_TCSDetails = "F02/PostF02TDS_TCSDetails"; // TDS_TCS Details Post
  static PostM14Ent = "M14/PostM14Ent"; //Taxclass Post
  static GetM31JB = "M31/GetM31JB"; // Job Process List
  static GetMailingLatter = "LTR/GetMailingLatter";//Mailing Letter
  static PostMailingLatter = "LTR/PostMailingLatter";//Mailing Letter
  static GetAddressBook = "M02/GetAddressBook"// Utility / Address Book
  static PostAddressBook = "M02/PostAddressBook"// Utility /Address Book
  static GetF02DataFreez = "F02/F02DataFreez" // Utility / Data Freeze
  static PostF02DataFreez = "F02/PostF02DataFreez" // Utility / Data Freeze
  static GetReminder = "REMD/GetReminder" // Utility / Reminder 
  static PostReminder = "REMD/PostReminder" // Utility / Reminder
  static GetM00Ent = "Cmp/GetM00Ent"; //Company Add/Edit Ent
  static CreateCmp = "cmp/CreateCmp"; // Company Post
  static GetF02CashPartyDetails = "F02/GetF02CashPartyDetails"; // setup/voucher setup/sales invoice/entry options/cash party detail
  static GetF02GSTRules = "F02/GetF02GSTRules"; // setup/voucher setup/sales invoice/entry options/GST Rules to check for 
  static GetF02PendingVoucherList = "F02/GetF02PendingVoucherList"; // setup/voucher setup/sales invoice/other options/PLUSM471
  static GetF02ProductHistoryDetails = "F02/GetF02ProductHistoryDetails"; // setup/voucher setup/sales invoice/product entry option/applicable % of tax rate (65%)
  // static PostM31Ent = "M31/PostM31Ent"; // Job Process List
  static GetUserMasterEntry = "Msc/GetMscEnt"; // Navbar User Master
  static GetUserMasterEntryDG = "Msc/GetQAData"; // User Master Ent for dataGrid data
  static GetAdvanceEnt = "PDialog/GetAdvanceEnt"; //Print Dialogue Ent
  static PostAdvanceEnt = "PDialog/PostAdvanceEnt"; //Print Dialogue Post
  static PostUserMasterEntry = "Msc/PostMscEnt" //User Master Entry Test certificate
  static getUserSecurity = "User/GetUserSec"; //User security 
  static getUserDetail = "User/GetUser"; //User detail
  static GetChlnData = "Vou/GetChlnData"; //Pending Challan data
  static GetUserField = "Vou/GetUserField";
  static GetS92Src = "Vouformat/GetS92Src"; //Print_Voucher_Reports
  static GetQuoData = "Vou/GetQuoData"; // Pending Quotation Data
  static GetT02Quo = "Vou/GetT02Quo"; //Selected Quotation row data
  static GetOrdData = "Vou/GetOrdData"; //pending Order data
  static GetT02Ord = "Vou/GetT02Ord"; // Selected order row data
  static GetJOrderData = "Vou/GetJOrderData"; // Pending Order Data
  static GetVouPrtData = "Vou/GetVouPrtData"; // BillfromChallan Party A/C data
  static mstinfo = "common/mstinfo"; // sales_executive equitation data
  static PostUserSec = "User/PostUserSec"; // User Security
  static PostUserDet = "User/PostUser"; // User Detail
  static GetM35NA ="M35/GetM35NA"; // Narration
  static PostM35NA = "M35/PostM35NA";//Narration post
  static M45EnDisable = "M45/M45EnDisable" // Expense enable-disable
  static GetE01Ent = "E01/GetE01Ent"; // E-Invoice
  static PostE01Ent = "E01/PostE01Ent"; // E-Invoice - Json
  static GetG04Ent = "G04/GetG04Ent"; // E-way Bill
  static PostG04Ent = "G04/PostG04Ent"; // POST E-way Bill
  //SMS - WhtsUp - Email Btn
  static GetWhatsApp = "WAP/GetWhatsApp";
  static GetEMAIL = "EML/GetEMAIL";
  static GetSMS = "SMS/GetSMS";
  static PostEMAIL ="EML/PostEMAIL";
  static PostWhatsApp ="WAP/PostWhatsApp";
  static PostSMS ="SMS/PostSMS";
  //report Designer
  static GetS92Data = "VouFormat/GetS92Data"; // designer data and Saved Design List 
  static GetC50Ent = "VouFormat/GetC50Ent"; // add api  
  static PostC50Ent = "VouFormat/PostC50Ent"; // post Api

  static DataGridOptions = {

  }
  static Notify = (type: "success" | "error" | "info" | "warning", title: String, msg: String, loading: Boolean = false, autoClose = 6000,id:string="") => {
    switch (type) {
      case "error":
        notifications.show({
          id:id??null,
          title: title,
          message: msg,
          withBorder: true,
          withCloseButton: loading?false: true,
          color: 'red',
          radius: 'lg',
          icon: <IconCircleXFilled />,
          autoClose:  autoClose,
          loading: loading,
          
        })
        break;
      case "success":
        notifications.show({
          id:id??null,
          title: title,
          message: msg,
          withBorder: true,
          withCloseButton: loading?false: true,
          color: 'green',
          radius: 'lg',
          icon: <IconThumbUpFilled />,
          autoClose:  autoClose,
          loading: loading
        })
        break;
      case "warning":
        notifications.show({
          id:id??null,
          title: title,
          message: msg,
          withBorder: true,
          withCloseButton: loading?false: true,
          color: 'yellow',
          radius: 'lg',
          icon: <IconAlertCircleFilled />,
          autoClose: autoClose,
          loading: loading
        })
        break;
      case "info":
        notifications.show({
          id:id??null,
          title: title,
          message: msg,
          withBorder: true,
          withCloseButton: loading?false: true,
          color: 'blue',
          radius: 'lg',
          icon: <IconInfoCircleFilled />,
          autoClose: autoClose,
          loading: loading
        })
        break;
      default:
        break;
    }

  }
  static Notifyupdate = (type: "success" | "error" | "info" | "warning", title: String, msg: String, loading: Boolean = false, autoClose= 6000,id:string="") => {
    switch (type) {
      case "error":
        notifications.update({
          id:id??null,
          title: title,
          message: msg,
          withBorder: true,
          withCloseButton: loading?false: true,
          color: 'red',
          radius: 'lg',
          icon: <IconCircleXFilled />,
          autoClose:  autoClose,
          loading: loading
        })
        break;
      case "success":
        notifications.update({
          id:id??null,
          title: title,
          message: msg,
          withBorder: true,
          withCloseButton: loading?false: true,
          color: 'green',
          radius: 'lg',
          icon: <IconThumbUpFilled />,
          autoClose:  autoClose,
          loading: loading
        })
        break;
      case "warning":
        notifications.update({
          id:id??null,
          title: title,
          message: msg,
          withBorder: true,
          withCloseButton: loading?false: true,
          color: 'yellow',
          radius: 'lg',
          icon: <IconAlertCircleFilled />,
          autoClose: autoClose,
          loading: loading
        })
        break;
      case "info":
        notifications.update({
          id:id??null,
          title: title,
          message: msg,
          withBorder: true,
          withCloseButton: loading?false: true,
          color: 'blue',
          radius: 'lg',
          icon: <IconInfoCircleFilled />,
          autoClose:  autoClose,
          loading: loading
        })
        break;
      default:
        break;
    }

  }
  static RenderLoader = (type: "dots" | "skeleton", loop: Number = 5, style: Boolean = false) => {
    switch (type) {
      case "dots":
        return <Loader variant="dots" />;
        break;
      case "skeleton":
        var r = [];
        for (let i = 0; i < (loop ? loop : 5); i++) {
          if (style == true) {
            r.push(<Grid>
              <Grid.Col span={3}>
                <Skeleton height={15} mt={6} radius="xl" />
              </Grid.Col>
              <Grid.Col span={9}>
                <Skeleton height={15} mt={6} radius="xl" />
              </Grid.Col>
            </Grid>);
          } else {
            r.push(<Skeleton height={15} mt={6} radius="xl" />);
          }
        }
        return <>{r}</>;
        break;
      default:
        return <Loader variant="dots" />;
        break;
    }
  }
  static Alert = (type: "success" | "error" | "info" | "warning", index, custom : Boolean = false, p0 : String = "AB") => {
    switch (type) {
      case "info":
        // console.log("info call");
        return (
          // <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 1000, backgroundColor: "rgba(0,0,0,0.5)", height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Overlay  blur={10} center pos={"absolute"} zIndex={"999999"}><Alert icon={<IconAlertCircle size="1rem" />} title="Info!" withCloseButton
              onClose={() => {
                Store.dispatch(ModalDelete(index))
              }}
            >
            {custom==false ? "Audited Voucher Can't be Modified or Delete!" : p0 =="E" ? "This account is created by external module.You cannot modify it" : "Account cannot be deleted.Because it is created by external module."}  
            </Alert></Overlay>
          // </div>
        );
        break;

      default:
        break;
    }

  }
  static Confirm = (color:String="blue",title: String = "Are you Sure to Delete?", msg: String="It will be Deleted Forever and Cannot Be Recovered!!!",onConfirm:Function = ()=>{},onCancel:Function = ()=>{},cancelText:String="Cancel",confirmText:String="Confirm") =>{
    Store.dispatch(ModalFunction({
      // MTitle: <Text fw={700}>{"Delete Report"}</Text>,
      MAction: true,
      MSize: "auto",
      // position:'right',
      Overlay:{ opacity: 0.5, blur: 4 },
      fullScreen:false,
      Styles:{body:{padding:0}},
      MBody: (i) =><><Alert icon={<IconAlertCircle size="1rem" />}  title={title} color={color} variant="light">
      {msg}<br/>
      <div style={{justifyContent:'space-between',marginTop:16}}>
      <Button  variant="outline" style={{flex:1, }} color="blue" size="xs" onClick={()=>{
                onCancel()
               Store.dispatch(ModalDelete(i))
               
              }}>{cancelText}</Button>
              <Button variant="filled" style={{flex:1,float:'right'}} size="xs" color={color} onClick={()=>{
                onConfirm();
                Store.dispatch(ModalDelete(i))
              }}>{confirmText}</Button>
              </div>
    </Alert>
    
    </>,//<NewReportViewer obj={obj} index={i} />,
      MClose: false,
      MCentered:true,
      onclose: () => { },
      onClickCloseButton:()=>{
          // Store.dispatch(GetInvTypeDropDown(null))
      }
    }))
  }
  static slugify = (str) =>{
    const specialCharMap = {
      '+': '_plus_',
      '%': '_percentage_',
      '&': '_and_',
      '@': '_at_',
      '#': '_hash_',
      '*': '_star_',
      '!': '_exclamation_',
      '$': '_dollar_',
      '^': '_caret_',
      '(': '_left_paren_',
      ')': '_right_paren_',
      '-': '_dash_',
      '=': '_equals_',
      '{': '_left_brace_',
      '}': '_right_brace_',
      '[': '_left_bracket_',
      ']': '_right_bracket_',
      ':': '_colon_',
      ';': '_semicolon_',
      '"': '_quote_',
      "'": '_single_quote_',
      '<': '_less_than_',
      '>': '_greater_than_',
      ',': '_comma_',
      '.': '_dot_',
      '?': '_question_',
      '/': '_slash_',
      '\\': '_backslash_',
      '|': '_pipe_',
      '~': '_tilde_',
      '`': '_backtick_'
    };
    let inputString = String(str).trim();
    let replacedString = inputString.replace(/[+%&@#*!$^\-={}[\]:;"'<>,.?/\\|~`]/g, match => specialCharMap[match]);
    let noSpaces = replacedString.replace(/\s+/g, '_');
    let result = noSpaces.replace(/[^a-zA-Z0-9_]/g, '').replace(/(_[a-z_]+_)$/, '').toLowerCase();
  
    return result;
  }

  static keyArr = ['backspace', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  static getdataparam() {
    return JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cWhere: "",
    });
  }
  static formNextInput = (e,formRef) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const focusableElements = formRef?.current?.querySelectorAll('input, select, button,[role="combobox"]');
      const focusable = Array.from(focusableElements).filter(el => !el.disabled && el.tabIndex >= 0 && this.isVisible(el));
      const index = focusable?.indexOf(e.target) + 1;
      const nextIndex = index >= focusable?.length ? 0 : index;
      focusable[nextIndex]?.focus();
    }
  };
  static isVisible = (element) => {
    return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
  };

}
