import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";

// get all Product List
function slugify(str) {
  const specialCharMap = {
    '+': '_plus_',
    '%': '_percentage_',
    '&': '_and_',
    '@': '_at_',
    '#': '_hash_',
    '*': '_star_',
    '!': '_exclamation_',
    '$': '_dollar_',
    '^': '_caret_',
    '(': '_left_paren_',
    ')': '_right_paren_',
    '-': '_dash_',
    '=': '_equals_',
    '{': '_left_brace_',
    '}': '_right_brace_',
    '[': '_left_bracket_',
    ']': '_right_bracket_',
    ':': '_colon_',
    ';': '_semicolon_',
    '"': '_quote_',
    "'": '_single_quote_',
    '<': '_less_than_',
    '>': '_greater_than_',
    ',': '_comma_',
    '.': '_dot_',
    '?': '_question_',
    '/': '_slash_',
    '\\': '_backslash_',
    '|': '_pipe_',
    '~': '_tilde_',
    '`': '_backtick_'
  };
  let inputString = String(str).trim();
  let replacedString = inputString.replace(/[+%&@#*!$^\-={}[\]:;"'<>,.?/\\|~`]/g, match => specialCharMap[match]);
  let noSpaces = replacedString.replace(/\s+/g, '_');
  let result = noSpaces.replace(/[^a-zA-Z0-9_]/g, '').replace(/(_[a-z_]+_)$/, '').toLowerCase();

  return result;
}
const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    // Get the value of the key to group by
    let groupKey = currentValue[key];
    if(groupKey){
      groupKey = slugify(groupKey)
    }
    // If the group doesn't exist yet, create it
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    
    // Push the current value into the group
    result[groupKey].push(currentValue);
    
    return result;
  }, {}); // Initial value is an empty object
};
const SysType = (e)=>{
  switch (e) {
    case "C":
      return "System.String"
      break;
    case "N":
      return "System.Int16"
      break;
    case "D":
      return "System.Date"
      break;
    default:
      return "System.String"
      break;
  }
}
export const GetReportDesignerData = createAsyncThunk(
  "ReportData/GetReportData",
  async (obj, thunkAPI) => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cCode: obj?.cCode ?? "SALE_",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetS92Data + "?pa=" + param
      );
      if(response.data?.data){
        let main ={final:"",P_C50:"",P_C50DT:""}
        if(response.data?.data?.oS92){
        let arr = groupBy(response.data?.data?.oS92,"S92F24");
        console.log("arry1",arr);
        
        let final = [];
        let common = [];
        Object.keys(arr).map((dataset,i)=>{
          let nameSet = new Set();
          let j = 0;
          console.log(dataset,arr[dataset]);
          final.push({ 
              Name:dataset,
              Fields:arr[dataset].map((e,i)=>{ 
                let originalName = slugify(e.S92F03);
                let uniqueName = originalName;
                let counter = 1;
                while (nameSet.has(uniqueName)) {
                  
                  common.push({dataset:dataset,S92F03:e.S92F03,S92F10:e.S92F10})
                  uniqueName = `${originalName}_${counter}`;
                  counter++;
                }
          
                nameSet.add(uniqueName);
                return { 
                  DataField: e?.S92F10?.trim()?.toLowerCase(), Name:slugify(e.S92F03), TypeName:SysType(e.S92F05)
                }
              }),
              Query:{
                DataSourceName: "DataSource1",
              },
            })
            
          })
          
          console.log("nameset",common);
          main = {...main,final:final}
        }
        main = {...main, P_C50:response.data?.data?.P_C50,P_C50DT:response?.data?.data?.P_C50DT?.jData}
          return [
            main,
            obj?.id,
            response.data?.data?.oS92 // Akash Sir
          ];
      }
      else{
        return { error:"No Data Available Or Some Error Occured" };
      }
    } catch (error) {
      return { error };
    }
  }
);
export const SaveReportDesignerData = createAsyncThunk(
  "ReportData/SaveReportData",
  async (obj, thunkAPI) => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction:obj?.cAction??"A",
        cOPara:obj?.cOPara ?? "",
        cCode: obj?.cCode ?? "",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetC50Ent + "?pa=" + param
      );
      if(response.data?.data){
          return [
            response.data?.data,
            obj?.id,
          ];
      }
      else{
        return { error:"No Data Available Or Some Error Occured" };
      }
    } catch (error) {
      return { error };
    }
  }
);

const ReportDesignerSlice = createSlice({
  name: "ReportList",
  initialState: {
    Data: {},
    Original:{}, // Akash Sir
    rowCount: 0,
    isRefetching: false,
    isLoading: true,
    hasError: false,
    ErrorMsg: {},
    Obj:{},
    ObjisLoading: true,
    ObjhasError: false,
    ObjErrorMsg: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetReportDesignerData.pending, (state, action) => {
        state.hasError = false;
        state.isLoading = true;
        
      })
      .addCase(GetReportDesignerData.fulfilled, (state, action) => {
        // console.log("fulfilled", action.payload);
        if (!action.payload?.error) {
          
          state.Data = {
            ...state.Data,
            [action.payload[1]]: action.payload[0],
          };
          state.Original = {
            ...state.Original,
            [action.payload[1]]: action.payload[2],
          } // Akash Sir
          state.isLoading = false;
          state.hasError = false;
          state.ErrorMsg = null;
          } else {
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }

        // state.OneProductData = {};
      })
      .addCase(GetReportDesignerData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(SaveReportDesignerData.pending, (state, action) => {
        state.ObjhasError = false;
        state.ObjisLoading = true;
        
      })
      .addCase(SaveReportDesignerData.fulfilled, (state, action) => {
        // console.log("fulfilled", action.payload);
        if (!action.payload?.error) {
          state.Obj = action.payload;
          state.ObjisLoading = false;
          state.ObjhasError = false;
          state.ObjErrorMsg = null;
          } else {
          state.ObjisLoading = false;
          state.ObjhasError = true;
          state.ObjErrorMsg = action.payload?.error;
        }
      })
      .addCase(SaveReportDesignerData.rejected, (state, action) => {
        state.ObjhasError = true;
        state.ObjisLoading = false;
      })
      
  },
});
export const { getGroupEdited } = ReportDesignerSlice.actions;
export default ReportDesignerSlice.reducer;