import { GetTMFormat } from "../../utils/slices/AccountListSlice";
import Store from "../../utils/store";

export function G1RET(data) {
    console.log("data", data);
    const OBJ = JSON?.parse(data);
    const obj = OBJ?.BtnPnlObj

    console.log("OBJ", OBJ);


    if (OBJ?.p0 == "B2B") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM0000B2B" }));
    }
    else if (OBJ?.p0 == "B2BA") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM000B2BA" }));

    }
    else if (OBJ?.p0 == "B2CL") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM000B2CL" }));

    }
    else if (OBJ?.p0 == "B2CLA") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM00B2CLA" }));

    }

    else if (OBJ?.p0 == "B2CS") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM000B2CS" }));

    }
    else if (OBJ?.p0 == "B2CSA") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM00B2CSA" }));

    }
    else if (OBJ?.p0 == "CDNR") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM000CDNR" }));

    }
    else if (OBJ?.p0 == "CDNRA") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM00CDNRA" }));

    }
    else if (OBJ?.p0 == "CDNUR") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM00CDNUR" }));

    }
    else if (OBJ?.p0 == "CDNURA") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM0CDNURA" }));

    }
    else if (OBJ?.p0 == "EXP") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM0000EXP" }));

    }
    else if (OBJ?.p0 == "EXPA") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM000EXPA" }));

    }
    else if (OBJ?.p0 == "AT") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM00000AT " }));

    } else if (OBJ?.p0 == "ATADJ") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM00ATADJ " }));

    } else if (OBJ?.p0 == "EXEMP") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM00EXEMP" }));

    }
    else if (OBJ?.p0 == "HSN") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM0000HSN" }));

    }
    else if (OBJ?.p0 == "DOCS") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR1RETURN", p2: "TFORM000DOCS" }));

    }

}