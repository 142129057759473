import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import GlobalClass from '../GlobalClass';
import gensetting from '../gensetting';
import axios from 'axios';

export const GetEWayBill = createAsyncThunk(
    "EWayBill/GetEWayBill",
    async (obj) => {
        console.log("TSalesSlices/GetPLData", obj);
        try {
            let param = JSON.stringify({
                "CorpID": gensetting.getcorpid(),
                "cUser": gensetting.getcuser(),
                "cPass": gensetting.getcpass(),
                "CmpNo": gensetting.getcmpno(),
                "cAction": "A",
                "cCode": obj.id,
                "cOPara": ""
            });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetG04Ent + "?pa=" + param);
            return response?.data?.data;
        } catch (error) {
            console.error(error);
            return { error };
        }
    }
)

const EWayBillSlice = createSlice({
    name: "EWayBill",
    initialState: {
        EWayBillData: [],
        ButtonLoading: {
            generate: false,
            upload: false,
            generateQr: false,
            getEWB: false,
            updateVeh: false,
            cancel: false,
        },
        isLoading: false,
        hasError: false,
        ErrorMsg: {},
    },
    reducers: {
        GetButtonLoading: (state, action) => {
            state.ButtonLoading = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetEWayBill.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetEWayBill.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    state.EWayBillData = action.payload;
                    state.isLoading = false;
                    state.hasError = false;
                } else {
                    state.isLoading = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error;
                }
            })
            .addCase(GetEWayBill.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })
    }
});

export const { GetButtonLoading } = EWayBillSlice.actions

export default EWayBillSlice.reducer