import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetJobProcessList } from '../../utils/slices/JobProcessListSlice';
import { Button, Grid, Group, Text } from '@mantine/core';
import PLTextBox from '../../PlusComponent/PLTextBox';
import GlobalClass from '../../utils/GlobalClass';
import Store from '../../utils/store';
import gensetting from '../../utils/gensetting';
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice';
import { ModalDelete } from '../../utils/slices/ModalSlice';
import { GetAccountList } from '../../utils/slices/AccountListSlice';

const JobProcessListForm = (props) => {
  console.log("JobProcessListForm=>", props);

  const formRef = useRef(null)
  const [FieldObj, setFieldObj] = useState({
    "FIELD01": "",
    "FIELD02": "",
    "FIELD02_01": "",
    "FIELD02_02": "",
    "FIELD02_03": "",
    "FIELD03": "",
    "FIELD04": "",
    "FIELD05": "",
    "FIELD10": "J",
    "FLDUNQ": 0,
    "FLDAED": "A",
    "FLDBRC": ""
  })

  const { obj } = props;

  const dispatch = useDispatch();

  const JobProcessListData = useSelector((state) => state?.JobProcessListSlice?.JobProcessListData)
  const isLoading = useSelector((state) => state?.JobProcessListSlice?.isLoading)
  const SaveLoading = useSelector((state) => state?.DataSaveLoadingSlice?.SaveLoading);


  useEffect(() => {
    if (obj) {
      dispatch(GetJobProcessList({ ...obj, id: obj.p0 == "A" ? "" : obj?.id }))
    }
  }, [obj])

  useEffect(() => {
    if (JobProcessListData) {
      console.log("JobProcessListData", JobProcessListData);
      setFieldObj(JobProcessListData)
    }
  }, [JobProcessListData])

  useEffect(() => {
    const formElement = formRef.current;
    console.log("formRef", formRef);

    if (formElement) {
      formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
    }

    return () => {
      if (formElement) {
        formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
      }
    };
  }, [formRef])

  const JobProcessListSubmit = () => {
    dispatch(DataSaveLoading(true))
    console.log("FieldObj=>", FieldObj);
    let PostObj = { ...FieldObj };
    GlobalClass.Notify(
      "info",
      obj?.p0 == "A" ? "Adding" : "Editing",
      "Please wait while we process your data"
    );

    console.log("ObjSend==>", PostObj);

    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj.p0 == "E" ? "E" : "A",
      cCode: obj.p0 == "E" ? obj.id : "",
      // "cOPara": "",
      cSData: JSON.stringify(PostObj),
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj.p0 == "E" ? "E" : "A",
      cCode: obj.p0 == "E" ? obj.id : "",
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostM31Ent + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "SUCCESS") {
          let PaginationObj =
            Store.getState().DataSaveLoadingSlice.PaginationObj;
          Promise.resolve(dispatch(DataSaveLoading(false)))
            .then(() => {
              if (obj.p0 == "A") {
                dispatch(GetJobProcessList({ ...obj, id: obj.p0 == "A" ? "" : obj?.id }))
              } else {
                dispatch(ModalDelete(props.index))
              }
            })
            .then(() => dispatch(
              GetAccountList({
                "id": "01320123",
                "name": "Job Process List",
                "p0": "MT",
                "p1": "T_M31J",
                "p2": "",
                "p3": "",
                "type": "A",
                "pagination": PaginationObj["01320123"]

              }))).then(() =>
                GlobalClass.Notify(
                  "success",
                  obj.p0 == "A" ? "Added" : "Edited",
                  obj.p0 == "A" ? "Added successfully" : "Edited successfully"
                )
              );
        } else if (data.status == "FAIL") {
          Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
            GlobalClass.Notify("error", data?.status, data?.message)
          )
        }
      })
      .catch((e) =>
        Promise.resolve(
          GlobalClass.Notify("error", "Error", `${e.message}`)
        ).then(() => dispatch(DataSaveLoading(false)))
      );
  }

  return (<div ref={formRef}>
    {
      isLoading == true ? GlobalClass.RenderLoader("dots") : <>
        {
          SaveLoading == true ? GlobalClass.RenderLoader("dots") : <>
            <Grid>
              <Grid.Col span={12} style={{ display: "flex", flexDirection: "row", padding: 0 }}>
                <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>Jobwork Process</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <PLTextBox
                    autoFocus
                    value={FieldObj.FIELD02}
                    setEdit={(e) => {
                      setFieldObj({ ...FieldObj, FIELD02: e.target.value })
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group spacing={"xs"} position="center">
                  <Button
                    onKeyDown={(e)=>{
                      if(e?.key == "Enter"){
                        if(FieldObj?.FIELD02 != ""){
                          JobProcessListSubmit()
                        }else{
                          GlobalClass.Notify("warning", "Warning", "Empty Value Not Allowed")
                        }
                      }
                    }}
                    size="xs"
                    onClick={() => FieldObj.FIELD02 != "" ? JobProcessListSubmit() : GlobalClass.Notify("warning", "Warning", "Empty Value Not Allowed")}
                  >
                    Ok
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </>
        }
      </>
    }
  </div>)
}

export default JobProcessListForm