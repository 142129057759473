import { Button, Grid, Modal, Text } from '@mantine/core'
import { MantineReactTable, useMantineReactTable} from 'mantine-react-table'
import React, { useEffect, useState } from 'react'


import useWindowDimensions from "../../utils/UseWindowDimensions";
import { useSelector } from 'react-redux';
import { createColumnHelper } from '@tanstack/react-table';

const DeducteeTypeTable = (props) => {
let dra = {
  title: "",
  size: "md",
  body: "",
  open: false,
  onClickCloseBtn: () => {}
};
const columnHelper = createColumnHelper();
const TDSMasterData = useSelector(
    (state) => state.TDSMasterGroup.TDSMasterGroupData
  );

  const [rowSelection, setRowSelection] = useState({0 : "0"});
  
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [selectedRow,setSelectedRow] = useState();
  const [deducteeName,setDeducteeName]= useState();
  const { height, width } = useWindowDimensions();
  const [updatedColumn,setUpdatedColumn] = useState();

  // useEffect(()=>{
  //     const filteredData = TDSMasterData?.data?.data?.jData?.filter((a,i)=>{
  //      return a?.FIELD01 == selectedRow?.FIELD01
  //     });
  //     console.log("filteredData",filteredData)
  // },[selectedRow])

  useEffect(() => {
    let column = [];
    column.push(
      columnHelper.accessor("FIELD02", {
        header: "Deductee Name",
        enableColumnFilter: false,
        enableGrouping: true,
        enableSorting: false,
        size: "200",
      })
    );
    column.push(
      columnHelper.accessor("FIELD06", {
        header: "Surcharge Limit",
        enableColumnFilter: false,
        enableSorting: false,
        size: "125",
      })
    );
    column.push(
      columnHelper.accessor("FIELD07", {
        header: "Surc.",
        enableColumnFilter: false,
        enableSorting: false,
        size: "120",
      })
    );
    column.push(
      columnHelper.accessor("FIELD08", {
        header: "Cess",
        enableColumnFilter: false,
        enableSorting: false,
        size: "100",
      })
    );
    column.push(
      columnHelper.accessor("FIELD09", {
        header: "A. Cess",
        enableColumnFilter: false,
        enableSorting: false,
        size: "100",
      })
    );
    column.push(
      columnHelper.accessor("FIELD11", {
        header: "Applicable Date",
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) =>
          cell.getValue().slice(6, 8) +
          "/" +
          cell.getValue().slice(4, 6) +
          "/" +
          cell.getValue().slice(0, 4),
        size: "110",
      })
    );

    setUpdatedColumn(column);
  }, [deducteeName]);

  const showDetailsClicked = () =>{
    if(Object.keys(rowSelection).length > 0){
    const filteredData = TDSMasterData?.data?.data?.jData?.filter((a,i)=>{
      return a?.FIELD01 == selectedRow?.FIELD01
     });
    Promise.resolve(setDeducteeName(filteredData)).then(()=>{
      setDrawerObj({
        ...dra,
        body: (
          <>
          <MantineReactTable table={deducteeNameTable}/>
          <Grid>
          <Grid.Col
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          mt={5}
        >
          {/* <Button size="sm" compact>
            Show Details
          </Button> */}
        </Grid.Col>
        </Grid>
        {/* <DeducteeTypeTable showDetails={filteredData} updatedColumn1={updatedColumn1}/> */}
          </>
        ),
        open: true,
        size: "75%",
        position: "right",
      });
    })}
  }
    
    const deducteeTypeTable = useMantineReactTable({
        data : props?.showDetails ? props.showDetails : [],
        columns : props?.updatedColumn1 ? props?.updatedColumn1 : [],
        enableFilters: false,
        enableColumnActions: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableTopToolbar: false,
        enablePagination: false,
        enableBottomToolbar:false,
        onRowSelectionChange: setRowSelection,
        mantineTableContainerProps: {
          sx: { height: height * 0.40 },
        },
        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
          onClick: () => {
            setRowSelection((prev) => ({
              [row.id]: !prev[row.id],
            }))
            setSelectedRow(row?.original);
          },
          selected: rowSelection[row.id],
          sx: { cursor: "pointer" },
        }),
        initialState:{
          density:"0px"
        },
        state:{
          rowSelection 
        }
    });

    const deducteeNameTable = useMantineReactTable({
      data : deducteeName ? deducteeName : [],
      columns : updatedColumn ? updatedColumn : [],
      enableFilters: false,
      enableColumnActions: false,
      enableFullScreenToggle: false,
      enableDensityToggle: false,
      enableTopToolbar: false,
      enablePagination: false,
      enableBottomToolbar:false,
      mantineTableContainerProps: {
        sx: { height: height * 0.40 },
      },
      initialState:{
        density:"0px"
      },
    })
  return (
    <>
    <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={() => {}}
        fullScreen={false} //
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: (e) => {

            if (typeof DrawerObj?.onClickCloseButton == "function") {
              DrawerObj?.onClickCloseButton();
            }
            else {
              setDrawerObj(dra);
            }
          },
        }}
        title={DrawerObj.title}
        size={DrawerObj.size}
        // centered={.Centered}
        closeOnClickOutside={true}
        closeOnEscape={false}
        // overlayProps={m.Overlay}
        position={DrawerObj?.position ?? "bottom"}
        onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
        // scrollAreaComponent={ScrollArea.Autosize}
      >
        {DrawerObj.body}
      </Modal>
      <MantineReactTable table={deducteeTypeTable}/>
          <Grid>
          <Grid.Col
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          mt={5}
        >
          <Button size="sm" compact onClick={showDetailsClicked}>
            Show Details
          </Button>
        </Grid.Col>
        </Grid>
    </>
  )
}

export default DeducteeTypeTable
