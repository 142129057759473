import React, { useEffect, useState } from "react";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { Box, Button, Drawer, Grid, Group, Text } from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { GetReportDesignerData } from "../../utils/PrintSlice/ReportDesignerSlice";
import { useSelector } from "react-redux";
import Store from "../../utils/store";
import {
  GetAccountList,
  GetTMFormat,
} from "../../utils/slices/AccountListSlice";
import { createColumnHelper } from "@tanstack/react-table";
import GlobalClass from "../../utils/GlobalClass";

export default function FieldValueList({ obj, setOpened, setMsg }) {
  const [ccode, setccode] = useState(null);
  const [columns, setColumns] = useState([]);
  // const [grouping, setGrouping] = useState([]);
  const [groupedData, setGroupedData] = useState([]);

  const { TMFormatData: tmList } = useSelector((state) => state.AccountList);
  const { isLoading, hasError, ErrorMsg, Data, Original } = useSelector(
    (state) => state?.ReportDesigner
  );
  const columnHelper = createColumnHelper();

  useEffect(() => {
    if (tmList[obj?.BtnPnlObj?.page]) {
      setccode(tmList[obj?.BtnPnlObj?.page]?.oS60?.cS92ID);
    }
  }, [tmList]);
  console.log(tmList, "tmList-", Data);

  const tabData = Original[obj?.BtnPnlObj?.page];

  useEffect(() => {
    // console.log(obj?.BtnPnlObj?.page, "obj00");
    Store.dispatch(GetTMFormat({ ...obj?.BtnPnlObj }));
  }, [obj]);

  useEffect(() => {
    // console.log(ccode, "ccode8");
    if (ccode)
      Store.dispatch(
        GetReportDesignerData({ id: obj?.BtnPnlObj?.page, cCode: ccode })
      );
  }, [ccode]);

  useEffect(() => {
    const columns = [
      {
        header: "Fields", // Change header to something appropriate
        accessorKey: "S92F03",
        // enableGrouping: true,
        // Use Cell to render individual field objects
      },

      {
        header: "Format",
        accessorKey: "S92F24",
      },
    ];
    setColumns(columns);
  }, []);
  console.log(Original, "Original[obj?.BtnPnlObj?.page]=");
  // let  fieldArr = tabData?.final?.map(v=>v?.Fields)
  // console.log(fieldArr, "useMantineReactTable-");
  const table = useMantineReactTable({
    data: Original[obj?.BtnPnlObj?.page] ? Original[obj?.BtnPnlObj?.page] : [],
    columns: columns,
    // enableRowSelection: true, //enable some features
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableResizing: true,
    // onGroupingChange: setGrouping,
    enableExpanding: true,
    initialState: {
      expanded: true,
      // grouping,
      grouping: ["S92F24"],

      density: "xs",
    },
    enableRowVirtualization: true,
    mantineTableContainerProps: { sx: { maxHeight: "600px" } },
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableTableFooter: false,
    enableGrouping: true,
    mantineTableProps: { 
      highlightOnHover: false  
    },
    mantineTableBodyCellProps: ({ cell, row, table, column }) => ({
      onDoubleClick: () => {
        // console.log(row, "cell?.row?.original?.S92F24",  table);
        console.log(`<<${row?.original?.S92F03}>>`, "row?.original?.S92F03")
        Promise.resolve(setMsg(`<<${row?.original?.S92F03?.trim()}>>`)).then(() => {
          setOpened(false);
        });
      },
    }),
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onDoubleClick: () => {
        console.log(row, "staticRowIndex", cell, staticRowIndex);
        // Promise.resolve(setMsg(row?.original?.S92F24)).then(()=>{
        //   setOpened(false)
        // })
      },

      onClick: () => {
        // console.log(row?.original, "row?.original");
      },
      sx: { cursor: "pointer" },
      state: {
        grouping: ["Name"],
        density: "0px",
      },
      height: 50,
    }),
  });
  return (
    <>
      {isLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <>
          <Grid>
            <Grid.Col span={12}>
              <MantineReactTable table={table} />
            </Grid.Col>
          </Grid>
        </>
      )}
    </>
  );
}
