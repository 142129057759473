import Localize from "../../utils/rc";

export const Qty_CheckStk = (ExpenseObj) => {
    console.log("Qty_CheckStk ExpenseObj =>",ExpenseObj);
    //cF091 = "", nVal = 0, lNotSetFocus = "N" => PArameter
    let RC = new Localize().rc;
    // let oVou;
    let CSep =""; //"~C~";
    let nVal = ExpenseObj?.T02Obj?.FIELD33;
    let lNotSetFocus = "N";
    let cMsg = "OK";
    let nQtyVal = 0;
    let nStk = 0;
    let lFlag = true;
    nQtyVal = nVal;
    let T02D = { ...ExpenseObj?.T02Obj };
    let oM21D = { ...ExpenseObj?.selectedProductObj };

    // Dictionary<string, object> T02D = T02Dic; //Unknown
    // T02D["FIELD33"] = nVal;
    if (["PP", "OP", "HP", "PR"].includes(ExpenseObj?.OS11?.S11F01)) {
        T02D["FIELD06"] = nQtyVal * oM21D["FIELD07"];
        nQtyVal = T02D["FIELD06"];
    } else {
        T02D["FIELD06"] = T02D["FIELD33"] + T02D["FIELD81"];
    }

    //JobWork Stock Checking Pending

    //Check Stock For Batch Wise  Product
    if (oM21D?.FIELD51 == "Y" && ExpenseObj?.oYear?.OSC?._BATCHSTOC == "Y") { //M21.FIELD51 == "Y",OSC.GETL("_BATCHSTOC")
        //let drM21 = oVou.TM21.GetDataRow(T02D["FIELD03"]); // Unknown
        let drM21 = { ...oM21D }
        // let dr11 = SeekT11(drM21, T02D["FIELD11"], T02D["FIELD20"], T02D["FIELD05"] == "C" ? oVou.TT11.dtT11 : oVou.TT11.dtIT11); //Unknown // batch issue select object

        //T11.FIELD01 - T02.FIELD11 = Batch issue
        //T11.FIELD13 - T02.FIELD20 = Location
        //T11.FIELD08 - T02.FIELD03 = Product name

        let dr11 = {...ExpenseObj?.BatchIssue};

        //In case of Receipt Stock, Check for Qty is not Less Than Alredy sale Qty
        if (T02D["FIELD05"] == "C") {
            lFlag = false;
            if (dr11 == null) {
                nStk = T02D["OLDQTY"];
            } else {
                nStk = T02D["OLDQTY"] - (dr11["T11F04"] - dr11["T11F09"]);
            }

            if (nStk != 0 && nQtyVal < nStk) {
                // cMsg = RC("MSGSTK02", oVou.lCode);
                cMsg = RC("MSGSTK02");
                lNotSetFocus = "Y";
            }
        }

        //In case of Issue Stock, Check for Qty is not greater than available Qty
        if (T02D["FIELD05"] == "D") {
            lFlag = false;
            if (dr11 == null) {
                nStk = T02D["FIELD06"];
            } else {
                //m.oEnt.BClStock = FIELD04 - (FIELD09 - This.oentry.FIELD06 ) 
                nStk = dr11["FIELD04"] - (dr11["FIELD09"] - Math.abs(T02D["OLDQTY"]));
            }


            //Stock will contain negative value
            if (nQtyVal > nStk) {
                if (ExpenseObj?.OSV?._NGSTKWARN != "N") {
                    // cMsg = "Stock will contain negative value  of" + (nStk - Math.abs(nQtyVal));
                    // cMsg = RC("MSGSTK01", oVou.lCode) + " " + (nStk - Math.abs(nQtyVal));
                    cMsg = RC("MSGSTK01") + " " + (nStk - Math.abs(nQtyVal));
                    lNotSetFocus = ExpenseObj?.OSV?._NGSTKWARN == "A" ? "Y" : "N";
                }

                //Check For Negative Batch Allowd Or not
                // if not Then do not Move Focus from Qty
                if (ExpenseObj?.oYear?.OSC?._NAGBATCH == "N") { //&& (nQtyVal - nStk) != 0)
                    lNotSetFocus = "Y";
                }
            }

        }
    }

    if (lFlag && ["Y", "A"].includes(ExpenseObj?.OSV?._NGSTKWARN) && oM21D["FIELD52"] == "Y") {
        nStk = CalcStk({ ...ExpenseObj, M21D: oM21D }) - (nQtyVal - T02D["FIELD06"]);
        if (T02D["FIELD05"] == "D" && nQtyVal > (nStk + T02D["OLDQTY"])) {

            // cMsg = "Stock will contain negative value  of" + nStk;
            // cMsg = RC("MSGSTK01", oVou.lCode) + " " + nStk;
            cMsg = RC("MSGSTK01") + " " + nStk;

            if (ExpenseObj?.OSV?._NGSTKWARN == "A") {
                lNotSetFocus = "Y";
            }
        }
    }
    // return cMsg + CSep + lNotSetFocus;
    return cMsg

}

const CalcStk = (ExpenseObj) => {
    let nQty = 0;
    //nQty = MApp.pc.ToDouble(oVou.oM21B["M21CLA"]);
    nQty = ExpenseObj?.M21D["FIELD98"];
    // for (int i = 0; i < oVou.dtT02.Rows.Count; i++)
    ExpenseObj?.T02Data?.forEach((e) => {
        // if (e["FIELD03"] == oVou.TT02.T02Dic["FIELD03"]) {
        if (e["FIELD03"] == ExpenseObj?.M21D["FIELD03"]) {
            nQty = nQty - e["FIELD06"];
        }
    });
    return nQty;
}