import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetVoucherSetup,
  GetVoucherSetupEnt,
} from "../../utils/slices/VoucherSetupSlice";
import { Button, Grid, Group, Skeleton, Text } from "@mantine/core";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import AccountListSlice, {
  GetAccountList,
} from "../../utils/slices/AccountListSlice";
import gensetting from "../../utils/gensetting";
import { notifications } from "@mantine/notifications";
import GlobalClass from "../../utils/GlobalClass";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";

const EntryTypeForm = (props) => {
  console.log("EntryTypeForm props", props);

  const okButtonRef = useRef(null)

  const [TypeCopData, setTypeCopData] = useState([])
  const [ACTypeCopData, setACTypeCopData] = useState([])

  const dispatch = useDispatch();

  const VoucherSetupEntData = useSelector(
    (state) => state?.VoucherSetup?.VoucherSetupEntData
  );

  const { M01D, COP1, COP40, M01DDT, M45 } = useSelector(
    (state) => state?.VoucherSetup
  );

  const isLoading = useSelector((state) => state?.VoucherSetup?.isLoadingEnt);

  const [isLoadingState, setIsLoadingState] = useState(true);

  const [FormType, setFormType] = useState({
    EntryTypeForm: [
      "SPSS0001",
      "SPPPX001",
      "SPHCX001",
      "SPHDX001",
      "SPBP0012",
      "SPBR0012",
      "SPCP0012",
      "SPCR0012",
      "SPJJ0032",
      "SPJZ0032"
    ],
    ExtraDetailForm: [
      "SPSS0002",
      "SPPP0002",
      "SPHC0002",
      "SPHD0002",
      "SPBP0011",
      "SPBR0011",
      "SPCP0011",
      "SPCR0011",
      "SPSH0006",
      "SPJJ0033",
      "SPJZ0033"
    ],
  });

  const [showForm, setShowForm] = useState(null);

  const [PropsData, setPropsData] = useState(props?.obj?.VoucherFormType);

  const [EntryTypeObj, setEntryTypeObj] = useState({
    FIELD01: "",
    FIELD02: "",
    FIELD03: "",
    FIELD04: "",
    FIELD05: "",
    FIELD06: "",
    FIELD07: 0.0,
    FIELD08: "",
    FIELD09: "",
    FIELD10: "",
    FIELD11: "",
    FIELD12: 0.0,
    FIELD13: "",
    FIELD14: "",
    FIELD15: "",
    FIELD16: "",
    FIELD17: 0.0,
    FIELD18: 0.0,
    FIELD19: 0.0,
    FIELD20: "",
    FIELD21: "",
    FIELD22: "",
    FIELD23: "",
    FIELD24: 0.0,
    FIELD25: "",
    FIELD26: "",
    FIELD27: "",
    FIELD28: "",
    FIELD29: 0.0,
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD34: "",
    FIELD40: "",
    FIELD51: false,
    FIELD52: "",
    FIELD53: "F",
    FIELD54: "",
    FIELD55: "",
    FIELD61: "",
    FIELD62: "",
    FIELD63: "",
    FIELD65: "",
    FIELD71: "",
    FIELD72: "",
    FIELD73: "",
    FIELD74: "",
    FIELD75: "",
    FIELD76: "",
    FIELD99: "",
    M45UID: "",
    FLDUNQ: 0,
    FLDAED: "",
    FLDBRC: null,
    M01F02: null,
    M45F09COP: "Enable",
  });

  const [ExtraDetailObj, setExtraDetailObj] = useState({
    FIELD01: "",
    FIELD02: "",
    FIELD03: "",
    FIELD04: "",
    FIELD05: "",
    FIELD06: "",
    FIELD07: 0.0,
    FIELD08: "",
    FIELD09: "",
    FIELD10: "",
    FIELD11: "",
    FIELD12: 0.0,
    FIELD13: "",
    FIELD14: "",
    FIELD15: "",
    FIELD16: "",
    FIELD17: 0.0,
    FIELD18: 0.0,
    FIELD19: 0.0,
    FIELD20: "",
    FIELD21: "",
    FIELD22: "",
    FIELD23: "",
    FIELD24: 0.0,
    FIELD25: "",
    FIELD26: "",
    FIELD27: "",
    FIELD28: "",
    FIELD29: 0.0,
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD34: "",
    FIELD40: "",
    FIELD51: false,
    FIELD52: "",
    FIELD53: "",
    FIELD54: "",
    FIELD55: "",
    FIELD61: "",
    FIELD62: "",
    FIELD63: "",
    FIELD65: "",
    FIELD71: "",
    FIELD72: "",
    FIELD73: "",
    FIELD74: "",
    FIELD75: "",
    FIELD76: "",
    FIELD99: "",
    M45UID: "",
    FLDUNQ: 0,
    FLDAED: "",
    FLDBRC: "",
    M01F02: null,
    TXTAXF02: "Exempted",
    M45F53COP: "Itemwise",
    M45F09COP: "Enable",
    M45F75COP: "No",
    M45F71COP: "All",
    M45F10COP: "No",
  });

  const [ActionPara, setActionPara] = useState({
    action: null,
    para: null,
  });

  const VoucherSetupData = useSelector(
    (state) => state.VoucherSetup.VoucherSetupData
  );
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  console.log("SaveLoading==>", SaveLoading);

  console.log("VoucherSetupData Form Submit Data===>", VoucherSetupData);

  useEffect(() => {
    if (ExtraDetailObj?.FIELD05 == "B" || ExtraDetailObj?.FIELD05 == "I" || ExtraDetailObj?.FIELD05 == "J" || ExtraDetailObj?.FIELD05 == "D") {
      return okButtonRef.current?.focus(); // Focus on the "Ok" button
    }
  }, [ExtraDetailObj?.FIELD05]);

  useEffect(() => {
    if (showForm == "EntryType") {
      setEntryTypeObj(VoucherSetupEntData?.M45);
    } else {
      // Promise.resolve(setExtraDetailObj(VoucherSetupEntData?.M45)).then(()=>TypeTwoDropdown(VoucherSetupEntData?.M45?.FIELD03)).then(()=>ACTypeDropdown(VoucherSetupEntData?.M45?.FIELD06))

      setExtraDetailObj(VoucherSetupEntData?.M45)
    }
  }, [VoucherSetupEntData?.M45]);

  useEffect(() => {
      if (ExtraDetailObj?.FIELD03 != "") {
        TypeTwoDropdown(ExtraDetailObj?.FIELD03)
      }
  }, [ExtraDetailObj?.FIELD03])

  useEffect(() => {
      if (ExtraDetailObj?.FIELD06 != "") {
        ACTypeDropdown(ExtraDetailObj?.FIELD06)
      }
  }, [showForm,ExtraDetailObj?.FIELD06])

  // useEffect(()=>{
  //   if(TypeCopData){
  //     setExtraDetailObj({...ExtraDetailObj,FIELD06:})
  //   }
  // },[TypeCopData])

  // useEffect(() => {
  //   if (props?.obj?.p0 == "E" && props?.obj?.id != "") {
  //     setEntryTypeObj(props?.obj?.DataGridRow);
  //   } else {
  //     setExtraDetailObj(props?.obj?.DataGridRow);
  //   }
  // }, [props]);

  useEffect(() => {
    if (PropsData != undefined) {
      Promise.resolve(pageDataRender(PropsData)).then(() => {
        if (FormType.EntryTypeForm.includes(PropsData)) {
          // dispatch(GetAccountList({
          //   "id": 'P_M01',
          //   "name": "Account Master",
          //   "type": "A",
          //   "p0": "M01",
          //   "p1": "P_M01",
          //   "p2": "",
          //   "p3": ""
          // }))
          setShowForm("EntryType");
          setIsLoadingState(false);
        } else if (FormType.ExtraDetailForm.includes(PropsData)) {
          // dispatch(GetAccountList({
          //   "id": "00510001",
          //   "name": "Account List",
          //   "p0": "MT",
          //   "p1": "T_M01",
          //   "p2": "",
          //   "p3": "",
          //   "type": "A"
          // }))
          setShowForm("ExtraDetail");
          setIsLoadingState(false);
        } else {
          setShowForm(null);
          setIsLoadingState(false);
        }
      });
    } else {
      setShowForm(null);
      setIsLoadingState(false);
    }
  }, [PropsData]);
  console.log("showForm===>", showForm);

  const pageDataRender = (d) => {
    switch (d) {
      case "SPSS0001": //Sales invoice "TS"
        dispatch(
          GetVoucherSetupEnt({
            action: "T",
            para: "TS",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); //Entry Type
        setActionPara({ action: "T", para: "TS" });
        break;
      case "SPSS0002": //Sales invoice "TS"
        dispatch(
          GetVoucherSetupEnt({
            action: "E",
            para: "TS",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); // Extra Detail
        setActionPara({ action: "E", para: "TS" });

        break;
      case "SPPPX001": //Purchase Invoice – ‘TP’
        dispatch(
          GetVoucherSetupEnt({
            action: "T",
            para: "TP",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); //Entry Type
        setActionPara({ action: "T", para: "TP" });

        break;
      case "SPPP0002": //Purchase Invoice – ‘TP’
        dispatch(
          GetVoucherSetupEnt({
            action: "E",
            para: "TP",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); // Extra Detail
        setActionPara({ action: "E", para: "TP" });

        break;
      case "SPJJ0032": //Jobwork Bill(Out) – ‘JJ’ // Entry Type
        dispatch(GetVoucherSetupEnt({
          action: "T",
          para: "JJ",
          code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
        }));
        setActionPara({ action: "T", para: "JJ" });
        break;
      case "SPJJ0033": //Jobwork Bill(Out) – ‘JJ’ // Extra Detail
        dispatch(GetVoucherSetupEnt({
          action: "E",
          para: "JJ",
          code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
        }));
        setActionPara({ action: "E", para: "JJ" });
        break;
      case "SPJZ0032": //Jobwork Bill(In) – ‘JZ’ // Entry Type
        dispatch(GetVoucherSetupEnt({ action: "T", para: "JZ" }));
        setActionPara({ action: "T", para: "JZ" });
        break;
      case "SPJZ0033": //Jobwork Bill(In) – ‘JZ’ // Extra Detail
        dispatch(GetVoucherSetupEnt({
          action: "E",
          para: "JZ",
          code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
        }));
        setActionPara({ action: "E", para: "JZ" });
        break;
      // case "SPSS0001":   //Jobwork Bill(in) – ‘JZ’
      //     dispatch(GetVoucherSetupEnt({ action: "T", para: "JZ" }))
      //     break;
      // case "SPSS0001":   //Receipt Note – ‘VM’
      //     dispatch(GetVoucherSetupEnt({ action: "T", para: "VM" }))
      //     break;
      // case "SPSS0001":    //Issue Note – ‘VN’
      //     dispatch(GetVoucherSetupEnt({ action: "T", para: "VN" }))
      //     break;
      // case "SPSS0001":    //Rent Bill – ‘RB’
      //     dispatch(GetVoucherSetupEnt({ action: "T", para: "RB" }))
      //     break;
      case "SPHCX001": //Credit note – ‘HC’ //  Credit note into Expense Detail not found Credit note(stock) found
        dispatch(
          GetVoucherSetupEnt({
            action: "T",
            para: "HC",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); //Entry Type
        setActionPara({ action: "T", para: "HC" });

        break;
      case "SPHC0002": //Credit note – ‘HC’ //  Credit note into Expense Detail not found Credit note(stock) found
        dispatch(
          GetVoucherSetupEnt({
            action: "E",
            para: "HC",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); // Extra Detail
        setActionPara({ action: "E", para: "HC" });

        break;
      case "SPHDX001": //Debit note – ‘HD’ //  Debit note into Expense Detail not found Debit note(stock) found
        dispatch(
          GetVoucherSetupEnt({
            action: "T",
            para: "HD",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); //Entry Type
        setActionPara({ action: "T", para: "HD" });

        break;
      case "SPHD0002": //Debit note – ‘HD’ //  Debit note into Expense Detail not found Debit note(stock) found
        dispatch(
          GetVoucherSetupEnt({
            action: "E",
            para: "HD",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); // Extra Detail
        setActionPara({ action: "E", para: "HD" });

        break;
      case "SPBP0012": //Bank Payment – ‘BP’
        dispatch(
          GetVoucherSetupEnt({
            action: "T",
            para: "BP",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); //Entry Type
        setActionPara({ action: "T", para: "BP" });

        break;
      case "SPBP0011": //Bank Payment – ‘BP’
        dispatch(
          GetVoucherSetupEnt({
            action: "E",
            para: "BP",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); // Extra Detail
        setActionPara({ action: "E", para: "BP" });

        break;
      case "SPBR0012": //Bank Receipt – ‘BR’
        dispatch(
          GetVoucherSetupEnt({
            action: "T",
            para: "BR",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); //Entry Type
        setActionPara({ action: "T", para: "BR" });

        break;
      case "SPBR0011": //Bank Receipt – ‘BR’
        dispatch(
          GetVoucherSetupEnt({
            action: "E",
            para: "BR",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); // Extra Detail
        setActionPara({ action: "E", para: "BR" });

        break;
      case "SPCP0012": //Cash Payemnt -‘CP’
        dispatch(
          GetVoucherSetupEnt({
            action: "T",
            para: "CP",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); //Entry Type
        setActionPara({ action: "T", para: "CP" });

        break;
      case "SPCP0011": //Cash Payemnt -‘CP’
        dispatch(
          GetVoucherSetupEnt({
            action: "E",
            para: "CP",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); // Extra Detail
        setActionPara({ action: "E", para: "CP" });

        break;
      case "SPCR0012": //Cash Receipt – ‘CR’
        dispatch(
          GetVoucherSetupEnt({
            action: "T",
            para: "CR",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); //Entry Type
        setActionPara({ action: "T", para: "CR" });

        break;
      case "SPCR0011": //Cash Receipt – ‘CR’
        dispatch(
          GetVoucherSetupEnt({
            action: "E",
            para: "CR",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); // Extra Detail
        setActionPara({ action: "E", para: "CR" });

        break;
      // case "SPSS0001": //Share invoice – ‘SH’ // Found in menu but EntryType not found
      //     dispatch(GetVoucherSetupEnt({ action: "T", para: "SH" })) //Entry Type
      //     break;
      case "SPSH0006": //Share invoice – ‘SH’
        dispatch(
          GetVoucherSetupEnt({
            action: "E",
            para: "SH",
            code: props?.obj?.p0 == "A" ? "" : props?.obj?.M45UID,
          })
        ); // Extra Detail
        setActionPara({ action: "E", para: "SH" });

        break;
      // case "SPSS0001": //Sub jobwork (In) – ‘SJ’  // not found in menu
      //     dispatch(GetVoucherSetupEnt({ action: "T", para: "SJ" }))
      //     break;
      default:
        dispatch(GetVoucherSetupEnt());
        break;
    }
  };

  console.log("VoucherSetupEntData===>", VoucherSetupEntData);

  function RenderLoading(loop) {
    var r = [];
    for (let i = 0; i < loop; i++) {
      r.push(<Skeleton height={15} mt={6} radius="xl" />);
    }

    return r;
  }

  const TypeTwoDropdown = (val) => {
    console.log("TypeTwoDropdown=>", val);
    switch (val) {
      case "3": //GST
        setTypeCopData(VoucherSetupEntData?.COP62)
        // setExtraDetailObj({ ...ExtraDetailObj, FIELD05: "", FIELD06: "" })
        break;
      case "4": //IGST
        setTypeCopData(VoucherSetupEntData?.COP63)
        // setExtraDetailObj({ ...ExtraDetailObj, FIELD05: "", FIELD06: "" })
        break;
      case "V": //VAT
        setTypeCopData(VoucherSetupEntData?.COP61)
        // setExtraDetailObj({ ...ExtraDetailObj, FIELD05: "", FIELD06: "" })
        break;
      case "C": //CST
        setTypeCopData(VoucherSetupEntData?.COP61)
        // setExtraDetailObj({ ...ExtraDetailObj, FIELD05: "", FIELD06: "" })
        break;
      case "D": //CST (Other)
        setTypeCopData(VoucherSetupEntData?.COP61)
        // setExtraDetailObj({ ...ExtraDetailObj, FIELD05: "", FIELD06: "" })
        break;
      case "E": //Expense
        setTypeCopData(VoucherSetupEntData?.COP61)
        // setExtraDetailObj({ ...ExtraDetailObj, FIELD05: "", FIELD06: "" })
        break;
      case "T": //TDS
        setTypeCopData(VoucherSetupEntData?.COP61)
        // setExtraDetailObj({ ...ExtraDetailObj, FIELD05: "", FIELD06: "" })
        break;
      case "S": //TCS
        setTypeCopData(VoucherSetupEntData?.COP61)
        // setExtraDetailObj({ ...ExtraDetailObj, FIELD05: "", FIELD06: "" })
        break;

      default:
        setTypeCopData(VoucherSetupEntData?.COP61)
        // setExtraDetailObj({ ...ExtraDetailObj, FIELD05: "", FIELD06: "" })
        break;
    }
  }

  const ACTypeDropdown = (val) => {
    console.log("ACTypeDropdown=>", val);
    switch (val) {
      case "B"://SGST
        setACTypeCopData(VoucherSetupEntData?.COP64)
        break;
      case "D"://CGST
        setACTypeCopData(VoucherSetupEntData?.COP65)
        break;
      case "J"://Cess
        setACTypeCopData(VoucherSetupEntData?.COP67)
        break;
      case "I"://IGST
        setACTypeCopData(VoucherSetupEntData?.COP66)
        break;
      default:
        setACTypeCopData(VoucherSetupEntData?.COP41)
        break;
    }
  }

  console.log("ACTypeCopData =>", ACTypeCopData);

  const EntryTypeFormSubmit = () => {
    dispatch(DataSaveLoading(true));
    GlobalClass.Notify(
      "info",
      props?.obj?.p0 == "A" ? "Adding" : "Editing",
      "Please wait while we process your data"
    );
    console.log("EntryTypeFormSubmit===>", EntryTypeObj);
    let PostObj = [...VoucherSetupData.M45DT, EntryTypeObj];

    console.log("PostObj===>", PostObj);

    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: ActionPara.action,
      cCode: "",
      cOPara: ActionPara.para,
      cSData: JSON.stringify(PostObj),
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: ActionPara.action,
      cCode: "",
      cOPara: ActionPara.para,
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostM45Ent + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "SUCCESS") {
          Promise.resolve(
            GlobalClass.Notify(
              "success",
              props?.obj?.p0 == "A" ? "Added" : "Edited",
              props?.obj?.p0 == "A"
                ? "Added successfully"
                : "Edited successfully"
            )
          )
            .then(() => dispatch(ModalDelete(props.index)))
            .then(() => dispatch(DataSaveLoading(false)));
        } else if (data.status == "FAIL") {
          Promise.resolve(
            GlobalClass.Notify("error", data?.status, data?.message)
          ).then(() => dispatch(DataSaveLoading(false)));
        }
      })
      .catch((e) =>
        Promise.resolve(
          GlobalClass.Notify("error", "Error", `${e.message}`)
        ).then(() => dispatch(DataSaveLoading(false)))
      );
  };

  const ExtraDetailFormSubmit = () => {
    dispatch(DataSaveLoading(true));
    GlobalClass.Notify(
      "info",
      props?.obj?.p0 == "A" ? "Adding" : "Editing",
      "Please wait while we process your data"
    );
    console.log("ExtraDetailFormSubmit===>", ExtraDetailObj);
    // ExtraDetailObj.FIELD07 = ExtraDetailObj.FIELD07.toFixed(3)
    let PostObj = [...VoucherSetupData?.M45DT, ExtraDetailObj];

    console.log("PostObj===>", PostObj);

    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: ActionPara.action,
      cCode: "",
      cOPara: ActionPara.para,
      cSData: JSON.stringify(PostObj),
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: ActionPara.action,
      cCode: "",
      cOPara: ActionPara.para,
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostM45Ent + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("ExtraDetailFormSubmit 1=>",data);

        if (data.status == "SUCCESS") {
        console.log("ExtraDetailFormSubmit 2=>",data);

          Promise.resolve(
            GlobalClass.Notify(
              "success",
              props?.obj?.p0 == "A" ? "Added" : "Edited",
              props?.obj?.p0 == "A"
                ? "Added successfully"
                : "Edited successfully"
            )
          ).then(() => dispatch(ModalDelete(props.index))).then(() => dispatch(DataSaveLoading(false)));
        } else if (data.status == "FAIL") {
        console.log("ExtraDetailFormSubmit 3=>",data);

          Promise.resolve(
            GlobalClass.Notify("error", data?.status, data?.message)
          ).then(() => dispatch(DataSaveLoading(false)));
        }
      })
      .catch((e) =>
        Promise.resolve(
          GlobalClass.Notify("error", "Error", `${e.message}`)
        ).then(() => dispatch(DataSaveLoading(false)))
      );
  };

  // console.log("ActionPara==>",ActionPara);

  return (
    <>
      {SaveLoading == true ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <>
          {isLoading == false && isLoadingState == false ? (
            <>
              {showForm == "EntryType" ? (
                <Grid gutter={4}>
                  <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>Name</Text>
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        value={EntryTypeObj?.FIELD02}
                        setEdit={(e) => {
                          // console.log(e);
                          setEntryTypeObj({
                            ...EntryTypeObj,
                            FIELD02: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                    <Grid.Col span={6} style={{ display: "flex", padding: 0 }}>
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Tax Type</Text>
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLComboBox
                          data={VoucherSetupEntData?.COP40}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          value={EntryTypeObj?.FIELD20}
                          setEdit={(e) => {
                            console.log("Tax Type FIELD20==>", e);
                            setEntryTypeObj({ ...EntryTypeObj, FIELD20: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={6} style={{ display: "flex", padding: 0 }}>
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Capital Goods</Text>
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLComboBox
                          value={EntryTypeObj?.FIELD10}
                          data={VoucherSetupEntData?.COP1}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          setEdit={(e) => {
                            // console.log(e);
                            setEntryTypeObj({ ...EntryTypeObj, FIELD10: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                    <Grid.Col span={6} style={{ display: "flex", padding: 0 }}>
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Reverse Charge</Text>
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLComboBox
                          disabled={
                            EntryTypeObj?.FIELD20 == "3"
                              ? false
                              : EntryTypeObj?.FIELD20 == "4"
                                ? false
                                : true
                          }
                          data={VoucherSetupEntData?.COP1}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          value={EntryTypeObj?.FIELD34}
                          setEdit={(e) => {
                            // console.log(e);
                            setEntryTypeObj({ ...EntryTypeObj, FIELD34: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={6} style={{ display: "flex", padding: 0 }}>
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>E-Commerce</Text>
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLComboBox
                          disabled={
                            EntryTypeObj?.FIELD20 == "R"
                              ? false
                              : EntryTypeObj?.FIELD20 == "Q"
                                ? false
                                : true
                          }
                          data={VoucherSetupEntData?.COP1}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          value={EntryTypeObj?.FIELD33}
                          setEdit={(e) => {
                            // console.log(e);
                            setEntryTypeObj({ ...EntryTypeObj, FIELD33: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>Sale A/C</Text>
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLDataGrid
                        // InputLenght={"1000px"}
                        position={"bottom"}
                        value={EntryTypeObj?.FIELD26}
                        // type={"EntryType_SaleAC"}
                        TmData={VoucherSetupEntData?.M01D}
                        data={VoucherSetupEntData?.M01DDT?.jData}
                        setEdit={(e) => {
                          // console.log(e);
                          setEntryTypeObj({
                            ...EntryTypeObj,
                            FIELD26: e.FIELD01,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>Form</Text>
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLComboBox
                        value={EntryTypeObj?.FIELD25}
                        data={VoucherSetupEntData?.COP1}
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        setEdit={(e) => {
                          // console.log(e);
                          setEntryTypeObj({ ...EntryTypeObj, FIELD25: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>Form Name</Text>
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        value={EntryTypeObj?.FIELD40}
                        setEdit={(e) => {
                          // console.log(e);
                          setEntryTypeObj({
                            ...EntryTypeObj,
                            FIELD40: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Group spacing={"xs"} position="center" display={"flex"}>
                      <Button
                        size="sm"
                        compact
                        onClick={() =>
                          EntryTypeObj?.FIELD02 != ""
                            ? EntryTypeFormSubmit()
                            : GlobalClass.Notify(
                              "warning",
                              "Warning",
                              "Blank Name is not Allow"
                            )
                        }
                      >
                        Ok
                      </Button>
                      {/* <Button size="sm" compact onClick={()=>dispatch(ModalDelete(props.index))}>
                        Cancel
                    </Button> */}
                    </Group>
                  </Grid.Col>
                </Grid>
              ) : showForm == "ExtraDetail" ? (
                <>
                  <Grid gutter={4}>
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        padding: 0,
                        alignItems: "center",
                      }}
                    >
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>Name</Text>
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLTextBox
                          value={ExtraDetailObj?.FIELD02}
                          setEdit={(e) => {
                            // console.log(e)
                            setExtraDetailObj({
                              ...ExtraDetailObj,
                              FIELD02: e.target.value,
                            });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>

                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        padding: 0,
                        alignItems: "center",
                      }}
                    >
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          padding: 0,
                          alignItems: "center",
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>Type</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLComboBox
                            value={ExtraDetailObj?.FIELD03}
                            data={VoucherSetupEntData?.COP46}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            setEdit={(e) => {
                              console.log("Type One=>", e);
                              // Promise.resolve(setExtraDetailObj({
                              //   ...ExtraDetailObj,
                              //   FIELD03: e,
                              // })).then(() => TypeTwoDropdown(e))
                              if (e == "3" || e == "4") {
                                setExtraDetailObj({ ...ExtraDetailObj, FIELD03: e, FIELD53: "I", FIELD14:"A" })
                              } else {
                                setExtraDetailObj({...ExtraDetailObj, FIELD03: e, FIELD53:"", FIELD14:""})
                              }
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          padding: 0,
                          alignItems: "center",
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>A/C Effect</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLComboBox
                            value={ExtraDetailObj?.FIELD61}
                            data={VoucherSetupEntData?.COP55}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            setEdit={(e) => {
                              // console.log(e);
                              setExtraDetailObj({
                                ...ExtraDetailObj,
                                FIELD61: e,
                              });
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </Grid.Col>

                    {ExtraDetailObj?.FIELD03 == "S" ? (
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          padding: 0,
                          alignItems: "center",
                        }}
                      >
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            padding: 0,
                            alignItems: "center",
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Duty Type</Text>
                          </Grid.Col>
                          <Grid.Col span={8}>
                            <PLComboBox
                              value={ExtraDetailObj?.FIELD65}
                              data={VoucherSetupEntData?.COP46}
                              dispexpr="DisplayMember"
                              valexpr="ValueMember"
                              setEdit={(e) => {
                                // console.log(e);
                                setExtraDetailObj({
                                  ...ExtraDetailObj,
                                  FIELD65: e,
                                });
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                      </Grid.Col>
                    ) : null}

                    <Grid.Col span={12}>
                      <hr />
                    </Grid.Col>
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        padding: 0,
                        alignItems: "center",
                      }}
                    >
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          padding: 0,
                          alignItems: "center",
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>Serial No.</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLNumberBox
                            id={"SerialNo"}
                            value={ExtraDetailObj?.FIELD12}
                            setEdit={(e) => {
                              // console.log(e);
                              setExtraDetailObj({
                                ...ExtraDetailObj,
                                FIELD12: e,
                              });
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          padding: 0,
                          alignItems: "center",
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>Add/Deduct</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLComboBox
                            disabled={ExtraDetailObj?.FIELD03 == "3" || ExtraDetailObj?.FIELD03 == "4" ? true : false}
                            value={ExtraDetailObj?.FIELD14}
                            data={VoucherSetupEntData?.COP54}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            setEdit={(e) => {
                              console.log("Add/Deduct",e);
                              setExtraDetailObj({
                                ...ExtraDetailObj,
                                FIELD14: e,
                              });
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </Grid.Col>

                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        padding: 0,
                        alignItems: "center",
                      }}
                    >
                      <Grid.Col
                        span={4}
                        style={{
                          display: "flex",
                          padding: 0,
                          alignItems: "center",
                        }}
                      >
                        <Grid.Col span={6}>
                          <Text style={{ fontSize: 12 }}>Calculation</Text>
                        </Grid.Col>
                        <Grid.Col span={6}>
                          <PLComboBox
                            disabled={ExtraDetailObj?.FIELD03 == "3" || ExtraDetailObj?.FIELD03 == "4" ? true : false}
                            value={ExtraDetailObj?.FIELD53}
                            data={VoucherSetupEntData?.COP48}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            setEdit={(e) => {
                              console.log("Calculation", e);
                              setExtraDetailObj({
                                ...ExtraDetailObj,
                                FIELD53: e,
                              });
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={4}
                        style={{
                          display: "flex",
                          padding: 0,
                          alignItems: "center",
                        }}
                      >
                        <Grid.Col span={6}>
                          <Text style={{ fontSize: 12 }}>Type</Text>
                        </Grid.Col>
                        <Grid.Col span={6}>
                          <PLComboBox
                            value={ExtraDetailObj?.FIELD06}
                            data={TypeCopData}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            setEdit={(e) => {
                              console.log("Type Two=>", e);
                              // Promise.resolve(setExtraDetailObj({
                              //   ...ExtraDetailObj,
                              //   FIELD06: e,
                              // })).then(() => ACTypeDropdown(e))

                              setExtraDetailObj({ ...ExtraDetailObj, FIELD06: e })
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={4}
                        style={{
                          display: "flex",
                          padding: 0,
                          alignItems: "center",
                        }}
                      >
                        <Grid.Col span={6}>
                          <Text style={{ fontSize: 12 }}>@ %</Text>
                        </Grid.Col>
                        <Grid.Col span={6}>
                          <PLNumberBox
                            disabled={ExtraDetailObj?.FIELD03 == "3" || ExtraDetailObj?.FIELD03 == "4" ? true : false}
                            value={ExtraDetailObj?.FIELD07}
                            setEdit={(e) => {
                              // console.log(e);
                              setExtraDetailObj({
                                ...ExtraDetailObj,
                                FIELD07: e,
                              });
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </Grid.Col>

                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        padding: 0,
                        alignItems: "center",
                      }}
                    >
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          padding: 0,
                          alignItems: "center",
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>A/C Type</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLComboBox
                            value={ExtraDetailObj?.FIELD05}
                            data={ACTypeCopData}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            setEdit={(e) => {
                              console.log("A/C Type", e);
                              setExtraDetailObj({
                                ...ExtraDetailObj,
                                FIELD05: e,
                              });
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          padding: 0,
                          alignItems: "center",
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>A/C Name</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLDataGrid
                            disabled={ExtraDetailObj?.FIELD05 == "B" || ExtraDetailObj?.FIELD05 == "I" || ExtraDetailObj?.FIELD05 == "J" || ExtraDetailObj?.FIELD05 == "D" ? true : false}
                            position={"top"}
                            width={300}
                            value={ExtraDetailObj?.FIELD04}
                            // type={"ExtraDetailACName"}
                            TmData={VoucherSetupEntData?.M01D}
                            data={VoucherSetupEntData?.M01DDT?.jData}
                            setEdit={(e) => {
                              // console.log(e);
                              setExtraDetailObj({
                                ...ExtraDetailObj,
                                FIELD04: e.FIELD01,
                              });
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </Grid.Col>

                    <Grid.Col span={12}>
                      <Group spacing={"xs"} position="center" display={"flex"}>
                        <Button
                          ref={okButtonRef}
                          size="sm"
                          onKeyDown={(e) => {
                            if (e.key == "Enter") {
                              console.log('Enter Press')
                            }
                          }}
                          compact
                          onClick={() =>
                            ExtraDetailObj?.FIELD02 == ""
                              ? GlobalClass.Notify(
                                "warning",
                                "Warning",
                                "Blank Name is not Allow"
                              )
                              : ExtraDetailObj?.FIELD12 == 0
                                ? Promise.resolve(
                                  GlobalClass.Notify(
                                    "warning",
                                    "Warning",
                                    "Sequance Value Should be Grater then Zero"
                                  )
                                ).then(() =>
                                  document.getElementById("SerialNo").focus()
                                )
                                : ExtraDetailFormSubmit()
                          }
                        >
                          Ok
                        </Button>
                      </Group>
                    </Grid.Col>
                  </Grid>
                </>
              ) : (
                <Text>Not Found Any Form Type</Text>
              )}
            </>
          ) : (
            GlobalClass.RenderLoader("dots")
          )}
        </>
      )}
    </>
  );
};

export default EntryTypeForm;
