import { ActionIcon, Button, Divider, Drawer, Flex, Grid, Modal, Paper, Spoiler, Text, Tooltip } from '@mantine/core'
import React, { useEffect, useRef, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLComboBox from '../../PlusComponent/PLComboBox'
import { BatchLogAddRemoveFun, TransactionApi } from '../../utils/TransactionSlices/Transaction'
import { useDispatch, useSelector } from 'react-redux'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import GlobalClass from '../../utils/GlobalClass'
import { IconEdit } from '@tabler/icons-react'
import { createColumnHelper } from '@tanstack/react-table'
import { GetAccountList, GetTMFormat } from '../../utils/slices/AccountListSlice'
import PLDateBox from '../../PlusComponent/PLDateBox'
import PRUserFieldEntry from './PRUserFieldEntry'
import PRUserField from './PRUserField'
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice'
import gensetting from '../../utils/gensetting'
import { ModalDelete } from '../../utils/slices/ModalSlice'
import Store from '../../utils/store'
import { modals } from '@mantine/modals'
import BatchReceipt from './BatchReceipt'

const ProductionT02Table = (props) => {
    const { T02DataSend, T41ObjSend, oYearSend, propsSend, OBJ, ProductionDataSend, processValSend, DrawerObjSend, setDrawerObjSend } = props
    console.log('props', props)

    const SaveLoading = useSelector(
        (state) => state?.DataSaveLoadingSlice?.SaveLoading
    );

    const [T02Data, setT02Data] = useState([]);
    // console.log('T02Data', T02Data)
    const [columns, setColumns] = useState([]);
    const [T02Obj, setT02Obj] = useState({
        CATNM: "",
        GRPNM: "",
        FIELD01: "",
        FIELD02: "",
        FIELD03: "",
        FIELD04: "",
        FIELD05: "",
        FIELD06: 0.0,
        FIELD07: 0.0,
        FIELD08: 0.0,
        FIELD09: 0.0,
        FIELD10: 0.0,
        FIELD11: "",
        FIELD12: "",
        FIELD56: "",
        FIELD13: "",
        FIELD14: "",
        FIELD15: "",
        FIELD16: "",
        FIELD17: "",
        FIELD18: "",
        FIELD19: "",
        FIELD20: "",
        FIELD21: 0.0,
        FIELD22: 0.0,
        FIELD23: 0.0,
        FIELD24: "",
        FIELD25: 0.0,
        FIELD26: "",
        FIELD27: "",
        FIELD28: false,
        FIELD29: 0.0,
        FIELD30: "",
        FIELD31: 0.0,
        FIELD32: 0.0,
        FIELD33: 0.0,
        FIELD41: "",
        FIELD50: 0.0,
        FIELD51: "",
        FIELD52: 0.0,
        FIELD71: "",
        FIELD81: 0.0,
        FIELD82: "",
        FIELD83: "",
        FIELD84: "",
        FIELD85: "",
        FIELD86: "",
        FIELD61: 0.0,
        FIELD62: 0.0,
        FIELD63: "",
        FIELD64: 0.0,
        FIELD65: "",
        FIELD66: 0.0,
        FIELD67: 0.0,
        FIELD72: "",
        FIELD68: "",
        FIELD69: "",
        FIELD53: "",
        FIELD54: 0.0,
        FIELD55: 0.0,
        FIELD091: 1.0,
        FIELD58: "",
        FIELD57: "",
        FIELD60: 0.0,
        T41PVT: "",
        FIELD87: 0.0,
        FIELD88: "",
        UGRO: 0.0,
        URATE: 0.0,
        UMUR: 0.0,
        UPE: 0.0,
        UAP: 0.0,
        URE: "",
        UISSUERA: 0.0,
        FLDUNQ: 0,
        FLDAED: "",
        FLDBRC: "",
        UUDIS: "",
        UUDISAAA: "",
        UUGROAAA: "",
        UUGR01: "",
        UUGRO: "",
        IA00000001: "",
        IA00000002: "",
        IA00000003: "",
        IA00000004: "",
        IA00000005: "",
        IA00000006: "",
        IA00000007: "",
        IA00000008: "",
        IA00000009: "",
        IA00000010: "",
        IA00000011: "",
        IA00000012: "",
        IA00000013: "",
        IA00000014: "",
        IA00000015: "",
        IA00000016: "",
        IA00000017: "",
        IA00000018: "",
        IA00000019: "",
        IA00000020: "",
        IA00000021: "",
        IA00000022: "",
        IA00000023: "",
        ID00000001: 0.0,
        ID00000002: 0.0,
        ID00000003: 0.0,
        ID00000004: 0.0,
        ID00000005: 0.0,
        ID00000006: 0.0,
        ID00000007: 0.0,
        ID00000008: 0.0,
        ID00000009: 0.0,
        ID00000010: 0.0,
        ID00000011: 0.0,
        ID00000012: 0.0,
        ID00000013: 0.0,
        ID00000014: 0.0,
        ID00000015: 0.0,
        ID00000016: 0.0,
        ID00000017: 0.0,
        ID00000018: 0.0,
        ID00000019: 0.0,
        ID00000020: 0.0,
        ID00000021: 0.0,
        ID00000022: 0.0,
        ID00000023: 0.0,
        IP00000001: 0.0,
        IP00000002: 0.0,
        IP00000003: 0.0,
        IP00000004: 0.0,
        IP00000005: 0.0,
        IP00000006: 0.0,
        IP00000007: 0.0,
        IP00000008: 0.0,
        IP00000009: 0.0,
        IP00000010: 0.0,
        IP00000011: 0.0,
        IP00000012: 0.0,
        IP00000013: 0.0,
        IP00000014: 0.0,
        IP00000015: 0.0,
        IP00000016: 0.0,
        IP00000017: 0.0,
        IP00000018: 0.0,
        IP00000019: 0.0,
        IP00000020: 0.0,
        IP00000021: 0.0,
        IP00000022: 0.0,
        IP00000023: 0.0,
        IT00000001: "",
        IT00000002: "",
        IT00000003: "",
        IT00000004: "",
        IT00000005: "",
        IT00000006: "",
        IT00000007: "",
        IT00000008: "",
        IT00000009: "",
        IT00000010: "",
        IT00000011: "",
        IT00000012: "",
        IT00000013: "",
        IT00000014: "",
        IT00000015: "",
        IT00000016: "",
        IT00000017: "",
        IT00000018: "",
        IT00000019: "",
        IT00000020: "",
        IT00000021: "",
        IT00000022: "",
        IT00000023: "",
        UDIS: 0.0,
        UUOS: "",
        UT76: "",
        USERFLD: "",
        USERFLDB: "2",
        SRDETAIL: "",
        EXPDUMMY: " ",
        BARCODE: "",
        OLDPRD: "",
        OLDQTY: 0.0,
        OQTY2: 0.0,
        OLDRATE: 0.0,
        LADDNEW: "Y",
        PRICELIST: " ",
        _BATSTOCK: " ",
        _LOCWISE: " ",
        LDUALSTK: " ",
        SRNOSTK: " ",
        MRPSTK: " ",
        RORI: " ",
        ORDDUMMY: " ",
        SRIDUMMY: " ",
        SRRDUMMY: " ",
        SRIDUMMYF: " ",
        SRRDUMMYF: " ",
        SRISSCD: "",
        OLDSRNO: "",
        SCNOCL: "",
        SCNOCL1: "",
        MANUDATE: "",
        EXPDATE: "",
        _EXPTOTALAMT: 0.0,
    });
    const [T41Obj, setT41Obj] = useState({})
    const [selectedProductObj, setSelectedProductObj] = useState({});
    const [GroupCatogaryProduct, setGroupCatogaryProduct] = useState({
        "Group": "",
        "Catogary": "",
        "Product": ""
    })
    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
    }
    const [DrawerObj, setDrawerObj] = useState(dra);
    const [tableEditingRow, setTableEditingRow] = useState(null)
    const [LocationObj, setLocationObj] = useState()
    const [BatchIssueObj, setBatchIssueObj] = useState()
    const [recieptIssueVal, setRecieptIssueVal] = useState()
    const [FIELD01Value, setFIELD01value] = useState("");
    const [T02FilteredRow, setT02FilteredRow] = useState([])
    const [processVal, setProcessVal] = useState(null)
    const [flag, setFlag] = useState(false)
    const [rowSelection, setRowSelection] = useState({})

    const {
        isLoading: AccountisLoading,
        AccountListData: accList,
        TMFormatData: tmList
    } = useSelector((state) => state.AccountList);

    const locationRef = useRef();

    useEffect(() => {
        //     console.log("AccountisLoading", AccountisLoading)
        //     console.log('accList', accList)
        //     console.log('tmList', tmList)
        //     console.log('T02DataSend', T02DataSend)
        //     console.log('T02Obj', T02Obj)
        //     console.log('tableEditingRow', tableEditingRow)
        //     console.log('T02Data', T02Data)
        console.log('T41ObjSend', T41ObjSend)
        console.log('T41Obj', T41Obj)
        //     console.log('selectedProductObj', selectedProductObj)
    }, [AccountisLoading, accList, tmList, T02DataSend, T02Obj, tableEditingRow, T02Data, T41ObjSend, T41Obj, selectedProductObj])

    const NextIndexFun = (index) => {
        const nextInd = index + 1;
        console.log("nextInd=>", nextInd)
        const nextInput = document.getElementById(`T02Ent_${nextInd}`)
        console.log("nextInput=>", nextInput)

        if (nextInput) {
            nextInput.focus()
        }
    }

    const seperate = (e) => {
        var paraArr = e.split("~C~");
        return paraArr;
    };

    useEffect(() => {
        if (T02DataSend) {
            setT02Obj(T02DataSend?.[0])
        }
    }, [T02DataSend])

    useEffect(() => {
        // if(AccountisLoading){
        console.log("AccountisLoading useEffect =>", AccountisLoading);
        console.log('accList', accList["P_M21"])
        // }
    }, [AccountisLoading, accList])

    const dispatch = useDispatch();

    useEffect(() => {
        Promise.resolve(dispatch(GetAccountList({
            "id": "P_M21",
            "name": "",
            "type": "A",
            "p0": "P_M21",
            "p1": "P_M21",
            "p2": "",
            "p3": ""
        }))).then(() => dispatch(GetTMFormat({
            "id": "P_M21",
            "name": "",
            "type": "A",
            "p0": "P_M21",
            "p1": "P_M21",
            "p2": "",
            "p3": ""
        })))
    }, []);

    useEffect(() => {
        if (ProductionDataSend?.T02) {
            setT02Data(ProductionDataSend?.T02)
        }
    }, [ProductionDataSend?.T02])

    useEffect(() => {
        if (T41ObjSend) {
            setT41Obj(T41ObjSend)
        }
    }, [T41ObjSend])

    // for T02 Row Add 
    useEffect(() => {
        if (ProductionDataSend?.P_M73DT && T41ObjSend?.FIELD04 !== "") {
            let FilteredArr = []
            ProductionDataSend?.P_M73DT?.filter((v) => v?.FIELD01 == T41ObjSend?.FIELD04).map((v) => {
                return FilteredArr.push(v)
            })
            // console.log("FilteredArr", FilteredArr)
            setT02FilteredRow(FilteredArr)
        }
    }, [ProductionDataSend?.P_M73DT, T41ObjSend?.FIELD04])

    let updatedData = [];
    useEffect(() => {
        if (T02FilteredRow && T02FilteredRow.length > 0 && T41ObjSend?.FIELD18 !== 0 && processValSend !== null && flag == false) {
            console.log("useEffect Run")
            // setT02Data([]);
            let T02 = { ...T02Obj }
            T02FilteredRow.map((r) => {

                T02.FIELD03 = r?.FIELD04;
                // T02.FIELD05 = r?.FIELD07 === "O" ? "D" : "C";
                T02.FIELD05 = r?.FIELD07 === "O" ? "C" : "D";
                T02.FIELD14 = r?.FIELD00;
                T02.FIELD19 = r?.FIELD08;

                accList["P_M21"]?.map((p) => {

                    if (r?.FIELD04 == p?.FIELD01) {

                        console.log("match data==>", p);
                        T02.FIELD07 = T02?.FIELD05 == "D" ? p?.FIELD21 : p?.FIELD22
                        let RateConv = T02?.FIELD05 == "D" ? p?.FIELD10 : p?.FIELD18
                        let nResultRate = T02?.FIELD07 / RateConv
                        // console.log('RateConv', RateConv)
                        // console.log('nResultRate', nResultRate)
                        T02.FIELD22 = r.FIELD27 == 0 ? p.FIELD28 : r.FIELD27
                        T02.FIELD06 = ((r.FIELD05 / processValSend?.FIELD04) * T41ObjSend?.FIELD18)
                        T02.FIELD08 = (T02?.FIELD06 * nResultRate)
                        T02.FIELD33 = ((r.FIELD05 / processValSend?.FIELD04) * T41ObjSend?.FIELD18)
                        T02.FIELD21 = ((r.FIELD26 / processValSend?.FIELD04) * T41ObjSend?.FIELD18)
                        // T02.F03NM = p?.FIELD02

                        updatedData.push({
                            ...T02,
                            F03NM: p?.FIELD02
                        })
                    }
                })
            });
            console.log("updatedData==>", updatedData);
            setFlag(false)
            setT02Data([...updatedData]);
        } else if (T02FilteredRow?.length === 0 && flag == false) {
            // Clear or reset T02Data when T02FilteredRow is empty
            setT02Data([ProductionDataSend?.T02]); // or any default value you need
        }
    }, [T02FilteredRow, T41ObjSend?.FIELD18, accList["P_M21"], tmList, processValSend]);

    // let FinalOne = T02Data?.map(v => accList["P_M21"]?.find(t => v.FIELD03 == t.FIELD01))
    // console.log('FinalOne', FinalOne)

    const columnHelper = createColumnHelper();

    const Summary = (obj, i, tag) => {
        var value = "";

        switch (obj?.S53F03) {
            case "Product Name":
                value = (
                    <Text py={2}>
                        {" "}
                        Total Entry :{" "}
                        {/* {selectedRow ? TotalRow + "/" + (selectedRow?.index + 1) : TotalRow} */}
                        {T02Data?.length}
                    </Text>
                );
                break;
            case "Qty":
                value = parseInt((ReceiptQtySum - IssueQtySum).toFixed(0));
                // T02Data.reduce((a,b) => a = a + b?.FIELD33, 0)
                break;
            case "F. Qty":
                value = T02Data?.reduce((a, b) => (a = a + b?.FIELD81), 0);
                break;
            case "Amount":
                value = recieptAmountSum - IssueAmountSum;
                // rowSum
                break;
            case "Qty-2":
                value = RecieptQty2Sum - IssueQty2Sum;
                // T02Data.reduce((a,b) => a = a + b?.FIELD21, 0)
                break;
            default:
                value = null;
                break;
        }
        return value;
    };

    const productDataFun = () => {
        if (GroupCatogaryProduct?.Group != "" && GroupCatogaryProduct?.Catogary == "") {
            console.log('if 1')
            return accList["P_M21"]?.filter((a) => a?.FIELD11 == GroupCatogaryProduct?.Group)
        } else if (GroupCatogaryProduct?.Catogary != "" && GroupCatogaryProduct?.Group == "") {
            console.log('if 2')
            return accList["P_M21"]?.filter((a) => a?.FIELD12 == GroupCatogaryProduct?.Catogary)
        } else if (GroupCatogaryProduct?.Group != "" && GroupCatogaryProduct?.Catogary != "") {
            console.log('if 3')
            return accList["P_M21"]?.filter((a) => a?.FIELD11 == GroupCatogaryProduct?.Group && a?.FIELD12 == GroupCatogaryProduct?.Catogary)
        } else {
            console.log('if 4')
            return accList["P_M21"]
        }
        // return data
    }

    const renderInputBox = (uID, v, c, cellid, index) => {
        // console.log('v=>', v)
        if (c?.S53F07 == 0) {
            return <Button
                id={`T02Ent_${index}`}
                variant="default"
                onFocus={() => {
                    document.activeElement.blur()
                    console.log("Test=>", `T02Ent_${index}`)
                    HiddenButtonField(uID, v, c, cellid, index)
                }}
                onClick={() => HiddenButtonField(uID, v, c, cellid, index)}
            // style={{ padding: "0px", margin: "0px" }}
            />
        } else {
            let setInput;
            switch (c?.S53F14) {
                case "PLPOPUP":
                    if (c.S53F02 == "E00FIELD03") { // Product Name
                        setInput = <PLDataGrid
                            id={`T02Ent_${index}`}
                            width={'500px'}
                            // autoFocus={true}
                            // cWhere={`M21.FIELD11 LIKE '%${GroupCatogaryProduct?.Group}%' AND M21.FIELD12 LIKE '%${GroupCatogaryProduct?.Catogary}%'`}
                            value={T02Obj?.[c?.S53F04]}
                            // p1={seperate(c.S53F15)[1]}
                            TmData={tmList["P_M21"]?.cSTID}
                            // data={productData}
                            // data={
                            //   GroupCatogaryProduct?.Group != "" ? accList["P_M21"]?.filter((a) => a?.FIELD11 == GroupCatogaryProduct?.Group) :
                            //   GroupCatogaryProduct?.Catogary != "" ? accList["P_M21"]?.filter((a) => a?.FIELD12 == GroupCatogaryProduct?.Catogary) :
                            //   GroupCatogaryProduct?.Group != "" && GroupCatogaryProduct?.Catogary != "" ? accList["P_M21"]?.filter((a) => a?.FIELD11 == GroupCatogaryProduct?.Group && a?.FIELD12 == GroupCatogaryProduct?.Catogary) :
                            //   accList["P_M21"]
                            // }
                            data={productDataFun()}
                            setEdit={(e) => {
                                console.log("Product Name PLDataGrid", e);
                                Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01, F03NM: e?.FIELD02 }))
                                    .then(() => setSelectedProductObj(e))
                                    .then(() => setFIELD01value(e.FIELD01))
                                    .then(() => {
                                        NextIndexFun(index)
                                    })
                            }}
                        />
                    } else if (c.S53F02 === "E00FIELD20") { // Location
                        setInput = (
                            <PLDataGrid
                                autoFocus={oYearSend?.OSC?._BATCHSTOC == "N" ? true : false}
                                ref={locationRef}
                                id={`T02Ent_${index}`}
                                width={"500px"}
                                value={{ value: T02Obj[c?.S53F04], label: T02Obj?.F20NM == "" ? "Select" : T02Obj?.F20NM }}
                                p1={seperate(c.S53F15)[1]}
                                setEdit={(e) => {
                                    // console.log('DataGrid object=>>', e)
                                    Promise.resolve(setT02Obj({
                                        ...T02Obj,
                                        [c?.S53F04]: e?.FIELD01,
                                        F20NM: e?.FIELD02
                                    }))
                                        .then(() => {
                                            setLocationObj(e)
                                        })
                                }}
                            />
                        );
                    } else {
                        setInput = (
                            <PLDataGrid
                                id={`T02Ent_${index}`}
                                width={"500px"}
                                value={T02Obj[c?.S53F04]}
                                p1={seperate(c.S53F15)[1]}
                                setEdit={(e) => {
                                    // console.log('DataGrid object=>>', e)
                                    Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 })).then(() => {
                                        if (ProductionDataSend?.dtUFT11 && selectedProductObj?.FIELD51 === "N") {
                                            NextIndexFun(index)
                                        } else {
                                            NextIndexFun(index + 1)
                                        }
                                    })
                                }}
                            />
                        );
                    }
                    break;
                case "COMBOBOX":
                    if (c.S53F02 == "E00FIELD05") { // Receipt/Issue
                        setInput = (
                            <PLComboBox
                                cmbid={`T02Ent_${index}`}
                                // autoFocus={true}
                                value={T02Obj[c?.S53F04]}
                                copno={seperate(c.S53F15)[1]}
                                dispexpr={"DisplayMember"}
                                valexpr={"ValueMember"}
                                setEdit={(e) => {
                                    console.log('reciept/issue value', e.value)
                                    Promise.resolve(setRecieptIssueVal(e))
                                        .then(() => setT02Obj({ ...T02Obj, [c?.S53F04]: e.value }))
                                        .then(() => {
                                            NextIndexFun(index)
                                        })
                                }}
                            />
                        );
                    }
                    else {
                        setInput = (
                            <PLComboBox
                                cmbid={`T02Ent_${index}`}
                                // autoFocus={true}
                                value={T02Obj[c?.S53F04]}
                                copno={seperate(c.S53F15)[1]}
                                dispexpr={"DisplayMember"}
                                valexpr={"ValueMember"}
                                setEdit={(e) => {
                                    Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e.value })).then(() => {
                                        NextIndexFun(index)
                                    })
                                }}
                            />
                        );
                    }
                    break;
                case "TEXTBOX":
                    if (c?.S53F02 == "E00FIELD41") { // Batch Receipt
                        setInput = (
                            <>
                                <PLTextBox
                                    autoFocus={true}
                                    // selectedProductObj?.FIELD51 === "N" ||
                                    // disabled={selectedProductObj?.FIELD51 === "N" || T02Obj?.FIELD05 == "D" ? true : false}
                                    id={`T02Ent_${index}`}
                                    value={T02Obj?.[c?.S53F04]}
                                    onKeyDown={(e) => {
                                        if (e.key == "Tab" || e.key == "Enter") {
                                            if (oYearSend?.OSC?._LOCATIONW == "N") {
                                                handleEditSave()
                                            } else {
                                                locationRef?.current?.handlePopoverToggle();
                                            }
                                        }
                                    }}
                                    setEdit={(e) => {
                                        setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
                                    }}
                                />
                            </>
                        );
                    } else {
                        setInput = (
                            <PLTextBox
                                id={`T02Ent_${index}`}
                                value={T02Obj[c?.S53F04]}
                                onKeyDown={(e) => {
                                    if (e.key == "Tab") {
                                        NextIndexFun(index)
                                    }
                                }}
                                setEdit={(e) => {
                                    setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
                                }}
                            />
                        );
                    }
                    break;
                case "NUMBOX":
                    if (c?.S53F02 == "E00FIELD21") { // Qty - 2
                        setInput = (
                            <PLNumberBox
                                disabled={selectedProductObj?.FIELD59 == "N" ? true : false}
                                id={`T02Ent_${index}`}
                                value={T02Obj[c?.S53F04]}
                                setEdit={(e) => {
                                    setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                                }}
                                onKeyDown={(e) => {
                                    if (e.key == "Tab") {
                                        NextIndexFun(index - 1)
                                    }
                                }}
                            />
                        );
                    } else if (c?.S53F02 == "E00FIELD33") { // Qty
                        setInput = (
                            <PLNumberBox
                                id={`T02Ent_${index}`}
                                value={T02Obj[c?.S53F04]}
                                precision={3}
                                decimalSeparator={true}
                                setEdit={(e) => {
                                    setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                                }}
                                onKeyDown={(e) => {
                                    if (e.key == "Tab") {
                                        if (selectedProductObj?.FIELD51 === "N" || T02Obj?.FIELD05 == "D" && oYearSend?.OSC?._BATCHSTOC == "Y") {
                                            NextIndexFun(index + 1)
                                        } else if (oYearSend?.OSC?._BATCHSTOC == "Y") {
                                            NextIndexFun(index - 1)
                                        }
                                    }
                                }}
                            />
                        );
                    } else if (c?.S53F02 == "E00FIELD22") { // conversion
                        setInput = (
                            <PLNumberBox
                                precision={3}
                                decimalSeparator={true}
                                id={`T02Ent_${index}`}
                                value={T02Obj[c?.S53F04]}
                                setEdit={(e) => {
                                    setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                                }}
                                onKeyDown={(e) => {
                                    if (e.key == "Tab") {
                                        NextIndexFun(index - 1)
                                    }
                                }}
                            />
                        );
                    } else {
                        setInput = (
                            <PLNumberBox
                                id={`T02Ent_${index}`}
                                value={T02Obj[c?.S53F04]}
                                setEdit={(e) => {
                                    setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                                }}
                                onKeyUp={(e) => {
                                    console.log("Enter key Press", e)
                                    if (c.S53F02 == "E00FIELD08") { // Amount
                                        if (e.key == "Enter") {
                                            if (tableEditingRow == null) {
                                                console.log("Add Product Call")
                                                if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                                                    GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                                                } else {
                                                    handleCreateRow()
                                                }
                                            } else {
                                                console.log("Edit Product Call")
                                                if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                                                    GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                                                } else {
                                                    handleEditSave()
                                                }
                                            }
                                        }
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key == "Tab") {
                                        if (selectedProductObj?.FIELD51 === "N" && c?.S53F02 == "E00FIELD41") {
                                            console.log('if call')
                                            NextIndexFun(index)
                                        } else {
                                            console.log('else call')
                                            NextIndexFun(index - 1)
                                        }
                                    }
                                }}
                            />
                        );
                    }
                    break;
                case "DATEPICKER":
                    setInput = <PLDateBox dispformat="YYYY/MM/DD" disabled />;
                    break;
                default:
                    setInput = null;
                    break;
            }
            return setInput;
        }
    };

    const HiddenButtonField = (uID, v, c, cellid, index) => {

        switch (c.S53F02) {
            case "E00FIELD65": //Purchase Detail
                console.log('Purchase Detail')
                setDrawerObj({
                    ...dra,
                    title: <Text fw={700}>{c.S53F03}</Text>,
                    body: <>
                        <PLTextBox
                            id={`T02Ent_${index}`}
                            value={T02Obj[c?.S53F04]}
                            onKeyDown={(e) => {
                                if (e.key == "Tab") {
                                    Promise.resolve(NextIndexFun(index - 1))
                                        .then(() => {
                                            setDrawerObj({ ...DrawerObj, open: false })
                                            // NextIndexFun(index)
                                        })
                                }
                            }}
                            setEdit={(e) => {
                                setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
                            }}
                        />
                    </>,
                    open: true,
                    size: "sm",
                    position: "right"
                })
                break;
            case "E00FIELD86": // Quantity Detail
                setDrawerObj({
                    ...dra,
                    title: <Text fw={700}>{c.S53F03}</Text>,
                    body: <>
                        <PLTextBox
                            id={`T02Ent_${index}`}
                            value={T02Obj[c?.S53F04]}
                            onKeyDown={(e) => {
                                if (e.key == "Tab") {
                                    Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                                        .then(() => {
                                            NextIndexFun(index)
                                        })
                                }
                            }}
                            setEdit={(e) => {
                                setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
                            }}
                        />
                    </>,
                    open: true,
                    size: "sm",
                    position: "right"
                })
                break;
            case "E00FIELD11": // Batch Issue
                if (T02Obj?.FIELD05 == "D") {
                    setDrawerObj({
                        ...dra,
                        title: <Text fw={700}>{c.S53F03}</Text>,
                        body: <>
                            <PLDataGrid
                                autoFocus={true}
                                // setBlur={()=>T02UF?.length > 0 ? TableUserFieldFun() : null}
                                id={`T02Ent_${index}`}
                                cWhere={`T11.FIELD08 LIKE '%${FIELD01Value ?? ""}%'`}
                                width={"500px"}
                                value={T02Obj?.[c?.S53F04]}
                                p1={seperate(c.S53F15)[1]}
                                // p1={'P_M21'}
                                setEdit={(e) => {
                                    console.log("Batch Issue Object=>", e);
                                    Promise.resolve(setBatchIssueObj(e))
                                        .then(() => setDrawerObj({ ...DrawerObj, open: false }))
                                        .then(() => { NextIndexFun(index) })
                                }}
                            />
                        </>,
                        open: true,
                        size: "sm",
                        position: "right"
                    })
                }
                break;
            case "E00USERFLD": // user Field
                console.log("userfield call");
                if (ProductionDataSend?.T02UF && ProductionDataSend?.T02UF.length > 0) {
                    setDrawerObj({
                        ...dra, title: <Text fw={700}>User Field</Text>, body: <>
                            <PRUserFieldEntry
                                T02UF={ProductionDataSend?.T02UF}
                                T02ObjSend={T02Obj}
                                setT02Objsend={(e) => {
                                    Promise.resolve(setT02Obj(e)).then(() => {
                                        if (selectedProductObj?.FIELD59 == "N") {
                                            NextIndexFun(index + 1)
                                        } else {
                                            NextIndexFun(index)
                                        }
                                    })
                                    // console.log("FormUserField=>",e);
                                }}
                                DrawerObjSend={DrawerObj}
                                setDrawerObjSend={(e) => {
                                    setDrawerObj(e)
                                }}
                            />
                        </>, open: true, size: "sm", position: "right"
                    })
                }
                break;
            case "E00USERFLDB": //Batch User Field
                if (selectedProductObj?.FIELD51 === "N") {
                    if (ProductionDataSend?.dtUFT11) {
                        setDrawerObj({
                            ...dra, title: <Text fw={700}>Batch User FIeld</Text>, body: <>
                                <PRUserField
                                    dtUFT11={ProductionDataSend?.dtUFT11}
                                    T02ObjSend={T02Obj}
                                    setT02Objsend={(e) => {
                                        Promise.resolve(setT02Obj(e)).then(() => {
                                            NextIndexFun(index)
                                        })
                                        // console.log("FormUserField=>",e);
                                    }}
                                    DrawerObjSend={DrawerObj}
                                    setDrawerObjSend={(e) => {
                                        setDrawerObj(e)
                                    }}
                                />
                            </>, open: true, size: "sm", position: "right"
                        })
                    }
                }
                break;
            default:
                break;
        }
    }

    // columns
    useEffect(() => {
        if (ProductionDataSend?.T02ENT) {
            let cols = [];
            cols.push(
                columnHelper.accessor('GRPNM', {
                    header: "Product Group",
                    id: "Product_Group",
                    size: 150,
                    Edit: ({ cell, row }) => {
                        let val = cell.getValue();
                        // console.log("Cell =>>", cell);
                        // return <><Text>Test</Text></>
                        // console.log("cell?.id==>", cell?.id);
                        return <PLDataGrid
                            autoFocus={true}
                            width={"500px"}
                            TmData={tmList["T_M32G"]?.cSTID}
                            data={accList["T_M32G"]}
                            value={T02Obj?.GRPNM}
                            setEdit={(e) => {
                                console.log("Product Group Obj=>", e);
                                setGroupCatogaryProduct({ ...GroupCatogaryProduct, Group: e?.FIELD01 })
                                setT02Obj({ ...T02Obj, GRPNM: e?.FIELD01 })
                            }}
                        />
                    },
                })
            )
            cols.push(
                columnHelper.accessor('CATNM', {
                    header: "Product Catogary",
                    id: "Product_Catogary",
                    size: 150,
                    Edit: ({ cell, row }) => {
                        let val = cell.getValue();
                        // console.log("Cell =>>", cell);
                        // return <><Text>Test</Text></>
                        // console.log("cell?.id==>", cell?.id);
                        return <PLDataGrid
                            width={"500px"}
                            TmData={tmList["T_M32C"]?.cSTID}
                            data={accList["T_M32C"]}
                            value={T02Obj?.CATNM}
                            setEdit={(e) => {
                                console.log("Product Category Obj=>", e);
                                setGroupCatogaryProduct({ ...GroupCatogaryProduct, Catogary: e?.FIELD01 })
                                setT02Obj({ ...T02Obj, CATNM: e?.FIELD01 })
                            }}
                        />
                    },
                })
            )
            ProductionDataSend?.T02ENT?.filter((v, i) => v?.S53F00 === "C").map((v, i) => {
                cols.push(
                    columnHelper.accessor(v.S53F04, {
                        enableEditing: v.S53F03 == "Product Name" ? false : true,
                        header: v.S53F03,
                        // v.S53F07 == 0 ? "" :
                        id: v.S53UID,
                        // size: v.S53F07 == 0 ? 0 : 150,
                        Edit: ({ cell, row }) => {
                            let val = cell.getValue();
                            return renderInputBox(v.S53UID, val, v, cell?.id, i);
                        },
                        Cell: ({ cell, row }) => {
                            // console.log("cell.getValue()", cell.getValue())
                            let val = cell.getValue();
                            // console.log("T02Data[i]=>",T02Data[row.id]);
                            if (v.S53F02 == "E00FIELD03") {
                                return T02Data[row.id]?.F03NM ?? T02Data[row.id]?.PrdNM
                                // return "Test"
                            } else if (v.S53F02 == "E00FIELD20") {
                                return T02Data[row.id]?.F20NM
                            }
                            return v.S53F07 == 0 ? "" : val
                        },
                        Footer: () => <div>{Summary(v, i, "c")}</div>,
                    })
                );
            });
            return setColumns(cols);
        }
    }, [T02Data, ProductionDataSend?.T02ENT, ProductionDataSend?.T02, T02Obj, selectedProductObj, accList, tmList, GroupCatogaryProduct]);

    // Amount Calculation
    useEffect(() => {
        if (T02Obj?.FIELD33 !== null && T02Obj?.FIELD07 !== null) {
            setT02Obj({ ...T02Obj, FIELD08: T02Obj?.FIELD33 * T02Obj?.FIELD07 });
        }
    }, [T02Obj?.FIELD33, T02Obj?.FIELD07]);

    // Quantity Calculation
    useEffect(() => {
        if (T02Obj?.FIELD21 !== null && T02Obj?.FIELD22 !== null) {
            setT02Obj({ ...T02Obj, FIELD33: T02Obj?.FIELD21 * T02Obj?.FIELD22 });
        }
    }, [T02Obj?.FIELD21, T02Obj?.FIELD22]);

    const handleCreateRow = () => {
        console.log('on enter call function')
        console.log('handleCreateUser T02obj=>>', T02Obj)
        console.log('handleCreateUser T02Data=>>', T02Data)

        dispatch(BatchLogAddRemoveFun({ T02Data: T02Data, Row: T02Obj, OBJ: OBJ }));

        setT02Data([...T02Data, T02Obj]);
        // exitCreatingMode();
        setT02Obj({
            CATNM: "",
            GRPNM: "",
            FIELD01: "",
            FIELD02: "",
            FIELD03: "",
            FIELD04: "",
            FIELD05: "",
            FIELD06: 0.0,
            FIELD07: 0.0,
            FIELD08: 0.0,
            FIELD09: 0.0,
            FIELD10: 0.0,
            FIELD11: "",
            FIELD12: "",
            FIELD56: "",
            FIELD13: "",
            FIELD14: "",
            FIELD15: "",
            FIELD16: "",
            FIELD17: "",
            FIELD18: "",
            FIELD19: "",
            FIELD20: "",
            FIELD21: 0.0,
            FIELD22: 0.0,
            FIELD23: 0.0,
            FIELD24: "",
            FIELD25: 0.0,
            FIELD26: "",
            FIELD27: "",
            FIELD28: false,
            FIELD29: 0.0,
            FIELD30: "",
            FIELD31: 0.0,
            FIELD32: 0.0,
            FIELD33: 0.0,
            FIELD41: "",
            FIELD50: 0.0,
            FIELD51: "",
            FIELD52: 0.0,
            FIELD71: "",
            FIELD81: 0.0,
            FIELD82: "",
            FIELD83: "",
            FIELD84: "",
            FIELD85: "",
            FIELD86: "",
            FIELD61: 0.0,
            FIELD62: 0.0,
            FIELD63: "",
            FIELD64: 0.0,
            FIELD65: "",
            FIELD66: 0.0,
            FIELD67: 0.0,
            FIELD72: "",
            FIELD68: "",
            FIELD69: "",
            FIELD53: "",
            FIELD54: 0.0,
            FIELD55: 0.0,
            FIELD091: 1.0,
            FIELD58: "",
            FIELD57: "",
            FIELD60: 0.0,
            T41PVT: "",
            FIELD87: 0.0,
            FIELD88: "",
            UGRO: 0.0,
            URATE: 0.0,
            UMUR: 0.0,
            UPE: 0.0,
            UAP: 0.0,
            URE: "",
            UISSUERA: 0.0,
            FLDUNQ: 0,
            FLDAED: "",
            FLDBRC: "",
            UUDIS: "",
            UUDISAAA: "",
            UUGROAAA: "",
            UUGR01: "",
            UUGRO: "",
            IA00000001: "",
            IA00000002: "",
            IA00000003: "",
            IA00000004: "",
            IA00000005: "",
            IA00000006: "",
            IA00000007: "",
            IA00000008: "",
            IA00000009: "",
            IA00000010: "",
            IA00000011: "",
            IA00000012: "",
            IA00000013: "",
            IA00000014: "",
            IA00000015: "",
            IA00000016: "",
            IA00000017: "",
            IA00000018: "",
            IA00000019: "",
            IA00000020: "",
            IA00000021: "",
            IA00000022: "",
            IA00000023: "",
            ID00000001: 0.0,
            ID00000002: 0.0,
            ID00000003: 0.0,
            ID00000004: 0.0,
            ID00000005: 0.0,
            ID00000006: 0.0,
            ID00000007: 0.0,
            ID00000008: 0.0,
            ID00000009: 0.0,
            ID00000010: 0.0,
            ID00000011: 0.0,
            ID00000012: 0.0,
            ID00000013: 0.0,
            ID00000014: 0.0,
            ID00000015: 0.0,
            ID00000016: 0.0,
            ID00000017: 0.0,
            ID00000018: 0.0,
            ID00000019: 0.0,
            ID00000020: 0.0,
            ID00000021: 0.0,
            ID00000022: 0.0,
            ID00000023: 0.0,
            IP00000001: 0.0,
            IP00000002: 0.0,
            IP00000003: 0.0,
            IP00000004: 0.0,
            IP00000005: 0.0,
            IP00000006: 0.0,
            IP00000007: 0.0,
            IP00000008: 0.0,
            IP00000009: 0.0,
            IP00000010: 0.0,
            IP00000011: 0.0,
            IP00000012: 0.0,
            IP00000013: 0.0,
            IP00000014: 0.0,
            IP00000015: 0.0,
            IP00000016: 0.0,
            IP00000017: 0.0,
            IP00000018: 0.0,
            IP00000019: 0.0,
            IP00000020: 0.0,
            IP00000021: 0.0,
            IP00000022: 0.0,
            IP00000023: 0.0,
            IT00000001: "",
            IT00000002: "",
            IT00000003: "",
            IT00000004: "",
            IT00000005: "",
            IT00000006: "",
            IT00000007: "",
            IT00000008: "",
            IT00000009: "",
            IT00000010: "",
            IT00000011: "",
            IT00000012: "",
            IT00000013: "",
            IT00000014: "",
            IT00000015: "",
            IT00000016: "",
            IT00000017: "",
            IT00000018: "",
            IT00000019: "",
            IT00000020: "",
            IT00000021: "",
            IT00000022: "",
            IT00000023: "",
            UDIS: 0.0,
            UUOS: "",
            UT76: "",
            USERFLD: "",
            USERFLDB: "2",
            SRDETAIL: "",
            EXPDUMMY: " ",
            BARCODE: "",
            OLDPRD: "",
            OLDQTY: 0.0,
            OQTY2: 0.0,
            OLDRATE: 0.0,
            LADDNEW: "Y",
            PRICELIST: " ",
            _BATSTOCK: " ",
            _LOCWISE: " ",
            LDUALSTK: " ",
            SRNOSTK: " ",
            MRPSTK: " ",
            RORI: " ",
            ORDDUMMY: " ",
            SRIDUMMY: " ",
            SRRDUMMY: " ",
            SRIDUMMYF: " ",
            SRRDUMMYF: " ",
            SRISSCD: "",
            OLDSRNO: "",
            SCNOCL: "",
            SCNOCL1: "",
            MANUDATE: "",
            EXPDATE: "",
            _EXPTOTALAMT: 0.0,
        });
        setSelectedProductObj({})
        setBatchIssueObj()
        // setRecieptIssueVal(null)
        setGroupCatogaryProduct({
            "Group": "",
            "Catogary": "",
            "Product": ""
        })
        table.setCreatingRow(null)
    };

    const handleEditSave = () => {
        console.log('on enter call function')
        console.log('T02Data in Edit Case', T02Data)
        let EditT02Data = [...T02Data];
        EditT02Data[tableEditingRow?.index] = T02Obj;

        dispatch(BatchLogAddRemoveFun({
            PageCode: "ININ",
            T02Data: T02Data,
            Row: T02Obj,
            OBJ: OBJ,
            T41Obj: T41ObjSend
        }));

        setT02Data(EditT02Data);
        setT02Obj(ProductionDataSend?.T02?.[0])
        // setT02Obj({
        //     CATNM: "",
        //     GRPNM: "",
        //     FIELD01: "",
        //     FIELD02: "",
        //     FIELD03: "",
        //     FIELD04: "",
        //     FIELD05: "",
        //     FIELD06: 0.0,
        //     FIELD07: 0.0,
        //     FIELD08: 0.0,
        //     FIELD09: 0.0,
        //     FIELD10: 0.0,
        //     FIELD11: "",
        //     FIELD12: "",
        //     FIELD56: "",
        //     FIELD13: "",
        //     FIELD14: "",
        //     FIELD15: "",
        //     FIELD16: "",
        //     FIELD17: "",
        //     FIELD18: "",
        //     FIELD19: "",
        //     FIELD20: "",
        //     FIELD21: 0.0,
        //     FIELD22: 0.0,
        //     FIELD23: 0.0,
        //     FIELD24: "",
        //     FIELD25: 0.0,
        //     FIELD26: "",
        //     FIELD27: "",
        //     FIELD28: false,
        //     FIELD29: 0.0,
        //     FIELD30: "",
        //     FIELD31: 0.0,
        //     FIELD32: 0.0,
        //     FIELD33: 0.0,
        //     FIELD41: "",
        //     FIELD50: 0.0,
        //     FIELD51: "",
        //     FIELD52: 0.0,
        //     FIELD71: "",
        //     FIELD81: 0.0,
        //     FIELD82: "",
        //     FIELD83: "",
        //     FIELD84: "",
        //     FIELD85: "",
        //     FIELD86: "",
        //     FIELD61: 0.0,
        //     FIELD62: 0.0,
        //     FIELD63: "",
        //     FIELD64: 0.0,
        //     FIELD65: "",
        //     FIELD66: 0.0,
        //     FIELD67: 0.0,
        //     FIELD72: "",
        //     FIELD68: "",
        //     FIELD69: "",
        //     FIELD53: "",
        //     FIELD54: 0.0,
        //     FIELD55: 0.0,
        //     FIELD091: 1.0,
        //     FIELD58: "",
        //     FIELD57: "",
        //     FIELD60: 0.0,
        //     T41PVT: "",
        //     FIELD87: 0.0,
        //     FIELD88: "",
        //     UGRO: 0.0,
        //     URATE: 0.0,
        //     UMUR: 0.0,
        //     UPE: 0.0,
        //     UAP: 0.0,
        //     URE: "",
        //     UISSUERA: 0.0,
        //     FLDUNQ: 0,
        //     FLDAED: "",
        //     FLDBRC: "",
        //     UUDIS: "",
        //     UUDISAAA: "",
        //     UUGROAAA: "",
        //     UUGR01: "",
        //     UUGRO: "",
        //     IA00000001: "",
        //     IA00000002: "",
        //     IA00000003: "",
        //     IA00000004: "",
        //     IA00000005: "",
        //     IA00000006: "",
        //     IA00000007: "",
        //     IA00000008: "",
        //     IA00000009: "",
        //     IA00000010: "",
        //     IA00000011: "",
        //     IA00000012: "",
        //     IA00000013: "",
        //     IA00000014: "",
        //     IA00000015: "",
        //     IA00000016: "",
        //     IA00000017: "",
        //     IA00000018: "",
        //     IA00000019: "",
        //     IA00000020: "",
        //     IA00000021: "",
        //     IA00000022: "",
        //     IA00000023: "",
        //     ID00000001: 0.0,
        //     ID00000002: 0.0,
        //     ID00000003: 0.0,
        //     ID00000004: 0.0,
        //     ID00000005: 0.0,
        //     ID00000006: 0.0,
        //     ID00000007: 0.0,
        //     ID00000008: 0.0,
        //     ID00000009: 0.0,
        //     ID00000010: 0.0,
        //     ID00000011: 0.0,
        //     ID00000012: 0.0,
        //     ID00000013: 0.0,
        //     ID00000014: 0.0,
        //     ID00000015: 0.0,
        //     ID00000016: 0.0,
        //     ID00000017: 0.0,
        //     ID00000018: 0.0,
        //     ID00000019: 0.0,
        //     ID00000020: 0.0,
        //     ID00000021: 0.0,
        //     ID00000022: 0.0,
        //     ID00000023: 0.0,
        //     IP00000001: 0.0,
        //     IP00000002: 0.0,
        //     IP00000003: 0.0,
        //     IP00000004: 0.0,
        //     IP00000005: 0.0,
        //     IP00000006: 0.0,
        //     IP00000007: 0.0,
        //     IP00000008: 0.0,
        //     IP00000009: 0.0,
        //     IP00000010: 0.0,
        //     IP00000011: 0.0,
        //     IP00000012: 0.0,
        //     IP00000013: 0.0,
        //     IP00000014: 0.0,
        //     IP00000015: 0.0,
        //     IP00000016: 0.0,
        //     IP00000017: 0.0,
        //     IP00000018: 0.0,
        //     IP00000019: 0.0,
        //     IP00000020: 0.0,
        //     IP00000021: 0.0,
        //     IP00000022: 0.0,
        //     IP00000023: 0.0,
        //     IT00000001: "",
        //     IT00000002: "",
        //     IT00000003: "",
        //     IT00000004: "",
        //     IT00000005: "",
        //     IT00000006: "",
        //     IT00000007: "",
        //     IT00000008: "",
        //     IT00000009: "",
        //     IT00000010: "",
        //     IT00000011: "",
        //     IT00000012: "",
        //     IT00000013: "",
        //     IT00000014: "",
        //     IT00000015: "",
        //     IT00000016: "",
        //     IT00000017: "",
        //     IT00000018: "",
        //     IT00000019: "",
        //     IT00000020: "",
        //     IT00000021: "",
        //     IT00000022: "",
        //     IT00000023: "",
        //     UDIS: 0.0,
        //     UUOS: "",
        //     UT76: "",
        //     USERFLD: "",
        //     USERFLDB: "2",
        //     SRDETAIL: "",
        //     EXPDUMMY: " ",
        //     BARCODE: "",
        //     OLDPRD: "",
        //     OLDQTY: 0.0,
        //     OQTY2: 0.0,
        //     OLDRATE: 0.0,
        //     LADDNEW: "Y",
        //     PRICELIST: " ",
        //     _BATSTOCK: " ",
        //     _LOCWISE: " ",
        //     LDUALSTK: " ",
        //     SRNOSTK: " ",
        //     MRPSTK: " ",
        //     RORI: " ",
        //     ORDDUMMY: " ",
        //     SRIDUMMY: " ",
        //     SRRDUMMY: " ",
        //     SRIDUMMYF: " ",
        //     SRRDUMMYF: " ",
        //     SRISSCD: "",
        //     OLDSRNO: "",
        //     SCNOCL: "",
        //     SCNOCL1: "",
        //     MANUDATE: "",
        //     EXPDATE: "",
        //     _EXPTOTALAMT: 0.0,
        // });
        setFlag(false)
        table.setEditingRow(null); //exit editing mode
        // setSelectedProductObj(null)
        // setBatchIssueObj()
        setGroupCatogaryProduct({
            "Group": "",
            "Catogary": "",
            "Product": ""
        })
        setTableEditingRow(null)
    };

    useEffect(() => {
        if (LocationObj) {
            handleEditSave()
        }
    }, [LocationObj])

    const handleNullT02Obj = () => {
        setT02Obj(ProductionDataSend?.T02?.[0])
        setGroupCatogaryProduct({
            "Group": "",
            "Catogary": "",
            "Product": ""
        })
        setTableEditingRow(null)
    };

    const SubmitButton = () => {
        // return setDrawerObj({
        //     ...dra, title:
        //             <Text fw={700}></Text>,
        //     body: <>
        //     <BatchReceipt
        //         OBJSend={OBJ}
        //         T41ObjSend={T41Obj}
        //         setT41ObjSend={(e) => {
        //             // console.log("setT41ObjSend=>", e);
        //             setT41Obj(e);
        //         }}
        //         oYearSend={oYearSend}
        //         propsSend={propsSend}
        //         T02DataSend={T02Data}
        //         setT02DataSend={(e) => {
        //             console.log('e =>', e)
        //             setT02Data(e)
        //         }}
        //         ProductionDataSend={ProductionDataSend}
        //         DrawerObjSend={DrawerObj}
        //         setDrawerObjSend={(e) => {
        //             setDrawerObj(e)
        //         }}
        //     />
        // </>, open: true, size: "xl", position: "right"
        // })

        dispatch(DataSaveLoading(true));
        GlobalClass.Notify(
            "info",
            "Adding",
            "Please wait while we process your data"
        );
        var JData = { T41: T41Obj, T02: T02Data };

        console.log("JData==>", JData);
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "A",
            cCode: "ININ",
            cSData: JSON.stringify(JData),
        };
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "A",
            cCode: "ININ",
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                // console.log('api data', data);
                // console.log('api data status', data?.status);
                if (data.status == "SUCCESS") {
                    let PaginationObj =
                        Store.getState().DataSaveLoadingSlice.PaginationObj;
                    Promise.resolve(dispatch(DataSaveLoading(false)))
                        .then(() => setDrawerObjSend({ ...DrawerObjSend, open: false }))
                        .then(() => dispatch(ModalDelete(propsSend?.index)))
                        .then(() =>
                            dispatch(
                                GetAccountList({
                                    id: "03900002",
                                    name: "Production",
                                    p0: "MT",
                                    p1: "TXNVOULISTIN",
                                    p2: "",
                                    p3: "",
                                    type: "A",
                                    pagination: PaginationObj["03900002"],
                                })
                            )
                        )
                        .then(() =>
                            GlobalClass.Notify(
                                "success",
                                "Added",
                                "Added successfully"
                            )
                        );
                } else {
                    Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
                        GlobalClass.Notify("error", data?.status, data?.message)
                    );
                }
            })
            .catch((e) => {
                Promise.resolve(
                    GlobalClass.Notify("error", "Error", `${e.message}`)
                ).then(() => dispatch(DataSaveLoading(false)));

            });
    }

    const handleRowKeyDown = (e, row) => {
        if (e?.key == "Enter") {
            setFlag(true)
            console.log("handleRowKeyDown run")
            table.setEditingRow(row)
            setTableEditingRow(row)
            setT02Obj(row?.original)
        }
    }

    const table = useMantineReactTable({
        columns,
        data: T02Data,
        enableTopToolbar: false,
        enableHiding: false,
        enableEditing: true,
        enableMultiRowSelection: false,
        enableSelectAll: false,
        enableColumnActions: false,
        enableSorting: false,
        enableFilters: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        editDisplayMode: "row",
        mantineTableBodyRowProps: ({ row }) => ({
            tabIndex: 0,
            onKeyUp: (e) => handleRowKeyDown(e, row),
            onClick: () => {
                setRowSelection((prev) => {
                    if (!prev[row.id]) {
                        return { [row.id]: true };
                    }
                    return prev;
                });
            },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
        }),
        mantineTableContainerProps: {
            sx: { maxHeight: "250px", minHeight: "300px" },
        },
        state: {
            density: "0px",
            rowSelection
        },
        // createDisplayMode: "row",
        initialState: {
            columnVisibility: {

                Product_Group: false, Product_Catogary: false, 336: false, 337: false, 335: false, 317: false, 320: false, 326: false, 331: false, 332: false, 325: false, 333: false, 334: false
            },
            density: "0px",
        },
        // onCreatingRowSave: () => {
        //     if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
        //         return GlobalClass.Notify(
        //             "warning",
        //             "Validation error",
        //             "Please fill out all the required fields"
        //         );
        //     } else {
        //         handleCreateRow();
        //     }
        // },
        onEditingRowSave: handleEditSave,
        // onCreatingRowCancel: handleNullT02Obj,
        onEditingRowCancel: handleNullT02Obj,
        renderRowActions: ({ row, table }) => (
            <Flex gap="md">
                <Tooltip label="Edit">
                    <ActionIcon
                        onClick={() => {
                            table.setEditingRow(row);
                            console.log("table.setEditingRow=>", row);
                            setT02Obj(row.original);
                            setTableEditingRow(row)
                            setFlag(true)
                        }}
                    >
                        <IconEdit size={"20px"} />
                    </ActionIcon>
                </Tooltip>
            </Flex>
        )
    });

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key == "Escape") {
                Promise.resolve()
                    .then(() => handleNullT02Obj())
                    .then(() => {
                        setTimeout(() => {
                            if (tableEditingRow == null) {
                                table.setCreatingRow(false)
                            } else {
                                table.setEditingRow(null)
                            }
                        }, 500)
                    })
            }
        })
    }, [tableEditingRow])

    let rowSum = T02Data.reduce((a, b) => (a = a + b?.FIELD08), 0);

    // let TotalRow = table.getRowModel().rows?.length;
    // let selectedRow = table.getSelectedRowModel().flatRows[0];

    let ReceiptArray = T02Data.filter((d, i) => d.FIELD05 == "C");
    let IssueArray = T02Data.filter((d, i) => d.FIELD05 == "D");

    let recieptAmountSum = ReceiptArray.reduce((a, b) => (a = a + b?.FIELD08), 0);
    let IssueAmountSum = IssueArray.reduce((a, b) => (a = a + b?.FIELD08), 0);
    // console.log('recieptAmount==>>', recieptAmountSum)
    // console.log('IssueAmountSum=>>', IssueAmountSum)

    // reciept amount & issue amount
    useEffect(() => {
        if (recieptAmountSum >= 0 && IssueAmountSum >= 0) {
            setT41Obj({
                ...T41ObjSend,
                FIELD06: recieptAmountSum,
                FIELD07: IssueAmountSum,
            });
        }
    }, [recieptAmountSum, IssueAmountSum]);

    // reciept Qty sum
    let ReceiptQtySum = ReceiptArray.reduce((a, b) => (a = a + b?.FIELD33), 0);
    // console.log('ReceiptQtySum', ReceiptQtySum)

    // issue Qty sum
    let IssueQtySum = IssueArray.reduce((a, b) => (a = a + b?.FIELD33), 0);
    // console.log('IssueQtySum', IssueQtySum)

    // reciept Qty - 2 sum
    let RecieptQty2Sum = ReceiptArray.reduce((a, b) => (a = a + b?.FIELD21), 0);

    // issue Qty - 2 sum
    let IssueQty2Sum = IssueArray.reduce((a, b) => (a = a + b?.FIELD21), 0);

    const spoilerControlRef = useRef();

    return (
        <>
            {
                SaveLoading == true ?
                    GlobalClass.RenderLoader("dots") :
                    <>
                        {
                            AccountisLoading["P_M21"] == true ? GlobalClass.RenderLoader("dots") :
                                <>
                                    <Modal
                                        opened={DrawerObj?.open}
                                        // onClose={close}
                                        withCloseButton={() => {

                                        }}
                                        fullScreen={false}
                                        closeButtonProps={{
                                            onClick: () => {
                                                setDrawerObj(dra);
                                            },
                                        }}
                                        title={DrawerObj.title}
                                        size={DrawerObj.size}
                                        // centered={.Centered}
                                        closeOnClickOutside={true}
                                        position={DrawerObj?.position ?? 'bottom'}
                                        onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
                                    >
                                        {DrawerObj.body}
                                    </Modal>

                                    <Paper shadow="md" radius="md" p="xs" withBorder>
                                        <Grid gutter={4}>
                                            <Grid.Col span={12} my="xs" style={{ padding: 0 }}>
                                                <MantineReactTable table={table} />
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Grid.Col span={6}>
                                                    <Grid.Col
                                                        span={12}
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                        }}
                                                    >
                                                        <Grid.Col span={5}>
                                                            <Text size={12}>Quantity</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={5}>
                                                            <Text size={12}>Amount</Text>
                                                        </Grid.Col>
                                                    </Grid.Col>

                                                    <Grid.Col span={12} style={{ display: "flex" }}>
                                                        <Grid.Col span={2}>
                                                            <Text size={12}>Input :</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={5}>
                                                            <PLNumberBox disabled value={ReceiptQtySum} />
                                                        </Grid.Col>
                                                        <Grid.Col span={5}>
                                                            <PLNumberBox disabled value={T41Obj?.FIELD06} />
                                                        </Grid.Col>
                                                    </Grid.Col>

                                                    <Grid.Col span={12} style={{ display: "flex" }}>
                                                        <Grid.Col span={2}>
                                                            <Text size={12}>Output :</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={5}>
                                                            <PLNumberBox disabled value={IssueQtySum} />
                                                        </Grid.Col>
                                                        <Grid.Col span={5}>
                                                            <PLNumberBox disabled value={T41Obj?.FIELD07} />
                                                        </Grid.Col>
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Button
                                                    style={{ height: "30px", width: "100px" }}
                                                    onClick={() => {
                                                        // handleSubmit()
                                                        SubmitButton()
                                                    }}
                                                >OK</Button>
                                            </Grid.Col>
                                        </Grid>
                                    </Paper>
                                </>
                        }
                    </>
            }
        </>
    )
}

export default ProductionT02Table