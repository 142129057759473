import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GlobalClass from "../GlobalClass";
import axios from "axios";
import gensetting from "../gensetting";
import Store from "../store";
import { DataSaveLoading } from "./DataSaveLoadingSlice";


//Entry Type List & Extra Detail List
export const GetVoucherSetup = createAsyncThunk(
    "VoucherSetup/GetVoucherSetup",
    async (obj) => {
        console.log("VoucherSetup/GetVoucherSetup obj===>", obj);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": obj.action ? obj.action : "T",
                    "cCode": "",
                    "cOPara": obj.para ? obj.para : "TS"
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM45List + "?pa=" + param);
            console.log("VoucherSetupSlice/GetVoucherSetup response=>>", response.data.data);
            return response.data.data;
        } catch (error) {
            console.error(error);
        }
    }
)


//Entry Type Ent & Extra Detail Ent
export const GetVoucherSetupEnt = createAsyncThunk(
    "VoucherSetup/GetVoucherSetupEnt",
    async (obj) => {
        console.log("VoucherSetup/GetVoucherSetupEnt obj===>", obj);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": obj.action ? obj.action : "T",
                    "cCode": obj.code != "" ? obj.code : "",
                    "cOPara": obj.para ? obj.para : "TS"
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM45Ent + "?pa=" + param);
            console.log("VoucherSetupSlice/GetVoucherSetup response=>>", response.data.data);
            return response.data.data;
        } catch (error) {
            console.error(error);
        }
    }
)

export const GetVouSetupEnableDisable = createAsyncThunk(
    "VoucherSetup/GetVouSetupEnableDisable",
    async (obj) => {
        console.log("VoucherSetup/GetVouSetupEnableDisable obj===>", obj);
        try {
            let EnableDsableobj = {...obj?.obj?.DataGridRow,FIELD09:obj?.obj?.DataGridRow?.FIELD09 == "E" ? "D" : "E"}
            let param ={
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": obj?.ActionPara?.action ? obj?.ActionPara?.action : "T",
                    "cOPara": obj?.ActionPara?.para ? obj?.ActionPara?.para : "TS",
                    "cCode": obj?.obj?.M45UID,
                    "cSData": JSON.stringify(EnableDsableobj)
                };
            const response = await axios.post(GlobalClass.ApiUrl + GlobalClass.M45EnDisable, param);
            console.log("VoucherSetupSlice/GetVoucherSetup response=>>", response.data);
            if(response.data?.status == "SUCCESS"){
                Promise.resolve(GlobalClass.Notify("success", "Status", "Status Change successfully")).then(()=>Store.dispatch(
                    GetVoucherSetup({
                      action: obj?.ActionPara?.action,
                      para: obj?.ActionPara?.para,
                    })
                  ))
                //   .then(()=>Store.dispatch(DataSaveLoading(false)))
                
            }else{
                Promise.resolve(GlobalClass.Notify(
                    "error",
                    response?.data?.status,
                    response?.data?.message
                  ))
                //   .then(()=>Store.dispatch(DataSaveLoading(false)))
            }
            return response.data.data;
        } catch (error) {
            console.error(error);
        }
    }
)


const VoucherSetupSlice = createSlice({
    name: 'VoucherSetup',
    initialState: {
        VoucherSetupData: [],
        VoucherSetupEntData: [],
        ApiActionPara: {},
        EnableDisableStatus:[],
        isLoading: false,
        hasError: false,
        isLoadingEnt: false,
    },
    reducers: {
        getApiActionPara: (state, action) => {
            console.log("getApiActionPara==>", action.payload);
            state.ApiActionPara = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetVoucherSetup.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetVoucherSetup.fulfilled, (state, action) => {
                state.VoucherSetupData = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(GetVoucherSetup.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })
            .addCase(GetVoucherSetupEnt.pending, (state, action) => {
                state.isLoadingEnt = true;
                state.hasError = false;
            })
            .addCase(GetVoucherSetupEnt.fulfilled, (state, action) => {
                state.VoucherSetupEntData = action.payload;
                state.isLoadingEnt = false;
                state.hasError = false;
            })
            .addCase(GetVoucherSetupEnt.rejected, (state, action) => {
                state.hasError = true
                state.isLoadingEnt = false;
            })
            .addCase(GetVouSetupEnableDisable.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetVouSetupEnableDisable.fulfilled, (state, action) => {
                state.EnableDisableStatus = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(GetVouSetupEnableDisable.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })
    }
})

export const { getApiActionPara } = VoucherSetupSlice.actions

export default VoucherSetupSlice.reducer