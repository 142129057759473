import React, { useEffect, useState } from "react";
import { MultiSelect, NativeSelect, Text } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { ComboBoxUrl } from "../utils/slices/CompanySetupSlice";
import { Select } from "@mantine/core";
import DataGrid from "../components/DataGrid";
import { IconSearch } from "@tabler/icons-react";
export default function PLComboBox(props) {
  // console.log('props cops=>',props.copno);
  // console.log('props data==>', props.data);

  const ComboBox = useSelector((state) => state.CompanySetup);
  const [ComboBoxUrlData, setComboBoxUrlData] = useState([]);
  const [isMultiple, setisMultiple] = useState(false);
  var loadData = ["Loading..."];
  const dispatach = useDispatch();
  const [iconsProperty, setIconsProperty] = useState(false);
  const [comboValue, setComboValue] = useState();
  

  useEffect(() => {
    setisMultiple(props.multiple ? props.multiple : false);
  }, [props.multiple]);
  const CustomDropdown = () => (
    <div style={{ maxHeight: "300px", overflowY: "auto" }}>
      <DataGrid />
      <button name="OK">OK</button>
    </div>
  );

  function setCombo() {
    if (props?.setComboBox && typeof props.setComboBox == "function") {
      props?.setComboBox("");
    }
  }

  useEffect(() => {
    setComboValue(props?.value);

  }, [props?.value]);

  useEffect(() => {
    if (props.data) {
      var dt = null;
      if (Array.isArray(props.data)) {
        dt = props.data
      }
      // else if(typeof props.copno == "string" && props.copno?.startsWith("@M ")){
      //  dt = props.copno?.replace("@M ","")?.split(",")?.map((e)=>({[props.dispexpr]:e,[props.valexpr]:e}))

      // }
      // console.log("@M",dt);
      setComboBoxUrlData(
        props.data?.map((v, i) => ({
          ...v,
          label: v[props.dispexpr],
          value: v[props.valexpr],
        }))
      );

    } else {
      // dispatach(ComboBoxUrl(props.copno))
      console.log("@M " + props.name, props.copno);
      if (props.copno) { //&& !ComboBox

        if (typeof props.copno == "string" && props.copno?.startsWith("@M ")) {
          let dt = props.copno?.replace("@M ", "")?.split(",")?.map((e) => ({ [props.dispexpr]: e, [props.valexpr]: e }))

          setComboBoxUrlData(
            dt?.map((v, i) => ({
              ...v,
              label: v[props.dispexpr],
              value: v[props.valexpr],
            }))
          );

        } else {
          dispatach(ComboBoxUrl(props.copno));
        }

      }
    }
  }, [props.data, props.copno]);
  return (
    <>
      {
        // ComboBox.isLoading ? <div><h2>Loading...</h2></div>
        //   :
        //   ComboBox.hasError ? <div><h2>{ComboBox.hasError}</h2></div>
        //     :
        isMultiple ? (
          <MultiSelect
            withinPortal
            // dropdownOpened={props?.dropdownOpened}

            disabled={props.disabled}
            name={props?.name}
            label={props.label}
            dropdownPosition={props?.dropdownPosition}
            // dropdownPosition='to p'
            // zIndex={99999}
            // style={{zIndex:'99999',display:'contents'}}
            // onDropdownOpen={() => {
            //   if (props.data) {
            //     // console.log("value data",props.data);
            //     // setComboBoxUrlData(
            //     //   props?.data?.map((v, i) => ({
            //     //     ...v,
            //     //     label: v[props.dispexpr],
            //     //     value: v[props.valexpr],
            //     //   }))
            //     // );

            //   } else {
            //     if (props.copno) {
            //       dispatach(ComboBoxUrl(props?.copno));
            //     }
            //     console.log("ComboBoxUrlData", ComboBox.ComboBoxUrlData);
            //   }
            // }}
            data={
              props.data || (typeof props.copno == "string" && props.copno?.startsWith("@M "))
                ? ComboBoxUrlData
                : ComboBox?.ComboBoxUrlData[props.copno]
                  ? ComboBox?.ComboBoxUrlData[props.copno]?.map((v, i) => ({
                    ...v,
                    label: v[props.dispexpr],
                    value: v[props.valexpr],
                  }))
                  : []
            }
            id={props?.cmbid ?? props?.id}
            value={props.value}
            size={props?.size ? props?.size : "xs"}
            onBlur={() => {
              setCombo();
            }}
            onChange={(e) => {
              console.log("onchange=>", e);
              console.log("onchange=> props.data", props.data);
              if (!props.data) {
                let dt = null
                if (typeof props.copno == "string" && props.copno?.startsWith("@M ")) {
                  dt = ComboBoxUrlData
                }
                else {
                  dt = ComboBox.ComboBoxUrlData[props.copno]
                }

                dt.map((v, i) => ({
                  ...v,
                  label: v[props.dispexpr],
                  value: v[props.valexpr],
                }))
                  .map((v, i) => {
                    if (v.value == e) {
                      console.log("editDrop", v.label);
                      props.setEdit({ value: v.value, label: v.label });
                    }
                  });
                // props.setComboBox ? props.setComboBox("") : '';
              } else {
                props.setEdit(e);
              }
            }}
          />
        ) : (
          <Select
            withinPortal
            onKeyDown={(e) => {
              if (props?.onKeyDown && typeof props?.onKeyDown == 'function') {
                props.onKeyDown(e)
              }
            }}
            dropdownOpened={props?.dropdownOpened}
            initiallyOpened={props.initiallyOpened}
            autoFocus={props?.autoFocus}
            name={props?.name}
            label={props.label}
            disabled={props.disabled}
            searchable={props.searchable}
            rightSection={() => {
              if (props.searchable) {
                return <IconSearch
                  style={{
                    color: iconsProperty == false ? "lightgray" : "darkgray",
                  }}
                  onClick={() => setIconsProperty(!iconsProperty)}
                />
              }
              else {
                return
              }
            }
            }
            placeholder={props.placeholder}
            defaultValue={props.value}
            dropdownPosition={"flip"}
            // ref={props.ref}
            // zIndex={300}
            // style={{zIndex:'99999',display:'contents'}}
            // onDropdownOpen={() => {
            //   if (props.data) {
            //     // console.log("value data",props.data);
            //     // setComboBoxUrlData(
            //     //   props.data.map((v, i) => ({
            //     //     ...v,
            //     //     label: v[props.dispexpr],
            //     //     value: v[props.valexpr],
            //     //   }))
            //     // );
            //     // console.log("combo", props.data.map((v, i) => ({
            //     //   ...v, label: v[props.dispexpr], value: v[props.valexpr]
            //     // })));
            //   } else if (props.copno) {
            //     dispatach(ComboBoxUrl(props?.copno));
            //     // console.log("ComboBoxUrlData", ComboBox.ComboBoxUrlData);
            //   }
            // }}
            onLoad={() => {
              // if (props.data) {
              //   // console.log("value data",props.data);
              //   // setComboBoxUrlData(
              //   //   props.data.map((v, i) => ({
              //   //     ...v,
              //   //     label: v[props.dispexpr],
              //   //     value: v[props.valexpr],
              //   //   }))
              //   // );
              //   // console.log("combo", props.data.map((v, i) => ({
              //   //   ...v, label: v[props.dispexpr], value: v[props.valexpr]
              //   // })));
              // } else if (props.copno) {
              //   dispatach(ComboBoxUrl(props?.copno));
              //   // console.log("ComboBoxUrlData", ComboBox.ComboBoxUrlData);
              // }
            }}
            data={
              props.data || (typeof props.copno == "string" && props.copno?.startsWith("@M "))
                ? ComboBoxUrlData
                : ComboBox.isLoading
                  ? [{ label: "", value: "" }]
                  : ComboBox?.ComboBoxUrlData && ComboBox?.ComboBoxUrlData[props.copno]
                    ? ComboBox?.ComboBoxUrlData[props.copno]?.map((v, i) => ({
                      ...v,
                      label: v[props.dispexpr],
                      value: v[props.valexpr],
                    }))
                    : []
            }
            id={props.cmbid}
            value={comboValue}
            size={props?.size ? props?.size : "xs"}
            onBlur={() => {
              setCombo();
            }}
            itemComponent={props?.itemComponent}
            onChange={(e) => {
              console.log("onchange=>", e);
              console.log("onchange=> props.data", props.data);
              if (!props.data) {
                let dt = null
                if (typeof props.copno == "string" && props.copno?.startsWith("@M ")) {
                  dt = ComboBoxUrlData
                }
                else {
                  dt = ComboBox.ComboBoxUrlData[props.copno]
                }
                dt.map((v, i) => ({
                  ...v,
                  label: v[props?.dispexpr],
                  value: v[props?.valexpr],
                }))
                  .map((v, i) => {
                    if (v?.value == e) {
                      // console.log("editDrop", v.label);
                      props.setEdit({ value: v?.value, label: v?.label });
                    }
                  });
                // props.setComboBox ? props.setComboBox("") : '';
              } else {
                props.setEdit(e);
                setComboValue(e);
              }
            }}
            onFocus={(e) => {
              //  typeof props?.onFocus == "function" ? props?.onFocus(e) : null
              if (props?.onFocus && typeof props?.onFocus == "function") {
                props?.onFocus(e)
              }

              if (props?.open) {
                const spacebarEvent = new KeyboardEvent("keydown", {
                  key: " ",
                  code: "Space",
                  keyCode: 32, // spacebar key code
                  which: 32,
                  bubbles: true,
                });

                e.target.dispatchEvent(spacebarEvent);
              }
            }}
          />
        )
      }
    </>
  );
}
