import { createColumnHelper } from '@tanstack/react-table';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../../utils/UseWindowDimensions';
import PLComboBox from '../../PlusComponent/PLComboBox';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import GlobalClass from '../../utils/GlobalClass';
import gensetting from '../../utils/gensetting';
import Store from '../../utils/store';
import { DeleteItemwiseExpenseList, GetItemwiseExpenseList } from '../../utils/slices/ItemwiseExpenseSlice';
import { GetItemwiseExpenseListEnt } from '../../utils/slices/ItemwiseExpenseEntSlice';
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice';

const ItemWiseExpenseTableTwo = (props) => {

  const { M46Field02, S16F03 } = props;

  // console.log("M46Field02==>", M46Field02)

  const [columns, setColumns] = useState([])
  const [Data, setData] = useState([])
  const [M45EditObj, setM45EditObj] = useState(null);
  const [rowSelection, setRowSelection] = useState({});
  const [tableEditingRow, setTableEditingRow] = useState(null);
  const [type,setType] = useState(null);
  const { height } = useWindowDimensions();

  const dispatch = useDispatch();

  const ItemwiseExpenseListData = useSelector((state) => state?.ItemwiseExpenseListGroup?.ItemwiseExpenseListData?.data);

  const { isLoading } = useSelector((state) => state?.ItemwiseExpenseListGroup)

  // const ItemwiseExpenseListEntData = useSelector((state) => state?.ItemwiseExpenseListEntGroup?.ItemwiseExpenseListEntData)

  const columnHelper = createColumnHelper();

  // useEffect(() => {
  //   if (ItemwiseExpenseListData) {
  //     setData(ItemwiseExpenseListData?.M45)
  //   }
  // }, [ItemwiseExpenseListData])

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e?.key == "Escape") {
        Promise.resolve()
        .then(()=>{
          rowCancelHandler();
        })
          .then(() => {
              table.setEditingRow(null);

          });
      }
    });
  }, [tableEditingRow]);

  useEffect(()=>{
  if(type!=null){
    Promise.resolve(setM45EditObj({...M45EditObj,FIELD06:type})).then(()=>{
      if(M45EditObj?.FIELD06==type){
      handleEditData();}
    })
  }
  },[type,M45EditObj?.FIELD06])

  const renderDynamicVal = (col, val) => {
    var ret = '';
    switch (col) {
      case "Sale/Purc":
        ret = val == "PP" ? "Purchase" :
          val == "SS" ? "Sale" : val
        break;
      case "Type":
        ret = val == "C" ? "Cumulative" : val
      // val == "P" ? "Percentage" :
      //   val == "F" ? "Fixed" :
      //     val == "Q" ? "Quantity" :
      //       val == "S" ? "Second Qty" : val
      default:
        ret = val
        break;
    }
    return ret;
  }

  let ArrayOne = [];
  let ArrayTwo = [];
  let ArrayThree = [];
  useEffect(() => {
    if (ItemwiseExpenseListData?.M45 && ItemwiseExpenseListData?.M46) {
      ItemwiseExpenseListData?.M45?.map((a, i) => {
        ItemwiseExpenseListData?.M46?.map((b, j) => {
          if (a?.FIELD54 == b?.FIELD12) {
            ArrayOne.push({
              ...a,
              M46F02: b?.FIELD02,
              FIELD06: b?.FIELD03,
              FIELD07: b?.FIELD04,
              FIELD21: b?.FIELD10,
              FIELD54: b?.FIELD12,
              FIELD04: b?.FIELD13
            })
          }
        })
      })
      // console.log("ArrayOne==>", ArrayOne);
      // console.log("ArrayTwo==>", ArrayOne.filter((d)=>d?.M46F02 == M46Field02?.FIELD01))

      Promise.resolve(ArrayOne.filter((d) => d?.M46F02 == M46Field02?.FIELD01)).then((res1) => {
        // console.log("Res1", res1)
        // let ArrayTwo = []
        if (res1.length <= 0) {
          // console.log("first");
          // ArrayTwo = [...ItemwiseExpenseListData?.M45]
          return [...ItemwiseExpenseListData?.M45]
        } else {
          // console.log("second condition res1=>", res1);
            let AddM45Obj = ItemwiseExpenseListData.M45.filter(aa => {
              return !res1.some(bb => bb.FIELD54 === aa.FIELD54);
            })

          // console.log("AddM45Obj==>", AddM45Obj, "res1=>", res1);

          return [...AddM45Obj,...res1]
        }
      }).then((res2) => setData(res2))
    }

  }, [ItemwiseExpenseListData, M46Field02])

  useEffect(() => {
    if (!isLoading) {
      let col = [];
      col.push(
        columnHelper.accessor("FIELD02", {
          header: "Description",
          size: 100,
          enableEditing: false
        })
      )
      col.push(
        columnHelper.accessor("FIELD21", {
          header: "Sale/Purc",
          size: 100,
          enableEditing: false,
          Cell: ({ cell }) => {
            var val = cell.getValue()
            if (val == "PP") {
              return "Purchase"
            } else if (val == "SS") {
              return "Sale"
            }
            return val
          }
        })
      )
      col.push(
        columnHelper.accessor("FIELD07", {
          header: "Rate",
          size: 50,
          enableEditing: true,
          Edit: ({ cell, row }) => {
            return (<PLNumberBox
              decimalSeparator={true}
              precision={2}
              value={row?.original?.FIELD07}
              setEdit={(e) => {
                setM45EditObj({ ...M45EditObj, FIELD07: e })
              }}
              // onKeyDown={(e) => {
              //   if (e.key == "Tab") {
              //     const nextInput = document.getElementById("ItemwiseCmb");
              //     console.log("nextInput",nextInput)
              //     if (nextInput) {
              //       nextInput.focus();
              //     }
              //   }
              // }}
            />)
          },
        })
      )
      col.push(
        columnHelper.accessor("FIELD06", {
          header: "Type",
          size: 100,
          enableEditing: true,
          Cell: ({ cell }) => {
            var val1 = cell.getValue()
            // console.log("==>",val1)
            if (val1 == "C") {
              return "Cumulative"
            } else if (val1 == "P") {
              return "Percentage"
            } else if (val1 == "F") {
              return "Fixed"
            } else if (val1 == "Q") {
              return "Quantity"
            } else if (val1 == "S") {
              return "Second Qty"
            }
            // return renderDynamicVal("Type", val1)
            return val1
          },
          Edit: ({ cell, row }) => {
            return (<PLComboBox
            cmbid={"ItemwiseCmb"}
              data={ItemwiseExpenseListData?.COP26}
              value={row?.original?.FIELD06}
              dispexpr="DisplayMember"
              valexpr="ValueMember"
              setEdit={(e) => {
                // console.log(e)
                // Promise.resolve(
                  setType(e)
                //   ).then(()=>{
                //   handleEditData();
                // })
              }}
            />)
          },
        })
      )
      col.push(
        columnHelper.accessor("FIELD04", {
          header: "Account Name",
          size: 100,
          enableEditing: false
        })
      )
      setColumns(col)
    }
  }, [isLoading, M45EditObj])



  const handleRowKeyDown = (e, row) => {
    if (e?.key == "Enter") {
      Promise.resolve().then(() => {
        table.setEditingRow(row);
        setM45EditObj(row.original);
        setTableEditingRow(row);
      });
    }
  };

 

  // useEffect(() => {
  //   if (ItemwiseExpenseListEntData) {
  //     console.log("ItemwiseExpenseListEntData=>", ItemwiseExpenseListEntData);
  //   }

  // }, [ItemwiseExpenseListEntData])
const rowCancelHandler = () =>{
    setM45EditObj(null);
    table.setEditingRow(null);
    table.setCreatingRow(null);
    setTableEditingRow(null);
    setType(null);
}
  const handleEditData = () => {
    // console.log("M45EditObj", M45EditObj);

    let newObj = [...Data]
    newObj[tableEditingRow?.index] = { ...M45EditObj }
    setData(newObj)
    let M46Obj = {};

    if (M45EditObj?.M46F02) {
      ItemwiseExpenseListData?.M46?.map((b, j) => {
        if (M45EditObj?.M46F02 == b?.FIELD02 && M45EditObj?.FIELD54 == b?.FIELD12) {
          // console.log("M46Obj in map=>", b)
          M46Obj = {
            ...b,
            FIELD01: S16F03,
            FIELD02: M46Field02?.FIELD01,
            FIELD03: M45EditObj?.FIELD06,
            FIELD04: M45EditObj?.FIELD07,
            FIELD10: M45EditObj?.FIELD21,
            FIELD12: M45EditObj?.FIELD54,
            FIELD13: M45EditObj?.FIELD04
          }
        }
      })
    } else {
      M46Obj = {
        "FIELD01": S16F03,
        "FIELD02": M46Field02?.FIELD01,
        "FIELD03": M45EditObj?.FIELD06,
        "FIELD04": M45EditObj?.FIELD07,
        "FIELD05": 0,
        "FIELD10": M45EditObj?.FIELD21,
        "FIELD11": 0,
        "FIELD12": M45EditObj?.FIELD54,
        "FIELD13": M45EditObj?.FIELD04,
        "FLDUNQ": 0,
        "FLDAED": "A",
        "FLDBRC": ""
      }
    }

    // console.log("M46Obj out map==>", M46Obj);

    GlobalClass.Notify("info", "Editing", "Please wait while we process your data");
    var newData = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: M45EditObj?.M46F02 ? "E" :"A",
      cCode: M45EditObj?.M46F02 ? M46Field02?.FIELD01 : "",
      cSData: JSON.stringify(M46Obj),
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: M45EditObj?.M46F02 ? "E" :"A",
      cCode:M45EditObj?.M46F02 ? M46Field02?.FIELD01 : "",
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newData),
    };
    // console.log("newdata", newData);

    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostM46Ent + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("api data", data);
        // console.log("api data status", data?.status);
        if (data.status === "SUCCESS") {
          GlobalClass.Notify("success", "Edited", "Edited successfully");
          Store.dispatch(GetItemwiseExpenseList());
        } else {
          GlobalClass.Notify("error", data?.status, data?.message);
        }
      })
      .catch((e) => {
        GlobalClass.Notify("error", "Error occured", `Responded with ${e}`);
      });



    table.setEditingRow(null);
  setTableEditingRow(null);
    setM45EditObj(null);
    setType(null);

  }

  const openDeleteConfirmModal = (row) => {
    // console.log("Delete Row", row?.original)

    if (window.confirm("Are you sure to delete selected data?")) {
      dispatch(DataSaveLoading(true))
      let cOPara =
        "FIELD02=" +
        "'" +
        M46Field02?.FIELD01 +
        "'" +
        " AND FIELD10=" +
        "'" +
        row?.original?.FIELD21 +
        "'" +
        " AND FIELD01=" +
        "'" +
        S16F03 +
        "'" +
        " AND FIELD12=" +
        "'" +
        row?.original?.FIELD54 +
        "'";
      // console.log("cOPara", cOPara);
      Store.dispatch(DeleteItemwiseExpenseList(cOPara));
      // Store.dispatch(GetItemwiseExpenseList());
    } else {
      return null;
    }

  }

  const table = useMantineReactTable({
    data: Data ? Data : [],
    columns,
    mantineTableContainerProps: {
      sx: { height: height * 0.6 },
    },
    enableFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableHiding: false,
    enableColumnActions: false,
    enablePagination: false,
    enableStickyHeader: true,
    enableRowSelection:false,
    enableMultiRowSelection: false,
    enableRowActions: true,
    enableEditing: true,
    initialState: {
      density: "0px"
    },
    editDisplayMode: "row",
    createDisplayMode: 'row',
    onEditingRowSave: handleEditData,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon onClick={() => {
            table.setEditingRow(row);
            setTableEditingRow(row);
            setM45EditObj(row.original);
          }}>
            <IconEdit size={'20px'} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash size={'20px'} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    mantineTableBodyRowProps: ({ row }) => ({
      tabIndex: 0,
      onKeyUp: (e) => {
        handleRowKeyDown(e, row);
      },
      onClick: () => {
        // console.log("Hello from selection");
          setRowSelection((prev) => ({
            [row?.id]: !prev[row?.id],
          }))
      },
      selected: rowSelection[row?.id],
      sx: {
        cursor: "pointer",
      },
    }),
    state:{
      rowSelection
    }
  })

  return (<>
    <MantineReactTable table={table} />
  </>)
}

export default ItemWiseExpenseTableTwo