import { Button, Center, Grid, Text } from "@mantine/core";
import React, { useEffect, useState, useRef } from "react";
import PLDataGrid from "../../../../PlusComponent/PLDataGrid";
import PLTextBox from "../../../../PlusComponent/PLTextBox";
import PLNumberBox from "../../../../PlusComponent/PLNumberBox";
import PLDateBox from "../../../../PlusComponent/PLDateBox";
import { useDispatch, useSelector } from "react-redux";
import {
  GetUserMasterEntry,
  GetUserMasterEntryDG,
} from "../../../../utils/slices/UserMasterEntrySlice";
import GlobalClass from "../../../../utils/GlobalClass";
import Store from "../../../../utils/store";
import { getProductName } from "../../../../utils/TransactionSlices/TSalesSlices";
import { DataSaveLoading } from "../../../../utils/slices/DataSaveLoadingSlice";
import { GetAccountList, GetTMFormat } from "../../../../utils/slices/AccountListSlice";
import gensetting from "../../../../utils/gensetting";
import { ModalDelete } from "../../../../utils/slices/ModalSlice";

const TestCertificateForm = (props) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const UserMasterEntryData = useSelector(
    (state) => state?.UserMasterEntry?.UserMasterEntryData
  );
  const UserMasterEntryDataDG = useSelector(
    (state) => state?.UserMasterEntry?.UserMasterEntryDataDG
  );
  const isLoading = useSelector((state) => state?.UserMasterEntry?.isLoading);
  const hasError = useSelector((state) => state?.UserMasterEntry?.hasError);
  const isLoading1 = useSelector((state) => state?.UserMasterEntry?.isLoading1);
  const hasError1 = useSelector((state) => state?.UserMasterEntry?.hasError1);
  const ErrorMsg = useSelector((state) => state?.UserMasterEntry?.ErrorMsg);
  const ErrorMsg1 = useSelector((state) => state?.UserMasterEntry?.ErrorMsg1);
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const {
    AccountListData: accList,
    TMFormatData: tmList,
    isLoading: AccNameisLoading,
  } = useSelector((state) => state.AccountList);
  // console.log("TMFormatData",tmList)
  const [UMEData, setUMEData] = useState([]);
  const [UMEDGData, setUMEDGData] = useState([]);
  const [hasFocused, setHasFocused] = useState(false);
  // const [hasFocused1, setHasFocused1] = useState(false);
  const inputRef = useRef();
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  // const splan = useRef();
  const [filterData, setFilterData] = useState([]);
  const [category,setCategory]=useState();
  const [productEdited, setProductEdited] = useState(false);
  const [selectedProduct,setSelectedProduct] = useState();
  const [selectedCategory,setSelectedCategory] = useState();
  useEffect(() => {
    dispatch(GetUserMasterEntry(props?.OBJ));
    dispatch(GetUserMasterEntryDG(props?.OBJ));
    Promise.resolve(
      dispatch(
        GetAccountList({
          id: "T_M32C",
          name: "",
          type: "A",
          p0: "T_M32C",
          p1: "T_M32C",
          p2: "",
          p3: "",
        })
      )
    ).then(() =>
      dispatch(
        GetTMFormat({
          id: "T_M32C",
          name: "",
          type: "A",
          p0: "T_M32C",
          p1: "T_M32C",
          p2: "",
          p3: "",
        })
      )
    ).then(()=>{
      if(props.OBJ?.p0=="E"){
        dispatch(
          GetAccountList({
            id: "T_M21",
            name: "",
            type: "A",
            p0: "T_M21",
            p1: "T_M21",
            p2: "",
            p3: "",
          })
        )
      }
    })
  }, []);

  useEffect(() => {
    Promise.resolve(
    setUMEData({
      ...UserMasterEntryData?.oMsc,
    })).then(()=>{
      if(props?.OBJ?.p0=="E" && UserMasterEntryData?.oMsc?.UTC00001){
    //  console.log("UserMasterEntryData?.oMsc",UserMasterEntryData?.oMsc) 
    //  console.log("TMList",accList);
    let selectedProduct1 = accList?.T_M21?.filter((a,i)=>{
      return  a?.FIELD01 == UserMasterEntryData?.oMsc?.UTC00001;
     });
     setSelectedProduct(selectedProduct1?.[0]);
    //  console.log("accList?.T_M32C",accList?.T_M32C);
    //  console.log("selectedProduct1?.FIELD12",selectedProduct1?.[0]?.FIELD12)
     let selectedCategory1 = accList?.T_M32C?.filter((a,i)=>{
      if(a?.FIELD01 == selectedProduct1?.[0]?.FIELD12){
        // console.log("a of category",a);
      }
     return a?.FIELD01 == selectedProduct1?.[0]?.FIELD12
     });
    //  console.log("selectedCategory",selectedCategory1?.[0]);
     setSelectedCategory(selectedCategory1?.[0]);
        }
    })
  }, [UserMasterEntryData?.oMsc,accList]);


  useEffect(() => {
    setUMEDGData(UserMasterEntryDataDG);
    setHasFocused(true);
  }, [UserMasterEntryDataDG]);

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    if (hasError1 && !isLoading1) {
      GlobalClass.Notify(
        "error",
        "Error: " + ErrorMsg1.code,
        ErrorMsg1.message
      );
    }
  }, [hasError1]);

  useEffect(() => {
    if (hasFocused) {
      inputRef?.current?.handlePopoverToggle();
      setHasFocused(false);
    }
  }, [hasFocused]);

  // useEffect(() => {
  //   if (hasFocused1) {
  //     inputRef1?.current?.handlePopoverToggle();
  //   }
  // }, [hasFocused1]);

  useEffect(()=>{
    const ele = document?.querySelector("#Splan");
    ele?.focus();
  },[UMEData?.UTC00001])
  useEffect(()=>{
    const ele = document?.querySelector("#Ssize");
    ele?.focus();
  },[UMEData?.UTC00002])

  

  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);

  function DateToString(e) {
    let year = e?.toLocaleString("en-IN", { year: "numeric" });
    let month = e?.toLocaleString("en-IN", {
      month: "2-digit",
    });
    let day = e?.toLocaleString("en-IN", { day: "2-digit" });

    let formatedStartDate = day + "/" + month + "/" + year;
    let startDate = year + month + day;
    return startDate;
  }

  const handleSubmit = () => {
    dispatch(DataSaveLoading(true));

    GlobalClass.Notify(
      "info",
      props?.OBJ.p0 == "A" ? "Adding" : "Editing",
      "Please wait while we process your data"
    );
    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: props?.OBJ?.p0 ? props?.OBJ.p0 : "A",
      cCode: props?.OBJ?.p0 == "A" ? "" : props?.OBJ?.id,
      cOPara: props?.OBJ?.BtnPnlObj?.p1?.split("_")[1],
      cSData: JSON.stringify(UMEData),
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: props?.OBJ?.p0 ? props?.OBJ.p0 : "A",
      cCode: props?.OBJ?.p0 == "A" ? "" : props?.OBJ?.id,
      cOPara: props?.OBJ?.BtnPnlObj?.p1?.split("_")[1],
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostUserMasterEntry + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          GlobalClass.Notify(
            "success",
            props?.OBJ.p0 == "A" ? "Added" : "Edited",
            props?.OBJ.p0 == "A" ? "Added successfully" : "Edited successfully"
          );
          let PaginationObj =
            Store.getState().DataSaveLoadingSlice.PaginationObj;
          Promise.resolve(dispatch(DataSaveLoading(false)))
            .then(() => dispatch(ModalDelete(props.index)))
            .then(() =>
              dispatch(
                GetAccountList({
                  id: "U1380168",
                  name: "Test Certi.",
                  p0: "MT",
                  p1: "T_TESTCERT",
                  p2: "",
                  p3: "",
                  type: "A",
                  pagination: PaginationObj["U1380168"],
                })
              )
            );
        } else {
          GlobalClass.Notify("error", data?.status, data?.message);
          dispatch(DataSaveLoading(false));
        }
      })
      .catch((e) => {
        GlobalClass.Notify("error", "Error", `${e.message}`);
        dispatch(DataSaveLoading(false));
      });
  };

  return isLoading || SaveLoading || isLoading1 ? (
    GlobalClass.RenderLoader("dots")
  ) : (
    <div ref={formRef}>
      <Grid gutter={2}>
        <Grid.Col span={12} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4} style={{ display: "flex", flexDirection: "row" }}>
            <Grid.Col span={4}>
              <Text style={{ fontSize: "12px" }}>Category</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <PLDataGrid
                ref={inputRef}
                // autoFocus={true}
                height={"31px"}
                width={"500px"}
                TmData={tmList["T_M32C"]?.cSTID}
                value={props?.OBJ?.p0=="A" ? {value:category?.FIELD01,label:category?.FIELD02} : {value:selectedCategory?.FIELD01,label:selectedCategory?.FIELD02}}
                setEdit={(e) => {
                  Promise.resolve(
                    // setUMEData({ ...UMEData, UTC00001: e?.FIELD01 })
                    setCategory(e)
                  ).then(() => {
                    setSelectedCategory(e);
                    inputRef2.current?.handlePopoverToggle();
                    
                  });
                  // .then(() => {
                  //   if (e?.FIELD01?.length > 0) {
                  //     setHasFocused1(true);
                  //   }
                  // });
                }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={4} style={{ display: "flex", flexDirection: "row" }}>
            <Grid.Col span={4}>
              <Text style={{ fontSize: "12px" }}>Product</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <PLDataGrid
              ref={inputRef2}
                // autoFocus={true}
                height={"31px"}
                width={"500px"}
                TmData={UMEDGData?.P_M21}
                cWhere={`M21.FIELD12 LIKE '%${category?.FIELD01?? ""}%'`}
                value={props?.OBJ?.p0=="A" ? UMEData?.UTC00001 : {value:selectedProduct?.FIELD01,label:selectedProduct?.FIELD02}}
                setEdit={(e) => {
                  Promise.resolve(
                    setUMEData({ ...UMEData, UTC00001: e?.FIELD01 })
                  ).then(() => {
                    setSelectedProduct(e);
                    setProductEdited(true);
                    let data =UMEDGData?.P_T11DT?.jData?.filter(
                      (t11) => t11?.FIELD08 == e?.FIELD01
                    );
                    setFilterData(data);
                  })
                  // .then(() => {
                  //   if (e?.FIELD01?.length > 0) {
                  //     setHasFocused1(true);
                  //   }
                  // });
                }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={4} style={{ display: "flex", flexDirection: "row" }}>
            <Grid.Col span={4}>
              <Text style={{ fontSize: "12px" }}>Sample Plan</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <PLTextBox
              id={"Splan"}
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                value={UMEData?.UTC00062}
                setEdit={(e) => {
                  setUMEData({ ...UMEData, UTC00062: e?.target?.value });
                }}
              />
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col span={12} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4} style={{ display: "flex", flexDirection: "row" }}>
            <Grid.Col span={4}>
              <Text style={{ fontSize: "12px" }}>Lot Qty</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <PLNumberBox
                onKeyDown={(e) => {
                  inputRef1?.current?.handlePopoverToggle();
                }}
                value={UMEData?.UTC00064}
                setEdit={(e) => {
                  setUMEData({ ...UMEData, UTC00064: e });
                }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={4} style={{ display: "flex", flexDirection: "row" }}>
            <Grid.Col span={4}>
              <Text style={{ fontSize: "12px" }}>Batch</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              {UMEData?.UTC00001 != "" ? (
                <PLDataGrid
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                  ref={inputRef1}
                  height={"31px"}
                  width={"500px"}
                  data={
                    productEdited==false ? UMEDGData?.P_T11DT?.jData?.filter((t11)=>t11?.FIELD08==UMEData?.UTC00001) :
                    filterData
                  }
                  TmData={UMEDGData?.P_T11}
                  value={UMEData?.UTC00002}
                  setEdit={(e) => {
                    setUMEData({ ...UMEData, UTC00002: e?.FIELD01 });
                  }}
                />
              ) : (
                <Button
                  fullWidth
                  style={{
                    padding: "0px 5px",
                    height: "31px",
                    display: "flex",
                    alignItems: "flex-start",
                    fontWeight: "normal",
                    fontSize: "12px",
                    overflow: "hidden",
                  }}
                  variant="default"
                  onClick={() =>
                    GlobalClass.Notify(
                      "warning",
                      "warning",
                      "Select Any Product"
                    )
                  }
                >
                  Select
                </Button>
              )}
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={4} style={{ display: "flex", flexDirection: "row" }}>
            <Grid.Col span={4}>
              <Text style={{ fontSize: "12px" }}>Sample Size</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <PLTextBox
              id={"Ssize"}
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                value={UMEData?.UTC00063}
                setEdit={(e) => {
                  setUMEData({ ...UMEData, UTC00063: e?.target?.value });
                }}
              />
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{ display: "flex", flexDirection: "row", columnGap: "3px" }}
        >
          <Grid.Col span={4} style={{ display: "flex", flexDirection: "row" }}>
            <Grid.Col span={4}>
              <Text style={{ fontSize: "12px" }}>Date</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <PLDateBox
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                defaultval={
                  UMEData?.UTC00065?.trim() == ""
                    ? DateToString(new Date())
                    : UMEData?.UTC00065
                }
                setEdit={(e) => {
                  setUMEData({ ...UMEData, UTC00065: DateToString(e) });
                }}
                dispformat="DD/MM/YYYY"
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col span={4} style={{ display: "flex", flexDirection: "row" }}>
            <Grid.Col span={4}>
              <Text style={{ fontSize: "12px" }}>Specification</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <PLDataGrid
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                height={"31px"}
                width={"500px"}
                data={UMEDGData?.P_UQCDISPCDT?.jData}
                TmData={UMEDGData?.P_UQCDISPC}
                value={UMEData?.UTC00070}
                setEdit={(e) => {
                  setUMEData({ ...UMEData, UTC00070: e?.FIELD01 });
                }}
              />
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{ display: "flex", flexDirection: "row", columnGap: "3px" }}
        >
          <Grid.Col span={8}>
            <Text
              style={{
                fontSize: "14px",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              Dimensional Inspections
            </Text>
          </Grid.Col>

          <Grid.Col span={4}>
            <Text
              style={{
                fontSize: "14px",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              Chemical Analysis
            </Text>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 0,
            columnGap: "4px",
          }}
        >
          <Grid.Col
            span={8}
            style={{
              border: "1px solid black",
              padding: 0,
            }}
          >
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                padding: 0,
                borderBottom: "1px solid black",
              }}
            >
              <Grid.Col
                span={2}
                style={{
                  padding: "0 4px",
                  borderRight: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontSize: "12px" }}>
                  (Measurements in milimeters)
                </Text>
              </Grid.Col>
              <Grid.Col span={10} style={{ padding: 0 }}>
                <Grid.Col style={{ borderBottom: "1px solid black" }}>
                  {" "}
                  <Text style={{ fontSize: "12px" }}>&nbsp;</Text>
                </Grid.Col>
                <Grid.Col
                  style={{ display: "flex", flexDirection: "row", padding: 0 }}
                >
                  <Grid.Col span={4} style={{ borderRight: "1px solid black" }}>
                    <Text style={{ fontSize: "12px" }}>
                      <Center>Specification</Center>
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={4} style={{ borderRight: "1px solid black" }}>
                    <Text style={{ fontSize: "12px" }}>
                      <Center>Actual Result</Center>
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Text style={{ fontSize: "12px" }}>
                      <Center>Result</Center>
                    </Text>
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>

            <Grid.Col
              span={12}
              style={{
                display: "flex",
                padding: 0,
                borderBottom: "1px solid black",
              }}
            >
              <Grid.Col
                span={2}
                style={{
                  padding: "0",
                  borderRight: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Characteristics</Text>
              </Grid.Col>
              <Grid.Col
                span={10}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <Text style={{ fontSize: "12px" }}>
                      <Center>Min.</Center>
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Text style={{ fontSize: "12px" }}>
                      <Center>Max.</Center>
                    </Text>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <Text style={{ fontSize: "12px" }}>
                      <Center>Min.</Center>
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Text style={{ fontSize: "12px" }}>
                      <Center>Max.</Center>
                    </Text>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={4} style={{ padding: 0, display: "flex" }}>
                  <Grid.Col span={6}>
                    <Text style={{ fontSize: "12px" }}>
                      <Center>Min.</Center>
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Text style={{ fontSize: "12px" }}>
                      <Center>Max.</Center>
                    </Text>
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col
                span={2}
                style={{
                  padding: "0",
                  borderRight: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Head Thickness</Text>
              </Grid.Col>
              <Grid.Col
                span={10}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00003}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00003: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00004}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00004: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00005}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00005: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00006}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00006: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={4} style={{ padding: 0, display: "flex" }}>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00007}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00007: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00008}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00008: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col
                span={2}
                style={{
                  padding: "0",
                  borderRight: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Across Flat</Text>
              </Grid.Col>
              <Grid.Col
                span={10}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00009}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00009: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00010}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00010: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00011}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00011: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00012}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00012: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={4} style={{ padding: 0, display: "flex" }}>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00013}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00013: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00014}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00014: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col
                span={2}
                style={{
                  padding: "0",
                  borderRight: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Across Corner</Text>
              </Grid.Col>
              <Grid.Col
                span={10}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00071}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00071: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00072}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00072: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00073}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00073: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00074}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00074: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={4} style={{ padding: 0, display: "flex" }}>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00075}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00075: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00076}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00076: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col
                span={2}
                style={{
                  padding: "0",
                  borderRight: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Thread Length</Text>
              </Grid.Col>
              <Grid.Col
                span={10}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00077}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00077: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00078}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00078: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00079}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00079: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00080}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00080: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={4} style={{ padding: 0, display: "flex" }}>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00081}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00081: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00082}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00082: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col
                span={2}
                style={{
                  padding: "0",
                  borderRight: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Head Diameter</Text>
              </Grid.Col>
              <Grid.Col
                span={10}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00083}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00083: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00084}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00084: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00085}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00085: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00086}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00086: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={4} style={{ padding: 0, display: "flex" }}>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00087}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00087: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00088}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00088: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col
                span={2}
                style={{
                  padding: "0",
                  borderRight: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Hex Depth</Text>
              </Grid.Col>
              <Grid.Col
                span={10}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00089}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00089: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00090}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00090: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00091}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00091: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00092}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00092: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={4} style={{ padding: 0, display: "flex" }}>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00093}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00093: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00094}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00094: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col
                span={2}
                style={{
                  padding: "0",
                  borderRight: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Total Thickness</Text>
              </Grid.Col>
              <Grid.Col
                span={10}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00095}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00095: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00096}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00096: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00097}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00097: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00098}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00098: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={4} style={{ padding: 0, display: "flex" }}>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00099}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00099: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00100}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00100: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col
                span={2}
                style={{
                  padding: "0",
                  borderRight: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Inner Diameter</Text>
              </Grid.Col>
              <Grid.Col
                span={10}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00101}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00101: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00102}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00102: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00103}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00103: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00104}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00104: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={4} style={{ padding: 0, display: "flex" }}>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00105}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00105: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00106}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00106: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col
                span={2}
                style={{
                  padding: "0",
                  borderRight: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Outer Diameter</Text>
              </Grid.Col>
              <Grid.Col
                span={10}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00107}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00107: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00108}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00108: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00109}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00109: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00110}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00110: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={4} style={{ padding: 0, display: "flex" }}>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00111}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00111: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00112}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00112: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col
                span={2}
                style={{
                  padding: "0",
                  borderRight: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Thickness</Text>
              </Grid.Col>
              <Grid.Col
                span={10}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00113}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00113: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00114}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00114: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    borderRight: "1px solid black",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00115}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00115: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00116}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00116: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={4} style={{ padding: 0, display: "flex" }}>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00117}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00117: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00118}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00118: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col
                span={2}
                style={{
                  padding: "0",
                  borderRight: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Thread</Text>
              </Grid.Col>
              <Grid.Col
                span={10}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col
                  span={4}
                  style={{ borderRight: "1px solid black", padding: 0 }}
                >
                  <PLDataGrid
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    height={"31px"}
                    width={"500px"}
                    data={UMEDGData?.P_UQCSTTSPCDT?.jData}
                    TmData={UMEDGData?.P_UQCSTTSP}
                    value={UMEData?.UTC00015}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00015: e?.FIELD01 });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{ borderRight: "1px solid black", padding: 0 }}
                >
                  <PLDataGrid
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    height={"31px"}
                    width={"500px"}
                    data={UMEDGData?.P_UQCSTTARDT?.jData}
                    TmData={UMEDGData?.P_UQCSTTAR}
                    value={UMEData?.UTC00016}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00016: e?.FIELD01 });
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={4} style={{ padding: 0, display: "flex" }}>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00017}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00017: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00018}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00018: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col
                span={2}
                style={{
                  padding: "0",
                  borderRight: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Appearence</Text>
              </Grid.Col>
              <Grid.Col
                span={10}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col
                  span={4}
                  style={{ borderRight: "1px solid black", padding: 0 }}
                >
                  <PLDataGrid
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    height={"31px"}
                    width={"500px"}
                    data={UMEDGData?.P_UQCSTASPDT?.jData}
                    TmData={UMEDGData?.P_UQCSTASP}
                    value={UMEData?.UTC00019}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00019: e?.FIELD01 });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{ borderRight: "1px solid black", padding: 0 }}
                >
                  <PLDataGrid
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    height={"31px"}
                    width={"500px"}
                    data={UMEDGData?.P_UQCSTAARDT?.jData}
                    TmData={UMEDGData?.P_UQCSTAAR}
                    value={UMEData?.UTC00020}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00020: e?.FIELD01 });
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={4} style={{ padding: 0, display: "flex" }}>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00021}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00021: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLNumberBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      decimalSeparator={true}
                      value={UMEData?.UTC00023}
                      setEdit={(e) => {
                        setUMEData({ ...UMEData, UTC00023: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={4}
            style={{
              padding: 0,
              height: "100%",
              alignContent: "space-around",
              border: "1px solid black",
            }}
          >
            <Grid.Col
              span={12}
              style={{
                padding: 0,
                borderBottom: "1px solid black",
                height: "33.33%",
              }}
            >
              {/* //4-4 na 3 part krya */}
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                  padding: 0,
                  height: "25%",
                }}
              >
                {" "}
                {/*Pchhi ani andar row wise bhag krya etle flexDirection=column  */}
                <Grid.Col
                  span={3}
                  style={{ padding: 0, borderRight: "1px solid black" }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>&nbsp;</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>C%</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Min%</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{ padding: 0, alignContent: "center" }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Si%</Center>
                  </Text>
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  height: "25%",
                }}
              >
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Min.</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00035}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00035: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00038}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00038: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{ padding: 0, alignContent: "center" }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00041}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00041: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  height: "25%",
                }}
              >
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Max.</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00036}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00036: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00039}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00039: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{ padding: 0, alignContent: "center" }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00042}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00042: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  height: "25%",
                }}
              >
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Actual</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00037}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00037: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00040}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00040: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{ padding: 0, alignContent: "center" }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00043}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00043: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{
                padding: 0,
                borderBottom: "1px solid black",
                height: "33.33%",
              }}
            >
              {/* //4-4 na 3 part krya */}
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                  padding: 0,
                  height: "25%",
                }}
              >
                {" "}
                {/*Pchhi ani andar row wise bhag krya etle flexDirection=column  */}
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>&nbsp;</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>S%</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>P%</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{ padding: 0, alignContent: "center" }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Ni%</Center>
                  </Text>
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  height: "25%",
                }}
              >
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Min.</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00044}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00044: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00047}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00047: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{ padding: 0, alignContent: "center" }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00050}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00050: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  height: "25%",
                }}
              >
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Max.</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00045}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00045: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00048}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00048: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{ padding: 0, alignContent: "center" }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00051}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00051: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  height: "25%",
                }}
              >
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Actual</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00046}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00046: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00049}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00049: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{ padding: 0, alignContent: "center" }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00052}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00052: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ padding: 0, height: "33.33%" }}>
              {/* //4-4 na 3 part krya */}
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                  padding: 0,
                  height: "25%",
                }}
              >
                {" "}
                {/*Pchhi ani andar row wise bhag krya etle flexDirection=column  */}
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>&nbsp;</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Cr%</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Mo%</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{ padding: 0, alignContent: "center" }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Cu%</Center>
                  </Text>
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  height: "25%",
                }}
              >
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Min.</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00053}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00053: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00056}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00056: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{ padding: 0, alignContent: "center" }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00059}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00059: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  height: "25%",
                }}
              >
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Max.</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00054}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00054: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00057}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00057: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{ padding: 0, alignContent: "center" }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00060}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00060: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  height: "25%",
                }}
              >
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "12px" }}>
                    <Center>Actual</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00055}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00055: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{
                    padding: 0,
                    borderRight: "1px solid black",
                    alignContent: "center",
                  }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00058}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00058: e });
                    }}
                  />
                </Grid.Col>
                <Grid.Col
                  span={3}
                  style={{ padding: 0, alignContent: "center" }}
                >
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={UMEData?.UTC00061}
                    setEdit={(e) => {
                      setUMEData({ ...UMEData, UTC00061: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text
            style={{
              textDecoration: "underline",
              fontSize: "14px",
              fontWeight: "bold",
            }}
            mt={"md"}
            mb={"sm"}
          >
            <Center>Mechanical Properties</Center>
          </Text>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{ border: "1px solid black", padding: 0, height: "40%" }}
        >
          <Grid.Col
            span={12}
            style={{ display: "flex", flexDirection: "row", padding: 0 }}
          >
            <Grid.Col
              span={3}
              style={{
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                Characteristics
              </Text>
            </Grid.Col>
            <Grid.Col
              span={3}
              style={{
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                Test Method
              </Text>
            </Grid.Col>
            <Grid.Col
              span={3}
              style={{
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                Requirement
              </Text>
            </Grid.Col>
            <Grid.Col span={3} style={{ padding: 0 }}>
              <Grid.Col
                span={12}
                style={{ borderBottom: "1px solid black", padding: 0 }}
              >
                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                  <Center>Result</Center>
                </Text>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col
                  span={6}
                  style={{ borderRight: "1px solid black", padding: 0 }}
                >
                  <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                    <Center>Acc.</Center>
                  </Text>
                </Grid.Col>
                <Grid.Col span={6} style={{ padding: 0 }}>
                  <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                    <Center>Reg.</Center>
                  </Text>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            borderBottom: "1px solid black",
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
            padding: 0,
            height: "15%",
          }}
        >
          <Grid.Col
            span={12}
            style={{ display: "flex", flexDirection: "row", padding: 0 }}
          >
            <Grid.Col
              span={3}
              style={{
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: "12px" }}>
                Tensile Strength N/mm<sup>2</sup>
              </Text>
            </Grid.Col>
            <Grid.Col span={3} style={{ borderRight: "1px solid black" }}>
              <PLDataGrid
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                height="29px"
                width={"500px"}
                data={UMEDGData?.P_UQCSTTTMDT?.jData}
                TmData={UMEDGData?.P_UQCSTTTM}
                value={UMEData?.UTC00024}
                setEdit={(e) => {
                  setUMEData({ ...UMEData, UTC00024: e?.FIELD01 });
                }}
              />
            </Grid.Col>
            <Grid.Col span={3} style={{ borderRight: "1px solid black" }}>
              <PLDataGrid
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                height="29px"
                width={"500px"}
                data={UMEDGData?.P_UQCSTTRQDT?.jData}
                TmData={UMEDGData?.P_UQCSTTRQ}
                value={UMEData?.UTC00022}
                setEdit={(e) => {
                  setUMEData({ ...UMEData, UTC00022: e?.FIELD01 });
                }}
              />
            </Grid.Col>
            <Grid.Col
              span={3}
              style={{ padding: 0, display: "flex", flexDirection: "row" }}
            >
              <Grid.Col
                span={6}
                style={{ padding: 0, borderRight: "1px solid black" }}
              >
                <PLNumberBox
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                  decimalSeparator={true}
                  value={UMEData?.UTC00025}
                  setEdit={(e) => {
                    setUMEData({ ...UMEData, UTC00025: e });
                  }}
                />
              </Grid.Col>
              <Grid.Col span={6} style={{ padding: 0 }}>
                <PLNumberBox
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                  decimalSeparator={true}
                  value={UMEData?.UTC00026}
                  setEdit={(e) => {
                    setUMEData({ ...UMEData, UTC00026: e });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            borderBottom: "1px solid black",
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
            padding: 0,
            height: "15%",
          }}
        >
          <Grid.Col
            span={12}
            style={{ display: "flex", flexDirection: "row", padding: 0 }}
          >
            <Grid.Col
              span={3}
              style={{
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: "12px" }}>
                Yeild Strength N/mm<sup>2</sup>
              </Text>
            </Grid.Col>
            <Grid.Col span={3} style={{ borderRight: "1px solid black" }}>
              <PLDataGrid
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                height="29px"
                width={"500px"}
                data={UMEDGData?.P_UQCSTYTMDT?.jData}
                TmData={UMEDGData?.P_UQCSTYTM}
                value={UMEData?.UTC00027}
                setEdit={(e) => {
                  setUMEData({ ...UMEData, UTC00027: e?.FIELD01 });
                }}
              />
            </Grid.Col>
            <Grid.Col span={3} style={{ borderRight: "1px solid black" }}>
              <PLDataGrid
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                height="29px"
                width={"500px"}
                data={UMEDGData?.P_UQCSTYRQDT?.jData}
                TmData={UMEDGData?.P_UQCSTYRQ}
                value={UMEData?.UTC00028}
                setEdit={(e) => {
                  setUMEData({ ...UMEData, UTC00028: e?.FIELD01 });
                }}
              />
            </Grid.Col>
            <Grid.Col
              span={3}
              style={{ padding: 0, display: "flex", flexDirection: "row" }}
            >
              <Grid.Col
                span={6}
                style={{ padding: 0, borderRight: "1px solid black" }}
              >
                <PLNumberBox
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                  decimalSeparator={true}
                  value={UMEData?.UTC00029}
                  setEdit={(e) => {
                    setUMEData({ ...UMEData, UTC00029: e });
                  }}
                />
              </Grid.Col>
              <Grid.Col span={6} style={{ padding: 0 }}>
                <PLNumberBox
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                  decimalSeparator={true}
                  value={UMEData?.UTC00030}
                  setEdit={(e) => {
                    setUMEData({ ...UMEData, UTC00030: e });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            borderBottom: "1px solid black",
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
            padding: 0,
            height: "15%",
          }}
        >
          <Grid.Col
            span={12}
            style={{ display: "flex", flexDirection: "row", padding: 0 }}
          >
            <Grid.Col
              span={3}
              style={{
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: "12px" }}>Elongation (mm)</Text>
            </Grid.Col>
            <Grid.Col span={3} style={{ borderRight: "1px solid black" }}>
              <PLDataGrid
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                height="29px"
                width={"500px"}
                data={UMEDGData?.P_UQCSTETMDT?.jData}
                TmData={UMEDGData?.P_UQCSTETM}
                value={UMEData?.UTC00031}
                setEdit={(e) => {
                  setUMEData({ ...UMEData, UTC00031: e?.FIELD01 });
                }}
              />
            </Grid.Col>
            <Grid.Col span={3} style={{ borderRight: "1px solid black" }}>
              <PLDataGrid
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                height="29px"
                width={"500px"}
                data={UMEDGData?.P_UQCSTERQDT?.jData}
                TmData={UMEDGData?.P_UQCSTERQ}
                value={UMEData?.UTC00032}
                setEdit={(e) => {
                  setUMEData({ ...UMEData, UTC00032: e?.FIELD01 });
                }}
              />
            </Grid.Col>
            <Grid.Col
              span={3}
              style={{ padding: 0, display: "flex", flexDirection: "row" }}
            >
              <Grid.Col
                span={6}
                style={{ padding: 0, borderRight: "1px solid black" }}
              >
                <PLNumberBox
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                  decimalSeparator={true}
                  value={UMEData?.UTC00033}
                  setEdit={(e) => {
                    setUMEData({ ...UMEData, UTC00033: e });
                  }}
                />
              </Grid.Col>
              <Grid.Col span={6} style={{ padding: 0 }}>
                <PLNumberBox
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                  decimalSeparator={true}
                  value={UMEData?.UTC00034}
                  setEdit={(e) => {
                    setUMEData({ ...UMEData, UTC00034: e });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            borderBottom: "1px solid black",
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
            padding: 0,
            height: "15%",
          }}
        >
          <Grid.Col
            span={12}
            style={{ display: "flex", flexDirection: "row", padding: 0 }}
          >
            <Grid.Col
              span={3}
              style={{
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: "12px" }}>Proof Load (N)</Text>
            </Grid.Col>
            <Grid.Col span={3} style={{ borderRight: "1px solid black" }}>
              <PLDataGrid
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                height="29px"
                width={"500px"}
                data={UMEDGData?.P_UQCSTPTMDT?.jData}
                TmData={UMEDGData?.P_UQCSTPTM}
                value={UMEData?.UTC00066}
                setEdit={(e) => {
                  setUMEData({ ...UMEData, UTC00066: e?.FIELD01 });
                }}
              />
            </Grid.Col>
            <Grid.Col span={3} style={{ borderRight: "1px solid black" }}>
              <PLDataGrid
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                height="29px"
                width={"500px"}
                data={UMEDGData?.P_UQCSTPRQDT?.jData}
                TmData={UMEDGData?.P_UQCSTPRQ}
                value={UMEData?.UTC00067}
                setEdit={(e) => {
                  setUMEData({ ...UMEData, UTC00067: e?.FIELD01 });
                }}
              />
            </Grid.Col>
            <Grid.Col
              span={3}
              style={{ padding: 0, display: "flex", flexDirection: "row" }}
            >
              <Grid.Col
                span={6}
                style={{ padding: 0, borderRight: "1px solid black" }}
              >
                <PLNumberBox
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                  decimalSeparator={true}
                  value={UMEData?.UTC00068}
                  setEdit={(e) => {
                    setUMEData({ ...UMEData, UTC00068: e });
                  }}
                />
              </Grid.Col>
              <Grid.Col span={6} style={{ padding: 0 }}>
                <PLNumberBox
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                  decimalSeparator={true}
                  value={UMEData?.UTC00069}
                  setEdit={(e) => {
                    setUMEData({ ...UMEData, UTC00069: e });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>

        <Grid.Col span={12}>
          <Center>
            <Button
              size="sm"
              mt={"md"}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              onClick={handleSubmit}
            >
              Ok
            </Button>
          </Center>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default TestCertificateForm;
