import { Button, Grid, Group, Text } from '@mantine/core'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import PLComboBox from '../../PlusComponent/PLComboBox'
import PLDateBox from '../../PlusComponent/PLDateBox'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

export default function CBUserField(props) {

    const { T41, setT41, OBJ, UFT41H, DrawerOBJ, setDrawerOBJ,setFocus} = props;
    // const TransactionData = useSelector(store => store?.Transaction?.TransactionData);
    // const [UFT41H,setUFT41H] = useState()

    // useEffect(() => {
    //     console.log("TransactionData.UFT41H",TransactionData?.UFT41H);
    //   setUFT41H(TransactionData?.UFT41H)  
    // },[TransactionData])

    const seprate = (e) => {
        var paraArr = e.split('~C~');
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr
    }

    const saveBtn = () => {
        setDrawerOBJ({ ...DrawerOBJ, open: false })
        setFocus()
    }

    // console.log("UFT41H",UFT41H);


    return (
        <>
            {
                UFT41H && UFT41H.length > 0 ? UFT41H?.map((item, index) => {
                    // console.log("item",item);
                    return <Grid gutter={4}>
                        {
                            item?.S13F14 === 'PLPOPUP' &&
                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLDataGrid autoFocus={index = 0 ? true : false} height={"28px"} position=""
                                        name={item?.S13F02}
                                        value={T41?.[item.S13F02]}
                                        p1={seprate(item?.S13F09)[1]}
                                        valexpr="FIELD01"
                                        dispexpr={OBJ.page === "04830462" ? "USALES_E" : "FIELD02"}
                                        setEdit={(e) => {
                                            setT41({ ...T41, [item.S13F02]: e.FIELD01 })
                                            // setState((prevState) => ({
                                            //     ...prevState,
                                            //     T41: {
                                            //         ...prevState.T41,
                                            //         [item.S13F02]: e.FIELD01
                                            //     }
                                            // }))
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'TEXTBOX' &&
                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox position=""
                                        name={item?.S13F02}
                                        value={T41?.[item.S13F02]}
                                        setEdit={(e) => {
                                            setT41({ ...T41, [item.S13F02]: e.target.value })
                                            // setState((prevState) => ({
                                            //     ...prevState,
                                            //     T41: {
                                            //         ...prevState.T41,
                                            //         [item.S13F02]: e.target.value
                                            //     }
                                            // }))
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                        {
                            item?.S13F14 === 'NUMBOX' &&
                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLNumberBox
                                        name={item?.S13F02}
                                        value={T41?.[item.S13F02]}
                                        setEdit={(e) => {
                                            setT41({ ...T41, [item.S13F02]: e })
                                            // setState((prevState) => ({
                                            //     ...prevState,
                                            //     T41: {
                                            //         ...prevState.T41,
                                            //         [item.S13F02]: e
                                            //     }
                                            // }))
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                        {
                            item?.S13F14 === 'COMBOBOX' &&
                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLComboBox
                                        name={item?.S13F02}
                                        value={T41?.[item.S13F02]}
                                        copno={'val1'}
                                        dispexpr="DisplayMember"
                                        valexpr="ValueMember"
                                        setEdit={(e) => {
                                            // console.log(e);
                                            setT41({ ...T41, [item.S13F02]: e })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                        {
                            item?.S13F14 === 'DATEPICKER' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLDateBox
                                        setEdit={(e) => {
                                            // console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'TIMEPICKER' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLDateBox
                                        setEdit={(e) => {
                                            // console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                        {
                            item?.S13F14 === 'MEMOBOX' && item?.S13F21 === 'N' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox
                                        name={item?.S13F02}
                                        value={T41?.[item.S13F02]}
                                        setEdit={(e) => {
                                            // console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'MEMOBOX' && item?.S13F21 === 'H' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox
                                        name={item?.S13F02}
                                        value={T41?.[item.S13F02]}
                                        setEdit={(e) => {
                                            // console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'FILEUPLOAD' && item?.S13F21 === 'I' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox
                                        name={item?.S13F02}
                                        value={T41?.[item.S13F02]}
                                        setEdit={(e) => {
                                            // console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'FILEUPLOAD' && item?.S13F21 === 'A' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox
                                        setEdit={(e) => {
                                            // console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                    </Grid>
                }) : []
            }
            <Group spacing={'xs'} position="center" display={'flex'} mt={"10px"}>
                <Button
                    size='compact-sm' style={{ width: 100 }}
                    onClick={saveBtn}
                    onKeyDown={(e) => {
                        if (e.key == "Tab" || e.key == "Enter") {
                            saveBtn()
                        }
                    }}
                >
                    Ok
                </Button>
            </Group>
        </>

    )

}
