import {
  ActionIcon,
  Alert,
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  Loader,
  Modal,
  Paper,
  Skeleton,
  Spoiler,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLDateBox from "../../PlusComponent/PLDateBox";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { BatchLogAddRemoveFun, BillFormChallanPartyAc, GetNullTransactionData, GetUserFieldObj } from "../../utils/TransactionSlices/Transaction";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { TransactionApi } from "../../utils/TransactionSlices/Transaction";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import PLTextBox from "../../PlusComponent/PLTextBox";
import {
  IconAbc,
  IconAlertCircle,
  IconAlertCircleFilled,
  IconAlertTriangle,
  IconEdit,
  IconTrash,
} from "@tabler/icons-react";
import { ModalDelete, ModalFunction } from "../../utils/slices/ModalSlice";
import { modals } from "@mantine/modals";
import Store from "../../utils/store";
import {
  GetBillFromChallanData,
  GetCasePartyRequired,
  GetExpenseData,
  GetExpenseDataNull,
  GetInvTypeDropDown,
  GetMstinfoData,
  GetPLData,
  GetPendingOrderData,
  GetPendingOrderDataNull,
  GetPendingQuotationData,
  GetPendingQuotationDataNull,
  GetSingleOrder,
  GetSingleOrderRowNull,
  GetSingleSelectedOrder,
  GetSingleSelectedOrderRowNull,
  getProductName,
} from "../../utils/TransactionSlices/TSalesSlices";
import SIBatchWiseDetail from "./SIBatchWiseDetail";
import SIBillToBillTable from "./SIBillToBillTable";
import SITableUserField from "./SITableUserField";
import SICasePartyDetail from "./SICasePartyDetail";
import SIExpenseEntry from "./SIExpenseEntry";
import SITablePriceList from "./SITablePriceList";
import PLBillToBill from "../../PlusComponent/PLBillToBill";
import { notifications } from "@mantine/notifications";
import GlobalClass from "../../utils/GlobalClass";
import gensetting from "../../utils/gensetting";
import { IconBug } from "@tabler/icons-react";
import {
  DataSaveLoading,
  GetTableActionObj,
} from "../../utils/slices/DataSaveLoadingSlice";
import { GetAccountList, GetTMFormat } from "../../utils/slices/AccountListSlice";
import PLNarration from "../../PlusComponent/PLNarration";
import ExpenseEntryForm from "./ExpenseEntryForm";
import PLTextarea from "../../PlusComponent/PLTextarea";
import SIPendingChallanTable from "./SIPendingChallanTable";
import SITableBatchUserField from "./SITableBatchUserField";
import AssignItemDefaultFun, { AssignItemDefault, CalCFIPromise, MidCalc, RecalcFun, RefMe, RefMeFun, RetTaxAcc } from "./AssignItemDefaultFun";
import PendingQuotationTable from "./PendingQuotationTable";
import PendingOrderTable from "./PendingOrderTable";
import { EquationFunction } from "./EquationsFunctions";
import PLUserFieldBtn from "../../PlusComponent/PLUserFieldBtn";
import { Qty_CheckStk } from "./QtyCheckStock";
const TransactionSalesForm = (props) => {
  const { obj } = props;

  console.log("TransactionSalesForm props==>", props);

  //console.log("TransactionSalesForm obj=>", obj);

  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseBtn: null
  }

  const [DrawerObj, setDrawerObj] = useState(dra);

  const [FIELD01Value, setFIELD01value] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [GroupCatogaryProduct, setGroupCatogaryProduct] = useState({
    "Group": "",
    "Catogary": "",
    "Product": ""
  })
  const [CashPartyPopUpCondition, setCashPartyPopUpCondition] = useState({
    "PopUp": false,
    "PopUpFlag": false
  })

  const [GSTNoStatus, setGSTNoStatus] = useState({
    "status": false,
    "object": {}
  })

  const [EntryPages, setEntryPages] = useState({
    SaleEntry: ["02210213", "02250213", "02280213", "02340213", "02370213", "02430213", "02460213"],
    PurchaseEntry: ["02530249", "02590249", "02670249", "02700249", "02760249"]
  })

  const [Caption, setCaption] = useState({
    FldOne: "",
    FldTwo: "",
    FldThree: "",
    FldFour: ""
  })

  // useEffect(()=>{
  //     console.log("Sale entry Page=>",EntryPages?.SaleEntry?.includes(obj?.id??obj?.page),"#id=>",obj?.page);
  // },[obj])
  const formRef = useRef(null);
  const TableRef = useRef(null);

  const CategoryRef = useRef(null)
  const PartyAcRef = useRef(null);
  const PartyAc2Ref = useRef(null);
  const DeliveryAtRef = useRef(null);
  const InvTypeRef = useRef(null);
  const BillNoRef = useRef(null);
  const LocationRef = useRef(null)
  const PurcSaleRef = useRef(null)
  const BillOfSupplyRef = useRef(null)

  const ProductNameRef = useRef(null)
  const UserFieldRef = useRef(null)
  const [ExpenseArrayT02, setExpenseArrayT02] = useState([])
  const [ExpenseArrayT41, setExpenseArrayT41] = useState([])

  const [columns, setColumns] = useState([]);
  const [ExpenseRowSum, setExpenseRowSum] = useState(0);
  const [PendingOrderIndex, setPendingOrderIndex] = useState(0);
  const [BatchIssueObj, setBatchIssueObj] = useState(null)
  const [LocationObj, setLocationObj] = useState(null)
  const [receiptIssueObj, setReceiptIssueObj] = useState(null)
  const [selectedTaxAcc, setSelectedTaxAcc] = useState("")
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [PendingChallanStatus, setPendingChallanStatus] = useState(true)
  const [NagativeQtyPopUp, setNagativeQtyPopUp] = useState(false)


  const [T41Obj, setT41Obj] = useState({
    FIELD01: "",
    FIELD02: "20231213",
    FIELD03: 0,
    FIELD04: "",
    FIELD05: "",
    FIELD06: "0",
    FIELD07: "0",
    FIELD08: 0,
    FIELD09: 0,
    FIELD10: "",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "",
    FIELD14: "",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: 0,
    FIELD21: "",
    FIELD22: "",
    FIELD23: "",
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD70: "",
    FIELD71: "",
    FIELD75: "0",
    FIELD99: "",
    FIELD72: "",
    FIELD20: 0,
    FIELD80: "",
    FIELD51: 0,
    FIELD34: "",
    FIELD35: "",
    FIELD36: "",
    FIELD37: "",
    FIELD38: "",
    FLDJIO: "",
    FIELD39: "",
    FIELD40: "",
    FIELD41: "",
    FIELD42: "",
    FIELD43: "",
    FIELD44: "",
    FIELD45: "",
    FIELD46: "",
    FIELD47: "",
    FIELD48: "",
    FIELD49: "",
    FIELD50: "",
    FIELD52: "",
    FIELD53: "",
    T41PVT: "",
    FIELD54: "",
    T41VNO: "",
    CMID: 0,
    ADDID: 0,
    PRESCPATH: "",
    OSTATUS: "",
    DTYPE: "",
    DELURL: "",
    DELMBNO: "",
    FIELD55: "",
    FIELD56: "",
    T41POS: "",
    UBILTO: "",
    UGSTR: "",
    UOD: "",
    USALM: "",
    UTR: "",
    UDISP: "",
    ULEN: "",
    UODD: "",
    ULRD: "",
    UPAYT: "",
    USAL: 0,
    UPR: "",
    UNAME: "",
    UOP: "",
    UTRA: "",
    UODP: "",
    UREMA: "",
    UEMAIL: "",
    URA: "",
    FLDUNQ: 0,
    FLDAED: "",
    FLDBRC: "",
    PA000001: "",
    PA000002: "",
    PD000001: 0,
    PD000002: 0,
    UUASDF05: "",
    UUASD09: "",
    UUBAER: "",
    UUUBAER: "",
    EA00000001: "",
    EA00000002: "",
    EA00000003: "",
    EA00000004: "",
    EA00000005: "",
    EA00000006: "",
    EA00000007: "",
    EA00000008: "",
    EA00000009: "",
    EA00000010: "",
    EA00000011: "",
    EA00000012: "",
    EA00000013: "",
    EA00000014: "",
    EA00000015: "",
    ED00000001: 0,
    ED00000002: 0,
    ED00000003: 0,
    ED00000004: 0,
    ED00000005: 0,
    ED00000006: 0,
    ED00000007: 0,
    ED00000008: 0,
    ED00000009: 0,
    ED00000010: 0,
    ED00000011: 0,
    ED00000012: 0,
    ED00000013: 0,
    ED00000014: 0,
    ED00000015: 0,
    EP00000001: 0,
    EP00000002: 0,
    EP00000003: 0,
    EP00000004: 0,
    EP00000005: 0,
    EP00000006: 0,
    EP00000007: 0,
    EP00000008: 0,
    EP00000009: 0,
    EP00000010: 0,
    EP00000011: 0,
    EP00000012: 0,
    EP00000013: 0,
    EP00000014: 0,
    EP00000015: 0,
    UUASWER: "",
    UUUASWER: "",
    DSDATE: "2023-04-01T00:00:00+03:00",
    DEDATE: "2024-03-31T00:00:00+02:00",
    CACTION: "A",
    lInvType: true,
    lMTaxEnt: true,
    T92F02: "",
    CFRMTITLE: "",
    BTOBEFF: "",
    M01BAL: "0",
    M01GRP: "",
    M01OPPGRP: "",
    M01CT: "",
    F07ACC: "",
    LBLF08AMT: "0.00   ",
    LBLF07AMT: "0.00   ",
    LBLF08SYMB: null,
    LBLF07SYMB: null,
    T92F00: null,
    FIELD04NM: "",
    FIELD05NM: "",
    FIELD39NM: ""
  });

  const [FooterExpenseObj, setFooterExpenseObj] = useState({});
  const [FooterExpenseFlag, setFooterExpenseFlag] = useState(false);
  const [HeaderExpenseFlag, setHeaderExpenseFlag] = useState(false);



  const [T02Data, setT02Data] = useState([]);

  const [T02Obj, setT02Obj] = useState({
    GRPNM: "",
    CATNM: "",
    F03NM: "",
    F20NM: "",
    FIELD01: "",
    FIELD02: "",
    FIELD03: "",
    FIELD04: "",
    FIELD05: "",
    FIELD06: 0.0,
    FIELD07: 0.0,
    FIELD08: 0.0,
    FIELD09: 0.0,
    FIELD10: 0.0,
    FIELD11: "",
    FIELD12: "",
    FIELD56: "",
    FIELD13: "",
    FIELD14: "",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: "",
    FIELD19: "",
    FIELD20: "",
    FIELD21: 0.0,
    FIELD22: 0.0,
    FIELD23: 0.0,
    FIELD24: "",
    FIELD25: 0.0,
    FIELD26: "",
    FIELD27: "",
    FIELD28: false,
    FIELD29: 0.0,
    FIELD30: "",
    FIELD31: 0.0,
    FIELD32: 0.0,
    FIELD33: 0.0,
    FIELD41: "",
    FIELD50: 0.0,
    FIELD51: "",
    FIELD52: 0.0,
    FIELD71: "",
    FIELD81: 0.0,
    FIELD82: "",
    FIELD83: "",
    FIELD84: "",
    FIELD85: "",
    FIELD86: "",
    FIELD61: 0.0,
    FIELD62: 0.0,
    FIELD63: "",
    FIELD64: 0.0,
    FIELD65: "",
    FIELD66: 0.0,
    FIELD67: 0.0,
    FIELD72: "",
    FIELD68: "",
    FIELD69: "",
    FIELD53: "",
    FIELD54: 0.0,
    FIELD55: 0.0,
    FIELD091: 1.0,
    FIELD58: "",
    FIELD57: "",
    FIELD60: 0.0,
    T41PVT: "",
    FIELD87: 0.0,
    FIELD88: "",
    UGRO: 0.0,
    URATE: 0.0,
    UMUR: 0.0,
    UPE: 0.0,
    UAP: 0.0,
    URE: "",
    UISSUERA: 0.0,
    FLDUNQ: 0,
    FLDAED: "",
    FLDBRC: "",
    UUDIS: "",
    UUUDIS: "",
    UUDISAAA: "",
    UUGROAAA: "",
    UUGR01: "",
    UUGRO: "",
    IA00000001: "",
    IA00000002: "",
    IA00000003: "",
    IA00000004: "",
    IA00000005: "",
    IA00000006: "",
    IA00000007: "",
    IA00000008: "",
    IA00000009: "",
    IA00000010: "",
    IA00000011: "",
    IA00000012: "",
    IA00000013: "",
    IA00000014: "",
    IA00000015: "",
    IA00000016: "",
    IA00000017: "",
    IA00000018: "",
    IA00000019: "",
    IA00000020: "",
    IA00000021: "",
    IA00000022: "",
    IA00000023: "",
    ID00000001: 0.0,
    ID00000002: 0.0,
    ID00000003: 0.0,
    ID00000004: 0.0,
    ID00000005: 0.0,
    ID00000006: 0.0,
    ID00000007: 0.0,
    ID00000008: 0.0,
    ID00000009: 0.0,
    ID00000010: 0.0,
    ID00000011: 0.0,
    ID00000012: 0.0,
    ID00000013: 0.0,
    ID00000014: 0.0,
    ID00000015: 0.0,
    ID00000016: 0.0,
    ID00000017: 0.0,
    ID00000018: 0.0,
    ID00000019: 0.0,
    ID00000020: 0.0,
    ID00000021: 0.0,
    ID00000022: 0.0,
    ID00000023: 0.0,
    IP00000001: 0.0,
    IP00000002: 0.0,
    IP00000003: 0.0,
    IP00000004: 0.0,
    IP00000005: 0.0,
    IP00000006: 0.0,
    IP00000007: 0.0,
    IP00000008: 0.0,
    IP00000009: 0.0,
    IP00000010: 0.0,
    IP00000011: 0.0,
    IP00000012: 0.0,
    IP00000013: 0.0,
    IP00000014: 0.0,
    IP00000015: 0.0,
    IP00000016: 0.0,
    IP00000017: 0.0,
    IP00000018: 0.0,
    IP00000019: 0.0,
    IP00000020: 0.0,
    IP00000021: 0.0,
    IP00000022: 0.0,
    IP00000023: 0.0,
    IT00000001: "",
    IT00000002: "",
    IT00000003: "",
    IT00000004: "",
    IT00000005: "",
    IT00000006: "",
    IT00000007: "",
    IT00000008: "",
    IT00000009: "",
    IT00000010: "",
    IT00000011: "",
    IT00000012: "",
    IT00000013: "",
    IT00000014: "",
    IT00000015: "",
    IT00000016: "",
    IT00000017: "",
    IT00000018: "",
    IT00000019: "",
    IT00000020: "",
    IT00000021: "",
    IT00000022: "",
    IT00000023: "",
    UDIS: 0.0,
    USERFLD: "",
    USERFLDB: "",
    SRDETAIL: "",
    EXPDUMMY: " ",
    BARCODE: "",
    OLDPRD: "",
    OLDQTY: 0.0,
    OQTY2: 0.0,
    OLDRATE: 0.0,
    LADDNEW: "Y",
    PRICELIST: " ",
    _BATSTOCK: " ",
    _LOCWISE: " ",
    LDUALSTK: " ",
    SRNOSTK: " ",
    MRPSTK: " ",
    RORI: " ",
    ORDDUMMY: " ",
    SRIDUMMY: " ",
    SRRDUMMY: " ",
    SRIDUMMYF: " ",
    SRRDUMMYF: " ",
    SRISSCD: "",
    OLDSRNO: "",
    SCNOCL: "",
    SCNOCL1: "",
    _EXPTOTALAMT: 0.0,
  });

  const [NarrationData, setNarrationData] = useState([]);
  const [SelectedNarrationData, setSelectedNarrationData] = useState([]);

  const [challanData, setChallanData] = useState([])
  const [selectedChallan, setSelectedChallan] = useState([])
  const [selectedQuotation, setSelectedQuotation] = useState([])
  const [selectedOrder, setSelectedOrder] = useState([])
  const [cRorI, setcRorI] = useState(null)



  const [priceListParemeter, setPriceListParemeter] = useState({
    priceListCode: "",
    productCode: "",
    accountCode: "",
  });
  const [TableExpense, setTableExpense] = useState([]);
  const [tableExpenseVal, setTableExpenseVal] = useState(0);

  const [selectedProductObj, setSelectedProductObj] = useState(null);

  // let rowSum = T02Data.reduce((a, b) => (a = a + b?.FIELD08), 0);

  // const T41F07RowSum = T02Data.reduce((a, b) => {
  //   if (b?.FIELD05 === "C") {
  //     a = a + (b?.FIELD08 || 0);
  //   } else if (b?.FIELD05 === "D") {
  //     a = a - (b?.FIELD08 || 0);
  //   }
  //   return a;
  // }, 0)



  // const [T02Obj, setT02Obj] = useState(null)
  const [M01PRow, setM01PRow] = useState(null);
  const [DefaultBillDate, setDefaultBillDate] = useState(null);
  const [T05DataArray, setT05DataArray] = useState([]);
  const [DtExpFooterField, setDtExpFooterField] = useState([]);
  const [invTypeInput, setInvTypeInput] = useState("");
  const [expenseSaveBtn, setExpenseSaveBtn] = useState(false)
  const [userFieldSpoiler, setUserFieldSpoiler] = useState(false)
  const [tableEditingRow, setTableEditingRow] = useState(null)
  const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
    BillNoVal: "",
    TextInput: false,
    DataGridInput: true
  })

  const [BillNoStatus, setBillNoStatus] = useState(false)

  const [T02ObjSaveFlag, setT02ObjSaveFlag] = useState(false)

  const [InvAPIStatus, setInvAPIStatus] = useState(false)

  const [createSumArray, setCreateSumArray] = useState([])

  const [pagecCode, setPagecCode] = useState("");
  const [ExpenseArray, setExpenseArray] = useState({
    dtExp: null,
    dtExpDef: null,
    dtExp_I: null,
    dtExpDef_I: null,
    M01SP: null,
    M01SPDT: null
  })

  // Expense parameter
  const dtM14DT = Store.getState().Transaction?.TransactionData?.dtM14;
  const dtM51DT = Store.getState().Transaction?.TransactionData?.dtM51;
  const dtM52DT = Store.getState().Transaction?.TransactionData?.dtM52;
  const dtM46DT = Store.getState().Transaction?.TransactionData?.dtM46;
  // Expense parameter

  // let ExpenseObj = { OS11, OSV, oYear, M01SP, M01SPDT, dtM14DT, dtM51DT, dtM52DT, dtM46DT, dtExp, dtExpDef, dtExp_I, dtExpDef_I }

  const TransactionSalesFormData = useSelector(
    (state) => state.Transaction?.TransactionData
  );
  const CasePartyRequired = useSelector(
    (state) => state?.TSalesSlices?.CasePartyRequired
  );
  const InvTypeDropDown = useSelector(
    (state) => state?.TSalesSlices?.InvTypeDropDown
  );

  // //console.log("CasePartyRequired==>", CasePartyRequired);

  //for oYear Obj Get
  const GetGeneral = useSelector((state) => state?.Language?.General);
  //console.log("GetGeneral oYear object show=>", GetGeneral);

  const { oYear } = useSelector((state) => state?.Language?.General);
  // console.log("oYear=>", oYear);

  const { isLoading, hasError, ErrorMsg } = useSelector(
    (state) => state.Transaction
  );

  // const { COP8, COP75, M01D, M01DDT, M01P, M01PDT, M45I, M45IDT, OS11, OSV, PORT, PORTDT, T02, T02ENT, T05, T05ENT, T41, T92, T92DT, UFT41H, T02UF, dtExp, dtExp_I } = useSelector((state) => state.Transaction?.TransactionData)

  const GetYearAndDate = useSelector((state) => state?.UserList?.YearAndDateSend)

  const BillFormChallanPartyAcData = useSelector((state) => state.Transaction?.BillFormChallanPartyAcData)

  const COP8 = useSelector((state) => state.Transaction?.TransactionData?.COP8);
  const COP75 = useSelector(
    (state) => state.Transaction?.TransactionData?.COP75
  );
  const M01PRT = useSelector((state) => state.Transaction?.TransactionData?.M01PRT);

  const M01D = useSelector((state) => state.Transaction?.TransactionData?.M01D);
  const M01DDT = useSelector(
    (state) => state.Transaction?.TransactionData?.M01DDT
  );
  // const M01SP = useSelector((state) => state.Transaction?.TransactionData?.M01SP);
  // const M01SPDT = useSelector((state) => state.Transaction?.TransactionData?.M01SPDT);

  const M01P = useSelector((state) => state.Transaction?.TransactionData?.M01P);
  const M01PDT = useSelector(
    (state) => state.Transaction?.TransactionData?.M01PDT
  );
  const M45I = useSelector((state) => state.Transaction?.TransactionData?.M45I);
  const M45IDT = useSelector(
    (state) => state.Transaction?.TransactionData?.M45IDT
  );
  const OS11 = useSelector((state) => state.Transaction?.TransactionData?.OS11);
  const OSV = useSelector((state) => state.Transaction?.TransactionData?.OSV);
  const PORT = useSelector((state) => state.Transaction?.TransactionData?.PORT);
  const PORTDT = useSelector(
    (state) => state.Transaction?.TransactionData?.PORTDT
  );
  const T02 = useSelector((state) => state.Transaction?.TransactionData?.T02);
  const T02ENT = useSelector(
    (state) => state.Transaction?.TransactionData?.T02ENT
  );
  const T05 = useSelector((state) => state.Transaction?.TransactionData?.T05);
  const T05ENT = useSelector(
    (state) => state.Transaction?.TransactionData?.T05ENT
  );
  const T41 = useSelector((state) => state.Transaction?.TransactionData?.T41);
  const T92 = useSelector((state) => state.Transaction?.TransactionData?.T92);
  const T92DT = useSelector(
    (state) => state.Transaction?.TransactionData?.T92DT
  );
  const UFT41H = useSelector(
    (state) => state.Transaction?.TransactionData?.UFT41H
  );

  const dtUFT11 = useSelector(
    (state) => state.Transaction?.TransactionData?.dtUFT11
  );

  const T02UF = useSelector(
    (state) => state.Transaction?.TransactionData?.T02UF
  );

  const UFT05H = useSelector(
    (state) => state.Transaction?.TransactionData?.UFT05H
  );

  const dtExp = useSelector(
    (state) => state.Transaction?.TransactionData?.dtExp
  );
  const dtExpDef = useSelector(
    (state) => state.Transaction?.TransactionData?.dtExpDef
  );
  const dtExp_I = useSelector(
    (state) => state.Transaction?.TransactionData?.dtExp_I
  );
  const dtExpDef_I = useSelector(
    (state) => state.Transaction?.TransactionData?.dtExpDef_I
  );
  const PLDATA = useSelector(
    (state) => state.Transaction?.TransactionData?.PLDATA
  );
  const lEditVou = useSelector(
    (state) => state.Transaction?.TransactionData?.lEditVou
  );
  const lEditMsg = useSelector(
    (state) => state.Transaction?.TransactionData?.lEditMsg
  );
  const dtM14 = useSelector(
    (state) => state.Transaction?.TransactionData?.dtM14
  );

  const TransactionData = useSelector(
    (state) => state.Transaction?.TransactionData
  );


  const ExpenseData = useSelector((state) => state?.TSalesSlices?.ExpenseData)
  const { isLoadingExpenseData, hasErrorExpenseData, ErrorMsgExpenseData } = useSelector((state) => state?.TSalesSlices)
  const PendingChallanData = useSelector((state) => state?.TSalesSlices?.PendingChallanData)
  const PendingQuotationData = useSelector((state) => state?.TSalesSlices?.PendingQuotationData)
  const SingleSelectedOrderRow = useSelector((state) => state?.TSalesSlices?.SingleSelectedOrderRow)

  const PendingOrderData = useSelector((state) => state?.TSalesSlices?.PendingOrderData)
  const SingleOrderRow = useSelector((state) => state?.TSalesSlices?.SingleOrderRow)



  // useEffect(() => {
  //   if (ErrorMsgExpenseData) {
  //     console.log("ErrorMsgExpenseData", ErrorMsgExpenseData);
  //   }
  // }, [ErrorMsgExpenseData])
  // console.log("PendingChallanData=>", PendingChallanData);

  // console.log("ExpenseData Form=>", ExpenseData);

  // console.log("T02ENT",T02ENT);
  // console.log("TransactionData",TransactionData);



  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  // const TableActionObj = useSelector(state => state?.DataSaveLoadingSlice?.TableActionObj)
  const TransactionSalesPLdata = useSelector(
    (state) => state?.TSalesSlices?.TransactionSalesPLdata
  );

  const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)

  const { isUserFieldLoading, UserField } = useSelector(store => store?.Transaction)

  // console.log('alertmsg==>',AlertMsgShow)

  const [fieldDisable, setFieldDisable] = useState({
    lInvType: "",
    lMTaxEnt: "",
    Doc_No: "",
    Doc_Date: "",
    Delivery_at1: "",
    Delivery_at2: "",
    Delivery_at3: "",
    Applicable: "",
    T41_Field45_Render: "",
    CashPartyFormShow: "",
    UserFieldHideShow: "",
    VoucherNumber: ""
  });

  // useEffect(() => {
  //   if (expenseSaveBtn == true) {
  //     console.log("Call Table row Close")
  //     if(tableEditingRow == null){
  //       console.log("Add Product Call")
  //       Promise.resolve(handleCreateUser()).then(() => setExpenseSaveBtn(false))
  //     }else{
  //       console.log("Edit Product Call")
  //       Promise.resolve(handleSaveUser()).then(() => setExpenseSaveBtn(false))
  //     }
  //   }
  // }, [expenseSaveBtn])

  // useEffect(() => {
  //   console.log("userFieldSpoiler========>", userFieldSpoiler);
  // }, [userFieldSpoiler])

  useEffect(() => {
    const formElement = formRef.current;
    console.log("formRef", formRef);

    if (formElement) {
      formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
    }

    return () => {
      if (formElement) {
        formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
      }
    };
  }, [formRef])


  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
      // dispatch(ModalDelete(props.index))
    }
  }, [hasError]);

  useEffect(() => {
    if (hasErrorExpenseData && !isLoadingExpenseData) {
      // console.log("ErrorMsgExpenseData", ErrorMsgExpenseData);
      GlobalClass.Notify("error", "Error: " + ErrorMsgExpenseData.code, ErrorMsgExpenseData.message)
    }
  }, [hasErrorExpenseData])

  const dispatch = useDispatch();
  //dispatchhere
  useEffect(() => {
    if (obj && pagecCode != "") {
      if (obj?.id === "02700249" || obj?.id === "02340213") {
        Promise.resolve(dispatch(
          TransactionApi({
            p0: "A",
            p1: "",
            p2: "",
            page: obj?.id,
            text: "Add",
          })
        )).then(() => dispatch(
          BillFormChallanPartyAc({
            p0: "A",
            p1: "",
            p2: "",
            page: obj?.id,
            text: "Add",
          })
        )).then(() => {
          dispatch(GetAccountList({
            "id": "P_M21",
            "name": "",
            "type": "A",
            "p0": "P_M21",
            "p1": "P_M21",
            "p2": "",
            "p3": ""
          }))
          // Promise.resolve(dispatch(GetAccountList({
          //   "id": "T_M32G",
          //   "name": "",
          //   "type": "A",
          //   "p0": "T_M32G",
          //   "p1": "T_M32G",
          //   "p2": "",
          //   "p3": ""
          // }))).then(() => dispatch(GetTMFormat({
          //   "id": "T_M32G",
          //   "name": "",
          //   "type": "A",
          //   "p0": "T_M32G",
          //   "p1": "T_M32G",
          //   "p2": "",
          //   "p3": ""
          // }))).then(() => dispatch(GetAccountList({
          //   "id": "T_M32C",
          //   "name": "",
          //   "type": "A",
          //   "p0": "T_M32C",
          //   "p1": "T_M32C",
          //   "p2": "",
          //   "p3": ""
          // }))).then(() => dispatch(GetTMFormat({
          //   "id": "T_M32C",
          //   "name": "",
          //   "type": "A",
          //   "p0": "T_M32C",
          //   "p1": "T_M32C",
          //   "p2": "",
          //   "p3": ""
          // })))
        })
      } else {
        Promise.resolve(dispatch(TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 }))).then(() => setInvAPIStatus(true)).then(() => {
          if (obj?.page == "02210213" || obj?.page == "02230213" || obj?.page == "02280213" || obj?.page == "02430213" || obj?.page == "02530249" || obj?.page == "02670249") {
            dispatch(GetAccountList({
              "id": "P_M21",
              "name": "",
              "type": "A",
              "p0": "P_M21",
              "p1": "P_M21",
              "p2": "",
              "p3": ""
            }))
          }
          // Promise.resolve(dispatch(GetAccountList({
          //   "id": "T_M32C",
          //   "name": "",
          //   "type": "A",
          //   "p0": "T_M32C",
          //   "p1": "T_M32C",
          //   "p2": "",
          //   "p3": ""
          // }))).then(() => dispatch(GetTMFormat({
          //   "id": "T_M32C",
          //   "name": "",
          //   "type": "A",
          //   "p0": "T_M32C",
          //   "p1": "T_M32C",
          //   "p2": "",
          //   "p3": ""
          // })))
        })
      }
    }
  }, [obj, pagecCode]);

  // useEffect(() => {
  //   if (BillFormChallanPartyAcData) {
  //     console.log("BillFormChallanPartyAcData =>", BillFormChallanPartyAcData);
  //   }
  // }, [BillFormChallanPartyAcData])

  useEffect(() => {
    if (OS11?.S11F22) {
      let ReceiptOrIssue = ["R", "C"].includes(OS11?.S11F22) ? "C" : "D";
      setcRorI(ReceiptOrIssue)
    }
    if (OS11?.S11F01 && obj?.p0 == "U") {
      dispatch(GetUserFieldObj(OS11?.S11F01))
    }
  }, [OS11, obj])

  // useEffect(() => {
  //   if (UserField) {
  //     console.log("UserField =>", [{
  //       "UserField": UserField
  //     }]);
  //   }
  // }, [UserField])

  useEffect(() => {
    if (InvAPIStatus == true && obj.p0 == "E" && TransactionData?.T41) {
      GetExpenseFunction()
    }
  }, [TransactionData?.T41, obj, InvAPIStatus])

  const GetExpenseFunction = () => {
    // if (obj.p0 == "E") {
    // console.log("pagecCode=>", pagecCode);
    // console.log("TransactionData?.T41=>", T41Obj);
    dispatch(GetInvTypeDropDown(TransactionData?.T41?.FIELD13))
    dispatch(GetExpenseData({ action: "A", code: pagecCode, invCode: TransactionData?.T41?.FIELD13 }))
    setInvAPIStatus(false)
    // }
  }

  useEffect(() => {
    if (isLoading == false && TransactionData && oYear) {
      setFieldDisable({
        ...fieldDisable,
        lInvType: TransactionData?.T41?.lInvType,
        lMTaxEnt: TransactionData?.T41?.lMTaxEnt,
        Doc_No: TransactionData?.OSV?._DOCNODATE,
        Doc_Date: TransactionData?.OSV?._DOCNODATE,
        Delivery_at1: oYear?.OSC?._ECOMDLADD,
        Delivery_at2: oYear?.OSC?._GSTSETUP,
        Delivery_at3: TransactionData?.OS11?.S11F21,
        Applicable: oYear?.OSC?._GR1RTTAX,
        CashPartyFormShow: TransactionData?.OSV?._CASHPARTY,
        UserFieldHideShow: oYear?.OSC?._USERFIELD,
        VoucherNumber: TransactionData?.OSV?._VOUNUMBER
      });
    }
  }, [TransactionData, oYear, isLoading]);
  // console.log("fieldDisable==>",fieldDisable);

  // useEffect(() => {
  //   if (tmList && accList) {
  //     console.log("accList==>", accList["P_M21"])
  //     console.log("tmList==>", tmList["P_M21"])
  //   }
  // }, [tmList, accList])

  // useEffect(() => {
  //   if (ExpenseData) {
  //     Object.keys(T02Obj).map((t) => {
  //       ExpenseData?.dtExp_I?.map((e) => {
  //         if (('IA' + e?.FIELD54 || 'ID' + e?.FIELD54 || 'IP' + e?.FIELD54 || 'IT' + e?.FIELD54) != t) {
  //           setT02Obj({ ...T02Obj, ['IA' + e?.FIELD54]: "", ['ID' + e?.FIELD54]: "", ['IP' + e?.FIELD54]: "", ['IT' + e?.FIELD54]: "" })
  //         }
  //       })
  //     })
  //   }
  // }, [ExpenseData])

  useEffect(() => {
    if (obj?.id === "02700249" || obj?.id === "02340213" && T41Obj?.FIELD02.length > 0 && T41Obj?.FIELD04.length > 0) {
      // console.log("T41Obj?.FIELD02=>", T41Obj?.FIELD02, "T41Obj?.FIELD04=>", T41Obj?.FIELD04);
      dispatch(GetBillFromChallanData({ code: T41Obj?.FIELD04, date: T41Obj?.FIELD02, page: pagecCode }))
    }
  }, [obj?.id, T41Obj?.FIELD02, T41Obj?.FIELD04])

  useEffect(() => {
    if ((obj?.page == "02210213" || obj?.page == "02230213" || obj?.page == "02280213" || obj?.page == "02430213") && (T41Obj?.FIELD04 != "")) {
      // console.log("Pending Quotation dispatch");
      // Promise.resolve(dispatch(GetPendingQuotationData({ code: T41Obj?.FIELD04, date: T41Obj?.FIELD02, page: pagecCode }))).then(() => dispatch(GetAccountList({
      //   "id": "P_M21",
      //   "name": "",
      //   "type": "A",
      //   "p0": "P_M21",
      //   "p1": "P_M21",
      //   "p2": "",
      //   "p3": ""
      // })))
      dispatch(GetPendingQuotationData({ code: T41Obj?.FIELD04, date: T41Obj?.FIELD02, page: pagecCode }))

    }
  }, [obj?.page, T41Obj?.FIELD04])

  useEffect(() => {
    if ((obj?.page == "02210213" || obj?.page == "02280213" || obj?.page == "02530249" || obj?.page == "02670249") && (T41Obj?.FIELD04 != "")) {
      // console.log("Pending Order dispatch");
      // Promise.resolve(dispatch(GetPendingOrderData({ code: T41Obj?.FIELD04, date: T41Obj?.FIELD02, page: pagecCode }))).then(() => dispatch(GetAccountList({
      //   "id": "P_M21",
      //   "name": "",
      //   "type": "A",
      //   "p0": "P_M21",
      //   "p1": "P_M21",
      //   "p2": "",
      //   "p3": ""
      // })))
      dispatch(GetPendingOrderData({ code: T41Obj?.FIELD04, date: T41Obj?.FIELD02, page: pagecCode }))
    }
  }, [obj?.page, T41Obj?.FIELD04])


  // useEffect(() => {
  //   if (PendingOrderData) {
  //     console.log("PendingOrderData =>", PendingOrderData);
  //   }
  // }, [PendingOrderData])


  useEffect(() => {
    if (obj.page == "02210213" || obj.id == "02340213") {
      // sales Entry > sale invoice 1
      setPagecCode("SSSS");
      setCaption({
        FldOne: "Bill Date:",
        FldTwo: "Bill No.:",
        FldThree: "Doc No.:",
        FldFour: "Doc Date:"
      })
    } else if (obj.page == "02230213") {
      // sales Entry > sale invoice 2
      setPagecCode("SSWS");
      setCaption({
        FldOne: "Bill Date:",
        FldTwo: "Bill No.:",
        FldThree: "Doc No.:",
        FldFour: "Doc Date:"
      })
    } else if (obj.page == "02270213") {
      // sales Entry > sale return 1
      setPagecCode("SRWR");
      setCaption({
        FldOne: "Bill Date:",
        FldTwo: "Bill No.:",
        FldThree: "Doc No.:",
        FldFour: "Doc Date:"
      })
    } else if (obj.page == "02250213") {
      // sales Entry > sale return 2
      setPagecCode("SRSR");
      setCaption({
        FldOne: "Bill Date:",
        FldTwo: "Bill No.:",
        FldThree: "Doc No.:",
        FldFour: "Doc Date:"
      })
    } else if (obj.page == "02280213") {
      // sales Entry > sale Challan
      setPagecCode("HSHS");
      setCaption({
        FldOne: "Challan Date:",
        FldTwo: "Challan No.:",
        FldThree: "Doc. No.:",
        FldFour: "Doc Date:"
      })
    } else if (obj.page == "02430213") {
      // sales Entry > sale Order
      setPagecCode("OSOS");
      setCaption({
        FldOne: "Order Date:",
        FldTwo: "Order No.:",
        FldThree: "Doc. No.:",
        FldFour: "Doc Date"
      })
    } else if (obj.page == "02460213") {
      // sales Entry > sale Quotation
      setPagecCode("QTQT");
      setCaption({
        FldOne: "Bill Date:",
        FldTwo: "Quotation No.:",
        FldThree: "Doc No.:",
        FldFour: "Doc Date:"
      })
    } else if (obj.page == "02530249" || obj.id == "02700249") {
      // Purchase Entry > Purchase invoice-2
      setPagecCode("PPPP");
      setCaption({
        FldOne: "Vou. Date:",
        FldTwo: "Voucher No:",
        FldThree: "Bill No.:",
        FldFour: "Bill Date:"
      })
    } else if (obj.page == "02590249") {
      // Purchase Entry > Purchase Return-1
      setPagecCode("PRPR");
      setCaption({
        FldOne: "Bill Date:",
        FldTwo: "Voucher No.:",
        FldThree: "Bill No.:",
        FldFour: "Doc Date:"
      })
    } else if (obj.page == "02670249") {
      // Purchase Entry > Purchase Challan
      setPagecCode("HPHP");
      setCaption({
        FldOne: "Vou Date:",
        FldTwo: "Voucher No.:",
        FldThree: "Challan No.:",
        FldFour: "Challan Date:"
      })
    } else if (obj.page == "02700249") {
      // Purchase Entry > Bill From Challan
      setPagecCode("PPPP");
      setCaption({
        FldOne: "",
        FldTwo: "",
        FldThree: "",
        FldFour: ""
      })
    } else if (obj.page == "02760249") {
      // Purchase Entry > Purchase Order
      setPagecCode("OPOP");
      setCaption({
        FldOne: "Order Date:",
        FldTwo: "Order No.:",
        FldThree: "Doc No.:",
        FldFour: "Doc Date:"
      })
    }
  }, [obj.page]);

  useEffect(() => {
    if (T41) {
      setT41Obj(T41);
      setWeekDay(T41?.FIELD02)
    }
    // //console.log("T41Obj.FIELD13==>", T41Obj?.FIELD13);
  }, [T41]);

  const setWeekDay = (e) => {

    let y = e?.slice(0, 4);
    let d = e?.slice(4, 6);
    let m = e?.slice(6, 8);
    console.log("setWeekDay =>", e);
    setSelectedDay(new Date(parseInt(y), parseInt(d) - 1, parseInt(m)))
  }


  useEffect(() => {
    if (T02) {
      setT02Data(T02?.filter((t) => t?.FIELD03 != "" && t?.FIELD03 != null));
      if (obj.p0 == "E") {
        setT02Obj(T02?.[T02?.length - 1]);
      } else {
        setT02Obj(T02?.[0])
      }
    }
  }, [T02])

  useEffect(() => {

    if (obj.p0 == "E") {
      setM01PRow(M01PRT)
    }

    if (obj.p0 == "E" && T41Obj) {
      // PortFieldHideShow(T41Obj?.FIELD45);

      M45IDT?.map((d) => {
        if (d.FIELD01 == T41Obj?.FIELD13) {
          setT41Obj({ ...T41Obj, FIELD45: d.FIELD20 });
          PortFieldHideShow(d.FIELD20);
        }
      });
    }

    // if (obj.p0 == "E" && T41Obj?.FIELD13 != "") {
    //   // Promise.resolve(dispatch(GetInvTypeDropDown(T41Obj?.FIELD13))).then(()=>dispatch(GetExpenseData({ action: "A", code: pagecCode, invCode: T41Obj?.FIELD13 })))

    //   dispatch(GetInvTypeDropDown(T41Obj?.FIELD13))
    //   dispatch(GetExpenseData({ action: "A", code: pagecCode, invCode: T41Obj?.FIELD13 }))
    // }
  }, [TransactionData, obj?.p0])

  // console.log("TransactionData",);



  // useEffect(()=>{
  //   if ((obj.p0 == "E") && (TransactionData)) {

  //     dispatch(GetInvTypeDropDown(TransactionData?.T41?.FIELD13))
  //     dispatch(GetExpenseData({ action: "A", code: pagecCode, invCode: TransactionData?.T41?.FIELD13 }))
  //   }
  // },[TransactionData,obj.p0])

  useEffect(() => {
    // if(T41Obj.FIELD16 == "C"){
    //     console.log("Credit Call");
    //     setPriceListParemeter({...priceListParemeter,accountCode:T41Obj.FIELD31})
    // }else{
    //     console.log("Debit Call");
    //     setPriceListParemeter({...priceListParemeter,accountCode:T41Obj.FIELD04})
    // }

    if (
      priceListParemeter.accountCode != "" &&
      priceListParemeter.priceListCode != "" &&
      priceListParemeter.productCode != ""
    ) {
      // console.log("priceListParemeter=>", priceListParemeter)
      dispatch(GetPLData(priceListParemeter));
    }
  }, [priceListParemeter]);

  useEffect(() => {
    if (TransactionSalesPLdata?.nRate) {
      // console.log("TransactionSalesPLdata=>", TransactionSalesPLdata);
      setT02Obj({ ...T02Obj, FIELD07: TransactionSalesPLdata?.nRate });
    }
  }, [TransactionSalesPLdata]);
  // console.log("T41Obj.FIELD04==>", T41Obj.FIELD04);
  // console.log("T02Obj.FIELD07==>", T02Obj.FIELD07);
  let stringOfNames;

  useEffect(() => {
    if (SelectedNarrationData?.length > 0) {
      const stringifyArray = SelectedNarrationData?.map((a) => a.FIELD01);
      stringOfNames = stringifyArray.join(" ");
      console.log("stringOfNames", stringOfNames);
    }
    setT41Obj({ ...T41Obj, FIELD15: stringOfNames });
    // setT41Obj({...T41Obj,FIELD15:})
  }, [stringOfNames, SelectedNarrationData]);

  useEffect(() => {
    if (T05) {
      setT05DataArray(T05?.filter((t) => t?.FIELD04 != "" && t?.FIELD04 != null));
    }
  }, [T05]);


  const GetSlab = (M21, M14) => {
    console.log("GetSlab M21=>", M21);
    console.log("GetSlab M14=>", M14);

    // , string dDate
    let nRate = 0;
    if (M21.FIELD03 == "") {
      return "";
    }
    let sCode = "";
    let curCode = "";
    let sDate;
    let lRate = false;
    // let DTM = MApp.pc.STOD(dDate);
    let DTM = T41Obj?.FIELD02;
    // let curDate = DateTime.MinValue;
    // let dTRM14 = ODB.GetTable(oYear.TblYr+"M14", "", "","FIELD01 = '" + cM21F03 + "'");
    let dTRM14 = M14.filter((a) => a.FIELD01 == M21?.FIELD03);
    console.log("dTRM14===>", dTRM14);
    // lRate = nRate != "" && OSC.GETL("_GSTONRATE");
    if (nRate != "" && Object.keys(oYear?.OSC?._GSTONRATE)) {
      // console.log(
      //   "Call function=>",
      //   nRate,
      //   Object.keys(oYear?.OSC?._GSTONRATE)
      // );
      lRate = true;
    }
    curCode = dTRM14[0].FIELD03;
    dTRM14?.map((d, i) => {
      if (d.FIELD04 <= DTM) {
        if (lRate) {
          if (nRate > d.FIELD06) {
            curCode = d.FIELD03;
          }
        } else {
          curCode = d["FIELD03"];
        }
      }
    });
    console.log("curCode==>", curCode);
    setT02Obj({
      ...T02Obj,
      FIELD56: curCode,
      FIELD25: M21.FIELD62,
      FIELD29: M21.FIELD64,
      FIELD19: OS11?.S11F01 == "IN" ? M21.FIELD42 : OS11?.S11F01 == "JW" ? M21.FIELD42 : "",
      FIELD22: M21.FIELD28,
      FIELD61: OS11?.S11F01 == "PS" ? M21.FIELD98 : "",
      FIELD62: OS11?.S11F01 == "PS" ? M21.FIELD93 : "",
      // FIELD07: T02Obj.FIELD05 == "C" ? M21.FIELD21 : T02Obj.FIELD05 == "R" ? M21.FIELD21 : M21.FIELD22
    });
    // return curCode;
  };

  let lastKeyPressed = null;


  const NextIndexFun = (index) => {
    const nextInd = index + 1;
    console.log("nextInd=>", nextInd)
    const nextInput = document.getElementById(`T02Ent_${nextInd}`)
    console.log("nextInput=>", nextInput)

    if (nextInput) {
      nextInput.focus()
    }

  }

  useEffect(() => {

    // console.log("ExpenseData =>", ExpenseData);

    setExpenseArray({
      ...ExpenseArray,
      dtExp: ExpenseData?.dtExp,
      dtExpDef: ExpenseData?.dtExpDef,
      dtExp_I: ExpenseData?.dtExp_I,
      dtExpDef_I: ExpenseData?.dtExpDef_I,
      M01SP: ExpenseData?.M01SP,
      M01SPDT: ExpenseData?.M01SPDT?.jData
    })

    // setExpenseArrayT02(ExpenseData?.dtExp_I)
    // setExpenseArrayT41(ExpenseData?.dtExp)
  }, [ExpenseData])

  // console.log("ExpenseArray =>", ExpenseArray);

  // useEffect(() => {
  //   if (selectedProductObj) {
  //     console.log("Product set =>", selectedProductObj);
  //     // Promise.resolve(setT02Obj({
  //     //   ...T02Obj,
  //     //   FIELD03: selectedProductObj?.FIELD01,
  //     //   F03NM: selectedProductObj?.FIELD02
  //     // }))

  //     Promise.resolve().then(() => setT02Obj({
  //       ...T02Obj,
  //       FIELD03: selectedProductObj?.FIELD01,
  //       F03NM: selectedProductObj?.FIELD02
  //     })).then(()=>console.log("T02Obj Promis",T02Obj))
  //   }

  // }, [selectedProductObj, T02Obj?.FIELD03, T02Obj?.F03NM])

  let ExpenseObj = {
    OS11,
    OSV,
    oYear,
    M01SP: ExpenseArray?.M01SP,
    M01SPDT: ExpenseArray?.M01SPDT,
    dtM14DT,
    dtM51DT,
    dtM52DT,
    dtM46DT,
    dtExp: ExpenseArray?.dtExp,
    dtExpDef: ExpenseArray?.dtExpDef,
    dtExp_I: ExpenseArray?.dtExp_I,
    dtExpDef_I: ExpenseArray?.dtExpDef_I,
    InvTypeDropDown,
    ItemCode: selectedProductObj?.FIELD03,
    selectedProductObj,
    T02Obj: { ...T02Obj, VorC: T41Obj?.FIELD45 },
    T41Obj,
    T02Data
  }

  useEffect(() => {
    // InvTypeDropDown
    // console.log("ExpenseArray useEffect=>", ExpenseArray)
    if (T02Obj?.FIELD03 && !tableEditingRow) {

      setTimeout(() => {
        Promise.resolve(setT02Obj({
          ...T02Obj,
          FIELD16: RetTaxAcc("TS", false, "FIELD16", 0, "", T02Obj, false, ExpenseObj)
        }))
      }, 500)
    }
  }, [T02Obj?.FIELD03])

  useEffect(() => {
    if (T02Obj?.FIELD16 && !tableEditingRow) {
      new Promise((resolve, reject) => {
        let dt = AssignItemDefault(ExpenseObj);
        resolve(dt);
      })
        .then((expdt) => {
          console.log("AssignItemDefault event =>", expdt);
          setExpenseArrayT02(expdt?.dtExpArr);
          setT02Obj(expdt?.T02Object)
        })
        .then(() => {
          return RecalcFun(T41Obj, "", false, "", ExpenseObj)
        }).then((res) => {
          // Promise.resolve(SetFieldObj(res?.T02CalAarray)).then(() => setDtExpTableField(res?.ExpenseCalArray))
          Promise.resolve(setExpenseArrayT41(res?.ExpenseCalArray)).then(() => setT41Obj(res?.T02CalAarray))
        })
    }
  }, [T02Obj?.FIELD16])

  // useEffect(()=>{
  //   // setNagativeQtyPopUp
  //   if(NagativeQtyPopUp){
  //     GlobalClass.Notify("warning", "Warning", "Product Name Require to Enter")
  //   }

  // },[NagativeQtyPopUp])


  // console.table("ExpenseArrayT02 & T02Obj & T41Obj =>", [{
  //   "ExpenseArrayT02": ExpenseArrayT02,
  //   "T02Obj": T02Obj,
  //   "T41Obj": T41Obj
  // }]);

  const NagativeQtyPopUpFun = (e) => {
    GlobalClass.Notify("warning", "Warning", e)
  }

  let receiptIssueVal = null;
  const renderInputBox = (uID, v, c, cellid, index) => {
    if (oYear?.OSC?._CDINITEM == "N") {
      // console.log("receiptIssueVal condition true");
      receiptIssueVal = cRorI
    }

    if (c?.S53F07 == 0) {
      return <Button
        id={`T02Ent_${index}`}
        variant="default"
        onFocus={() => {
          document.activeElement.blur()
          // console.log("Test=>", `T02Ent_${index}`)
          HiddenButtonField(uID, v, c, cellid, index)
        }}
        onClick={() => HiddenButtonField(uID, v, c, cellid, index)}
        style={{ padding: "0px", margin: "0px" }}
      />
        {/* {c.S53F03}</Button> */}
    } else {
      let setInput;
      switch (c?.S53F14) {
        case "PLPOPUP":
          if (c.S53F02 == "E000T02F03") { //Product Name
            // console.log("PLDataGrid Product Value 1==>", [{
            //   "c": c,
            //   "T02Obj": T02Obj
            // }]);
            setInput = <>
              {/* {GroupCatogaryProduct?.Group != "" && GroupCatogaryProduct?.Catogary != "" ? */}
              <PLDataGrid
                ref={ProductNameRef}
                // TmData={tmList["P_M21"]?.cSTID}
                // data={accList["P_M21"]}
                id={`T02Ent_${index}`}
                width={'500px'}
                // autoFocus={true}

                // cWhere={`M21.FIELD11 LIKE '%${T02Obj?.GRPCD ?? ""}%' AND M21.FIELD12 LIKE '%${T02Obj?.CATCD ?? ""}%'`}

                cWhere={`M21.FIELD12 LIKE '%${T02Obj?.CATCD ?? ""}%'`}
                // value={T02Obj?.[c?.S53F04]}
                value={{ value: T02Obj?.[c?.S53F04], label: T02Obj?.F03NM }}
                p1={seperate(c.S53F15)[1]}
                setEdit={(e) => {
                  console.log("PLDataGrid Product Value 2==>", e);

                  new Promise((resolve) => {
                    let T02F05F07 = { ...T02Obj };
                    if (oYear?.OSC?._CDINITEM == "N") {
                      console.log("ConditionFIELD07 one");
                      T02F05F07 = {
                        ...T02F05F07,
                        [c?.S53F04]: e?.FIELD01,
                        F03NM: e?.FIELD02,
                        VorC: T41Obj?.FIELD45,
                        FIELD22: e?.FIELD28 == 0 ? 1 : e?.FIELD28,
                        FIELD05: cRorI,
                        FIELD07: ["C", "D"].includes(cRorI) ? e?.FIELD21 : e?.FIELD22
                      }
                    }
                    //  else {
                    //   console.log("ConditionFIELD07 two");
                    //   T02F05F07 = {
                    //     ...T02F05F07,
                    //     [c?.S53F04]: e?.FIELD01,
                    //     F03NM: e?.FIELD02,
                    //     VorC: T41Obj?.FIELD45,
                    //     FIELD22: e?.FIELD28 == 0 ? 1 : e?.FIELD28,
                    //     FIELD05: receiptIssueObj?.value ?? "",
                    //     FIELD07: ["C", "D"].includes(receiptIssueObj?.value) ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22
                    //   }
                    // }
                    resolve(setT02Obj(T02F05F07))
                  }).then(() => setPriceListParemeter({ ...priceListParemeter, productCode: e.FIELD01 })).then(() => setSelectedProductObj(e)).then(() => setFIELD01value(e.FIELD01)).then(() => {
                    setTimeout(() => {
                      NextIndexFun(index)
                    }, 500)
                  })





                  //FIELD28 = product conversion
                  // Promise.resolve(setT02Obj({
                  //   ...T02Obj,
                  //   [c?.S53F04]: e?.FIELD01,
                  //   F03NM: e?.FIELD02,
                  //   VorC: T41Obj?.FIELD45,
                  //   FIELD22: e?.FIELD28 == 0 ? 1 : e?.FIELD28
                  // })).then(() => setPriceListParemeter({ ...priceListParemeter, productCode: e.FIELD01 })).then(() => setSelectedProductObj(e)).then(() => setFIELD01value(e.FIELD01)).then(() => {
                  //   setTimeout(() => {
                  //     NextIndexFun(index)
                  //   }, 500)
                  // })
                }}
              />
              {/* : <PLTextBox disabled={true} placeholder={"Select"} />
              } */}
            </>
          } else {
            setInput = <PLDataGrid
              setFocus={() => {
                if (c.S53F02 == "E00T02F20") {
                  LocationRef?.current?.handlePopoverToggle()
                }
              }}
              ref={c.S53F02 == "E00T02F20" ? LocationRef : null}
              id={`T02Ent_${index}`}
              width={'500px'}
              // value={T02Obj?.[c?.S53F04]}
              disabled={(c.S53F02 == "E00T02F20" && oYear?.OSC?._LOCATIONW == "Y") ? selectedProductObj != null ? (selectedProductObj?.FIELD57 == "N" ? true : false) : false : false}
              // disabled={c.S53F02 == "E00T02F20" ? (oYear?.OSC?._LOCATIONW == "Y" ? (selectedProductObj?.FIELD57 == "Y" ? false : true ) : true)  : false}
              // disabled={(c.S53F02 == "E00T02F20" && oYear?.OSC?._LOCATIONW == "N" && selectedProductObj?.FIELD57 == "N") ? true : false}
              value={c.S53F02 == "E00T02F20" ? { value: T02Obj?.FIELD20, label: T02Obj?.F20NM } : T02Obj?.[c?.S53F04]}
              p1={seperate(c.S53F15)[1]}
              setEdit={(e) => {
                // console.log("PLDataGrid==>", e);
                // setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 })
                if (c.S53F02 == "E00T02F20") { //Location
                  // console.log("Location==>", e);
                  Promise.resolve(setLocationObj(e)).then(() => setTimeout(() => { NextIndexFun(index) }, 500))
                } else {
                  Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 })).then(() => NextIndexFun(index))
                }
              }}
            />
          }
          break;
        case "COMBOBOX":
          if (c.S53F02 == "E00T02RORI") { //Receipt/Issue

            setInput = <PLComboBox
              onKeyDown={(e) => {
                GlobalClass.formNextInput(e, formRef);
              }}
              cmbid={`T02Ent_${index}`}
              value={T02Obj?.[c?.S53F04]}
              copno={seperate(c.S53F15)[1]}
              dispexpr={"DisplayMember"}
              valexpr={"ValueMember"}
              setEdit={(e) => {
                console.log("COMBOBOX==>", e);
                // {value: 'C', label: 'Receipt'}
                // {value: 'D', label: 'Issue'}

                // Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e.value, FIELD07: e.value == "C" ? selectedProductObj?.FIELD21 : e.value == "D" ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22 })).then(() => NextIndexFun(index + 1))


                Promise.resolve(setReceiptIssueObj(e)).then(() => {
                  receiptIssueVal = e.value;
                }).then(() => NextIndexFun(index + 1))

                // FIELD07: T02Obj.FIELD05 == "C" ? M21.FIELD21 : T02Obj.FIELD05 == "R" ? M21.FIELD21 : M21.FIELD22
              }}
            />
          } else {
            setInput = <PLComboBox
              onKeyDown={(e) => {
                GlobalClass.formNextInput(e, formRef);
              }}
              cmbid={`T02Ent_${index}`}
              value={T02Obj?.[c?.S53F04]}
              copno={seperate(c.S53F15)[1]}
              dispexpr={"DisplayMember"}
              valexpr={"ValueMember"}
              setEdit={(e) => {
                console.log("COMBOBOX==>", e);
                setT02Obj({ ...T02Obj, [c?.S53F04]: e.value })
                // Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e.value }))

              }}
            />
          }

          break;
        case "TEXTBOX":
          if (c.S53F02 == "E00USERFLD") { //User FIeld
            // setInput = (
            //   <Button
            //     id={`T02Ent_${index}`}
            //     style={{ height: 30, marginTop: 5, width: "0px", padding: "0px" }}
            //     onFocus={() => {
            //       document.activeElement.blur();
            //       if (T02UF?.length > 0) {
            //         TableUserFieldFun(index)
            //       }
            //     }}
            //     variant="default"
            //     onClick={() => T02UF?.length > 0 ? TableUserFieldFun(index) : null}
            //   >
            //   </Button>
            // );
          } else if (c.S53F02 == "E00T02F23") { //Pricelist
            setInput = (
              <>
                <PLComboBox
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                  cmbid={`T02Ent_${index}`}
                  data={typeof PLDATA == "string" ? [{}] : PLDATA}
                  value={T02Obj?.[c?.S53F04]}
                  dispexpr={"FIELD02"}
                  valexpr={"FIELD01"}
                  setEdit={(e) => {
                    // console.log("Pricelist OBJ=>", e);
                    Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(() => {
                      if (T41Obj.FIELD16 == "C") {
                        setPriceListParemeter({
                          ...priceListParemeter,
                          accountCode: T41Obj.FIELD31,
                          priceListCode: e,
                        });
                      } else {
                        setPriceListParemeter({
                          ...priceListParemeter,
                          accountCode: T41Obj.FIELD04,
                          priceListCode: e,
                        });
                      }
                    })
                  }}
                />
              </>
            );
          } else if (c.S53F02 == "E00T02F11") { //Batch Issue
            setInput = (
              <>
                {
                  T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 != "N" ?
                    <PLDataGrid
                      // autoFocus={true}
                      // setBlur={()=>T02UF?.length > 0 ? TableUserFieldFun() : null}
                      id={`T02Ent_${index}`}
                      cWhere={`T11.FIELD08 LIKE '%${FIELD01Value ?? ""}%'`}
                      width={"500px"}
                      value={T02Obj?.[c?.S53F04]}
                      p1={seperate(c.S53F15)[1]}
                      // p1={'P_M21'}
                      setEdit={(e) => {
                        console.log("Batch Issue Object=>", e);
                        // setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 })
                        setBatchIssueObj(e)
                        // Promise.resolve(setBatchIssueObj(e))
                      }}
                    /> : <Button
                      fullWidth
                      disabled
                      style={{
                        padding: "0px 5px",
                        display: "flex",
                        alignItems: "flex-start",
                        fontWeight: "normal",
                        fontSize: "12px",
                        overflow: "hidden",
                      }}
                      variant='default'
                    // onClick={() => GlobalClass.Notify("info", "Info", "Select Any Product")}
                    >
                      Select
                    </Button>
                }
              </>
            );
          } else {
            console.log("Batch Receipt receiptIssueVal =>", [{
              "receiptIssueVal": receiptIssueVal
            }]);
            setInput = (
              <PLTextBox
                disabled={c?.S53F02 == "E00T02F41" && selectedProductObj?.FIELD51 == "N" ? true :
                  c?.S53F02 == "E00UFBAT" && selectedProductObj?.FIELD51 == "N" ? true :
                    c?.S53F02 == "E00T02F41" && ((oYear?.OSC?._CDINITEM == "N" ? cRorI : T02Obj?.FIELD05) != "C") ? true :
                      false} // Batch Receipt && Batch User FIeld
                id={`T02Ent_${index}`}
                value={T02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value })
                }}
              />
            );
          }
          break;
        case "NUMBOX":

          if (c.S53F02 == "E00EXPDUM") { //ItemWise Expense

            setInput = (
              <Button
                style={{ height: 30, marginTop: 5, width: "0px", padding: "0px" }}
                onFocus={() => {
                  document.activeElement.blur();
                  if (T02Obj.FIELD08 > 0 && InvTypeDropDown != null) {
                    TableExpenseEntry()
                  }
                }}
                id={`T02Ent_${index}`}
                variant="default"
                onClick={() => {
                  //console.log("T02Obj.FIELD08=>", T02Obj.FIELD08);
                  if (T02Obj.FIELD08 > 0 && InvTypeDropDown != null) {
                    TableExpenseEntry();
                  }
                }}
              >
              </Button>
            );
          } else if (c.S53F02 == "E00T02F08") { //Amount
            setInput = (
              <PLNumberBox
                decimalSeparator={c.S53F02 == "E00T02F08" ? true : false}
                precision={c.S53F02 == "E00T02F08" ? 2 : 0}
                onKeyDown={(e) => {
                  // e.stopPropagation();

                  // console.log("Aamount Number", parseFloat(e?.target?.value));


                  if (e.key == "Enter") {
                    let value = parseFloat(e?.target?.value)

                    if ((ExpenseArrayT02?.length > 0 || ExpenseData?.dtExp_I?.length > 0) && value > 0 && InvTypeDropDown != null) {
                      setTimeout(() => {
                        // TableExpenseEntry()
                        NextIndexFun(index)
                      }, 500)
                    } else {
                      console.log("Enter key Press", e)
                      if (tableEditingRow == null) {
                        console.log("Add Product Call")
                        if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                          GlobalClass.Notify("warning", "Warning", "Product Name Require to Enter")
                        } else {
                          // handleCreateUser()
                          Promise.resolve(handleCreateUser()).then(() => setTimeout(() => {
                            table.setCreatingRow(true)
                          }, 500))
                        }
                      } else {
                        console.log("Edit Product Call")
                        if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                          GlobalClass.Notify("warning", "Warning", "Product Name Require to Enter")
                        } else {
                          // handleSaveUser()
                          Promise.resolve(handleSaveUser()).then(() => setTimeout(() => {
                            table.setCreatingRow(true)
                          }, 500))
                        }
                      }
                    }
                  }
                }}
                onFocus={() => {
                  // runEQ(c);

                  Promise.resolve().then(() => { return EquationFunction(c, T02Obj, T41Obj, T02UF, obj) }).then((res) => {
                    console.log("Equtation Response Amount =>", res)
                    setT02Obj(res)
                  })
                }}
                id={`T02Ent_${index}`}
                value={T02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                }}
              />
            );

          } else {
            setInput = (
              <PLNumberBox
                setNumber={(e) => {
                  if (c.S53F02 == "E00T02F06") {
                    console.log("E00T02F06 T02 QTY-1 =>", [{
                      "QTY-1": e?.target?.value,
                      "T02OBJ": T02Obj
                    }]);
                    new Promise((resolve) => {
                      let qt = Qty_CheckStk({ ...ExpenseObj, BatchIssue: BatchIssueObj, ProductAllData: accList["P_M21"] })
                      resolve(qt)
                    }).then((res) => {
                      console.log("E00T02F06 T02 QTY-1 Response =>", [{
                        "res": res
                      }]);
                      if (res != "OK" && T02Obj?.FIELD33 != 0) {
                        Promise.resolve(NagativeQtyPopUpFun(res)).then(() => NextIndexFun(index - 1))
                        // NagativeQtyPopUpFun(res)
                      }
                    })
                  }
                }}
                decimalSeparator={c.S53F02 == "E00T02F07" ? true : false}
                precision={c.S53F02 == "E00T02F07" ? 2 : 0}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    console.log("onKeyDown=>", e);
                    NextIndexFun(index - 1)
                  }
                }}
                disabled={(c?.S53F02 == "E00T02F21" && oYear?.OSC?._DUALSTOCK == "Y") ? selectedProductObj != null ? (selectedProductObj?.FIELD59 == "N" ? true : false) : false : (c?.S53F02 == "E00T02F22" && oYear?.OSC?._DUALSTOCK == "Y") ? selectedProductObj != null ? (selectedProductObj?.FIELD59 == "N" ? true : false) : false : false} //Qty-2 //Conversion
                id={`T02Ent_${index}`}
                value={T02Obj?.[c?.S53F04]}
                onFocus={() => {
                  // runEQ(c)

                  Promise.resolve().then(() => { return EquationFunction(c, T02Obj, T41Obj, T02UF, obj) }).then((res) => {
                    console.log("Equtation Response Rate =>", res)
                    setT02Obj(res)
                  })

                }}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                }}
              />
            );
          }
          break;
        default:
          setInput = null;
          break;
      }
      return setInput;
    }
  };

  const runEQ = (c) => {
    if (T02UF && T02UF?.length > 0) {
      console.log("c?.S53F02 " + c?.S53F02 + " =>", c);
      let val = T02Obj[c?.S53F04];
      switch (c.S53F03) {
        case "Amount":
          val = eval("(T02Obj.UGRO ? (T02Obj.UGRO-(T02Obj.UGRO*T02Obj.UDIS/100.000000))*(T02Obj.FIELD33/T02Obj.UPE) : (T02Obj.UMUR-(T02Obj.UMUR*T02Obj.UDIS/100.000000))*(T02Obj.FIELD33/T02Obj.UPE))");
          console.log("EQEVAL=> Amount", val);
          setT02Obj({ ...T02Obj, [c?.S53F04]: val });
          break;
        case "Rate":
          val = eval("(T02Obj.UGRO ? (T02Obj.UGRO-(T02Obj.UGRO*T02Obj.UDIS/100.000000)) : (T02Obj.UMUR-(T02Obj.UMUR*T02Obj.UDIS/100.000000)))");

          console.log("EQEVAL=> Rate", val);
          setT02Obj({ ...T02Obj, [c?.S53F04]: val });
          break;
        default:
          break;
      }
    }
  }

  const HiddenButtonField = (uID, v, c, cellid, index) => {
    switch (c.S53F02) {
      case "E00ORDDUM": //Dummy Order 
        if (PendingOrderData?.dtOrd?.length > 0) {
          PendingOrderFun(index)
          // setPendingOrderIndex(index)
        } else {
          NextIndexFun(index)
        }
        // NextIndexFun(index)
        break;
      case "E00T02F11": //Batch Issue
        // if (T02Obj?.FIELD05 == "D") {
        console.log("Batch Issue if condition =>", [{
          "receiptIssueVal": receiptIssueVal,
          "T02Obj?.FIELD03": T02Obj?.FIELD03,
          "selectedProductObj": selectedProductObj
        }]);
        if (((oYear?.OSC?._CDINITEM == "N" ? cRorI : receiptIssueVal) == "D") && T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 != "N") {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>{c.S53F03}</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              <Grid>
                <Grid.Col span={12}>
                  <PLDataGrid
                    autoFocus={true}
                    // setBlur={()=>T02UF?.length > 0 ? TableUserFieldFun() : null}
                    id={`T02Ent_${index}`}
                    cWhere={`T11.FIELD08 LIKE '%${FIELD01Value ?? ""}%'`}
                    // width={"500px"}
                    value={T02Obj?.[c?.S53F04]}
                    p1={seperate(c.S53F15)[1]}
                    setEdit={(e) => {
                      console.log("Batch Issue Object=>", e);
                      setBatchIssueObj(e)
                      // setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 })
                      // Promise.resolve(setBatchIssueObj(e)).then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Button
                    variant="filled"
                    size="sm"
                    onClick={() => {
                      Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                      }
                    }}
                  >Ok</Button>
                </Grid.Col>
              </Grid>
            </>,
            open: true,
            size: "xl",
            position: "right"
          })
        } else {
          NextIndexFun(index)
        }
        break;
      case "E00USERFLD": //User FIeld
        if (T02UF && T02UF?.length > 0) {

          console.log("T02Obj userField", T02Obj);

          setDrawerObj({
            ...dra, title: <Text fw={700}>User Field</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              <SITableUserField
                PageObj={obj}
                T41Obj={T41Obj}
                T02UF={T02UF}
                T02ObjSend={T02Obj}
                // T02ObjSend={{...T02Obj,FIELD03:selectedProductObj?.FIELD01,F03NM:selectedProductObj?.FIELD02}}
                setT02Objsend={(e) => {
                  // setT02Obj(e)
                  console.log("SetT02E => ", e);
                  if (obj?.page == "02530249") {
                    // Promise.resolve(setT02Obj({...e,FIELD41:fieldDisable.Doc_No == "Y" ? (e?.UBA + T41Obj?.FIELD10) : (e?.UBA + T41Obj?.FIELD12)})).then(() => {
                    //   setTimeout(() => {
                    //     NextIndexFun(index)
                    //   }, [500])
                    // })

                    Promise.resolve(setT02Obj({ ...e, FIELD41: (e?.UBA ?? "") + T41Obj?.FIELD10 })).then(() => {
                      if (oYear?.OSC?._DUALSTOCK == "Y") {
                        if (selectedProductObj?.FIELD59 == "N") {
                          setTimeout(() => {
                            NextIndexFun(index + 2)
                          }, 500)
                        } else {
                          setTimeout(() => {
                            NextIndexFun(index)
                          }, 500)
                        }
                      } else {
                        setTimeout(() => {
                          NextIndexFun(index)
                        }, 500)
                      }
                    })
                  } else {
                    Promise.resolve(setT02Obj(e)).then(() => {
                      if (oYear?.OSC?._DUALSTOCK == "Y") {
                        if (selectedProductObj?.FIELD59 == "N") {
                          setTimeout(() => {
                            NextIndexFun(index + 2)
                          }, 500)
                        } else {
                          setTimeout(() => {
                            NextIndexFun(index)
                          }, 500)
                        }
                      } else {
                        setTimeout(() => {
                          NextIndexFun(index)
                        }, 500)
                      }
                    })
                  }
                }}
                DrawerOBJ={DrawerObj}
                setDrawerOBJ={(e) => {
                  console.log("setDrawerOBJ=>", e);
                  setDrawerObj(e)
                }}
              />
            </>, open: true, size: "sm", position: "right"
          })
        } else {
          NextIndexFun(index)
        }
        break;
      case "E00UFBAT": //Batch User FIeld
        setTimeout(() => {
          if (dtUFT11 && dtUFT11?.length > 0 && selectedProductObj?.FIELD51 == "N") {
            setDrawerObj({
              ...dra,
              title: <Text fw={700}>{c.S53F03}</Text>,
              onClickCloseButton: () => {
                // setDrawerObj(dra);
                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
              },
              onClose: () => {
                // setDrawerObj(dra);
                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
              },
              body: <>
                <SITableBatchUserField
                  PageObj={obj}
                  DTUFT11={dtUFT11}
                  T02ObjSend={T02Obj}
                  setT02Objsend={(e) => {
                    //Lot No = ULO
                    // setT02Obj(e)

                    // new Promise((resolve, reject) => {
                    // let ResolveObj;
                    // if(obj?.page == "02530249"){
                    //   ResolveObj={...e,ULO:e?.UBA}
                    // }else{
                    //   ResolveObj={...e}
                    // }
                    // let dt = AssignItemDefault(ExpenseObj);
                    //   resolve(setT02Obj(ResolveObj));
                    // })
                    Promise.resolve(setT02Obj(e)).then(() => setTimeout(() => { NextIndexFun(index) }, 500))
                  }}
                  DrawerOBJ={DrawerObj}
                  setDrawerOBJ={(e) => {
                    console.log("setDrawerOBJ=>", e);
                    setDrawerObj(e)
                  }}
                />
              </>,
              open: true,
              size: "sm",
              position: "right"
            })
          } else {
            NextIndexFun(index)
          }
        }, 500)
        break;
      case "E00T02F23": //Pricelist => FIELD =PRICELIST,FIELD27
        if (selectedProductObj?.FIELD53 == "N" || "02530249,02590249,02670249,02760249".includes(obj?.page) || "02700249".includes(obj?.id)) {
          NextIndexFun(index)
        } else {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>{c.S53F03}</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              <Grid>
                <Grid.Col span={12}>
                  <PLComboBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    initiallyOpened={true}
                    cmbid={`T02Ent_${index}`}
                    data={typeof PLDATA == "string" ? [{}] : PLDATA}
                    // value={T02Obj?.[c?.S53F04]}
                    value={T02Obj?.FIELD27}
                    dispexpr={"FIELD02"}
                    valexpr={"FIELD01"}
                    setEdit={(e) => {
                      console.log("Pricelist OBJ=>", e);

                      if (T41Obj.FIELD16 == "C") {
                        setPriceListParemeter({
                          ...priceListParemeter,
                          accountCode: T41Obj.FIELD31,
                          priceListCode: e,
                        });
                      } else {
                        setPriceListParemeter({
                          ...priceListParemeter,
                          accountCode: T41Obj.FIELD04,
                          priceListCode: e,
                        });
                      }

                      // Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(() => {
                      //   if (T41Obj.FIELD16 == "C") {
                      //     setPriceListParemeter({
                      //       ...priceListParemeter,
                      //       accountCode: T41Obj.FIELD31,
                      //       priceListCode: e,
                      //     });
                      //   } else {
                      //     setPriceListParemeter({
                      //       ...priceListParemeter,
                      //       accountCode: T41Obj.FIELD04,
                      //       priceListCode: e,
                      //     });
                      //   }
                      // })
                      // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Button
                    variant="filled"
                    size="sm"
                    onClick={() => {
                      Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                      }
                    }}
                  >Ok</Button>
                </Grid.Col>
              </Grid>

            </>,
            open: true,
            size: "sm",
            position: "right"
          })
        }
        break;
      case "E00EXPDUM": //ItemWise Expense
        if (T02Obj?.FIELD08 > 0 && InvTypeDropDown != null) {
          setDrawerObj({
            ...dra, title: <Text fw={700}>Expense Entry</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index - 2))
              // NextIndexFun(index-2)
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index - 2))
              // NextIndexFun(index-2)//
            },
            body: <>
              <ExpenseEntryForm
                ExpenseObj={ExpenseObj}
                ProductObj={selectedProductObj}
                isExpense={true}
                expenseArr={ExpenseArrayT02 && ExpenseArrayT02?.length > 0 ? ExpenseArrayT02 : ExpenseData?.dtExp_I}
                // expenseArr={ExpenseData?.dtExp_I}
                expenseArrDef={ExpenseData?.dtExpDef_I}
                M01PData={ExpenseData?.M01SPDT?.jData}
                M01Format={ExpenseData?.M01SP}
                valueSaveObj={T02Obj}
                T41ObjSend={T41Obj}
                TableExpenseArr={TableExpense}
                onChange={(e) => {
                  console.log("TableExpenseEntry Object=>", e);
                  // setSelectedTaxAcc(e.FIELD16)

                  if (tableEditingRow == null) {
                    console.log("Add Product Call")
                    if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                      GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                    } else {
                      // handleCreateUser(e)
                      Promise.resolve(handleCreateUser(e)).then(() => setTimeout(() => {
                        table.setCreatingRow(true)
                      }, 500))
                    }
                  } else {
                    console.log("Edit Product Call")
                    if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                      GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                    } else {
                      // handleSaveUser(e)
                      Promise.resolve(handleSaveUser(e)).then(() => setTimeout(() => {
                        table.setCreatingRow(true)
                      }, 500))
                    }
                  }
                }}
                DrawerOBJ={DrawerObj}
                setDrawerOBJ={(e) => setDrawerObj(e)}
              />
            </>, open: true, size: "50%", position: "right"
          })
        }
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (priceListParemeter?.priceListCode) {
      Promise.resolve().then(() => {
        setT02Obj({ ...T02Obj, PRICELIST: priceListParemeter?.priceListCode, FIELD27: priceListParemeter?.priceListCode })
      })
    }
  }, [priceListParemeter?.priceListCode])

  // console.log("T02Object console =>", T02Obj);

  // console.log("T02Obj=>", T02Obj);

  // console.log("FIELD01Value", FIELD01Value);
  // console.log("selectedProductObj=>");

  //console.log("TransactionSalesFormData=>", TransactionSalesFormData);

  const columnHelper = createColumnHelper();

  const Summary = (obj, i, tag) => {
    // console.log("Summary==============>",obj);
    // let stype = "sum";
    var value = "";

    switch (obj?.S53F03) {
      // case 128:
      //     value = T02Data?.reduce((a, b) => a = a + b?.FIELD25, 0)
      //     break;
      case "Qty":
        value = T02Data?.reduce((a, b) => (a = a + b?.FIELD33), 0);
        break;
      // case 131:
      //     value = T02Data?.reduce((a, b) => a = a + b?.FIELD81, 0)
      //     break;
      case "Amount":
        value = T02Data?.reduce((a, b) => (a = a + b?.FIELD08), 0);
        break;
      default:
        value = null;
        break;
    }

    return value;
  };

  const ProductGroupDrawer = () => {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Product Group</Text>,
      body: <>
        <Grid>
          <Grid.Col span={12}>
            <PLDataGrid
              autoFocus={true}
              width={"500px"}
              TmData={tmList["T_M32G"]?.cSTID}
              data={accList["T_M32G"]}
              value={T02Obj?.GRPNM}
              setEdit={(e) => {
                console.log("Product Group Obj=>", e);
                setGroupCatogaryProduct({ ...GroupCatogaryProduct, Group: e?.FIELD01 })
                setT02Obj({ ...T02Obj, GRPNM: e?.FIELD01 })
                // Promise.resolve(setT02Obj({ ...T02Obj, GRPNM: e?.FIELD01 }))
                // .then(()=>document.getElementById("Product_Catogary").focus())

              }}
            />
          </Grid.Col>
          <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button
              variant="filled"
              size="sm"
              onClick={() => {
                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(99))
              }}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(99))
                }
              }}
            >Ok</Button>
          </Grid.Col>
        </Grid>
      </>,
      open: true,
      size: "sm",
      position: "right"
    })
  }

  const ProductCategoryDrawer = () => {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Product Category</Text>,
      body: <>
        <Grid>
          <Grid.Col span={12}>
            <PLDataGrid
              // autoFocus={true}
              width={"500px"}
              TmData={tmList["T_M32C"]?.cSTID}
              data={accList["T_M32C"]}
              value={T02Obj?.CATNM}
              setEdit={(e) => {
                console.log("Product Category Obj=>", e);
                setGroupCatogaryProduct({ ...GroupCatogaryProduct, Catogary: e?.FIELD01 })
                setT02Obj({ ...T02Obj, CATNM: e?.FIELD01 })

                // Promise.resolve(setGroupCatogaryProduct({ ...GroupCatogaryProduct, Catogary: e?.FIELD01 })).then(()=>setT02Obj({ ...T02Obj, CATNM: e?.FIELD01 })).then(()=>document.getElementById(`T02Ent_${1}`))
              }}
            />
          </Grid.Col>
          <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button
              variant="filled"
              size="sm"
              onClick={() => {
                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => document.activeElement.blur()).then(() => NextIndexFun(0, "CATNM"))
              }}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => document.activeElement.blur()).then(() => NextIndexFun(0, "CATNM"))
                }
              }}
            >Ok</Button>
          </Grid.Col>
        </Grid>
      </>,
      open: true,
      size: "sm",
      position: "right"
    })
  }

  useEffect(() => {
    let col = [];
    if (isLoading == false) {
      // col.push(
      //   columnHelper.accessor("GRPCD", {
      //     header: "Product Group",
      //     id: "Product_Group",
      //     size: 150,
      //     Edit: ({ cell, row }) => {
      //       let val = cell.getValue();
      //       // console.log("Cell =>>", cell);
      //       // return <><Text>Test</Text></>
      //       // console.log("cell?.id==>", cell?.id);

      //       return <PLDataGrid
      //         autoFocus={true}
      //         width={"500px"}
      //         // p1={"T_M32G"}
      //         TmData={tmList["T_M32G"]?.cSTID}
      //         // data={accList["T_M32G"]}
      //         // value={T02Obj?.GRPNM}
      //         value={{ value: T02Obj?.GRPCD, label: T02Obj?.GRPNM }}
      //         setEdit={(e) => {
      //           console.log("Product Group Obj=>", e);
      //           setGroupCatogaryProduct({ ...GroupCatogaryProduct, Group: e?.FIELD01 })
      //           setT02Obj({ ...T02Obj, GRPNM: e?.FIELD02, GRPCD: e?.FIELD01 })
      //           // Promise.resolve(setT02Obj({ ...T02Obj, GRPNM: e?.FIELD01 }))
      //           // .then(()=>document.getElementById("Product_Catogary").focus())

      //         }}
      //       />

      //       // return <Button
      //       //   // id={`T02Ent_${99}`}
      //       //   autoFocus={true}
      //       //   variant="default"
      //       //   onFocus={() => {
      //       //     ProductGroupDrawer()
      //       //   }}
      //       //   onClick={() => ProductGroupDrawer()}
      //       //   style={{ padding: "0px", margin: "0px" }}
      //       // >Product Group</Button>
      //     },
      //     Cell: ({ cell, row }) => {
      //       let val = cell.getValue();
      //       return T02Data[row.id]?.GRPNM
      //     },
      //   })
      // )
      col.push(
        columnHelper.accessor("CATCD", {
          header: "Product Category",
          id: "Product_Category",
          size: 150,
          Edit: ({ cell, row }) => {
            let val = cell.getValue();
            // console.log("Cell =>>", cell);
            // return <><Text>Test</Text></>
            // console.log("cell?.id==>", cell?.id);

            return <PLDataGrid
              // autoFocus={true}
              ref={CategoryRef}
              width={"500px"}
              TmData={"T_M32C"}
              value={{ value: T02Obj?.CATCD, label: T02Obj?.CATNM }}
              // data={accList["T_M32C"]}
              // value={T02Obj?.CATNM}
              setEdit={(e) => {
                console.log("Product Category Obj=>", e);

                Promise.resolve(setGroupCatogaryProduct({ ...GroupCatogaryProduct, Catogary: e?.FIELD01 })).then(() => setT02Obj({ ...T02Obj, CATCD: e?.FIELD01, CATNM: e?.FIELD02 })).then(() => ProductNameRef?.current?.handlePopoverToggle())
                // Promise.resolve(setGroupCatogaryProduct({ ...GroupCatogaryProduct, Catogary: e?.FIELD01 })).then(()=>setT02Obj({ ...T02Obj, CATNM: e?.FIELD01 })).then(()=>document.getElementById(`T02Ent_${1}`))
              }}
            />

            // return <Button
            //   id={`T02Ent_${100}`}
            //   variant="default"
            //   onFocus={() => {
            //     ProductCategoryDrawer()
            //   }}
            //   onClick={() => ProductCategoryDrawer()}
            //   style={{ padding: "0px", margin: "0px" }}
            // >Product Category</Button>
          },
          Cell: ({ cell, row }) => {
            let val = cell.getValue();
            return T02Data[row.id]?.CATNM

          },
        })
      )
      T02ENT?.filter((h, index) => h.S53F00 != "H").map((c, i) => {
        col.push(
          columnHelper.accessor(c.S53F04, {
            id: c.S53UID,
            // header: c.S53F03,
            // size: 150,
            header: c.S53F07 == 0 ? "" : c.S53F03,
            size: c.S53F07 == 0 ? 0 : 150,
            Edit: ({ cell, row }) => {
              let val = cell.getValue();
              return renderInputBox(c.S53UID, val, c, cell?.id, i);
            },
            Cell: ({ cell, row }) => {
              // console.log("cell.getValue()", cell.getValue())
              let val = cell.getValue();
              // console.log("T02Data[i]=>",T02Data[row.id]);
              if (c.S53F02 == "E000T02F03") { //Product Name
                return T02Data[row?.id]?.F03NM ?? T02Data[row?.id]?.PrdNM
                // return "Test"
              } else if (c.S53F02 == "E00T02F20") { // Location
                return T02Data[row?.id]?.F20NM
              }
              return c.S53F07 == 0 ? "" : val
            },
            Footer: () => <div>{c.S53F02 == "E000T02F03" ? `Total Entry :${table?.getSelectedRowModel()?.flatRows.length > 0 ? (parseInt(table?.getSelectedRowModel()?.flatRows[0]?.index) + 1) + "/" : ""}${T02Data?.length}` : Summary(c, i, "c")}</div>,
            // ColumnVisibility : c?.S53F00 == "C" ? true : false
          })
        );
        // console.log(`${i} #Field => ${c.S53F04} #header => ${c.S53F03} #Id => ${c.S53UID} #Input => ${c.S53F14}`);
      });
      setColumns(col);
    }
    // setTimeout(()=>{
    //   console.log("T02Ent Column =>",col);

    // },500)
  }, [T02ENT, T02, T02Obj, selectedProductObj, tmList, accList, GroupCatogaryProduct, T02Data]);
  // console.log("DrawerObj=>",DrawerObj);
  // console.log("T02Object Group and Category =>", T02Obj);
  // console.log("T02Data Array=>", T02Data);

  useEffect(() => {
    //receipt/issue FIELD05
    //Location FIELD20 
    //batch issue FIELD11
    //batch Receipt FIELD41

    // FIELD07: e.value == "C" ? selectedProductObj?.FIELD21 : e.value == "D" ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22 })
    // if(receiptIssueObj?.value == "C"){
    //   setT02Obj({
    //     ...T02Obj,
    //     FIELD05: receiptIssueObj?.value??"",
    //     FIELD07: receiptIssueObj?.value == "C" ? selectedProductObj?.FIELD21 : receiptIssueObj?.value == "D" ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22,
    //     FIELD11: BatchIssueObj?.FIELD01??"",
    //     FIELD41:BatchIssueObj?.FIELD02??"",
    //     FIELD20: LocationObj?.FIELD01??""
    //   })
    let T02F05F07 = {};

    // if (oYear?.OSC?._CDINITEM == "N") {
    //   console.log("ConditionFIELD07 one");
    //   Promise.resolve().then(() => {
    //     T02F05F07 = {
    //       ...T02Obj,
    //       FIELD05: cRorI,
    //       FIELD07: ["C", "D"].includes(cRorI) ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22
    //     }

    //     setT02Obj(T02F05F07)

    //     // console.log("T02Object FIELD07 Value one =>", [{
    //     //   "T02Obj": T02F05F07,
    //     //   "T02 FIELD07": receiptIssueObj?.value == "C" ? selectedProductObj?.FIELD21 : receiptIssueObj?.value == "D" ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22
    //     // }]);
    //   })
    // } else
    if (receiptIssueObj) {
      console.log("ConditionFIELD07 two");
      Promise.resolve().then(() => {
        T02F05F07 = {
          ...T02Obj,
          FIELD05: receiptIssueObj?.value ?? "",
          FIELD07: ["C", "D"].includes(receiptIssueObj?.value) ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22
        }

        setT02Obj(T02F05F07)

        // console.log("T02Object FIELD07 Value two =>", [{
        //   "T02Obj": T02F05F07,
        //   "T02 FIELD07": receiptIssueObj?.value == "C" ? selectedProductObj?.FIELD21 : receiptIssueObj?.value == "D" ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22
        // }]);
      })
    }

    // }
  }, [receiptIssueObj])

  useEffect(() => {
    if (T02Obj?.FIELD05 == "D") {

      setT02Obj({
        ...T02Obj,
        FIELD11: BatchIssueObj?.FIELD01 ?? "",
        FIELD41: BatchIssueObj?.FIELD02 ?? "",
      })

    } else {

      setT02Obj({
        ...T02Obj,
        FIELD11: BatchIssueObj?.FIELD01 ?? ""
      })

    }
  }, [T02Obj?.FIELD05, BatchIssueObj])

  useEffect(() => {
    // if(LocationObj != null){
    Promise.resolve().then(() => {
      setT02Obj({
        ...T02Obj,
        FIELD20: LocationObj?.FIELD01 ?? "",
        F20NM: LocationObj?.FIELD02 ?? ""
      })
    })
    // }
    console.log("LocationObj =>", LocationObj);
    // if(LocationObj != null){
    //     setT02Obj({
    //       ...T02Obj,
    //       FIELD20: LocationObj?.FIELD01 ?? ""
    //     })
    // }

  }, [LocationObj])
  // console.log("T02Obj==>", T02Obj);

  useEffect(() => {
    if (T02Obj?.FIELD21 && T02Obj?.FIELD22) {
      setT02Obj({ ...T02Obj, FIELD33: parseInt(T02Obj?.FIELD21) * parseInt(T02Obj?.FIELD22) });
    }
    // Qty- 2(FIELD21 ) * conversion(FIELD22) = Qty-1(FIELD33)
    // Qt-1(FIELD33 ) *Rate(FIELD07 ) = Amount(FIELD08 )
  }, [T02Obj?.FIELD21, T02Obj?.FIELD22]);

  useEffect(() => {
    if (T02Obj?.FIELD33 && T02Obj?.FIELD07) {
      setT02Obj({ ...T02Obj, FIELD08: parseInt(T02Obj?.FIELD33) * parseInt(T02Obj?.FIELD07) });
    }
  }, [T02Obj?.FIELD33, T02Obj?.FIELD07])

  useEffect(() => {
    if (T02Obj?.FIELD33) {
      setT02Obj({ ...T02Obj, FIELD21: parseInt(T02Obj?.FIELD33) / parseInt(T02Obj?.FIELD22) })
    }
  }, [T02Obj?.FIELD33])

  const PortFieldHideShow = (e) => {
    // //console.log("PortFieldHideShow=>", e);
    switch (e) {
      case "X":
        setFieldDisable({ ...fieldDisable, T41_Field45_Render: true });
        break;
      case "I":
        setFieldDisable({ ...fieldDisable, T41_Field45_Render: true });
        break;
      case "M":
        setFieldDisable({ ...fieldDisable, T41_Field45_Render: true });
        break;
      case "S":
        setFieldDisable({ ...fieldDisable, T41_Field45_Render: true });
        break;
      case "F":
        setFieldDisable({ ...fieldDisable, T41_Field45_Render: true });
        break;
      case "6":
        setFieldDisable({ ...fieldDisable, T41_Field45_Render: true });
        break;
      case "Y":
        setFieldDisable({ ...fieldDisable, T41_Field45_Render: true });
        break;
      case "W":
        setFieldDisable({ ...fieldDisable, T41_Field45_Render: true });
        break;
      default:
        setFieldDisable({ ...fieldDisable, T41_Field45_Render: false });
        break;
    }
  };

  const seperate = (e) => {
    var paraArr = e.split("~C~");
    // console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const TableBatchIssue = () => {
    // BatchWiseDetail
    modals.openContextModal({
      modal: "SIBatchWiseDetail",
      title: <Text fw={700}>Select From BatchWise Detail</Text>,
      zIndex: 300,
      size: "sm",
      withCloseButton: true,
      innerProps: {
        modalBody: (
          <>
            <SIBatchWiseDetail />
          </>
        ),
      },
    });
  };

  const TableUserFieldFun = (index) => {

    setDrawerObj({
      ...dra, title: <Text fw={700}>User Field</Text>, body: <>
        <SITableUserField
          T02UF={T02UF}
          T02ObjSend={T02Obj}
          setT02Objsend={(e) => {
            Promise.resolve(setT02Obj(e)).then(() => {
              const nextInd = index + 1;
              console.log("nextInd=>", nextInd)
              const nextInput = document.getElementById(`T02Ent_${nextInd}`)
              console.log("nextInput=>", nextInput)

              if (nextInput) {
                nextInput.focus()
              }
            })
          }}
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => setDrawerObj(e)}
        />
      </>, open: true, size: "sm", position: "right"
    })
  };

  // console.log("TableExpense===>",TableExpense);
  let SumArray = [];

  const TableExpenseEntry = () => {
    setDrawerObj({
      ...dra, title: <Text fw={700}>Expense Entry</Text>, body: <>
        <ExpenseEntryForm
          // saveBtnPara={(e) => {
          //   console.log("setExpenseSaveBtn=>", e);
          //   setExpenseSaveBtn(e)
          // }}
          ProductObj={selectedProductObj}
          isExpense={true}
          expenseArr={ExpenseData?.dtExp_I}
          expenseArrDef={ExpenseData?.dtExpDef_I}
          M01PData={ExpenseData?.M01SPDT?.jData}
          M01Format={ExpenseData?.M01SP}
          valueSaveObj={T02Obj}
          TableExpenseArr={TableExpense}
          onChange={(e) => {
            console.log("TableExpenseEntry Object=>", e);
            // setSelectedTaxAcc(e.FIELD16)

            if (tableEditingRow == null) {
              console.log("Add Product Call")
              if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
              } else {
                // handleCreateUser(e)

                Promise.resolve(handleCreateUser(e)).then(() => setTimeout(() => {
                  table.setCreatingRow(true)
                }, 500))
              }
            } else {
              console.log("Edit Product Call")
              if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
              } else {
                // handleSaveUser(e)
                Promise.resolve(handleSaveUser(e)).then(() => setTimeout(() => {
                  table.setCreatingRow(true)
                }, 500))
              }
            }

            setT41Obj(prevFieldObj => {
              // Use functional update to avoid overwriting previous state
              return ExpenseData?.dtExp?.reduce((updatedFieldObj, d) => {
                // Only update if the corresponding IA key exists in FieldObj
                if (d?.FIELD54) {
                  return { ...updatedFieldObj, ['EA' + d.FIELD54]: e?.FIELD16 };
                } else {
                  return updatedFieldObj;
                }
              }, { ...prevFieldObj }); // Spread previous state to avoid modifying it directly
            })


            // [...T02Data, e].map((a, i) => console.log(`[...T02Data, e]||${i}==>`, a))

            // let obj2 = { ...T02Obj }

            // Promise.resolve(setT41Obj({ ...T41Obj, FIELD07: [...T02Data, e].reduce((a, b) => a = a + b?.T02F08EXP, 0) })).then(() => {
            //   const filteredObject = Object.keys(e).reduce((acc, key) => {
            //     if (key.startsWith("IA") || key.startsWith("ID") || key.startsWith("IP") || key.startsWith("IT") || key.startsWith("FIELD16") || key.startsWith("T02F08EXP")) {
            //       acc[key] = e[key];
            //     }
            //     return acc;
            //   }, {})

            //   for (const key in filteredObject) {
            //     if (filteredObject.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
            //       obj2[key] = filteredObject[key];
            //     }
            //   }
            //   return obj2

            // }).then((res1) => {
            //   return res1
            // }).then((res2) => {

            //   setTableExpenseVal([...T02Data, res2].reduce((a, b) => {
            //     if (b?.FIELD05 === "C") { // Receipt
            //       a = a + (b?.FIELD08 || 0);
            //     } else if (b?.FIELD05 === "D") { // Issue
            //       a = a - (b?.FIELD08 || 0);
            //     }
            //     return a;
            //   }, 0))
            //   return res2
            // }).then((res3) => {

            //   if (tableEditingRow == null) {
            //     console.log("Add Product Call")
            //     if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
            //       GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
            //     } else {
            //       handleCreateUser(res3)
            //     }
            //   } else {
            //     console.log("Edit Product Call")
            //     if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
            //       GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
            //     } else {
            //       handleSaveUser(res3)
            //     }
            //   }
            //   return res3
            // })

          }}
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => setDrawerObj(e)}
        />
      </>, open: true, size: "50%", position: "right"
    })
  };

  // const FooterExpenseRefresh = () => {
  //   console.log("FooterExpenseRefreshExpenseObj", ExpenseObj);
  //   let newT02Arr = [...(ExpenseObj?.T02Data || [])];

  //   new Promise((resolve) => {
  //     newT02Arr?.map(async (t) => {
  //       return await new Promise((resolve) => {
  //         let newObject = {
  //           ...ExpenseObj,
  //           dtExp_I: ExpenseObj?.dtExp_I?.map((e) => {
  //             let updatedObj = { ...e };
  //             Object.keys(t).map((v) => {
  //               if (v == ("IP" + e?.FIELD54)) {
  //                 updatedObj.EXPPERC = t[v]
  //               } else if (v == ("ID" + e?.FIELD54)) {
  //                 updatedObj.EXPAMT = t[v]
  //               }
  //             })
  //             return updatedObj
  //           }),
  //           T02Obj: { ...t }
  //         }
  //         resolve(newObject)
  //       }).then((res) => {
  //         console.log("resnewObject=>", res);
  //         return RecalcFun(t, "", true, "", res)
  //       }).then((res2) => {
  //         console.log("RecalcFunRes2 =>", res2);
  //         // newT02Arr.push(res2?.T02CalAarray)
  //         return res2?.T02CalAarray
  //       })
  //     })

  //     console.log("newT02Arr =>", newT02Arr);
  //     resolve(newT02Arr)
  //   }).then((res) => {
  //     return RefMeFun({ ...ExpenseObj, T02Data: res })
  //   }).then((res) => {
  //     console.log("RefreshFunResponse 1=>", [{
  //       "RefreshFun": res
  //     }])
  //     let newDtExpArr = [...(res?.newDtExp || [])]
  //     let newT41 = { ...ExpenseObj?.T41Obj }
  //     newDtExpArr?.map((arr, i) => {
  //       if (arr?.FIELD13 in newT41) {
  //         newT41[arr?.FIELD13] = arr?.EXPAMT
  //       }
  //       if (arr?.FIELD23 in newT41) {
  //         newT41[arr?.FIELD23] = arr?.EXPPERC
  //       }

  //     })
  //     console.log("RefreshFunResponse 2", [{
  //       "newT41": newT41
  //     }]);
  //     // setT41Obj(newT41)
  //     return newT41
  //   }).then((resT41) => {

  //     let newObject = {
  //       ...ExpenseObj,
  //       dtExp: ExpenseObj?.dtExp?.map((e) => {
  //         let updatedObj = { ...e };
  //         Object.keys(resT41).map((v) => {
  //           if (v == e?.FIELD23) {
  //             updatedObj.EXPPERC = resT41[v]
  //           } else if (v == e?.FIELD13) {
  //             updatedObj.EXPAMT = resT41[v]
  //           }
  //         })
  //         return updatedObj
  //       }),
  //       T41Obj: { ...resT41 }
  //     }

  //     return RecalcFun(resT41, "", false, "", newObject, true,)
  //   }).then((res2) => {
  //     console.log("Res2T02CalAarrayFinal=>", res2);
  //     // setT41Obj(res2?.T02CalAarray)

  //     // console.log("ExpenseObj T41Object=>", ExpenseObj);
  //     return MidCalc({ ...ExpenseObj, T41Obj: res2?.T02CalAarray })
  //   }).then((res) => {
  //     console.log("MidCalc(ExpenseObj).oT41D =>", res)
  //     setT41Obj(res?.T41Object)
  //     // return RecalcFun(res?.T41Object, "", false, "", ExpenseObj)
  //   })
  // }

  useEffect(() => {
    if (T02Data) {
      if (ExpenseArray?.dtExp && ExpenseArray?.dtExp_I) {
        console.log("HeaderExpenseFlag", HeaderExpenseFlag);
        new Promise((resolve) => {
          let fun = CalCFIPromise({ ...ExpenseObj, Flag: HeaderExpenseFlag })
          resolve(fun)
        }).then((res) => {
          console.log("CalCFIPromiseResponse =>", res);
          if (HeaderExpenseFlag) {
            setT02Data(res?.T02CalCFIData)
            new Promise((resolve) => {
              let dt = MidCalc({ ...ExpenseObj, T41Obj: res?.T02CalAarray })
              // console.log("ExpenseObj T41Object=>", ExpenseObj);
              resolve(dt)
            }).then((res2) => {
              setT41Obj(res2?.T41Object)
            })
          } else {
            setT41Obj(res)
          }

          setHeaderExpenseFlag(false)
        })
      }else{
        new Promise((resolve) => {
          let dt = MidCalc(ExpenseObj)
          // console.log("ExpenseObj T41Object=>", ExpenseObj);
          resolve(dt)
        }).then((res2) => {
          setT41Obj(res2?.T41Object)
        })
      }

    }
  }, [T02Data, InvTypeDropDown,ExpenseArray, FooterExpenseFlag, HeaderExpenseFlag])

  const PriceListTable = () => {
    modals.openContextModal({
      modal: "SITablePriceList",
      title: <Text fw={700}>Price List</Text>,
      zIndex: 300,
      size: "50%",
      withCloseButton: true,
      innerProps: {
        modalBody: (
          <>
            <SITablePriceList />
          </>
        ),
      },
    });
  };

  // useEffect(() => {
  //   if (T02ObjSaveFlag && T41Obj?.FIELD07) {
  //     console.log("T02ObjSaveFlag =>", T02ObjSaveFlag);
  //     console.log("T02ObjSaveFlag ExpenseObj =>", ExpenseObj);
  //     Promise.resolve().then(() => {
  //       return RecalcFun(T41Obj, "", false, "", ExpenseObj)
  //     }).then((res) => {
  //       console.log("T02ObjSaveFlag Footer Expense Response =>", res)
  //       // Promise.resolve(SetFieldObj(res?.T02CalAarray)).then(() => setDtExpTableField(res?.ExpenseCalArray))
  //       Promise.resolve(setExpenseArrayT41(res?.ExpenseCalArray)).then(() => setT41Obj(res?.T02CalAarray))
  //     }).then(() => setT02ObjSaveFlag(false))
  //   }
  // }, [T02ObjSaveFlag, T41Obj?.FIELD07])


  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e?.key == "Escape") {
        if (tableEditingRow == null) {
          console.log("document.addEventListener =>", e);
          table.setCreatingRow(null)
        } else {
          console.log("document.addEventListener =>", e);
          table.setEditingRow(null);
          setTableEditingRow(null)
        }
      }
    })
  }, [tableEditingRow])

  // Edit case Product Detail
  useEffect(() => {
    if (accList["P_M21"]) {
      accList["P_M21"]?.map((p, i) => {
        if (p.FIELD01 == T02Obj?.FIELD03) {
          setSelectedProductObj({ ...p })
        }
      })
    }
  }, [accList["P_M21"]])

  //Add New Row
  const handleCreateUser = (e, multi, status) => {
    // console.log("handleCreateUser para=>", e);
    // console.log("handleCreateUser T02Obj==>", [{
    //   T02Obj
    // }], "#handleCreateUser Event==>", e);

    // setT02Data([...T02Data, {...T02Obj,FIELD08: T02Obj?.FIELD33 * T02Obj?.FIELD07}])
    // BatchLogAddRemove(T02Obj)
    setGroupCatogaryProduct(null)
    let objChange = (e ? { ...e, FIELD09: parseInt(T02Data?.length) + 1 } : { ...T02Obj, FIELD09: parseInt(T02Data?.length) + 1 })

    console.log("handleCreateUser objChange ==>", [{
      objChange: objChange,
      T02DataLength: T02Data?.length,
      multi: multi
    }]);
    if (obj?.id === "02700249" || obj?.id === "02340213") {
      console.log("BatchLogAddRemoveFun call if");
      if (Array.isArray(multi)) {
        dispatch(BatchLogAddRemoveFun({
          PageCode: pagecCode,
          T41Obj: T41Obj,
          T02Data: T02Data,
          Row: multi,
          OBJ: { ...obj, page: obj?.id }
        }))
      } else {
        dispatch(BatchLogAddRemoveFun({
          PageCode: pagecCode,
          T41Obj: T41Obj,
          T02Data: T02Data,
          Row: objChange,
          OBJ: { ...obj, page: obj?.id }
        }))
      }
    } else {
      console.log("BatchLogAddRemoveFun call else");
      dispatch(BatchLogAddRemoveFun({
        PageCode: pagecCode,
        T41Obj: T41Obj,
        T02Data: T02Data,
        Row: objChange,
        OBJ: obj
      }))
    }

    if (Array.isArray(multi)) {
      setT02Data([...multi, ...T02Data])
    } else {
      setT02Data([objChange, ...T02Data]);
    }

    if (obj.p0 == "E") {
      setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
    } else {
      setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 })
    }


    setHeaderExpenseFlag(true)
    setTimeout(() => {
      table.setCreatingRow(null)
      setReceiptIssueObj(null)
      setBatchIssueObj(null)
      setLocationObj(null)
      setSelectedProductObj(null)
      setT02ObjSaveFlag(true)
      setRowSelection({})
    }, 100)


    // table.setEditingRow(null);
  };

  //Update Row
  const handleSaveUser = (e) => {
    // { values, table, row }
    // console.log("handleSaveUser==>", T02Obj);
    // BatchLogAddRemove(T02Obj)
    // console.log("handleSaveUser T02Obj==>", T02Obj ,"#handleSaveUser Event==>",e );
    console.log("tableEditingRow.index=>", tableEditingRow);

    // console.log("handleSaveUser=>", e);

    let objChange = (e ? e : T02Obj)

    console.log("handleSaveUser==>", objChange);
    if (obj?.id === "02700249" || obj?.id === "02340213") {
      dispatch(BatchLogAddRemoveFun({
        PageCode: pagecCode,
        T41Obj: T41Obj,
        T02Data: T02Data,
        Row: objChange,
        OBJ: { ...obj, page: obj?.id }
      }));
    } else {
      dispatch(BatchLogAddRemoveFun({
        PageCode: pagecCode,
        T41Obj: T41Obj,
        T02Data: T02Data,
        Row: objChange,
        OBJ: obj
      }));
    }

    let EditT02Data = [...T02Data];
    EditT02Data[tableEditingRow.index] = objChange;
    // console.log("objChange handleSaveUser=>",objChange);
    setT02Data(EditT02Data)
    setHeaderExpenseFlag(true)

    if (obj.p0 == "E") {
      setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
    } else {
      setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 })
    }


    table.setEditingRow(null); //exit editing mode
    setGroupCatogaryProduct(null)
    setTableEditingRow(null)
    setReceiptIssueObj(null)
    setBatchIssueObj(null)
    setLocationObj(null)
    setSelectedProductObj(null)
    setT02ObjSaveFlag(true)
    setRowSelection({})

  }
  // console.log("tableEditingRow=====>",tableEditingRow);

  const handleNullT41Obj = () => {
    // setT02Obj({
    //   FIELD01: "",
    //   FIELD02: "",
    //   FIELD03: "",
    //   FIELD04: "",
    //   FIELD05: "",
    //   FIELD06: 0.0,
    //   FIELD07: 0.0,
    //   FIELD08: 0.0,
    //   FIELD09: 0.0,
    //   FIELD10: 0.0,
    //   FIELD11: "",
    //   FIELD12: "",
    //   FIELD56: "",
    //   FIELD13: "",
    //   FIELD14: "",
    //   FIELD15: "",
    //   FIELD16: "",
    //   FIELD17: "",
    //   FIELD18: "",
    //   FIELD19: "",
    //   FIELD20: "",
    //   FIELD21: 0.0,
    //   FIELD22: 0.0,
    //   FIELD23: 0.0,
    //   FIELD24: "",
    //   FIELD25: 0.0,
    //   FIELD26: "",
    //   FIELD27: "",
    //   FIELD28: false,
    //   FIELD29: 0.0,
    //   FIELD30: "",
    //   FIELD31: 0.0,
    //   FIELD32: 0.0,
    //   FIELD33: 0.0,
    //   FIELD41: "",
    //   FIELD50: 0.0,
    //   FIELD51: "",
    //   FIELD52: 0.0,
    //   FIELD71: "",
    //   FIELD81: 0.0,
    //   FIELD82: "",
    //   FIELD83: "",
    //   FIELD84: "",
    //   FIELD85: "",
    //   FIELD86: "",
    //   FIELD61: 0.0,
    //   FIELD62: 0.0,
    //   FIELD63: "",
    //   FIELD64: 0.0,
    //   FIELD65: "",
    //   FIELD66: 0.0,
    //   FIELD67: 0.0,
    //   FIELD72: "",
    //   FIELD68: "",
    //   FIELD69: "",
    //   FIELD53: "",
    //   FIELD54: 0.0,
    //   FIELD55: 0.0,
    //   FIELD091: 0.0,
    //   FIELD58: "",
    //   FIELD57: "",
    //   FIELD60: 0.0,
    //   T41PVT: "",
    //   FIELD87: 0.0,
    //   FIELD88: "",
    //   UGRO: 0.0,
    //   URATE: 0.0,
    //   UMUR: 0.0,
    //   UPE: 0.0,
    //   UAP: 0.0,
    //   URE: "",
    //   UISSUERA: 0.0,
    //   ID00000022: 0.0,
    //   IT00000022: "",
    //   IP00000022: 0.0,
    //   IA00000022: "",
    //   ID00000019: 0.0,
    //   IT00000019: "",
    //   IP00000019: 0.0,
    //   IA00000019: "",
    //   ID00000020: 0.0,
    //   IT00000020: "",
    //   IP00000020: 0.0,
    //   IA00000020: "",
    //   ID00000021: 0.0,
    //   IT00000021: "",
    //   IP00000021: 0.0,
    //   IA00000021: "",
    //   ID00000018: 0.0,
    //   IT00000018: "",
    //   IP00000018: 0.0,
    //   IA00000018: "",
    //   ID00000004: 0.0,
    //   IT00000004: "",
    //   IP00000004: 0.0,
    //   IA00000004: "",
    //   ID00000005: 0.0,
    //   IT00000005: "",
    //   IP00000005: 0.0,
    //   IA00000005: "",
    //   ID00000009: 0.0,
    //   IT00000009: "",
    //   IP00000009: 0.0,
    //   IA00000009: "",
    //   ID00000006: 0.0,
    //   IT00000006: "",
    //   IP00000006: 0.0,
    //   IA00000006: "",
    //   ID00000007: 0.0,
    //   IT00000007: "",
    //   IP00000007: 0.0,
    //   IA00000007: "",
    //   ID00000011: 0.0,
    //   IT00000011: "",
    //   IP00000011: 0.0,
    //   IA00000011: "",
    //   ID00000017: 0.0,
    //   IT00000017: "",
    //   IP00000017: 0.0,
    //   IA00000017: "",
    //   ID00000008: 0.0,
    //   IT00000008: "",
    //   IP00000008: 0.0,
    //   IA00000008: "",
    //   ID00000023: 0.0,
    //   IT00000023: "",
    //   IP00000023: 0.0,
    //   IA00000023: "",
    //   ID00000016: 0.0,
    //   IT00000016: "",
    //   IP00000016: 0.0,
    //   IA00000016: "",
    //   ID00000010: 0.0,
    //   IT00000010: "",
    //   IP00000010: 0.0,
    //   IA00000010: "",
    //   ID00000013: 0.0,
    //   IT00000013: "",
    //   IP00000013: 0.0,
    //   IA00000013: "",
    //   ID00000014: 0.0,
    //   IT00000014: "",
    //   IP00000014: 0.0,
    //   IA00000014: "",
    //   ID00000001: 0.0,
    //   IT00000001: "",
    //   IP00000001: 0.0,
    //   IA00000001: "",
    //   ID00000002: 0.0,
    //   IT00000002: "",
    //   IP00000002: 0.0,
    //   IA00000002: "",
    //   ID00000003: 0.0,
    //   IT00000003: "",
    //   IP00000003: 0.0,
    //   IA00000003: "",
    //   ID00000012: 0.0,
    //   IT00000012: "",
    //   IP00000012: 0.0,
    //   IA00000012: "",
    //   ID00000015: 0.0,
    //   IT00000015: "",
    //   IP00000015: 0.0,
    //   IA00000015: "",
    //   FLDUNQ: 0,
    //   FLDAED: "",
    //   FLDBRC: "",
    //   UUDIS: "",
    //   UUUDIS: "",
    //   UUDISAAA: "",
    //   UUGROAAA: "",
    //   UUGR01: "",
    //   UUGRO: "",
    //   USERFLD: "",
    //   USERFLDB: "",
    //   SRDETAIL: "",
    //   EXPDUMMY: " ",
    //   BARCODE: "",
    //   OLDPRD: "",
    //   OLDQTY: 0.0,
    //   OQTY2: 0.0,
    //   OLDRATE: 0.0,
    //   LADDNEW: "Y",
    //   PRICELIST: " ",
    //   _BATSTOCK: " ",
    //   _LOCWISE: " ",
    //   LDUALSTK: " ",
    //   SRNOSTK: " ",
    //   MRPSTK: " ",
    //   RORI: " ",
    //   ORDDUMMY: " ",
    //   SRIDUMMY: " ",
    //   SRRDUMMY: " ",
    //   SRIDUMMYF: " ",
    //   SRRDUMMYF: " ",
    //   SRISSCD: "",
    //   OLDSRNO: "",
    //   SCNOCL: "",
    //   SCNOCL1: "",
    //   _EXPTOTALAMT: 0.0,
    // });
    if (obj.p0 == "E") {
      setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
    } else {
      setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 })
    }
    setGroupCatogaryProduct(null)
    setTimeout(() => {
      table.setCreatingRow(null)
      setReceiptIssueObj(null)
      setBatchIssueObj(null)
      setLocationObj(null)
      setSelectedProductObj(null)
      setT02ObjSaveFlag(true)
      setRowSelection({})
      setTableEditingRow(null)
    }, 100)
  };

  const openDeleteConfirmModal = (row) => {
    console.log("openDeleteConfirmModal", row);
    if (window.confirm('Confirm to Delete ?')) {
      let newArray = T02Data?.filter((d, i) => i != row?.index)
      setT02Data(newArray)
    }
  }

  const table = useMantineReactTable({
    data: T02Data,
    columns,
    enableTopToolbar: true,
    mantineTableContainerProps: {
      sx: { maxHeight: "350px", minHeight: "300px" },
    },
    enableHiding: true,
    positionActionsColumn: "last",
    positionToolbarAlertBanner: "none",
    enableRowActions: true,
    // getRowId: (row) => row.FIELD01,
    initialState: {
      density: "0px"
    },
    state: {
      rowSelection
    },
    enableColumnActions: false,
    enableSorting: true,
    enableEditing: true,
    enableFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    editDisplayMode: "row",
    createDisplayMode: "row",
    enableSelectAll: false,
    enableMultiRowSelection: false,
    onCreatingRowSave: () => {
      if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
        GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
      } else {
        handleCreateUser()
      }
    },
    onEditingRowSave: () => {
      if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
        GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
      } else {
        handleSaveUser()
      }
    },
    onCreatingRowCancel: handleNullT41Obj,
    onEditingRowCancel: handleNullT41Obj,
    // mantineTableProps: () => ({
    //   ref:TableRef,
    //   tabIndex: 0,
    //   onKeyUp: (e) =>handleRowKeyDown(e)
    // }),
    mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
      tabIndex: 0,
      onKeyUp: (e) => handleRowKeyDown(e, row),
      onBlur: (e) => console.log(),
      onClick: () => {
        // console.log("Selected Row==>", row);
        // setSelectedRow(row.original);
        setRowSelection((prev) => {
          // [row.id]: !prev[row.id],
          if (!prev[row.id]) {
            return { [row.id]: true }
          }
          return prev
        })
      },
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              console.log("row.original =>", row?.original);
              dispatch(GetAccountList({
                "id": "P_M21",
                "name": "",
                "type": "A",
                "p0": "P_M21",
                "p1": "P_M21",
                "p2": "",
                "p3": "",
                "cWhere": `M21.FIELD12 LIKE '%${row?.original?.CATCD ?? ""}%'`
                // "cWhere": ""
              }))
              // cWhere={`M21.FIELD12 LIKE '%${T02Obj?.CATCD ?? ""}%'`}

              if (row?.original?.LVALID == "N") {
                GlobalClass?.Notify("warning", "Warning", "Bill prepared for this entry, cannot edit entry")
              } else {
                table.setEditingRow(row)
                setTableEditingRow(row)
                setT02Obj(row.original)
              }
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => {
            console.log("openDeleteConfirmModal =>", row);
            // let newRow = {...row?.original,LVALID:"N"}
            if (row?.original?.LVALID == "N") {
              GlobalClass?.Notify("warning", "Warning", "Bill prepared for this entry, cannot edit entry")
            } else {
              openDeleteConfirmModal(row)
            }
            setHeaderExpenseFlag(true)
          }}>
            <IconTrash size={'20px'} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table,row }) => {
      console.log("renderTopToolbarCustomActions =>",[{
       "table":table, 
       "row":row
      }]);
     return <Button
      disabled={tableEditingRow!=null}
        onFocus={() => {
          if (spoilerControlRef?.current?.innerText == "Hide") {
            spoilerControlRef.current.click()
          }

          setTimeout(() => {
            if (obj?.id === "02700249" || obj?.id === "02340213") {
              if (PendingChallanData?.dtchln?.length > 0 && PendingChallanStatus) {
                Promise.resolve(PendingChallanFun()).then(() => setPendingChallanStatus(false))
              } else {
                Promise.resolve(table.setCreatingRow(true)).then(() => setT02Obj({ ...T02Obj, FIELD05: OS11?.S11F21 }))
              }
            } else {
              Promise.resolve(table.setCreatingRow(true)).then(() => setT02Obj({ ...T02Obj, FIELD05: OS11?.S11F21 })).then(() => {
                // CategoryRef?.current?.focus()
                CategoryRef?.current?.handlePopoverToggle()
              })
            }
          }, 500)
        }}
        onClick={() => {
          setTimeout(() => {
            if (obj?.id === "02700249" || obj?.id === "02340213") {
              if (PendingChallanData?.dtchln?.length > 0 && PendingChallanStatus) {
                // Promise.resolve(PendingChallanFun()).then(() => setPendingChallanStatus(false))
                return
              } else {
                table.setCreatingRow(true);
              }
            } else {
              Promise.resolve(table.setCreatingRow(true)).then(() => {
                // CategoryRef?.current?.focus()
                CategoryRef?.current?.handlePopoverToggle()
              })

            }
            TableRef?.current?.focus()
          }, 500)
        }}
        size="xs"
      >
        Add New
      </Button>
   } ,
  });

  const handleRowKeyDown = (e, row) => {
    console.log("handleRowKeyDown parameter =>", [{
      "e": e,
      "row": row,
      "tableEditingRow": tableEditingRow
    }])
    if (e?.key == "Enter") {
      console.log("handleRowKeyDown parameter enter =>", [{
        "e": e,
        "row": row
      }])

      if (row?.id == 'mrt-row-create') {
        return
      } else {
        table.setEditingRow(row)
        setTableEditingRow(row)
        setT02Obj(row?.original)
      }
    }
    // if (e?.key == "Escape") {
    //   console.log("handleRowKeyDown parameter escape =>", [{
    //     "e": e,
    //     // "row": row
    //   }])
    //   if (tableEditingRow == null) {
    //     console.log("document.addEventListener =>", e);
    //     table.setCreatingRow(null)
    //   } else {
    //     console.log("document.addEventListener =>", e);
    //     table.setEditingRow(null);
    //     setTableEditingRow(null)
    //   }
    // }
  }



  const UFT41HRenderField = (e) => {
    //S13F02 Save UserField
    if (e == "Header") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H?.map((h) => {
          // console.log("User Field=>", h);
          if (h?.S13F87 == "HEADER") {
            //b?.S13F14
            // //console.log("UFT41HRenderHeader==>",h);
            let inputBox;
            switch (h?.S13F14) {
              case "TEXTBOX":
                inputBox = <>{
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        setText={() => {
                          if (obj?.id === "02700249" || obj?.id === "02340213") {
                            if (PendingChallanData?.dtchln?.length > 0) {
                              setPendingChallanStatus(true)
                            } else {
                              setPendingChallanStatus(false)
                            }
                          }
                        }}
                        nmaxlength={h?.S13F04}
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // //console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "NUMBOX":
                inputBox = <>{
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLNumberBox
                        setNumber={() => {
                          if (obj?.id === "02700249" || obj?.id === "02340213") {
                            if (PendingChallanData?.dtchln?.length > 0) {
                              setPendingChallanStatus(true)
                            } else {
                              setPendingChallanStatus(false)
                            }
                          }
                        }}
                        nmaxlength={h?.S13F04}
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "PLPOPUP":

                if (h.S13F07 == "Sales Executive") {
                  inputBox = <>{
                    <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLDataGrid
                          setBlur={() => {
                            if (obj?.id === "02700249" || obj?.id === "02340213") {
                              if (PendingChallanData?.dtchln?.length > 0) {
                                setPendingChallanStatus(true)
                              } else {
                                setPendingChallanStatus(false)
                              }
                            }
                          }}
                          setFocus={(e) => {
                            // console.log("page",e);
                            if (spoilerControlRef?.current?.innerText == "Show more") {
                              spoilerControlRef.current.click()
                            }
                          }}

                          width={"500px"}
                          name={h.S13F02}
                          value={T41Obj?.[h?.S13F02]}
                          p0={seperate(h.S13F09)[0]}
                          p1={seperate(h.S13F09)[1]}
                          // dispexpr={"USALES_E"}
                          setEdit={(e) => {
                            console.log("@P", e);
                            Promise.resolve(setT41Obj({ ...T41Obj, [h?.S13F02]: e?.FIELD01 })).then(() => {
                              if (obj.page == "02210213" && ("IDS00001,IDS00002,IDS00003,IDS00004,IDS00005".includes(T41Obj?.FIELD13))) {

                                // console.log("sales Executive if condition =>",T41Obj?.FIELD13);

                                dispatch(GetMstinfoData({ cCode: e?.FIELD01 }))
                              }
                            })
                            // M01handlechange({ id: item.S13F02, value: e })
                            // setUserFieldSpoiler(true)
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                } else {
                  inputBox = <>{
                    <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLDataGrid
                          setBlur={() => {
                            if (obj?.id === "02700249" || obj?.id === "02340213") {
                              if (PendingChallanData?.dtchln?.length > 0) {
                                setPendingChallanStatus(true)
                              } else {
                                setPendingChallanStatus(false)
                              }
                            }
                          }}
                          width={"500px"}
                          name={h.S13F02}
                          value={T41Obj?.[h?.S13F02]}
                          p0={seperate(h.S13F09)[0]}
                          p1={seperate(h.S13F09)[1]}
                          setEdit={(e) => {
                            //console.log(e);
                            console.log("@P", e);
                            setT41Obj({ ...T41Obj, [h?.S13F02]: e?.FIELD01 });
                            // M01handlechange({ id: item.S13F02, value: e })
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                }

                break;
              case "COMBOBOX":
                inputBox = <>{
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        setComboBox={() => {
                          if (obj?.id === "02700249" || obj?.id === "02340213") {
                            if (PendingChallanData?.dtchln?.length > 0) {
                              setPendingChallanStatus(true)
                            } else {
                              setPendingChallanStatus(false)
                            }
                          }
                        }}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        value={T41Obj?.[h?.S13F02]}
                        copno={seperate(h.S13F09)[1]}
                        // data={}
                        name={h?.S13F07}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        setEdit={(e) => {
                          //console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "DATEPICKER":
                inputBox = <>{
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        onBlur={(e) => {
                          if (obj?.id === "02700249" || obj?.id === "02340213") {
                            if (PendingChallanData?.dtchln?.length > 0) {
                              setPendingChallanStatus(true)
                            } else {
                              setPendingChallanStatus(false)
                            }
                          }
                        }}
                        // value={T41Obj?.[h?.S13F02].trim() == "" ? new Date() : T41Obj?.[h?.S13F02]}
                        // defaultval={T41Obj?.[h?.S13F02]}
                        defaultval={T41Obj?.[h?.S13F02]?.trim() != "" ? T41Obj?.[h?.S13F02] : GetYearAndDate?.strtDate}
                        dispformat="DD/MM/YYYY"
                        setEdit={(e) => {
                          console.log("DATEPICKER=>", e)
                          let year = e.toLocaleString("en-IN", {
                            year: "numeric",
                          });
                          let month = e.toLocaleString("en-IN", {
                            month: "2-digit",
                          });
                          let day = e.toLocaleString("en-IN", {
                            day: "2-digit",
                          });

                          let StringDate = year + month + day;
                          if (StringDate?.length == 8) {
                            setT41Obj({ ...T41Obj, [h?.S13F02]: StringDate });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "TIMEPICKER":
                inputBox = <>{
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        onBlur={(e) => {
                          if (obj?.id === "02700249" || obj?.id === "02340213") {
                            if (PendingChallanData?.dtchln?.length > 0) {
                              setPendingChallanStatus(true)
                            } else {
                              setPendingChallanStatus(false)
                            }
                          }
                        }}
                        // value={T41Obj?.[h?.S13F02].trim() == "" ? new Date() : T41Obj?.[h?.S13F02]}
                        // defaultval={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "MEMOBOX":
                if (h.S13F21 === "N") {
                  inputBox = <>{
                    <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLTextBox
                          setText={() => {
                            if (obj?.id === "02700249" || obj?.id === "02340213") {
                              if (PendingChallanData?.dtchln?.length > 0) {
                                setPendingChallanStatus(true)
                              } else {
                                setPendingChallanStatus(false)
                              }
                            }
                          }}
                          value={T41Obj?.[h?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                } else if (h.S13F21 === "H") {
                  // h.S13F21 === "H"
                  inputBox = <>{
                    <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLTextBox
                          setText={() => {
                            if (obj?.id === "02700249" || obj?.id === "02340213") {
                              if (PendingChallanData?.dtchln?.length > 0) {
                                setPendingChallanStatus(true)
                              } else {
                                setPendingChallanStatus(false)
                              }
                            }
                          }}
                          value={T41Obj?.[h?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                }
                break;
              case "FILEUPLOAD":
                if (h.S13F21 === "I") {
                  inputBox = <>{
                    <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLTextBox
                          setText={() => {
                            if (obj?.id === "02700249" || obj?.id === "02340213") {
                              if (PendingChallanData?.dtchln?.length > 0) {
                                setPendingChallanStatus(true)
                              } else {
                                setPendingChallanStatus(false)
                              }
                            }
                          }}
                          value={T41Obj?.[h?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                } else if (h.S13F21 === "A") {
                  // h.S13F21 === "A"
                  inputBox = <>{
                    <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLTextBox
                          setText={() => {
                            if (obj?.id === "02700249" || obj?.id === "02340213") {
                              if (PendingChallanData?.dtchln?.length > 0) {
                                setPendingChallanStatus(true)
                              } else {
                                setPendingChallanStatus(false)
                              }
                            }
                          }}
                          value={T41Obj?.[h?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                }
                break;

              default:
                break;
            }
            return inputBox
          }
        })
      );
    } else if (e == "Footer") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H?.map((f) => {
          if (f?.S13F87 == "FOOTER") {
            //b?.S13F14
            let inputBox;
            switch (f?.S13F14) {
              case "TEXTBOX":
                inputBox = <>{
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        nmaxlength={f?.S13F04}
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // //console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "NUMBOX":
                inputBox = <>{
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLNumberBox
                        nmaxlength={f?.S13F04}
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "PLPOPUP":
                inputBox = <>{
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDataGrid
                        width={"500px"}
                        name={f.S13F02}
                        value={T41Obj?.[f?.S13F02]}
                        p0={seperate(f.S13F09)[0]}
                        p1={seperate(f.S13F09)[1]}
                        setEdit={(e) => {
                          //console.log(e);
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e?.FIELD01 });
                          // M01handlechange({ id: item.S13F02, value: e })
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "COMBOBOX":
                inputBox = <>{
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLComboBox
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        value={T41Obj?.[f?.S13F02]}
                        copno={seperate(f.S13F09)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        setEdit={(e) => {
                          //console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "DATEPICKER":
                inputBox = <>{
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDateBox
                        // value={T41Obj?.[f?.S13F02].trim() == "" ? new Date() : T41Obj?.[f?.S13F02]}
                        // defaultval={T41Obj?.[f?.S13F02]}
                        defaultval={T41Obj?.[f?.S13F02]?.trim() != "" ? T41Obj?.[f?.S13F02] : GetYearAndDate?.strtDate}
                        dispformat="DD/MM/YYYY"
                        setEdit={(e) => {
                          console.log(e)

                          let year = e.toLocaleString("en-IN", {
                            year: "numeric",
                          });
                          let month = e.toLocaleString("en-IN", {
                            month: "2-digit",
                          });
                          let day = e.toLocaleString("en-IN", {
                            day: "2-digit",
                          });

                          let StringDate = year + month + day;
                          if (StringDate?.length == 8) {
                            setT41Obj({ ...T41Obj, [f?.S13F02]: StringDate });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "TIMEPICKER":
                inputBox = <>{
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDateBox
                        // value={T41Obj?.[f?.S13F02].trim() == "" ? new Date() : T41Obj?.[f?.S13F02]}
                        // defaultval={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "MEMOBOX":
                if (f.S13F21 === "N") {
                  inputBox = <>{
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <PLTextBox
                          value={T41Obj?.[f?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                } else {
                  // h.S13F21 === "H"
                  inputBox = <>{
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <PLTextBox
                          value={T41Obj?.[f?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                }
                break;
              case "FILEUPLOAD":
                if (f.S13F21 === "I") {
                  inputBox = <>{
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <PLTextBox
                          value={T41Obj?.[f?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                } else {
                  // h.S13F21 === "A"
                  inputBox = <>{
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <PLTextBox
                          value={T41Obj?.[f?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                }
                break;

              default:
                break;
            }
            return inputBox
          }
        })
      );
    }
  };

  const CasePartyDetailForm = () => {

    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Case Party Detail</Text>,
      onClickCloseButton: () => Promise.resolve(setCashPartyPopUpCondition({ ...CashPartyPopUpCondition, PopUpFlag: true })).then(() => setDrawerObj({ ...DrawerObj, open: false })),
      body: <>
        <SICasePartyDetail
          SendFIELD32={T41Obj?.FIELD32}
          SaveFIELD32={(e) => {
            console.log("T41Obj.FIELD32 EVENT===>", e);
            Promise.resolve(setT41Obj({ ...T41Obj, FIELD32: e })).then(() => setCashPartyPopUpCondition({ ...CashPartyPopUpCondition, PopUpFlag: true }))
          }}
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => {
            console.log("CasePartyDetailForm =>", e);
            setDrawerObj({ ...DrawerObj, open: false })
          }}
        />
      </>, open: true, size: "50%", position: "right"
    })

    // modals.openContextModal({
    //   modal: "SICasePartyDetail",
    //   title: <Text fw={700}>Case Party Detail</Text>,
    //   zIndex: 300,
    //   size: "lg",
    //   withCloseButton: true,
    //   innerProps: {
    //     modalBody: (
    //       <>
    //         <SICasePartyDetail
    //           FIELD32={(e) => {
    //             //console.log("T41Obj.FIELD32 EVENT===>", e);
    //             setT41Obj({ ...T41Obj, FIELD32: e });
    //           }}
    //         />
    //       </>
    //     ),
    //   },
    // });
  };

  // //console.log("M01PRow========>", M01PRow);

  const BillToBillTableFun = () => {
    document.getElementById("okbutton").focus();

    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Bill to Bill</Text>,
      onClickCloseButton: () => setDrawerObj({ ...DrawerObj, open: false }),
      body: <>
        <PLBillToBill
          OS11Obj={OS11}
          T41ObjSend={T41Obj}
          T05UserField={UFT05H}
          T05Format={T05ENT}
          T05Data={T05DataArray}
          setT05Data={(e) => setT05DataArray(e)}
        />
      </>, open: true, size: "50%", position: "right"
    })

    // modals.openContextModal({
    //   modal: "PLBillToBill",
    //   title: <Text fw={700}>Bill to Bill</Text>,
    //   zIndex: 300,
    //   size: "70%",
    //   withCloseButton: true,
    //   innerProps: {
    //     modalBody: (
    //       <>
    //         <PLBillToBill
    //           T05Format={T05ENT}
    //           T05Data={T05DataArray}
    //           setT05Data={(e) => setT05DataArray(e)}
    //         />
    //       </>
    //     ),
    //   },
    // });
  };

  const SalesInvoiceSubmit = (e) => {
    dispatch(DataSaveLoading(true));
    // //console.log("T41Obj==>", T41Obj);
    // console.log("T05Data=>", T05DataArray);

    // console.log("T02Data=>", T02Data);
    // console.log("TableExpense=>", TableExpense);

    // let PostObj = { "T41": { ...T41Obj, FIELD06: rowSum ,FIELD07:T02Data.reduce((a,b)=>a=a+b?.FIELD90,0) }, "T02": [...T02Data], "T05": [...T05DataArray] }
    // let PostObj = { T41: T41Obj, T02: [...TableExpense] };
    // FIELD07: T41F07RowSum
    let PostObj = { "T41": obj.p0 == "U" ? e : { ...T41Obj }, "T02": [...T02Data], "T05": (OSV?._AUTOBTOB == "Y" ? [...T05] : [...T05DataArray]) }

    GlobalClass.Notify(
      "info",
      obj.p0 == "A" ? "Adding" : "Editing",
      "Please wait while we process your data"
    );

    console.log("ObjSend==>", [{ "PostObj": PostObj, "pagecCode": pagecCode, "obj": obj }]);

    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj.p0 == "A" ? "A" : "E",
      cCode: obj.p0 == "A" ? pagecCode : obj.id,
      cOPara: obj.id == "02340213"
        ? "CTYPE~C~S!"
        : obj.id == "02700249"
          ? "CTYPE~C~P!" : "",
      cSData: JSON.stringify(PostObj),
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj.p0 == "A" ? "A" : "E",
      cCode: obj.p0 == "A" ? pagecCode : obj.id,
      cOPara: obj.id == "02340213"
        ? "CTYPE~C~S!"
        : obj.id == "02700249"
          ? "CTYPE~C~P!" : ""
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "SUCCESS") {
          let PaginationObj =
            Store.getState().DataSaveLoadingSlice.PaginationObj;
          Promise.resolve(dispatch(DataSaveLoading(false)))
            .then(() => {
              if (obj && pagecCode != "" && obj?.p0 == "A") {
                if (obj?.id === "02700249" || obj?.id === "02340213") {
                  Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(() => Store.dispatch(GetPendingQuotationDataNull([]))).then(() => Store.dispatch(GetSingleSelectedOrderRowNull([]))).then(() => Store.dispatch(GetPendingOrderDataNull([]))).then(() => Store.dispatch(GetSingleOrderRowNull([]))).then(() => Store.dispatch(GetExpenseDataNull([]))).then(() => Store.dispatch(GetNullTransactionData())).then(() => {
                    setM01PRow(null)
                    setBillSeriesHideShow({
                      BillNoVal: "",
                      TextInput: false,
                      DataGridInput: true
                    })
                  }).then(() => setInvAPIStatus(true)).then(() => dispatch(
                    TransactionApi({
                      p0: "A",
                      p1: "",
                      p2: "",
                      page: obj?.id,
                      text: "Add",
                    })
                  ))
                } else {
                  Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(() => Store.dispatch(GetPendingQuotationDataNull([]))).then(() => Store.dispatch(GetSingleSelectedOrderRowNull([]))).then(() => Store.dispatch(GetPendingOrderDataNull([]))).then(() => Store.dispatch(GetSingleOrderRowNull([]))).then(() => Store.dispatch(GetExpenseDataNull([]))).then(() => Store.dispatch(GetNullTransactionData())).then(() => {
                    setM01PRow(null)
                    setBillSeriesHideShow({
                      BillNoVal: "",
                      TextInput: false,
                      DataGridInput: true
                    })
                  }).then(() => setInvAPIStatus(true)).then(() => {
                    dispatch(TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 }))
                  })
                  // .then(() => {
                  //   if (obj?.p0 == "U") {
                  //     dispatch(GetUserFieldObj(OS11?.S11F01))
                  //   }
                  // })
                }
              } else {
                dispatch(ModalDelete(props.index))
              }
            })
            .then(() => {
              if (obj.page == "02210213") {
                // sales Entry > sale invoice 1
                dispatch(
                  GetAccountList({
                    id: "02210213",
                    name: "Sales Invoice",
                    p0: "MT",
                    p1: "T41VLISTSSSS",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["02210213"],
                  })
                );
              } else if (obj.page == "02230213") {
                // sales Entry > sale invoice 2
                dispatch(
                  GetAccountList({
                    id: "02230213",
                    name: "Sales Invoice",
                    p0: "MT",
                    p1: "T41VLISTSSWS",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["02230213"],
                  })
                );
              } else if (obj.page == "02270213") {
                // sales Entry > sale return 1
                dispatch(
                  GetAccountList({
                    id: "02270213",
                    name: "Sales Return",
                    p0: "MT",
                    p1: "T41VLISTSRWR",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["02270213"],
                  })
                );
              } else if (obj.page == "02250213") {
                // sales Entry > sale return 2
                dispatch(
                  GetAccountList({
                    id: "02250213",
                    name: "Sales Return",
                    p0: "MT",
                    p1: "T41VLISTSRSR",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["02250213"],
                  })
                );
              } else if (obj.page == "02280213") {
                // sales Entry > sale Challan
                dispatch(
                  GetAccountList({
                    id: "02280213",
                    name: "Sales Challan",
                    p0: "MT",
                    p1: "T41VLISTHSHS",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["02280213"],
                  })
                );
              } else if (obj.page == "02430213") {
                // sales Entry > sale Order
                dispatch(
                  GetAccountList({
                    id: "02430213",
                    name: "Sales Order",
                    p0: "MT",
                    p1: "T41VLISTOSOS",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["02430213"],
                  })
                );
              } else if (obj.page == "02460213") {
                // sales Entry > sale Quotation
                dispatch(
                  GetAccountList({
                    id: "02460213",
                    name: "Quotation",
                    p0: "MT",
                    p1: "T41VLISTQTQT",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["02460213"],
                  })
                );
              } else if (obj.page == "02530249") {
                // Purchase Entry > Purchase invoice-2
                dispatch(
                  GetAccountList({
                    id: "02530249",
                    name: "Purchase Invoice",
                    p0: "MT",
                    p1: "T41VLISTPPPP",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["02530249"],
                  })
                );
              } else if (obj.page == "02590249") {
                // Purchase Entry > Purchase Return-1
                dispatch(
                  GetAccountList({
                    id: "02590249",
                    name: "Purchase Return",
                    p0: "MT",
                    p1: "T41VLISTPRPR",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["02590249"],
                  })
                );
              } else if (obj.page == "02670249") {
                // Purchase Entry > Purchase Challan
                dispatch(
                  GetAccountList({
                    id: "02670249",
                    name: "Purchase Challan",
                    p0: "MT",
                    p1: "T41VLISTHPHP",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["02670249"],
                  })
                );
              } else if (obj.page == "02700249") {
                // Purchase Entry > Bill From Challan
                // dispatch(GetAccountList({
                //     id: "02210213",
                //     name: "Sales Invoice",
                //     p0: "MT",
                //     p1: "T41VLISTSSSS",
                //     p2: "",
                //     p3: "",
                //     type: "A",
                //     pagination: PaginationObj["02210213"]
                // }))
                return "";
              } else if (obj.page == "02760249") {
                // Purchase Entry > Purchase Order
                dispatch(
                  GetAccountList({
                    id: "02760249",
                    name: "Purchase Order",
                    p0: "MT",
                    p1: "T41VLISTOPOP",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["02760249"],
                  })
                );
              } else {
                // sales Entry > sale return 2
                dispatch(
                  GetAccountList({
                    id: "02210213",
                    name: "Sales Invoice",
                    p0: "MT",
                    p1: "T41VLISTSSSS",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["02210213"],
                  })
                );
              }
            })
            .then(() =>

              GlobalClass.Notify(
                "success",
                obj.p0 == "A" ? "Added" : "Edited",
                obj.p0 == "A" ? "Added successfully" : "Edited successfully"
              )
            ).then(() => Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(() => Store.dispatch(GetPendingQuotationDataNull([]))).then(() => Store.dispatch(GetSingleSelectedOrderRowNull([]))).then(() => Store.dispatch(GetPendingOrderDataNull([]))).then(() => Store.dispatch(GetSingleOrderRowNull([])))).then(() => dispatch(GetExpenseDataNull([])))
        } else if (data.status == "FAIL") {
          Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
            GlobalClass.Notify("error", data?.status, data?.message)
          )
        }
      })
      .catch((e) =>
        Promise.resolve(
          GlobalClass.Notify("error", "Error", `${e.message}`)
        ).then(() => dispatch(DataSaveLoading(false)))
      );
  };

  const RefreshExpense = () => {
    console.log("Click RefreshExpense");
  };

  //console.log("TransactionSalesFormData==>", TransactionSalesFormData);

  //console.log("rowSum==>", rowSum)

  // console.log("priceListParemeter==>", priceListParemeter);


  // useEffect(() => {
  //   if (BillSeriesHideShow.BillNoVal.length <= 0) {
  //     setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
  //   }
  // }, [BillSeriesHideShow.BillNoVal])



  const PendingChallanFun = () => {
    console.log("Pending challan call");
    // Promise.resolve(table.setCreatingRow(true)).then(() => setT02Obj({ ...T02Obj, FIELD05: OS11?.S11F21 }))
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Pending Challan</Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => table.setCreatingRow(true)).then(() => setT02Obj({ ...T02Obj, FIELD05: OS11?.S11F21 }))
      },
      body: <>
        <SIPendingChallanTable
          ChallanData={PendingChallanData?.dtchln}
          ChallanFormat={PendingChallanData?.T02C}
          selectedChallanArray={selectedChallan}
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => setDrawerObj(e)}
          selectedPendingChallan={(e) => {
            // console.log("selectedPendingChallan=>", e);
            // let Field03Name = accList["P_M21"]?.filter((p, i) => p?.FIELD01 == SingleSelectedOrderRow?.dtT02Q[0]?.FIELD03)
            if (e?.selectedChallan?.length > 0) {
              let newArr = [];
              e?.selectedChallan?.map((c, i) => {
                newArr.push({
                  ...c,
                  FIELD01: "",
                  CATCD: c?.M32F01,
                  CATNM: c?.M32F02,
                  F03NM: c?.M21F02,
                  FIELD09: T02Data?.length + i,
                  FIELD13: c?.FIELD01 + String(c?.FIELD09).substring(0, 4)
                })
              })

              //Challan Expense Calculation
              console.log("Pending Challan newArr 1 =>", newArr);
              let newArr2 = [];
              newArr?.map(async (n, i) => {
                let productObjFind = {}
                let newObject = {}
                let dt;

                new Promise((resolve, reject) => {
                  accList["P_M21"]?.map((p, i) => {
                    if (p?.FIELD01 == n?.FIELD03) {
                      productObjFind = { ...p }
                    }
                  })
                  resolve(productObjFind)
                }).then((PObject) => {

                  // console.log("PObject", PObject);
                  let ExpArr = [...(ExpenseArray?.dtExp_I || [])]

                  newObject = {
                    ...ExpenseObj,
                    dtExp_I: ExpArr?.map((e) => {
                      let updatedObj = { ...e };
                      Object.keys(n).map((v) => {
                        if (v == ("IP" + e?.FIELD54)) {
                          updatedObj.EXPPERC = n[v]
                        } else if (v == ("ID" + e?.FIELD54)) {
                          updatedObj.EXPAMT = n[v]
                        }
                      })
                      return updatedObj
                    }),
                    ItemCode: PObject?.FIELD03,
                    selectedProductObj: PObject,
                    T02Obj: n
                  }

                  console.log("UseEffect Itemwise Expense Open =>", [{
                    "newObject": newObject
                  }]);
                  return newObject
                }).then((newobj) => {
                  console.log("newobj======>", newobj);
                  dt = AssignItemDefault(newobj);
                  return { dt, newobj }
                }).then((expdt) => {
                  // let a = expdt.dt.dtExpArr;
                  // let b = expdt.dt.T02Object;
                  // let c = expdt.newobj
                  // console.log("AssignItemDefault response =>", [{ "expdt.T02Object": expdt.T02Object,"expdt":expdt.dtExpArr }]);
                  console.log("expdt =>", expdt);
                  return RecalcFun(expdt?.dt?.T02Object, "", true, "", { ...expdt.newobj, dtExp_I: expdt.dt.dtExpArr })
                }).then((res) => {
                  console.log("Response RecalcFun =>", res)
                  // newArr[i] = res?.T02CalAarray
                  newArr2.push(res?.T02CalAarray)

                  return RecalcFun(T41Obj, "", false, "", { ...ExpenseObj, T02Obj: res?.T02CalAarray })
                  // Promise.resolve(SetFieldObj(res?.T02CalAarray)).then(() => setDtExpTableField(res?.ExpenseCalArray))
                }).then((res) => setT41Obj(res?.T02CalAarray))
              })
              setTimeout(() => {
                console.log("Pending Challan newArr 2 =>", newArr2);

                //Add Row in T02 Table
                Promise.resolve(handleCreateUser("", newArr2)).then(() => setTimeout(() => { table.setCreatingRow(true) }, 500))
              }, 500)
            }

            setChallanData(e?.challanData)
            setSelectedChallan(e?.selectedChallan)
          }}
        />
      </>, open: true, size: "85%", position: "right"
    })
  }

  useEffect(() => {
    if (SingleSelectedOrderRow?.dtT02Q?.length > 0) {
      // setT02Data([...T02Data, ...SingleSelectedOrderRow])
      console.log("SingleSelectedOrderRow?.dtT02Q useeffect", SingleSelectedOrderRow);
      T02OQuotationRowFun()
    }
  }, [SingleSelectedOrderRow?.dtT02Q])

  useEffect(() => {
    if (SingleOrderRow?.dtT02O?.length > 0) {
      console.log("SingleOrderRow useEffect =>", SingleOrderRow);
      T02OOrderRowFun()
    }
  }, [SingleOrderRow?.dtT02O])

  const T02OQuotationRowFun = () => {
    let FIELD09Format = formatField09(SingleSelectedOrderRow?.dtT02Q[0]?.FIELD09)
    let Field03Name = accList["P_M21"]?.filter((p, i) => p?.FIELD01 == SingleSelectedOrderRow?.dtT02Q[0]?.FIELD03)

    let updatedObject = { ...T02Obj }
    let object2 = { ...SingleSelectedOrderRow?.dtT02Q[0] }

    Object.keys(object2).forEach(key => {
      if (key in updatedObject) {
        updatedObject[key] = object2[key]; // Assign object2's value to object1's key if they match
      }
    })

    let createT02Row = {
      ...updatedObject,
      FIELD01: "",
      CATCD: object2?.M32F01,
      CATNM: object2?.M32F02,
      F03NM: Field03Name[0]?.FIELD02,
      FIELD06: parseFloat(object2?.FIELD06) - parseFloat(object2?.FIELD50),
      FIELD09: T02Data?.length + 1,
      FIELD14: object2?.FIELD01 + FIELD09Format,
      FIELD21: parseFloat(object2?.FIELD21) - parseFloat(object2?.FIELD52)
    }
    console.log("T02OQuotationRowFun createT02Row =>", [{
      "createT02Row": createT02Row,
      "object2": object2
    }]);



    // Promise.resolve(handleCreateUser(createT02Row)).then(() => setTimeout(() => { table.setCreatingRow(true) }, 500)) // Old code

    Promise.resolve(table.setCreatingRow(true)).then(() => setT02Obj(createT02Row))

    // setT02Data([...T02Data, {...createT02Row}])
    // let newT02Arr = [...T02Data, {...createT02Row}]
    // console.log("newT02Arr =>",newT02Arr);
  }

  const T02OOrderRowFun = () => {
    let FIELD09Format = formatField09(SingleOrderRow?.dtT02O[0]?.FIELD09)
    let Field03Name = accList["P_M21"]?.filter((p, i) => p?.FIELD01 == SingleOrderRow?.dtT02O[0]?.FIELD03)
    setFIELD01value(SingleOrderRow?.dtT02O[0]?.FIELD03)

    let updatedObject = { ...T02Obj }
    let object2 = { ...SingleOrderRow?.dtT02O[0] }

    Object.keys(object2).forEach(key => {
      if (key in updatedObject) {
        updatedObject[key] = object2[key]; // Assign object2's value to object1's key if they match
      }
    })


    let createT02Row = {
      ...updatedObject,
      FIELD01: "",
      CATCD: object2?.M32F01,
      CATNM: object2?.M32F02,
      F03NM: Field03Name[0]?.FIELD02,
      FIELD06: parseFloat(object2?.FIELD06) - parseFloat(object2?.FIELD50),
      FIELD09: T02Data?.length + 1,
      FIELD14: object2?.FIELD01 + FIELD09Format,
      FIELD21: parseFloat(object2?.FIELD21) - parseFloat(object2?.FIELD52)
    }
    console.log("T02OOrderRowFun createT02Row =>", createT02Row);
    // handleCreateUser(createT02Row)
    Promise.resolve(table.setCreatingRow(true)).then(() => setT02Obj(createT02Row))
  }

  // PendingQuotationData
  const PendingQuotationFun = () => {
    setDrawerObj({
      ...dra,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
      },
      title: <Text fw={700}>Pending Quotation</Text>,
      body: <>
        <PendingQuotationTable
          QuotationData={PendingQuotationData?.dtQuo}
          QuotationFormat={PendingQuotationData?.T02Q}
          selectedQuotation={selectedQuotation}
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => setDrawerObj(e)}
          selectedPendingQuotation={(e) => {
            console.log("Selected Pending Quotation=>", [{
              "e": e,
              "e[0]": e?.selectedQuotation[0]
            }]);

            if (e?.selectedQuotation?.length > 0) {
              let FIELD09 = formatField09(e?.selectedQuotation[0]?.FIELD09)
              dispatch(GetSingleSelectedOrder({ code: e?.selectedQuotation[0]?.FIELD01 + FIELD09 }))
            }

            setSelectedQuotation(e?.selectedQuotation)
            setTimeout(() => {
              // NextIndexFun(PendingOrderIndex)
              NextIndexFun(1)
            }, 1000)
          }}
        />
      </>, open: true, size: "85%", position: "right"
    })
    console.log("Pending Quotation Call");
  }

  //Pending Order
  const PendingOrderFun = (index) => {
    console.log("PendingOrderFun index =>", [{
      "index": index
    }]);
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Pending Order</Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
      },
      body: <>
        <PendingOrderTable
          OrderData={PendingOrderData?.dtOrd}
          OrderFormat={PendingOrderData?.T02O}
          selectedOrder={selectedOrder}
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => setDrawerObj(e)}
          selectedPendingOrder={(e) => {
            console.log("Selected Pending Order=>", [{
              "e": e,
              "e[0]": e?.selectedOrder[0]
            }]);

            if (e?.selectedOrder?.length > 0) {
              let FIELD09 = formatField09(e?.selectedOrder[0]?.FIELD09)
              dispatch(GetSingleOrder({ code: e?.selectedOrder[0]?.FIELD01 + FIELD09 }))
            }

            setSelectedOrder(e?.selectedOrder)
            setTimeout(() => {
              // NextIndexFun(PendingOrderIndex)
              let ind = (index ? index : 1)
              NextIndexFun(ind)
            }, 1000)
            // setNextIndexStatus(index)
          }}
        />
      </>, open: true, size: "85%", position: "right"
    })

  }

  const formatField09 = (field09) => {
    // Ensure field09 is a string
    const strField09 = String(field09);

    // Pad with spaces or truncate to 4 characters
    if (strField09.length < 4) {
      return strField09.padStart(4, ' ');
    } else {
      return strField09.substring(0, 4);
    }
  };


  // console.log("T02Obj console", [{
  //   "T02Obj": T02Obj,
  //   "OS11?.S11F21": OS11?.S11F21
  // }]);

  const spoilerControlRef = useRef()
  // console.log("spoilerControlRef========>", spoilerControlRef);
  // console.log("fieldDisable?.UserFieldHideShow=>", fieldDisable?.UserFieldHideShow)

  // useEffect(()=>{
  //   // T92F00,FIELD12
  //   if(accList["02210213"] && accList["02210213"].filter((a)=>a?.T41BNO == T41Obj?.T92F00 || a?.T41BNO == T41Obj?.FIELD12)?.length > 0){
  //     // "".includes(accList["02210213"]?.T41BNO)
  //     // (`${T41Obj?.T92F00},${T41Obj?.FIELD12}`.includes(accList["02210213"]?.T41BNO))
  //     console.log("Duplicate Value log call");
  //     GlobalClass.Notify("warning","Warning","Duplicate Value")

  //   }
  // },[T41Obj?.T92F00,T41Obj?.FIELD12])

  const HandleUserFieldPopUp = (arr, e) => {
    console.log("HandleUserFieldPopUp =>", [{
      arr: arr,
      e: e,
      "H filter": arr?.filter((af) => af?.S13HMF == "H"),
      "F filter": arr?.filter((af) => af?.S13HMF == "F")
    }]);
    return arr?.filter((af) => af?.S13HMF == e)?.map((f) => {
      //b?.S13F14

      switch (f?.S13F14) {
        case "TEXTBOX":
          return <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              padding: 0,
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <PLTextBox
                nmaxlength={f?.S13F04}
                value={T41Obj?.[f?.S13F02]}
                setEdit={(e) => {
                  // //console.log(e)
                  setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                }}
              />
            </Grid.Col>
          </Grid.Col>

          break;
        case "NUMBOX":
          return <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              padding: 0,
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <PLNumberBox
                nmaxlength={f?.S13F04}
                value={T41Obj?.[f?.S13F02]}
                setEdit={(e) => {
                  //console.log(e)
                  setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                }}
              />
            </Grid.Col>
          </Grid.Col>

          break;
        case "PLPOPUP":
          return <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              padding: 0,
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <PLDataGrid
                width={"500px"}
                name={f.S13F02}
                value={T41Obj?.[f?.S13F02]}
                p0={seperate(f.S13F09)[0]}
                p1={seperate(f.S13F09)[1]}
                setEdit={(e) => {
                  //console.log(e);
                  setT41Obj({ ...T41Obj, [f?.S13F02]: e?.FIELD01 });
                  // M01handlechange({ id: item.S13F02, value: e })
                }}
              />
            </Grid.Col>
          </Grid.Col>

          break;
        case "COMBOBOX":
          return <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              padding: 0,
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <PLComboBox
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                value={T41Obj?.[f?.S13F02]}
                copno={seperate(f.S13F09)[1]}
                dispexpr={"DisplayMember"}
                valexpr={"ValueMember"}
                setEdit={(e) => {
                  //console.log(e)
                  setT41Obj({ ...T41Obj, [f?.S13F02]: e.value });
                }}
              />
            </Grid.Col>
          </Grid.Col>

          break;
        case "DATEPICKER":
          return <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              padding: 0,
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <PLDateBox
                // value={T41Obj?.[f?.S13F02].trim() == "" ? new Date() : T41Obj?.[f?.S13F02]}
                // defaultval={T41Obj?.[f?.S13F02]}
                defaultval={T41Obj?.[f?.S13F02]?.trim() != "" ? T41Obj?.[f?.S13F02] : GetYearAndDate?.strtDate}
                dispformat="DD/MM/YYYY"
                setEdit={(e) => {
                  console.log(e)

                  let year = e.toLocaleString("en-IN", {
                    year: "numeric",
                  });
                  let month = e.toLocaleString("en-IN", {
                    month: "2-digit",
                  });
                  let day = e.toLocaleString("en-IN", {
                    day: "2-digit",
                  });

                  let StringDate = year + month + day;
                  if (StringDate?.length == 8) {
                    setT41Obj({ ...T41Obj, [f?.S13F02]: StringDate });
                  }
                }}
              />
            </Grid.Col>
          </Grid.Col>

          break;
        case "TIMEPICKER":
          return <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              padding: 0,
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <PLDateBox
                // value={T41Obj?.[f?.S13F02].trim() == "" ? new Date() : T41Obj?.[f?.S13F02]}
                // defaultval={T41Obj?.[f?.S13F02]}
                setEdit={(e) => {
                  console.log(e)
                  setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                }}
              />
            </Grid.Col>
          </Grid.Col>

          break;
        case "MEMOBOX":
          if (f.S13F21 === "N") {
            return <Grid.Col
              span={6}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLTextBox
                  value={T41Obj?.[f?.S13F02]}
                  setEdit={(e) => {
                    //console.log(e)
                    setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                  }}
                />
              </Grid.Col>
            </Grid.Col>

          } else {
            // h.S13F21 === "H"
            return <Grid.Col
              span={6}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLTextBox
                  value={T41Obj?.[f?.S13F02]}
                  setEdit={(e) => {
                    //console.log(e)
                    setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                  }}
                />
              </Grid.Col>
            </Grid.Col>

          }
          break;
        case "FILEUPLOAD":
          if (f.S13F21 === "I") {
            return <Grid.Col
              span={6}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLTextBox
                  value={T41Obj?.[f?.S13F02]}
                  setEdit={(e) => {
                    //console.log(e)
                    setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                  }}
                />
              </Grid.Col>
            </Grid.Col>

          } else {
            // h.S13F21 === "A"
            return <Grid.Col
              span={6}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLTextBox
                  value={T41Obj?.[f?.S13F02]}
                  setEdit={(e) => {
                    //console.log(e)
                    setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                  }}
                />
              </Grid.Col>
            </Grid.Col>

          }
          break;

        default:
          break;
      }
      // return inputBox

    })
  }

  const HandleHistoryBtn = () => {
    setDrawerObj({
      ...dra, title: <Text fw={700}>History</Text>, body: <>
        <PLDataGrid
          // ref={PurcSaleRef}
          width={"500px"}
          // value={T41Obj?.FIELD05}
          // value={{ value: T41Obj?.FIELD05, label: T41Obj?.FIELD05NM }}
          TmData={"P_T41"}
          // data={ExpenseData?.M01SPDT?.jData}
          // cWhere={`T41.FIELD04 LIKE '%${T02Obj?.CATCD ?? ""}%'`}
          cWhere={`T41.FIELD04 LIKE '%${T41Obj?.FIELD04 ?? ""}%' OR T41.FIELD31 LIKE '%${T41Obj?.FIELD04 ?? ""}%'`}
          setEdit={(e) => {
            console.log("HandleHistoryBtn=>", e)
            // setT41Obj({ ...T41Obj, FIELD05: e.FIELD01, FIELD05NM: e?.FIELD02 });

            // Promise.resolve(dispatch(TransactionApi({
            //   id: e?.FIELD01,
            //   p0: "E",
            //   page: obj?.page,
            //   text: "Edit"
            // }))).then(() => setInvAPIStatus(true)).then(() => {
            //   if (obj?.page == "02210213" || obj?.page == "02230213" || obj?.page == "02280213" || obj?.page == "02430213" || obj?.page == "02530249" || obj?.page == "02670249") {
            //     dispatch(GetAccountList({
            //       "id": "P_M21",
            //       "name": "",
            //       "type": "A",
            //       "p0": "P_M21",
            //       "p1": "P_M21",
            //       "p2": "",
            //       "p3": ""
            //     }))
            //   }
            // })
          }}
        />

      </>, open: true, size: "50%", position: "right"
    })
  }

  const RowDetailHandle = (e) => {
    console.log("RowDetailHandle =>", e);

    let productObjFind = {};
    let cStr = "";
    accList["P_M21"]?.forEach((p, i) => {
      if (p?.FIELD01 == e?.FIELD03) {
        productObjFind = { ...p }
      }
    })

    let FUnit = (e?.FIELD05 == 'I' || e?.FIELD05 == 'D') ? (productObjFind?.FIELD06 == "" ? oYear?.OSC?._UNIT1 : productObjFind?.FIELD06) : (productObjFind?.FIELD05 == "" ? oYear?.OSC?._UNIT1 : productObjFind?.FIELD05);

    // let cStr = cStr + FUnit + "  " + productObjFind.FIELD98 + "   ";

    cStr = cStr + FUnit + "  " + productObjFind?.FIELD98 + "   ";

    if (oYear?.OSC?._DUALSTOCK == "Y" && productObjFind?.FIELD59 == 'Y') {
      let SUnit = productObjFind?.FIELD27 == "" ? oYear?.OSC?._UNIT2 : productObjFind?.FIELD27
      return cStr + SUnit + "  " + productObjFind?.FIELD93
    } else {
      return cStr
    }

  }

  return (<>
    {hasError ? <>
      <Text>{ErrorMsg?.message}</Text>
      <Button onClick={() => {
        if (obj && pagecCode != "") {
          if (obj?.id === "02700249" || obj?.id === "02340213") {
            Promise.resolve(dispatch(
              TransactionApi({
                p0: "A",
                p1: "",
                p2: "",
                page: obj?.id,
                text: "Add",
              })
            )).then(() => dispatch(GetAccountList({
              "id": "P_M21",
              "name": "",
              "type": "A",
              "p0": "P_M21",
              "p1": "P_M21",
              "p2": "",
              "p3": ""
            })))
          } else {
            Promise.resolve(dispatch(TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 }))).then(() => setInvAPIStatus(true)).then(() => {
              if (obj?.page == "02210213" || obj?.page == "02230213" || obj?.page == "02280213" || obj?.page == "02430213" || obj?.page == "02530249" || obj?.page == "02670249") {
                dispatch(GetAccountList({
                  "id": "P_M21",
                  "name": "",
                  "type": "A",
                  "p0": "P_M21",
                  "p1": "P_M21",
                  "p2": "",
                  "p3": ""
                }))
              }
            }).then(() => {
              if (obj?.p0 == "U") {
                dispatch(GetUserFieldObj(OS11?.S11F01))
              }
            })
          }
        }
      }}>Retry</Button>
    </> : <>
      {
        SaveLoading == true ? GlobalClass.RenderLoader('dots') :
          <>
            {
              isLoading || isUserFieldLoading ? GlobalClass.RenderLoader('dots') : <>
                {
                  obj?.p0 == "U" ? <>
                    <PLUserFieldBtn
                      UserFieldArray={UserField?.UFTbl}
                      T41={T41Obj}
                      onChange={(e) => {
                        // Promise.resolve(setT41Obj(e)).then(()=>
                        SalesInvoiceSubmit(e)
                        // ) 
                      }}
                    />
                  </> :
                    <div ref={formRef}>
                      <Modal
                        opened={DrawerObj?.open}
                        // onClose={close}
                        withCloseButton={true}

                        fullScreen={false}//
                        // overlayProps={{ opacity: 0.5, blur: 4 }}
                        closeButtonProps={{
                          onClick: (e) => {

                            if (typeof DrawerObj?.onClickCloseButton == "function") {
                              DrawerObj?.onClickCloseButton();
                            }
                            else {
                              setDrawerObj(dra);
                            }
                          },
                        }}
                        title={DrawerObj?.title}
                        size={DrawerObj?.size}
                        // centered={.Centered}
                        closeOnClickOutside={true}
                        // overlayProps={m.Overlay}
                        position={DrawerObj?.position ?? 'bottom'}
                        onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
                      // scrollAreaComponent={ScrollArea.Autosize}
                      >
                        {DrawerObj.body}
                      </Modal>
                      <Paper>
                        <Grid gutter={4}>
                          <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                            <Grid.Col span={4} style={{ alignItems: 'center', padding: 0 }} >
                              <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                <Grid.Col span={3}>
                                  <Text style={{ fontSize: 12 }}>Cash/Debit:</Text>
                                </Grid.Col>
                                <Grid.Col span={6}>
                                  <PLComboBox
                                    initiallyOpened
                                    autoFocus
                                    onKeyDown={(e) => {
                                      GlobalClass.formNextInput(e, formRef);
                                    }}
                                    value={T41Obj?.FIELD16}
                                    data={COP8 || []}
                                    dispexpr={"DisplayMember"}
                                    valexpr={"ValueMember"}
                                    setEdit={(e) => {
                                      console.log("Case/Debit=>", e)
                                      Promise.resolve(setT41Obj({ ...T41Obj, FIELD16: e }))
                                        .then(() => {
                                          // PartyAcRef?.current?.handlePopoverToggle()
                                          // PartyAcRef.current?.focus()
                                          // console.log("PartyAcRef?.current =>", PartyAcRef?.current)
                                          // id={"PartyAc"}
                                          setTimeout(() => {
                                            console.log('PartyAcRef?.current =>', PartyAcRef?.current);
                                            // document.getElementById("PartyAc").focus()
                                            PartyAcRef?.current?.handlePopoverToggle()
                                            // PartyAcRef?.current?.autoFocus(true)
                                          }, 500)
                                        })
                                    }}
                                  />
                                </Grid.Col>
                                <Grid.Col span={3}>
                                  <Text style={{ fontSize: 12, color: "red" }}>{
                                    M01PRow?.FIELD37 == "R" ? "Registered" :
                                      M01PRow?.FIELD37 == "U" ? "Unregistered" :
                                        M01PRow?.FIELD37 == "C" ? "Consumer" :
                                          M01PRow?.FIELD37 == "M" ? "Composition" :
                                            null
                                  }</Text> {/*R-Registered/U-Unregitered/C-Consumer/M-Composition*/}
                                </Grid.Col>
                              </Grid.Col>

                              <Grid.Col
                                span={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                }}
                              >
                                <Grid.Col span={3}>
                                  <Text style={{ fontSize: 12 }}>Party A/c:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                  {/* "P_M01~C~TFORM0000001~C~M01.FIELD07 IN ('CS','PR')" */}
                                  {
                                    (obj?.id === "02700249" || obj?.id === "02340213") ? <PLDataGrid
                                      id={"PartyAc"}
                                      setFocus={() => {
                                        PartyAcRef?.current?.handlePopoverToggle()
                                      }}
                                      ref={PartyAcRef}
                                      // width={"500px"}
                                      value={T41Obj?.FIELD04}
                                      // value={{ value: T41Obj?.FIELD04, label: T41Obj?.FIELD04NM }}
                                      // TmData={M01P}
                                      // form={true}
                                      TmData={T41Obj?.FIELD16 == "C" ? "P_M01~C~TFORM0000001~C~M01.FIELD07 IN ('CS')" : "P_M01~C~TFORM0000001~C~M01.FIELD07 IN ('PR')"}
                                      data={T41Obj?.FIELD16 == "C" ? BillFormChallanPartyAcData?.M01PDT?.filter((p) => p?.FIELD07 == "CS") : BillFormChallanPartyAcData?.M01PDT?.filter((p) => p?.FIELD07 == "PR")}
                                      setEdit={(e) => {
                                        console.log("Party Account e BillFromChallan =>", e)
                                        Promise.resolve(setT41Obj({ ...T41Obj, FIELD04: e?.FIELD01, FIELD04NM: e?.FIELD02, FIELD39: e?.FIELD01, FIELD39NM: e?.FIELD02 })).then(() => {
                                          console.log("setM01PRow =>", e);
                                          // BillFormChallanPartyAcData?.M01PDT?.map((d) => {
                                          //   if (d?.FIELD01 == e?.FIELD01) {
                                          setM01PRow(e);
                                          //   }
                                          // })
                                        }).then(() => {
                                          console.log("ExpenseData dispatch then")
                                          if (!M45IDT) {

                                            console.log("ExpenseData dispatch condition")

                                            dispatch(GetExpenseData({ action: "A", code: pagecCode, invCode: TransactionData?.T41?.FIELD13 }))
                                          }
                                        }).then(() => setGSTNoStatus({ ...GSTNoStatus, object: e, status: false })).then(() => {
                                          if (fieldDisable.Delivery_at1 == "Y" &&
                                            fieldDisable.Delivery_at2 == "Y" &&
                                            fieldDisable.Delivery_at3 == "D") {
                                            DeliveryAtRef?.current?.handlePopoverToggle()
                                          } else {
                                            InvTypeRef?.current?.handlePopoverToggle()
                                          }
                                        })
                                      }}
                                    // handleClose={(e)=>//console.log(e)}
                                    /> : <>
                                      {
                                        T41Obj?.FIELD16 == "C" ? <PLDataGrid
                                          id={"PartyAc"}
                                          setFocus={() => {
                                            PartyAcRef?.current?.handlePopoverToggle()
                                          }}
                                          ref={PartyAcRef}
                                          width={"500px"}
                                          // value={T41Obj?.FIELD04}
                                          // cWhere={`M01.FIELD07 LIKE '%CS%'`}
                                          value={{ value: T41Obj?.FIELD04, label: T41Obj?.FIELD04NM }}
                                          TmData={"P_M01~C~TFORM0000001~C~M01.FIELD07 IN ('CS')"}
                                          // form={true}
                                          // data={M01PDT}
                                          setEdit={(e) => {
                                            console.log("Party Account e ==>", e)
                                            Promise.resolve(setT41Obj({ ...T41Obj, FIELD04: e?.FIELD01, FIELD04NM: e?.FIELD02 })).then(() => {
                                              console.log("setM01PRow =>", e);
                                              // M01PDT?.map((d) => {
                                              //   if (d?.FIELD01 == e?.FIELD01) {
                                              setM01PRow(e);
                                              //   }
                                              // })
                                            }).then(() => {
                                              console.log("ExpenseData dispatch then")
                                              if (!M45IDT) {

                                                console.log("ExpenseData dispatch condition")

                                                dispatch(GetExpenseData({ action: "A", code: pagecCode, invCode: TransactionData?.T41?.FIELD13 }))
                                              }
                                            }).then(() => setGSTNoStatus({ ...GSTNoStatus, object: e, status: false })).then(() => {
                                              if (fieldDisable.Delivery_at1 == "Y" &&
                                                fieldDisable.Delivery_at2 == "Y" &&
                                                fieldDisable.Delivery_at3 == "D") {
                                                DeliveryAtRef?.current?.handlePopoverToggle()
                                              } else {
                                                InvTypeRef?.current?.handlePopoverToggle()
                                              }
                                            })
                                          }}
                                        // handleClose={(e)=>//console.log(e)}
                                        /> :
                                          <PLDataGrid
                                            id={"PartyAc"}
                                            setFocus={() => {
                                              PartyAcRef?.current?.handlePopoverToggle()
                                            }}
                                            ref={PartyAcRef}
                                            width={"500px"}
                                            // value={T41Obj?.FIELD04}
                                            value={{ value: T41Obj?.FIELD04, label: T41Obj?.FIELD04NM }}
                                            TmData={"P_M01~C~TFORM0000001~C~M01.FIELD07 IN ('PR')"}
                                            // TmData={M01P}
                                            // form={true}
                                            // data={M01PDT}
                                            setEdit={(e) => {
                                              console.log("Party Account e ==>", e)
                                              Promise.resolve(setT41Obj({ ...T41Obj, FIELD04: e?.FIELD01, FIELD04NM: e?.FIELD02, FIELD39: e?.FIELD01, FIELD39NM: e?.FIELD02 })).then(() => {
                                                console.log("setM01PRow =>", e);
                                                // M01PDT?.map((d) => {
                                                //   if (d?.FIELD01 == e?.FIELD01) {
                                                setM01PRow(e);
                                                //   }
                                                // })
                                              }).then(() => {
                                                console.log("ExpenseData dispatch then")
                                                if (!M45IDT) {

                                                  console.log("ExpenseData dispatch condition")

                                                  dispatch(GetExpenseData({ action: "A", code: pagecCode, invCode: TransactionData?.T41?.FIELD13 }))
                                                }
                                              }).then(() => setGSTNoStatus({ ...GSTNoStatus, object: e, status: false })).then(() => {
                                                if (fieldDisable.Delivery_at1 == "Y" &&
                                                  fieldDisable.Delivery_at2 == "Y" &&
                                                  fieldDisable.Delivery_at3 == "D") {
                                                  DeliveryAtRef?.current?.handlePopoverToggle()
                                                } else {
                                                  InvTypeRef?.current?.handlePopoverToggle()
                                                }
                                              })
                                            }}
                                          // handleClose={(e)=>//console.log(e)}
                                          />
                                      }
                                    </>
                                  }
                                </Grid.Col>

                              </Grid.Col>
                              <Grid.Col
                                span={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                }}
                              >
                                <Grid.Col span={3}></Grid.Col>
                                <Grid.Col span={9}>
                                  {fieldDisable?.CashPartyFormShow == "D" &&
                                    T41Obj?.FIELD16 == "C" ? (<>
                                      <PLTextBox
                                        ref={PartyAc2Ref}
                                        setOnFocus={() => {
                                          console.log("CasePartyRequired?.CasePartyFormName", [{ CasePartyRequired: CasePartyRequired?.CasePartyFormName }]);
                                          // Promise.resolve(CasePartyDetailForm()).then(()=>document.activeElement.blur())
                                          if (CasePartyRequired?.CasePartyFormName == "" && CashPartyPopUpCondition?.PopUpFlag == false) {
                                            CasePartyDetailForm()
                                          }
                                        }}
                                        setText={() => {
                                          console.log("setCashPartyPopUpCondition focus out");
                                          setCashPartyPopUpCondition({ ...CashPartyPopUpCondition, PopUpFlag: false })
                                        }}
                                        value={CasePartyRequired?.CasePartyFormName}
                                        setEdit={(e) => {
                                          dispatch(GetCasePartyRequired({ ...CasePartyRequired, CasePartyFormName: e.target.value }))
                                        }}
                                      />
                                    </>
                                  ) : null}

                                  {fieldDisable?.CashPartyFormShow == "Y" &&
                                    T41Obj?.FIELD16 == "C" && CashPartyPopUpCondition?.PopUp == false ? (
                                    <PLDataGrid
                                      ref={PartyAc2Ref}
                                      // id={"SecondPartyAC"}
                                      // autoFocus
                                      onKeyDown={(e) => {
                                        console.log("onkeydown call DataGrid =>", [{
                                          "e?.key": e?.key
                                        }]);
                                        if (e?.key == "Escape") {
                                          Promise.resolve(setCashPartyPopUpCondition({ ...CashPartyPopUpCondition, PopUp: true })).then(() => setT41Obj({ ...T41Obj, FIELD31: "" }))
                                        }
                                      }}
                                      width={"500px"}
                                      // value={T41Obj?.FIELD04}
                                      value={T41Obj?.FIELD31}
                                      TmData={"P_M01~C~TFORM0000001~C~M01.FIELD07 IN ('PR')"}
                                      // form={true}
                                      // data={M01PDT}
                                      setEdit={(e) => {
                                        console.log("Party Account e ==>", e)
                                        // FIELD39: e?.FIELD01, FIELD39NM:
                                        Promise.resolve(setT41Obj({ ...T41Obj, FIELD31: e?.FIELD01, FIELD39: e?.FIELD01, FIELD39NM: e?.FIELD02 })).then(() => setGSTNoStatus({ ...GSTNoStatus, object: e, status: false }))
                                        // .then(() => document.getElementById("SecondPartyAC").focus())

                                      }}
                                    // handleClose={(e)=>//console.log(e)}
                                    />
                                  ) : null}

                                  {
                                    CashPartyPopUpCondition?.PopUp == true ?
                                      <PLTextBox
                                        setOnFocus={() => {
                                          console.log("CasePartyRequired?.CasePartyFormName", [{ CasePartyRequired: CasePartyRequired?.CasePartyFormName }]);
                                          // Promise.resolve(CasePartyDetailForm()).then(()=>document.activeElement.blur())
                                          if (CasePartyRequired?.CasePartyFormName == "" && CashPartyPopUpCondition?.PopUpFlag == false) {
                                            CasePartyDetailForm()
                                          }
                                        }}
                                        setText={() => {
                                          console.log("setCashPartyPopUpCondition focus out");
                                          if (CasePartyRequired?.CasePartyFormName == "") {
                                            setCashPartyPopUpCondition({ ...CashPartyPopUpCondition, PopUpFlag: false, PopUp: false })
                                          } else {
                                            setCashPartyPopUpCondition({ ...CashPartyPopUpCondition, PopUpFlag: false, PopUp: true })
                                          }
                                        }}
                                        value={CasePartyRequired?.CasePartyFormName}
                                        setEdit={(e) => {
                                          dispatch(GetCasePartyRequired({ ...CasePartyRequired, CasePartyFormName: e.target.value }))
                                        }}
                                      />
                                      :
                                      null
                                  }
                                </Grid.Col>
                              </Grid.Col>

                              <Grid.Col
                                span={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                }}
                              >
                                <Grid.Col span={3}>{/*Blank Space */}</Grid.Col>
                                <Grid.Col span={9}>
                                  <Text style={{ fontSize: 12 }}>
                                    {M01PRow != null ? M01PRow?.FIELD17 : null}
                                  </Text>{" "}
                                  {/*City Name*/}
                                </Grid.Col>
                              </Grid.Col>

                              <Grid.Col
                                span={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                }}
                              >
                                <Grid.Col span={6}>
                                  <Button size="xs" variant="light" onClick={() => HandleHistoryBtn()}>
                                    History
                                  </Button>
                                </Grid.Col>
                                <Grid.Col span={6}>
                                  <Text style={{ fontSize: 12 }}>
                                    Bal :{M01PRow != null ? M01PRow?.FIELD99 : null} DB
                                  </Text>
                                </Grid.Col>
                              </Grid.Col>

                              <Grid.Col
                                span={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                }}
                              >
                                <Grid.Col span={3}>
                                  <Text style={{ fontSize: 12 }}>Delivery At:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                  {fieldDisable.Delivery_at1 == "Y" &&
                                    fieldDisable.Delivery_at2 == "Y" &&
                                    fieldDisable.Delivery_at3 == "D" ? (
                                    <PLDataGrid
                                      ref={DeliveryAtRef}
                                      width={"500px"}
                                      // value={T41Obj?.FIELD39}
                                      value={{ value: T41Obj?.FIELD39, label: T41Obj?.FIELD39NM }}
                                      TmData={M01D}
                                      // form={false}
                                      // data={M01DDT?.jData}
                                      setEdit={(e) => {
                                        // //console.log(e)

                                        Promise.resolve(setT41Obj({ ...T41Obj, FIELD39: e?.FIELD01, FIELD39NM: e?.FIELD02 })).then(() => {
                                          InvTypeRef?.current?.handlePopoverToggle()
                                        })


                                        // Promise.resolve(setT41Obj({ ...T41Obj, FIELD39: e?.FIELD01, FIELD39NM: e?.FIELD02 })).then(() => {
                                        //   console.log("PendingChallanData set Edit", PendingChallanData);
                                        //   if ((obj?.id === "02700249" || obj?.id === "02340213") && PendingChallanData?.dtchln?.length > 0) {
                                        //     PendingChallanFun()
                                        //   }
                                        // })
                                      }}
                                    />
                                  ) : (
                                    <PLTextBox disabled={true} placeholder={"Select"} />
                                  )}
                                </Grid.Col>
                              </Grid.Col>

                              {/* <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                    <Grid.Col span={3}>
                                        <Text style={{ fontSize: 12 }}>Revised Vou.:</Text>
                                    </Grid.Col>
                                    <Grid.Col span={9}>
                                        <PLComboBox />
                                    </Grid.Col>
                                </Grid.Col> */}
                            </Grid.Col>

                            <Grid.Col
                              span={4}
                              style={{ alignItems: "center", padding: 0 }}
                            >
                              <Grid.Col
                                span={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                }}
                              >
                                <Grid.Col span={3}>
                                  <Text style={{ fontSize: 12 }}>Inv Type:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                  {/* M45I = Invoice array */}
                                  {
                                    !M45I ? < PLTextBox disabled={true} placeholder={'Select'} /> : (("02430213,02760249,02280213,02670249,02460213".includes(obj?.page) && OSV?._REQITYPE == "Y") ?
                                      <PLDataGrid
                                        ref={InvTypeRef}
                                        setBlur={() => {
                                          if (obj?.id === "02700249" || obj?.id === "02340213") {
                                            if (PendingChallanData?.dtchln?.length > 0) {
                                              setPendingChallanStatus(true)
                                            } else {
                                              setPendingChallanStatus(false)
                                            }
                                          }
                                        }}
                                        // width={'500px'}
                                        value={T41Obj?.FIELD13}
                                        TmData={M45I}
                                        data={M45IDT}
                                        setEdit={(e) => {
                                          console.log("Inv Type======>", e)

                                          // .then(() => setT02Obj({ ...T02Obj, VorC: e?.FIELD20 }))

                                          Promise.resolve(setT41Obj({ ...T41Obj, FIELD45: e.FIELD20, FIELD13: e?.FIELD01 })).then(() => PortFieldHideShow(e.FIELD20)).then(() => dispatch(GetInvTypeDropDown(e))).then(() => dispatch(GetExpenseData({ action: "A", code: pagecCode, invCode: e.FIELD01 }))).then(() => {
                                            console.log("inv call one");
                                            if (fieldDisable?.lMTaxEnt == true) {
                                              // BillOfSupplyRef?.current?.focus()
                                              document?.getElementById("TaxBillId").focus()
                                            } else {
                                              // PurcSaleRef?.current?.handlePopoverToggle()
                                              PurcSaleRef?.current?.focus()
                                            }
                                          })

                                          // Promise.resolve(M45IDT?.map((d) => {
                                          //   if (d.FIELD01 == e?.FIELD01) {
                                          //     setT41Obj({ ...T41Obj, FIELD45: d.FIELD20, FIELD13: e?.FIELD01 })
                                          //     setT02Obj({...T02Obj,VorC:d?.FIELD20})
                                          //     PortFieldHideShow(d.FIELD20)
                                          //   }
                                          // })).then(() => dispatch(GetInvTypeDropDown(e))).then(() => dispatch(GetExpenseData({ action: "A", code: pagecCode, invCode: e.FIELD01 })))
                                          // DtExpFooterFunction(e.FIELD01)

                                        }}
                                      />
                                      :
                                      <PLDataGrid
                                        ref={InvTypeRef}
                                        setBlur={() => {
                                          if (obj?.id === "02700249" || obj?.id === "02340213") {
                                            if (PendingChallanData?.dtchln?.length > 0) {
                                              setPendingChallanStatus(true)
                                            } else {
                                              setPendingChallanStatus(false)
                                            }
                                          }
                                        }}
                                        // width={'500px'}
                                        value={T41Obj?.FIELD13}
                                        TmData={M45I}
                                        data={M45IDT}
                                        setEdit={(e) => {
                                          console.log("Inv Type======>", e)

                                          // .then(() => setT02Obj({ ...T02Obj, VorC: e?.FIELD20 }))

                                          Promise.resolve(setT41Obj({ ...T41Obj, FIELD45: e.FIELD20, FIELD13: e?.FIELD01 })).then(() => PortFieldHideShow(e.FIELD20)).then(() => dispatch(GetInvTypeDropDown(e))).then(() => dispatch(GetExpenseData({ action: "A", code: pagecCode, invCode: e.FIELD01 }))).then(() => {
                                            console.log("inv call two =>", [{
                                              'fieldDisable?.lMTaxEnt': fieldDisable?.lMTaxEnt
                                            }]);
                                            if (fieldDisable?.lMTaxEnt == true) {
                                              // BillOfSupplyRef?.current?.focus()
                                              document?.getElementById("TaxBillId").focus()
                                            } else {
                                              // PurcSaleRef?.current?.handlePopoverToggle()

                                              PurcSaleRef?.current?.focus()
                                            }
                                          })

                                          // Promise.resolve(M45IDT?.map((d) => {
                                          //   if (d.FIELD01 == e?.FIELD01) {
                                          //     setT41Obj({ ...T41Obj, FIELD45: d.FIELD20, FIELD13: e?.FIELD01 })
                                          //     setT02Obj({...T02Obj,VorC:d?.FIELD20})
                                          //     PortFieldHideShow(d.FIELD20)
                                          //   }
                                          // })).then(() => dispatch(GetInvTypeDropDown(e))).then(() => dispatch(GetExpenseData({ action: "A", code: pagecCode, invCode: e.FIELD01 })))
                                          // DtExpFooterFunction(e.FIELD01)

                                        }}
                                      />)
                                  }
                                </Grid.Col>
                              </Grid.Col>

                              <Grid.Col
                                span={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                }}
                              >
                                <Grid.Col span={3}>
                                  <Text style={{ fontSize: 12 }}>
                                    {
                                      EntryPages?.SaleEntry?.includes(obj?.id) || EntryPages?.SaleEntry?.includes(obj?.page) ?
                                        "Sales A/C:" : "Purc A/C:"
                                    }
                                  </Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                  {fieldDisable?.lMTaxEnt == true ? (
                                    <PLTextBox disabled={true} placeholder={"Select"} />
                                  ) : (
                                    <PLDataGrid
                                      ref={PurcSaleRef}
                                      setFocus={() => {
                                        PurcSaleRef?.current?.handlePopoverToggle()
                                      }}
                                      width={"500px"}
                                      // value={T41Obj?.FIELD05}
                                      value={{ value: T41Obj?.FIELD05, label: T41Obj?.FIELD05NM }}
                                      TmData={ExpenseData?.M01SP}
                                      // data={ExpenseData?.M01SPDT?.jData}
                                      setEdit={(e) => {
                                        //console.log(e)
                                        setT41Obj({ ...T41Obj, FIELD05: e.FIELD01, FIELD05NM: e?.FIELD02 });
                                      }}
                                    />
                                  )}

                                  {/* <PLDataGrid
                                            width={'500px'}
                                            value={T41Obj?.FIELD05}
                                            setEdit={(e) => {
                                                //console.log(e)
                                                setT41Obj({ ...T41Obj, FIELD05: e.FIELD01 })
                                            }}
                                        /> */}
                                </Grid.Col>
                              </Grid.Col>

                              <Grid.Col
                                span={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                }}
                              >
                                <Grid.Col span={3}>
                                  <Text style={{ fontSize: 12 }}>
                                    Tax/Bill of Supply:
                                  </Text>
                                </Grid.Col>
                                <Grid.Col span={5}>
                                  <PLComboBox
                                    // open={true}
                                    // ref={BillOfSupplyRef}
                                    cmbid={"TaxBillId"}
                                    onKeyDown={(e) => {
                                      GlobalClass.formNextInput(e, formRef);
                                    }}
                                    value={T41Obj?.FIELD21}
                                    data={COP75}
                                    dispexpr={"DisplayMember"}
                                    valexpr={"ValueMember"}
                                    setEdit={(e) => {
                                      Promise.resolve(setT41Obj({ ...T41Obj, FIELD21: e })).then(() => setGSTNoStatus({ ...GSTNoStatus, status: true })).then(() => {
                                        document.getElementById("FIELD02").focus()
                                      })
                                    }}
                                  />
                                </Grid.Col>
                                <Grid.Col span={4}>
                                  <Text style={{ fontSize: 12, color: "red" }}>
                                    {
                                      GSTNoStatus?.status == true && T41Obj?.FIELD21 == "T" && GSTNoStatus?.object?.FIELD35 == "" ? "GSTIN No. Not Present" :
                                        GSTNoStatus?.status == true && T41Obj?.FIELD21 == "R" && GSTNoStatus?.object?.FIELD35 != "" ? "GSTIN No. is Present" : ""
                                    }
                                  </Text>
                                </Grid.Col>
                              </Grid.Col>

                              {/* <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                    <Grid.Col span={3}>
                                        <Text style={{ fontSize: 12 }}>Broker:</Text>
                                    </Grid.Col>
                                    <Grid.Col span={9}>
                                        <PLDataGrid
                                            position={"unset"}
                                            width={350}
                                            value={T41Obj?.FIELD35}
                                            TmData={M01B}
                                            data={M01BDT}
                                            setEdit={(e) => {
                                                // //console.log(e)
                                                setT41Obj({ ...T41Obj, FIELD35: e.FIELD01 })
                                            }}
                                        />
                                    </Grid.Col>
                                </Grid.Col> */}

                              {fieldDisable.T41_Field45_Render == true ? (
                                <>
                                  <Grid.Col
                                    span={12}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: 0,
                                    }}
                                  >
                                    <Grid.Col span={3}>
                                      <Text style={{ fontSize: 12 }}>Port:</Text>
                                    </Grid.Col>
                                    <Grid.Col span={9}>
                                      <PLDataGrid
                                        width={"500px"}
                                        // position={"unset"}
                                        // width={500}
                                        value={T41Obj?.FIELD47}
                                        TmData={PORT}
                                        data={PORTDT?.jData}
                                        setEdit={(e) => {
                                          // //console.log(e)
                                          setT41Obj({ ...T41Obj, FIELD47: e.FIELD01 });
                                        }}
                                      />
                                    </Grid.Col>
                                  </Grid.Col>

                                  <Grid.Col
                                    span={12}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: 0,
                                    }}
                                  >
                                    <Grid.Col span={3}>
                                      <Text style={{ fontSize: 12 }}>
                                        Ship Bill Date:
                                      </Text>
                                    </Grid.Col>
                                    <Grid.Col span={9}>
                                      <PLDateBox
                                        // value={T41Obj?.FIELD41}
                                        id={"FIELD41"}
                                        defaultval={T41Obj?.FIELD41?.trim() != "" ? T41Obj?.FIELD41 : GetYearAndDate?.strtDate}
                                        dispformat="DD/MM/YYYY"
                                        setEdit={(e) => {

                                          // //console.log("Bill Date==>", e)
                                          let year = e.toLocaleString("en-IN", {
                                            year: "numeric",
                                          });
                                          let month = e.toLocaleString("en-IN", {
                                            month: "2-digit",
                                          });
                                          let day = e.toLocaleString("en-IN", {
                                            day: "2-digit",
                                          });

                                          let BillDate = year + month + day;
                                          // //console.log("startDate--", BillDate);
                                          if (BillDate?.length == 8) {
                                            setT41Obj({ ...T41Obj, FIELD41: BillDate });
                                          }
                                        }}
                                      />
                                    </Grid.Col>
                                  </Grid.Col>
                                </>
                              ) : null}
                            </Grid.Col>

                            <Grid.Col
                              span={4}
                              style={{ alignItems: "center", padding: 0 }}
                            >
                              <Grid.Col
                                span={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                }}
                              >
                                <Grid.Col span={3}>
                                  <Text style={{ fontSize: 12 }}>{Caption?.FldOne}</Text>
                                  {/* "02340213" == bill from challan */}
                                </Grid.Col>
                                <Grid.Col span={9} style={{ display: "flex", alignItems: "center" }}>
                                  <Grid.Col span={10}>
                                    <PLDateBox

                                      onBlur={(e) => {
                                        if (obj?.id === "02700249" || obj?.id === "02340213") {
                                          if (PendingChallanData?.dtchln?.length > 0) {
                                            setPendingChallanStatus(true)
                                          } else {
                                            setPendingChallanStatus(false)
                                          }
                                        }
                                      }}
                                      // value={new Date(T41Obj?.FIELD02).getTime()}
                                      name={"FIELD02"}
                                      dispformat="DD/MM/YYYY"
                                      // defaultval={DefaultBillDate}
                                      defaultval={T41Obj?.FIELD02?.trim() != "" ? T41Obj?.FIELD02 : GetYearAndDate?.strtDate}
                                      setEdit={(e) => {

                                        // console.log("Date to String==>",`"${new Date().getMonth() <= 9 ? (`0` + new Date().getMonth()) :new Date().getMonth()}"`);
                                        // ${new Date().getFullYear()} ${new Date().getDay()} ${new Date().getMonth()}
                                        // //console.log("Bill Date==>", e)
                                        let year = e.toLocaleString("en-IN", {
                                          year: "numeric",
                                        });
                                        let month = e.toLocaleString("en-IN", {
                                          month: "2-digit",
                                        });
                                        let day = e.toLocaleString("en-IN", {
                                          day: "2-digit",
                                        });

                                        let BillDate = year + month + day;
                                        // //console.log("startDate--", BillDate);
                                        if (BillDate.length == 8) {
                                          setT41Obj({ ...T41Obj, FIELD02: BillDate, FIELD11: BillDate })
                                        }

                                        // setSelectedDay(e)
                                        setSelectedDay(new Date(parseInt(year), parseInt(month) - 1, parseInt(day)))
                                        // new Date().toLocaleString("en-IN",{weekday:'short'});
                                      }}
                                    />
                                  </Grid.Col>

                                  <Grid.Col span={2}>
                                    <Text size={12} style={{ color: "red" }}>
                                      {selectedDay.toLocaleDateString("en-IN", { weekday: 'short' })}
                                    </Text>
                                  </Grid.Col>
                                </Grid.Col>
                              </Grid.Col>
                              <Grid.Col
                                span={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                }}
                              >
                                <Grid.Col span={3}>
                                  <Text style={{ fontSize: 12 }}>{Caption?.FldTwo}</Text>

                                </Grid.Col>
                                <Grid.Col span={9}>
                                  {/* EntryPages */}
                                  {
                                    obj?.page == "02530249" || obj?.p0 == "E" ? <PLTextBox
                                      // disabled={true}
                                      disabled={obj?.page == "02530249" ? (fieldDisable?.VoucherNumber == "Y" ? false : true) : false}
                                      setText={(e) => {
                                        if (e?.target?.value?.length > 0) {
                                          if (accList[obj?.page] && accList[obj?.page].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                            console.log("Duplicate Value log call");
                                            GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                            setBillNoStatus(true)
                                          } else {
                                            setBillNoStatus(false)
                                          }
                                        } else {
                                          setBillNoStatus(false)
                                        }
                                      }}
                                      // value={BillSeriesHideShow?.BillNoVal}
                                      ref={BillNoRef}
                                      value={T41Obj?.FIELD12}
                                      setEdit={(e) => {
                                        console.log("setEdit=>", e);
                                        setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                        console.log("EntryPages?.PurchaseEntry=>", [{
                                          'EntryPages?.PurchaseEntry': EntryPages?.PurchaseEntry?.includes(obj?.page),
                                          'obj?.id': obj?.id,
                                          'obj?.page': obj?.page
                                        }]);
                                        // if(EntryPages?.PurchaseEntry?.includes(obj?.page) || EntryPages?.PurchaseEntry?.includes(obj?.id)){
                                        //   setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value, FIELD10: e.target.value })
                                        // }else{
                                        setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                        // if(fieldDisable.Doc_No == "Y"){
                                        //   document.getElementById("FIELD10").focus()
                                        // }else{
                                        //   document.getElementById("FIELD11").focus()
                                        // }
                                        // }
                                      }}
                                    /> : <>
                                      {
                                        T92 ? <>
                                          {
                                            BillSeriesHideShow?.DataGridInput == true && <>
                                              {
                                                !T41Obj?.T92F00 ?
                                                  <PLDataGrid
                                                    ref={BillNoRef}
                                                    // width={"400px"}
                                                    position={"unset"}
                                                    valexpr={"FIELD00"}
                                                    // width={350} 
                                                    value={BillSeriesHideShow?.BillNoVal}
                                                    TmData={T92}
                                                    // data={T92DT}
                                                    setEdit={(e) => {
                                                      console.log("Bill No.=>", e)
                                                      //"FIELD00"
                                                      Promise.resolve(setT41Obj({ ...T41Obj, T92F00: e.FIELD00, FIELD12: "" })).then(() => {
                                                        if (e) {
                                                          var letr = e.FIELD02
                                                          console.log("num=>", num);
                                                          var num = parseInt(e.FIELD03) + 1
                                                          setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: false, TextInput: true, BillNoVal: letr + num })
                                                        }
                                                      }).then(() => {
                                                        if (fieldDisable.Doc_No == "Y") {
                                                          document.getElementById("FIELD10").focus()
                                                        } else {
                                                          document.getElementById("FIELD11").focus()
                                                        }
                                                      })

                                                    }}
                                                  /> : <PLTextBox
                                                    ref={BillNoRef}
                                                    value={T41Obj?.FIELD12}
                                                    // value={T41Obj?.T92F00}
                                                    setText={(e) => {
                                                      // console.log("bill number setText 1", [{
                                                      //   "e": e,
                                                      //   "value": e?.target?.value
                                                      // }]);
                                                      if (e?.target?.value?.length > 0) {
                                                        if (accList[obj?.page] && accList[obj?.page].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                          console.log("Duplicate Value log call");
                                                          GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                          setBillNoStatus(true)
                                                        } else {
                                                          setBillNoStatus(false)
                                                        }
                                                      } else {
                                                        setBillNoStatus(false)
                                                      }
                                                    }}
                                                    setEdit={(e) => {
                                                      console.log("setEdit=>", e);
                                                      // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                                      // setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                                      // if(EntryPages?.PurchaseEntry?.includes(obj?.page) || EntryPages?.PurchaseEntry?.includes(obj?.id)){
                                                      //   setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value, FIELD10: e.target.value })
                                                      // }else{
                                                      setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                                      // }
                                                    }}
                                                  />
                                              }
                                            </>
                                          }

                                          {BillSeriesHideShow?.TextInput == true && <PLTextBox
                                            setText={(e) => {
                                              // console.log("bill number setText 2", [{
                                              //   "e": e,
                                              //   "value": e?.target?.value
                                              // }]);
                                              if (e?.target?.value?.length > 0) {
                                                if (accList[obj?.page] && accList[obj?.page].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                  console.log("Duplicate Value log call");
                                                  GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                  setBillNoStatus(true)
                                                } else {
                                                  setBillNoStatus(false)
                                                }
                                              } else {
                                                setBillNoStatus(false)
                                              }
                                              if (BillSeriesHideShow.BillNoVal.length <= 0) {
                                                setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
                                              }
                                            }}
                                            value={BillSeriesHideShow?.BillNoVal}
                                            // value={T41Obj?.T92F00}
                                            setEdit={(e) => {
                                              console.log("setEdit=>", e);
                                              setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                              // setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                              // if(EntryPages?.PurchaseEntry?.includes(obj?.page) || EntryPages?.PurchaseEntry?.includes(obj?.id)){
                                              //   setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value, FIELD10: e.target.value })
                                              // }else{
                                              setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                              // }
                                            }}
                                          />
                                          }
                                        </> : <PLTextBox
                                          setText={(e) => {
                                            // console.log("bill number setText 3", [{
                                            //   "e": e,
                                            //   "value": e?.target?.value
                                            // }]);
                                            if (e?.target?.value?.length > 0) {
                                              if (accList[obj?.page] && accList[obj?.page].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                console.log("Duplicate Value log call");
                                                GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                setBillNoStatus(true)
                                              } else {
                                                setBillNoStatus(false)
                                              }
                                            } else {
                                              setBillNoStatus(false)
                                            }
                                          }}
                                          value={BillSeriesHideShow?.BillNoVal}
                                          // value={T41Obj?.T92F00}
                                          setEdit={(e) => {
                                            console.log("setEdit=>", e);
                                            setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                            // setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e?.target?.value })
                                            // if(EntryPages?.PurchaseEntry?.includes(obj?.page) || EntryPages?.PurchaseEntry?.includes(obj?.id)){
                                            //   setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value, FIELD10: e.target.value })
                                            // }else{
                                            setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                            // }
                                          }}
                                        />

                                      }
                                    </>
                                  }
                                </Grid.Col>



                              </Grid.Col>
                              <Grid.Col
                                span={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                }}
                              >
                                <Grid.Col span={3}>
                                  <Text style={{ fontSize: 12 }}>{Caption?.FldThree}</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                  {fieldDisable.Doc_No == "Y" ? (
                                    <PLTextBox
                                      id={"FIELD10"}
                                      // width={"500px"}
                                      value={T41Obj?.FIELD10}
                                      setEdit={(e) => {
                                        // //console.log(e)
                                        setT41Obj({ ...T41Obj, FIELD10: e?.target?.value });
                                      }}
                                    />
                                  ) : (
                                    <PLTextBox disabled={true} />
                                  )}
                                </Grid.Col>
                              </Grid.Col>
                              <Grid.Col
                                span={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                }}
                              >
                                <Grid.Col span={3}>
                                  <Text style={{ fontSize: 12 }}>{Caption?.FldFour}</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                  {/* {
                                            fieldDisable.Doc_No == "Y" ?  */}
                                  <PLDateBox
                                    name={"FIELD11"}
                                    // disabled={fieldDisable?.Doc_No == "Y" ? false : true}
                                    defaultval={T41Obj?.FIELD11?.trim() != "" ? T41Obj?.FIELD11 : GetYearAndDate?.strtDate}
                                    // defaultval={T41Obj?.FIELD11}
                                    dispformat="DD/MM/YYYY"
                                    setEdit={(e) => {
                                      // //console.log(e)

                                      let year = e.toLocaleString("en-IN", {
                                        year: "numeric",
                                      });
                                      let month = e.toLocaleString("en-IN", {
                                        month: "2-digit",
                                      });
                                      let day = e.toLocaleString("en-IN", {
                                        day: "2-digit",
                                      });

                                      let BillDate = year + month + day;
                                      if (BillDate?.length == 8) {
                                        setT41Obj({ ...T41Obj, FIELD11: BillDate });
                                      }
                                    }}
                                  />
                                </Grid.Col>
                              </Grid.Col>

                              {fieldDisable.T41_Field45_Render == true ? (
                                <Grid.Col
                                  span={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: 0,
                                  }}
                                >
                                  <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>Ship Bill No. :</Text>
                                  </Grid.Col>
                                  <Grid.Col span={9}>
                                    <PLNumberBox
                                      textAlign
                                      value={T41Obj?.FIELD42}
                                      setEdit={(e) => {
                                        // //console.log(e)
                                        setT41Obj({ ...T41Obj, FIELD42: e });
                                      }}
                                    />
                                  </Grid.Col>
                                </Grid.Col>
                              ) : null}

                              {fieldDisable.Applicable == "Y" ? (
                                <Grid.Col
                                  span={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: 0,
                                  }}
                                >
                                  <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>Applicable % ?:</Text>
                                  </Grid.Col>
                                  <Grid.Col span={9}>
                                    <PLComboBox
                                      onKeyDown={(e) => {
                                        GlobalClass.formNextInput(e, formRef);
                                      }}
                                      value={T41Obj?.FIELD53}
                                      copno={1}
                                      dispexpr={"DisplayMember"}
                                      valexpr={"ValueMember"}
                                      setEdit={(e) => {
                                        // //console.log("Applicable % ?=========>", e.value)
                                        setT41Obj({ ...T41Obj, FIELD53: e.value });
                                      }}
                                    />
                                  </Grid.Col>
                                </Grid.Col>
                              ) : null}
                            </Grid.Col>
                          </Grid.Col>
                        </Grid>
                        {
                          fieldDisable?.UserFieldHideShow == "Y" &&
                          <>
                            <Divider
                              my="xs"
                              label="User Fields"
                              labelPosition="center"
                              labelProps={{ component: "strong" }}
                            />
                            {/* Header User Field  */}
                            <Spoiler
                              // initialState={userFieldSpoiler}
                              // initialState={true}
                              maxHeight={80}
                              showLabel="Show more"
                              hideLabel="Hide"
                              controlRef={spoilerControlRef}
                            >
                              <Grid gutter={4} onKeyDown={(e) => {
                                GlobalClass.formNextInput(e, formRef);
                              }}>{UFT41HRenderField("Header")}</Grid>
                            </Spoiler>
                          </>
                        }

                        <Divider
                          my="xs"
                          label="Products"
                          labelPosition="center"
                          labelProps={{ component: "strong" }}
                        />
                        <Grid gutter={4}>
                          <Grid.Col span={12} style={{ padding: 0 }}>
                            {columns && <MantineReactTable table={table} />}
                          </Grid.Col>

                          <Grid.Col span={12} display={"flex"}>
                            <Grid.Col span={4}>
                              <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                                {
                                  table?.getSelectedRowModel()?.flatRows.length > 0 ? RowDetailHandle(table?.getSelectedRowModel()?.flatRows[0]?.original) : null
                                }
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={4}>
                              <Group spacing={"xs"} position="center">
                                <Button size="xs" variant="light" compact onClick={() => {
                                  if (table?.getSelectedRowModel()?.flatRows.length > 0) {
                                    setT02Data([...T02Data, table?.getSelectedRowModel()?.flatRows[0]?.original])
                                    setHeaderExpenseFlag(true)
                                  }
                                }}>
                                  Copy
                                </Button>
                                {/* <Button size='xs' variant='light' compact onClick={() => CasePartyDetailForm()}>
                                                Case Party Detail
                                            </Button> */}
                              </Group>
                            </Grid.Col>
                            <Grid.Col span={4}>

                            </Grid.Col>
                          </Grid.Col>
                        </Grid>
                        {/* <Divider my="xs" /> */}

                        <Grid gutter={4}>
                          <Grid.Col span={12} style={{ display: "flex", padding: "0px" }}>
                            <Grid.Col span={4}></Grid.Col>
                            <Grid.Col span={4}>
                              <Group spacing={"xs"} position="left">
                                {
                                  PendingQuotationData?.dtQuo && PendingQuotationData?.dtQuo?.length > 0 ?
                                    <Button
                                      size="xs"
                                      variant="light"
                                      compact
                                      onClick={() => PendingQuotationFun()}
                                    >
                                      Quotation
                                    </Button> : null
                                }
                                {
                                  PendingOrderData?.dtOrd && PendingOrderData?.dtOrd?.length > 0 ?
                                    <Button
                                      size="xs"
                                      variant="light"
                                      compact
                                      onClick={() => PendingOrderFun()}
                                    >
                                      Order
                                    </Button> : null
                                }

                              </Group>
                              {/* {
                          PendingQuotationData?.dtQuo && PendingQuotationData?.dtQuo?.length > 0 ?
                            <Button
                              size="xs"
                              variant="light"
                              compact
                              onClick={() => PendingQuotationFun()}
                            >
                              Quotation
                            </Button> : null
                        } */}
                            </Grid.Col>
                            <Grid.Col span={4}></Grid.Col>
                          </Grid.Col>
                          <Grid.Col span={12} style={{ display: "flex" }}>
                            <Grid.Col
                              span={6}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: 0,
                              }}
                            >
                              {/* <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                                NOS-NUMBERS 000000.0000 Qty-2
                              </Text> */}
                            </Grid.Col>
                            <Grid.Col
                              span={6}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: 0,
                              }}
                            >
                              <Grid.Col
                                span={6}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                                  Item Amount :
                                </Text>
                              </Grid.Col>
                              <Grid.Col
                                span={6}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                                  {T41Obj?.FIELD07} {OS11 ? (OS11["S11F21"] == "D" ? "CR" : "DB") : ""}
                                </Text>
                              </Grid.Col>
                            </Grid.Col>
                          </Grid.Col>

                          {/* Footer User Field */}
                          <Grid.Col span={6} onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}>
                            {
                              fieldDisable?.UserFieldHideShow == "Y" &&
                              UFT41HRenderField("Footer")

                            }
                          </Grid.Col>

                          <Grid.Col span={6}>

                            <Grid.Col span={12} style={{ border: "1px solid gray", height: "200px", overflow: "hidden", overflowY: "scroll" }}>

                              {
                                (obj.p0 == "E" && isLoadingExpenseData) ? GlobalClass.RenderLoader('skeleton', 6, true) : <>
                                  {/* Footer Expense */}
                                  {ExpenseData?.dtExp && <>
                                    {
                                      console.log("FooterExpenseT41Obj=>", T41Obj)
                                    }
                                    <ExpenseEntryForm
                                      ExpenseObj={ExpenseObj}
                                      ProductObj={selectedProductObj}
                                      isExpense={false}
                                      // expenseArr={ExpenseData?.dtExp}
                                      expenseArr={ExpenseArrayT41 && ExpenseArrayT41?.length > 0 ? ExpenseArrayT41 : ExpenseData?.dtExp}
                                      expenseArrDef={ExpenseData?.dtExpDef}
                                      M01PData={ExpenseData?.M01SPDT?.jData}
                                      M01Format={ExpenseData?.M01SP}
                                      valueSaveObj={T41Obj}
                                      // TableExpenseValue={T41F07RowSum}
                                      TableExpenseValue={T41Obj?.FIELD07}
                                      TableExpenseArr={T02Data}
                                      SendselectedTaxAcc={selectedTaxAcc}
                                      onChange={(e) => {
                                        new Promise(() => {
                                          setT41Obj(e?.ObjT41)
                                          setT02Data(e?.ArrT02)
                                        }).then(() => setTimeout(() => {
                                          setFooterExpenseFlag(!FooterExpenseFlag)
                                        }, 200))

                                        console.log("FooterTableExpenseEntryT41Obj =>", e);
                                        // Promise.resolve(setT41Obj(e?.FieldObj)).then(()=>{
                                        //   setTimeout(()=>{
                                        //     setT02Data(e.T02UpdateArray)
                                        //   },200)
                                        // })

                                      }}
                                      DrawerOBJ={DrawerObj}
                                      setDrawerOBJ={(e) => setDrawerObj(e)}
                                    // setT02Data={(e) =>{
                                    //   console.log("FooterTableExpenseEntryT41Obj T02=>", e);
                                    //    setT02Data(e)
                                    //   }}
                                    />
                                  </>
                                  }
                                </>
                              }


                            </Grid.Col>

                            {/* <Grid.Col span={12}>
                                            <Group spacing={'xs'} position='center'>
                                                <Button size='sm' variant='light' compact onClick={()=>RefreshExpense()}>
                                                    Refresh
                                                </Button>
                                            </Group>
                                        </Grid.Col> */}
                          </Grid.Col>
                        </Grid>

                        <Grid gutter={4}>
                          <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col
                              span={4}
                              style={{ alignItems: "center", padding: 0 }}
                            >
                              <Grid.Col span={10} p={0}>
                                <Text style={{ fontSize: 12 }}>Narration:</Text>
                              </Grid.Col>
                              <Grid.Col span={12} p={0} display={"flex"}>
                                <Grid.Col span={9} p={0}>
                                  <PLTextarea
                                    minRows={2}
                                    value={T41Obj?.FIELD15}
                                    onChange={(e) => {
                                      // //console.log(e)
                                      setT41Obj({ ...T41Obj, FIELD15: e.target.value });
                                    }}
                                    onKeyDown={(e) => {
                                      // console.log("Narration=>", e);
                                      if (e.ctrlKey && e.key === "F1") {
                                        //console.log("Narration call");

                                        setDrawerObj({
                                          ...dra, title: <Text fw={700}>Naration</Text>, body: <>
                                            <PLNarration

                                              NarrationArray={NarrationData}
                                              Flag={SelectedNarrationData?.length > 0 ? false : true}
                                              selectedNarrationArray={
                                                SelectedNarrationData
                                              }
                                              // NarrationVal={T41Obj}
                                              setNarrationArray={(e) => {
                                                // setT41Obj(e)
                                                console.log("setNarrationArray", e);
                                                setNarrationData(e.NarrationData);
                                                setSelectedNarrationData(
                                                  e.selectedNarration
                                                );
                                              }}
                                              DrawerOBJ={DrawerObj}
                                              setDrawerOBJ={(e) => setDrawerObj(e)}
                                            />
                                          </>, open: true, size: "50%", position: "right"
                                        })
                                      }
                                    }}
                                  />
                                </Grid.Col>
                                <Grid.Col span={3} p={0} style={{ display: "flex" }}>
                                  {/* <Grid.Col span={6} p={0}>
                                            <Button size='xs' variant='light' compact>Scheme Off</Button>
                                        </Grid.Col> */}
                                  <Grid.Col span={6} p={0}>

                                    {OSV?._AUTOBTOB == "Y" ? null : <>
                                      {
                                        T05ENT && <Button
                                          hidden
                                          size="sm"
                                          variant="light"
                                          compact
                                          onFocusCapture={() => {
                                            console.log("Bill to Bill call")
                                            BillToBillTableFun();

                                          }}
                                          onClick={() => BillToBillTableFun()}
                                        >
                                          Bill to Bill
                                        </Button>
                                      }
                                    </>
                                    }
                                  </Grid.Col>
                                </Grid.Col>
                              </Grid.Col>
                            </Grid.Col>

                            <Grid.Col
                              span={4}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                padding: 0,
                              }}
                            >
                              <Group spacing={"xs"} position="center">
                                <Button
                                  // disabled={T02Data.length > 2 ? false : true}
                                  style={{ height: "30px", width: "100px" }}
                                  size="sm"
                                  id="okbutton"
                                  // onClick={() => T02Data.length <= 1 ? SalesInvoiceSubmit() : GlobalClass.Notify("warning", "Warning", "Minimum 2 Item or Account Entry Present")}
                                  // onClick={() => SalesInvoiceSubmit()}
                                  onClick={() => BillNoStatus ? GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present") : T02Data?.length < 1 ? GlobalClass.Notify("warning", "Warning", "Minimum 1 Item or Account Entry Present") : SalesInvoiceSubmit()}
                                >
                                  Ok
                                </Button>
                                <Button style={{ height: "30px", width: "100px" }} size="sm">Print</Button>
                              </Group>
                            </Grid.Col>

                            {/* <Grid.Col span={4} style={{ display: "flex", alignItems: 'center',justifyContent:"end", padding: 0 }}> */}
                            <Grid.Col span={4} style={{ display: "flex", alignItems: 'center', justifyContent: "end", padding: 0 }}>
                              <Grid.Col span={6} style={{ display: "flex", flexDirection: "row" }}>
                                <Text style={{ fontSize: 12, fontWeight: "bold", padding: 0 }}>Bill Amount:</Text>
                              </Grid.Col>
                              <Grid.Col span={6} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                {/* <Text style={{ fontSize: 12, fontWeight: "bold", padding: 0 }}>{T02Data.reduce((a,b)=>a=a+b?.FIELD90,0)} DB</Text> */}
                                <Text style={{ fontSize: 12, fontWeight: "bold", padding: 0 }}>{T41Obj?.FIELD06 == 0 ? T41Obj?.FIELD07 : T41Obj?.FIELD06} {OS11 ? (OS11["S11F21"] == "C" ? "CR" : "DB") : ""}</Text>
                              </Grid.Col>
                            </Grid.Col>
                          </Grid.Col>
                        </Grid>
                      </Paper>
                      {
                        lEditVou == false && GlobalClass.Alert('info', props.index)
                      }
                    </div>
                }
              </>
            }
          </>
      }
    </>}
  </>)
}

export default TransactionSalesForm
