import { Alert, Button, Divider, Grid, Group, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react'
import PLTextBox from './PLTextBox';
import PLNumberBox from './PLNumberBox';
import PLDataGrid from './PLDataGrid';
import PLComboBox from './PLComboBox';
import PLDateBox from './PLDateBox';
import GlobalClass from '../utils/GlobalClass';
import { useSelector } from 'react-redux';
import { IconInfoCircle } from '@tabler/icons-react';

const PLUserFieldBtn = (props) => {
    const { UserFieldArray, T41, onChange } = props;

    const [T41Obj, setT41Obj] = useState(T41)

    const GetYearAndDate = useSelector((state) => state?.UserList?.YearAndDateSend)

    useEffect(() => {
        if (T41) {
            console.log("PLUserFieldBtn T41 =>", T41);
            setT41Obj(T41)
        }
    }, [T41])


    const seperate = (e) => {
        var paraArr = e.split("~C~");
        // console.log("paraArr=>", paraArr);
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr;
    };

    const HandleUserFieldPopUp = (arr, e) => {
        console.log("HandleUserFieldPopUp =>", [{
            arr: arr,
            e: e,
            "H filter": arr?.filter((af) => af?.S13HMF == "H"),
            "F filter": arr?.filter((af) => af?.S13HMF == "F")
        }]);
        return arr?.filter((af) => af?.S13HMF == e)?.map((f) => {
            switch (f?.S13F14) {
                case "TEXTBOX":
                    return <Grid.Col
                        span={6}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <PLTextBox
                                nmaxlength={f?.S13F04}
                                value={T41Obj?.[f?.S13F02]}
                                setEdit={(e) => {
                                    // //console.log(e)
                                    setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>

                    break;
                case "NUMBOX":
                    return <Grid.Col
                        span={6}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <PLNumberBox
                                nmaxlength={f?.S13F04}
                                value={T41Obj?.[f?.S13F02]}
                                setEdit={(e) => {
                                    //console.log(e)
                                    setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>

                    break;
                case "PLPOPUP":
                    return <Grid.Col
                        span={6}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <PLDataGrid
                                width={"500px"}
                                name={f.S13F02}
                                value={T41Obj?.[f?.S13F02]}
                                p0={seperate(f.S13F09)[0]}
                                p1={seperate(f.S13F09)[1]}
                                setEdit={(e) => {
                                    //console.log(e);
                                    setT41Obj({ ...T41Obj, [f?.S13F02]: e?.FIELD01 });
                                    // M01handlechange({ id: item.S13F02, value: e })
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>

                    break;
                case "COMBOBOX":
                    return <Grid.Col
                        span={6}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <PLComboBox
                                // onKeyDown={(e) => {
                                //     GlobalClass.formNextInput(e, formRef);
                                // }}
                                value={T41Obj?.[f?.S13F02]}
                                copno={seperate(f.S13F09)[1]}
                                dispexpr={"DisplayMember"}
                                valexpr={"ValueMember"}
                                setEdit={(e) => {
                                    //console.log(e)
                                    setT41Obj({ ...T41Obj, [f?.S13F02]: e.value });
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>

                    break;
                case "DATEPICKER":
                    return <Grid.Col
                        span={6}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <PLDateBox
                                // value={T41Obj?.[f?.S13F02].trim() == "" ? new Date() : T41Obj?.[f?.S13F02]}
                                // defaultval={T41Obj?.[f?.S13F02]}
                                defaultval={T41Obj?.[f?.S13F02]?.trim() != "" ? T41Obj?.[f?.S13F02] : GetYearAndDate?.strtDate}
                                dispformat="DD/MM/YYYY"
                                setEdit={(e) => {
                                    console.log(e)

                                    let year = e.toLocaleString("en-IN", {
                                        year: "numeric",
                                    });
                                    let month = e.toLocaleString("en-IN", {
                                        month: "2-digit",
                                    });
                                    let day = e.toLocaleString("en-IN", {
                                        day: "2-digit",
                                    });

                                    let StringDate = year + month + day;
                                    if (StringDate?.length == 8) {
                                        setT41Obj({ ...T41Obj, [f?.S13F02]: StringDate });
                                    }
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>

                    break;
                case "TIMEPICKER":
                    return <Grid.Col
                        span={6}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <PLDateBox
                                // value={T41Obj?.[f?.S13F02].trim() == "" ? new Date() : T41Obj?.[f?.S13F02]}
                                // defaultval={T41Obj?.[f?.S13F02]}
                                setEdit={(e) => {
                                    console.log(e)
                                    setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>

                    break;
                case "MEMOBOX":
                    if (f.S13F21 === "N") {
                        return <Grid.Col
                            span={6}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: 0,
                            }}
                        >
                            <Grid.Col span={4}>
                                <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                            </Grid.Col>
                            <Grid.Col span={8}>
                                <PLTextBox
                                    value={T41Obj?.[f?.S13F02]}
                                    setEdit={(e) => {
                                        //console.log(e)
                                        setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>

                    } else {
                        // h.S13F21 === "H"
                        return <Grid.Col
                            span={6}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: 0,
                            }}
                        >
                            <Grid.Col span={4}>
                                <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                            </Grid.Col>
                            <Grid.Col span={8}>
                                <PLTextBox
                                    value={T41Obj?.[f?.S13F02]}
                                    setEdit={(e) => {
                                        //console.log(e)
                                        setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>

                    }
                    break;
                case "FILEUPLOAD":
                    if (f.S13F21 === "I") {
                        return <Grid.Col
                            span={6}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: 0,
                            }}
                        >
                            <Grid.Col span={4}>
                                <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                            </Grid.Col>
                            <Grid.Col span={8}>
                                <PLTextBox
                                    value={T41Obj?.[f?.S13F02]}
                                    setEdit={(e) => {
                                        //console.log(e)
                                        setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>

                    } else {
                        // h.S13F21 === "A"
                        return <Grid.Col
                            span={6}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: 0,
                            }}
                        >
                            <Grid.Col span={4}>
                                <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                            </Grid.Col>
                            <Grid.Col span={8}>
                                <PLTextBox
                                    value={T41Obj?.[f?.S13F02]}
                                    setEdit={(e) => {
                                        //console.log(e)
                                        setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>

                    }
                    break;

                default:
                    break;
            }
            // return inputBox

        })
    }


    return (<>
        {
            UserFieldArray && UserFieldArray.length > 0 ?
                <Grid gutter={4}>
                    <Grid.Col span={12}>
                        <Divider
                            my="xs"
                            label="Header"
                            labelPosition="left"
                            labelProps={{ component: "strong" }}
                        />
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", flexWrap: "wrap" }}>
                        {
                            HandleUserFieldPopUp(UserFieldArray, "H")
                        }
                    </Grid.Col>
                    <Grid.Col span={12}>
                        {
                            UserFieldArray?.filter((d) => d?.S13HMF == "F")?.length > 0 &&
                            <Divider
                                my="xs"
                                label="Footer"
                                labelPosition="left"
                                labelProps={{ component: "strong" }}
                            />
                        }
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", flexWrap: "wrap" }}>
                        {
                            HandleUserFieldPopUp(UserFieldArray, "F")
                        }
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Group position="center">
                            <Button
                                // disabled={T02Data.length > 2 ? false : true}
                                style={{ height: "30px", width: "100px" }}
                                size="sm"
                                id="okbutton"
                                onClick={() => onChange(T41Obj)}
                            >
                                Ok
                            </Button>
                        </Group>
                    </Grid.Col>
                </Grid> : <Alert variant="light" color="blue" title="Not Available" icon={<IconInfoCircle />}>
                    User Field Not Available!!!
                </Alert>
        }
    </>)
}

export default PLUserFieldBtn