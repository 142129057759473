import React from 'react'
import Store from '../../utils/store'
import { ModalFunction } from '../../utils/slices/ModalSlice'
import JobWorkOpeningForm from './JobWorkOpeningForm'
import { Text } from '@mantine/core'
import GlobalClass from '../../utils/GlobalClass'
import { TransactionDeleteApi } from '../../utils/TransactionSlices/Transaction'


export function VouEntAct(data) {
  const OBJ = JSON.parse(data)
  // console.log('obj VouEntAct=>>', OBJ)
  if (OBJ.p0 == 'A') {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "98%",
        position: "right",
        MBody: (i) => (
          <JobWorkOpeningForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 == 'E' && OBJ?.id) {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "98%",
        position: "right",
        MBody: (i) => (
          <JobWorkOpeningForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 == 'D' && OBJ.id) {
    if (window.confirm('Confirm to Delete ?')) {
      Store.dispatch(TransactionDeleteApi(OBJ))
    }
  } else if (OBJ.p0 === 'U' && OBJ?.id) { // UserField
    Store.dispatch(ModalFunction({
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: "50%",
      // position:'right',
      MBody: (i) => (<JobWorkOpeningForm obj={OBJ} index={i} />),
      MClose: true,
      onclose: () => { }
    }))
  } else {
    return null
  }
}