import { createSlice } from "@reduxjs/toolkit";


const ModalSlice = createSlice({
    name: 'ModalSlice',
    initialState: {
        ModalAttr: [{
            MTitle: '',
            MAction: false,
            MSize: 'md',
            MBody: (e) => (e),
            MClose: false,
            onclose: (e) => (e),
            Overlay: null,
            Style: null,
            fullScreen:false,
            closeOnClickOutside: false,
            MCentered: true,
            onClickCloseButton: (e)=>(e),
            position:"bottom",
            Styles:null,
        }],
        // ModalBoxArray:[]

    },
    reducers: {
        ModalFunction: (state, action) => {
            // console.log("UpdateSelectedMenu=>",action.payload);
            // state.ModalAttr = action.payload
            state.ModalAttr = [...state.ModalAttr, action.payload]
            // console.log("state.ModalAttr===>", state.ModalAttr);
            // state.ModalAttr = {...state.ModalAttr,[action.payload[0]]:[action.payload[1]]}

        },
        ModalDelete: (state, action) => {
            console.log("ModalDelete action.payload", action.payload);

            let NewModalArray = state.ModalAttr.filter((m, i)=>i !== action.payload)
            state.ModalAttr = NewModalArray
            // console.log("ModalDelete state.ModalAttr", state.ModalAttr);

        }

    }
})

export const { ModalFunction, ModalDelete } = ModalSlice.actions;
export default ModalSlice.reducer;