import React from 'react'
import UserLevelForm from './UserLevelForm';
import { Text } from '@mantine/core';
import { ModalFunction } from '../../../utils/slices/ModalSlice';
import Store from '../../../utils/store';

export function M81GAED(data, Skey) {
    const OBJ = JSON.parse(data);
    console.log("M81GAED", OBJ);
  
    if (OBJ.p0 === "A") {
      Store.dispatch(
        ModalFunction({
          onclose: (e) => {},
          MTitle: <Text fw={700}>{OBJ?.text}</Text>,
          MAction: true,
          MSize: "lg",
          position: "right",
          MBody: (i) => <UserLevelForm OBJ={OBJ} index={i} />,
          MClose: true,
          Overlay: {
            backgroundOpacity: 0.8,
            blur: 5,
          },
        })
      );
    }  
}

export default M81GAED